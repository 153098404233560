import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import { withRouter } from '../../utils/withRouter';

import Drawer from '@mui/material/Drawer';
import filterResults from '../../utils/filterResults';

import CardWrapper from '../common/CardWrapper';
import {
  updateAttachedSpace,
  updateAttachedSpaceOnPresentation,
} from '../../store/actions/spaceActions';
import SpacesTable from './SpacesTable';
import ActionMenu from '../common/ActionMenu';
import SpaceDetailView from '../../containers/SpaceDetailView';
import GMap from './GMap';
import { ModalDialog, ModalFormDialog } from '../../utils/modalHelpers';

import exportSpaces from '../../utils/dataExport';

const inlineStyles = {
  drawerPaper: {
    position: 'absolute',
    border: '0px',
    width: '100%',
    display: 'block',
  },
  docked: {
    flexBasis: '66.6%',
    position: 'relative',
  },
};

let Presentation = ({
  navigate,
  templates,
  pid,
  attachedSpaces,
  updateAttachedSpaceOnPresentation,
  updateAttachedSpace,
  isAdmin,
  sid,
  projects,
  file,
  t,
  i18n,
  attr,
}) => {
  const [targetSpace, setTargetSpace] = useState('');
  const [spaceDetailsOpen, setSpaceDetailsOpen] = useState(false);
  const [searchTermsText, setSearchTermsText] = useState({});
  const [searchTermsDistance, setSearchTermsDistance] = useState({});
  const [
    cancelRejectionSpaceDialogOpen,
    setCancelRejectionSpaceDialogOpen,
  ] = useState(false);
  const [rejectSpaceDialogOpen, setRejectSpaceDialogOpen] = useState(false);

  /**
   * Change map focus and target space
   */
  const handleRowClick = (i, spaceId) => {
    const temp = templates ? 'templates/' : 'spaces/';
    navigate(`/presentation/${pid}/${temp}${spaceId}`);
    setTargetSpace(spaceId);
  };

  /**
   *
   */
  const showDetails = sid => {
    setSpaceDetailsOpen(!spaceDetailsOpen);
    setTargetSpace(sid);
    const temp = templates ? 'templates/' : 'spaces/';
    navigate(`/presentation/${pid}/${temp}${sid}`);
  };

  const notInterested = () => {
    const isCandidate = attachedSpaces[sid].candidate;

    if (isCandidate) {
      setTargetSpace(sid);
      setRejectSpaceDialogOpen(true);
    } else {
      setTargetSpace(sid);
      setCancelRejectionSpaceDialogOpen(true);
    }
  };

  const togglePresentationSelection = sid => {
    const selectedSpace = R.path([sid], attachedSpaces);
    const updateObject = { onPresentation: !selectedSpace.onPresentation };

    updateAttachedSpaceOnPresentation(sid, updateObject);
  };

  const closePopUps = () => {
    setCancelRejectionSpaceDialogOpen(false);
    setRejectSpaceDialogOpen(false);
  };

  /**
   * Set rejection reason and close dialog
   */
  const setRejectionReason = initialData => {
    const updateObject = {
      candidate: false,
      rejection: R.path(['rejection'], initialData),
    };

    setRejectSpaceDialogOpen(false);
    updateAttachedSpace(targetSpace, updateObject);
  };

  /**
   * Set rejection reason to empty string and close dialog
   */
  const cancelRejection = () => {
    const updateObject = { candidate: true, rejection: '' };

    setCancelRejectionSpaceDialogOpen(false);
    updateAttachedSpace(targetSpace, updateObject);
  };

  /**
   * Send text filters further for merge and save
   */
  const setTextFilters = event => {
    saveTextSearchTerms({ [event.target.name]: event.target.value });
  };

  /**
   * Send distance filters further for merge and save
   */
  const setDistanceFilters = (event, minMax, isAttr) => {
    saveDistanceSearchTerms({
      [event.target.name]: {
        value: event.target.value.toUpperCase(),
        minMax,
        isAttr,
      },
    });
  };

  const saveTextSearchTerms = newTerms => {
    const mergedTerms = {
      ...searchTermsText,
      ...newTerms,
    };

    setSearchTermsText(mergedTerms);
  };

  const saveDistanceSearchTerms = newTerms => {
    const mergedTerms = {
      ...searchTermsDistance,
      ...newTerms,
    };

    setSearchTermsDistance(mergedTerms);
  };

  const searchForMatchingItems = selectedSpaces => {
    /**
     * Bring all attributes to main level for filtering purposes
     */
    const extendedSelectedSpaces = selectedSpaces.map(item => ({
      ...item,
      ...item.attr,
    }));

    /**
     * Filter items first by text search results using partial search
     */
    const textSearchResults = filterResults(
      extendedSelectedSpaces,
      searchTermsText,
      true
    );

    /**
     * Save results items to a modifiable variable which we then start to filter with distance filters
     */
    let itemsForDistanceSearch = textSearchResults;

    /**
     * Filter items based on distances and other numeral data
     */
    Object.keys(searchTermsDistance).forEach(key => {
      itemsForDistanceSearch = itemsForDistanceSearch.filter(
        item =>
          searchTermsDistance[key].value === '' ||
          (searchTermsDistance[key].minMax === '>' &&
            !searchTermsDistance[key].isAttr &&
            item[key] >= searchTermsDistance[key].value) ||
          (searchTermsDistance[key].minMax === '<' &&
            !searchTermsDistance[key].isAttr &&
            item[key] <= searchTermsDistance[key].value) ||
          (searchTermsDistance[key].minMax === '>' &&
            searchTermsDistance[key].isAttr &&
            item.attr[key] >= searchTermsDistance[key].value) ||
          (searchTermsDistance[key].minMax === '<' &&
            searchTermsDistance[key].isAttr &&
            item.attr[key] <= searchTermsDistance[key].value)
      );
    });

    return itemsForDistanceSearch;
  };

  /**
   * Return all attached spaces in a different format
   */
  const getAttachedSpaces = attachedSpaces =>
    Object.entries(attachedSpaces).map(item => {
      const id = R.head(item);
      const fields = R.nth(1, item);
      if (item !== null) {
        return { id, ...fields };
      }
      return {};
    });

  const spacesArray = getAttachedSpaces(attachedSpaces);

  /**
   * Spaces that are candidates and on presentation
   */
  const selectedSpaces = spacesArray.filter(
    space => space.project === pid && space.candidate && space.onPresentation
  );

  /**
   * Filter selected spaces
   */
  const filteredSelectedSpaces = searchForMatchingItems(selectedSpaces);

  /**
   * Spaces that aren't on presentation
   */
  const notShowArr = spacesArray.filter(
    space =>
      space.project === pid && space.candidate && space.onPresentation === false
  );

  /**
   * Keys (refined headers) and id's are added for spaces that aren't on presentation
   */
  const refinedNotShowSpaces = Array.from(notShowArr).map(space => {
    const keys = ['city', 'address', 'spaceSize', 'distanceToMetro'];
    return {
      ...space,
      objectKeys: keys,
      id: space.id,
    };
  });

  const labels = [
    { key: 'city', finnish: t('labels.city') },
    { key: 'address', finnish: t('labels.address') },
    { key: 'spaceSize', finnish: t('labels.spaceSize') },
    { key: 'netRent', finnish: t('labels.netRent') },
    { key: 'icon', finnish: t('presentation.icon') },
  ];

  const spaceColumns = ['city', 'address', 'spaceSize', 'netRent'];

  const droppedLabels = [
    { key: 'city', finnish: t('labels.city') },
    { key: 'address', finnish: t('labels.address') },
    { key: 'spaceSize', finnish: t('labels.spaceSize') },
    { key: 'icon', finnish: t('presentation.icon') },
  ];

  const droppedSpaceColumns = ['city', 'address', 'spaceSize'];

  const filters = [
    { name: 'address', label: t('labels.address') },
    { name: 'city', label: t('labels.city') },
  ];

  const candidateSpaceLocations = filteredSelectedSpaces.map(item => ({
    id: R.path(['id'], item),
    lng: R.path(['location', 'lng'], item),
    lat: R.path(['location', 'lat'], item),
  }));

  const nonCandidateSpaceLocations = refinedNotShowSpaces.map(item => ({
    id: R.path(['id'], item),
    lng: R.path(['location', 'lng'], item),
    lat: R.path(['location', 'lat'], item),
  }));

  const exportPresentationSpaces = exportType => {
    /**
     * Create a spacesForExport list from attachedSpaces (all spaces) by selecting only
     * - spaces that belong to this project
     * - AND
     * - spaces that are candidates for selection
     */
    const filterSpacesForExport = n =>
      n.project === pid && n.candidate && n.onPresentation;
    const spacesForExport = R.filter(filterSpacesForExport, attachedSpaces);

    /*
     * At this stage get the translated column headers for spaces that will be exported
     * This is passed to the generic export functions
     */
    const translations = {};
    // build a simplified translation structure for data export
    R.keys(attr).forEach(key => {
      let attrName = attr[key]['key'];
      translations[attrName] = R.path([key, 'label', i18n.language], attr);
    });

    /* Send spaces & optional translations to the generic export lib */
    exportSpaces(
      spacesForExport,
      exportType,
      R.path([pid, 'projectInfo', 'name'], projects),
      translations
    );
  };
  const markers = R.union(candidateSpaceLocations, nonCandidateSpaceLocations);

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 2, backgroundColor: '#fff', paddingBottom: 60 }}>
          <SpacesTable
            data={filteredSelectedSpaces}
            labels={labels}
            columns={spaceColumns}
            navigate={navigate}
            handleRowClick={handleRowClick}
            icon="map"
            altIcon="info"
            showDetails={showDetails}
            menu={ActionMenu}
            actionMenuCB={notInterested}
            actionMenuCBII={togglePresentationSelection}
            actionMenuCBText={t('presentation.markAsUnselected')}
            actionMenuCBAltText={t('presentation.markAsSelected')}
            actionMenuCBIIText={t('presentation.removeFromPresentation')}
            actionMenuCBIIAltText={t('presentation.selectToPresentation')}
            spaceDetailsOpen={spaceDetailsOpen}
            setTextFilters={setTextFilters}
            setMaxFilters={setDistanceFilters}
            filters={filters}
            projects={projects}
            sid={sid}
            spacesOnPresentation
            selectFirstRowOnMount
          />
        </div>
        {!spaceDetailsOpen ? (
          <Drawer
            variant="permanent"
            sx={inlineStyles.docked}
            PaperProps={{ sx: inlineStyles.drawerPaper }}
            anchor="left"
            style={{ flex: 1, backgroundColor: '#fff' }}
          >
            <GMap
              containerElement={
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    position: 'relative',
                    minHeight: '650px',
                  }}
                />
              }
              mapElement={
                <div
                  style={{
                    height: '100%',
                    position: 'absolute',
                    width: '100%',
                    overflow: 'hidden',
                  }}
                />
              }
              markers={markers}
              defaultCenter={sid && R.find(R.propEq('id', sid))(markers)}
            />
          </Drawer>
        ) : (
          <SpaceDetailView
            templates={templates}
            isAdmin={isAdmin}
            isInProject
            navigate={navigate}
            presentation
          />
        )}
      </div>
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => exportPresentationSpaces('csv')}
      >
        {t('presentation.loadSelectedAsCSV')}
      </Button>
      <br />
      <br />
      {R.length(refinedNotShowSpaces) !== 0 ? (
        <CardWrapper name={t('presentation.spacesRemovedFromPresentation')}>
          <SpacesTable
            hideFilters
            data={refinedNotShowSpaces}
            labels={droppedLabels}
            columns={droppedSpaceColumns}
            navigate={navigate}
            handleRowClick={handleRowClick}
            icon="map"
            altIcon="info"
            showDetails={showDetails}
            menu={ActionMenu}
            actionMenuCB={notInterested}
            actionMenuCBII={togglePresentationSelection}
            actionMenuCBText={t('presentation.markAsUnselected')}
            actionMenuCBAltText={t('presentation.markAsSelected')}
            actionMenuCBIIText={t('presentation.removeFromPresentation')}
            actionMenuCBIIAltText={t('presentation.selectToPresentation')}
            spaceDetailsOpen={spaceDetailsOpen}
            projects={projects}
            sid={sid}
            file={file}
            selectFirstRowOnMount={R.length(filteredSelectedSpaces) === 0}
          />
        </CardWrapper>
      ) : null}
      <ModalDialog
        modalTitle="Cancel-rejection"
        modalDescription="Cancel-rejection"
        openState={cancelRejectionSpaceDialogOpen}
        onClose={closePopUps}
        modalHeadline={t('spaces.setAsCandidate')}
        modalSubHeadline=""
        // buttonSx={classes.button}
        // iconSx={classes.rightIcon}
        onCancelClick={closePopUps}
        cancelButtonText={t('common.cancel')}
        onSubmitClick={cancelRejection}
        submitButtonText={t('common.save')}
      />
      <ModalFormDialog
        modalTitle="Reject-space"
        modalDescription="Reject-space"
        openState={rejectSpaceDialogOpen}
        modalHeadline={t('presentation.setRejectionReason')}
        modalSubHeadline=""
        // buttonSx={classes.button}
        // iconSx={classes.rightIcon}
        onCancelClick={closePopUps}
        cancelButtonText={t('common.cancel')}
        cancelOption
        onSubmitClick={setRejectionReason}
        submitButtonText={t('common.save')}
        initValues={{}}
        fieldWidth={12}
        fields={[
          {
            key: 'rejection',
            label: t('labels.rejection'),
            type: 'textarea',
            required: true,
          },
        ]}
      />
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  const attachedSpaces = R.path(['space', 'attachedSpaces'], state);
  const sid = R.path(['router', 'params', 'documentId'], ownProps);
  const spaces = '';
  const users = R.path(['user', 'users'], state);
  const attr = R.path(['attr', 'attr'], state);
  return {
    attachedSpaces,
    spaces,
    sid,
    users,
    file: R.path(['file'], state),
    attr,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateAttachedSpaceOnPresentation: (sid, updateObject) =>
    dispatch(updateAttachedSpaceOnPresentation(sid, updateObject)),
  updateAttachedSpace: (sid, obj) =>
    dispatch(updateAttachedSpace(sid, obj, R.path(['t'], ownProps))),
});

Presentation.propTypes = {
  sortBy: PropTypes.func,
  filteredRows: PropTypes.array,
  navigate: PropTypes.func,
  projects: PropTypes.object.isRequired,
};

Presentation = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Presentation)
);

export default withTranslation('translations')(Presentation);
