import React from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import Icon from '@mui/material/Icon';

import { updateProjectsAttr } from '../../store/actions/projectActions';
import SortableForm from './SortableForm';
import FormAttrSelector from './FormAttrSelector';
import CardWrapper from '../common/CardWrapper';

import { withTranslation } from 'react-i18next';

const FormEditor = ({ project, doc, attrArr, t }) => {
  const dispatch = useDispatch();
  const onSelect = (ev, id, cat) => {
    const attr = project.attr;
    const rfiAttributes = attrArr.filter(attr => attr.key === id);
    const rfiAttribute = R.head(rfiAttributes);
    const isItIn = attr[cat].filter(attr => attr.key === id);
    const {
      basicInfo,
      spaceInfo,
      economicInfo,
      parking,
      realEstateServices,
      realEstateTechnical,
      risks,
      additionalInfo,
    } = attr;
    const obj = {
      basicInfo,
      spaceInfo,
      economicInfo,
      parking,
      realEstateServices,
      realEstateTechnical,
      risks,
      additionalInfo,
    };

    // Check if attr is selected already
    if (R.length(isItIn) === 0) {
      rfiAttribute.selected = true;
      // check which fieldCategory it is
      if (cat === 'basicInfo' && obj.basicInfo) {
        obj.basicInfo = [...attr.basicInfo, rfiAttribute];
      } else if (cat === 'spaceInfo' && obj.spaceInfo) {
        obj.spaceInfo = [...attr.spaceInfo, rfiAttribute];
      } else if (cat === 'economicInfo') {
        obj.economicInfo = [...attr.economicInfo, rfiAttribute];
      } else if (cat === 'parking') {
        obj.parking = [...attr.parking, rfiAttribute];
      } else if (cat === 'realEstateServices') {
        obj.realEstateServices = [...attr.realEstateServices, rfiAttribute];
      } else if (cat === 'realEstateTechnical') {
        obj.realEstateTechnical = [...attr.realEstateTechnical, rfiAttribute];
      } else if (cat === 'risks') {
        obj.risks = [...attr.risks, rfiAttribute];
      } else if (cat === 'additionalInfo') {
        obj.additionalInfo = [...attr.additionalInfo, rfiAttribute];
      }
    } else {
      // If item is in allready it will be removed on click
      rfiAttribute.selected = false;
      const catArr = attr[cat];
      const indx = catArr.findIndex(t => t.key === id);
      catArr.splice(indx, 1);
      if (cat === 'basicInfo') {
        obj.basicInfo = catArr;
      } else if (cat === 'spaceInfo') {
        obj.spaceInfo = catArr;
      } else if (cat === 'economicInfo') {
        obj.economicInfo = catArr;
      } else if (cat === 'parking') {
        obj.parking = catArr;
      } else if (cat === 'realEstateServices') {
        obj.realEstateServices = catArr;
      } else if (cat === 'realEstateTechnical') {
        obj.realEstateTechnical = catArr;
      } else if (cat === 'risks') {
        obj.risks = catArr;
      } else if (cat === 'additionalInfo') {
        obj.additionalInfo = catArr;
      }
    }
    dispatch(updateProjectsAttr(doc, obj, cat, t));
  };

  const onReorder = (data, cat) => {
    const { attr } = project;
    const {
      basicInfo,
      spaceInfo,
      economicInfo,
      parking,
      realEstateServices,
      realEstateTechnical,
      risks,
      additionalInfo,
    } = attr;
    const obj = {
      basicInfo,
      spaceInfo,
      economicInfo,
      parking,
      realEstateServices,
      realEstateTechnical,
      risks,
      additionalInfo,
    };
    if (cat === 'basicInfo') {
      obj.basicInfo = data;
    } else if (cat === 'spaceInfo') {
      obj.spaceInfo = data;
    } else if (cat === 'economicInfo') {
      obj.economicInfo = data;
    } else if (cat === 'parking') {
      obj.parking = data;
    } else if (cat === 'realEstateServices') {
      obj.realEstateServices = data;
    } else if (cat === 'realEstateTechnical') {
      obj.realEstateTechnical = data;
    } else if (cat === 'risks') {
      obj.risks = data;
    } else if (cat === 'additionalInfo') {
      obj.additionalInfo = data;
    }
    dispatch(updateProjectsAttr(doc, obj, cat, t));
  };

  const rfiAttributes = attrArr;
  const basicInfo = rfiAttributes.filter(
    attr => attr.fieldCategory === 'basicInfo' && !attr.default
  );
  const spaceInfo = rfiAttributes.filter(
    attr => attr.fieldCategory === 'spaceInfo' && !attr.default
  );
  const economicInfo = rfiAttributes.filter(
    attr => attr.fieldCategory === 'economicInfo'
  );
  const parking = rfiAttributes.filter(
    attr => attr.fieldCategory === 'parking'
  );
  const realEstateServices = rfiAttributes.filter(
    attr => attr.fieldCategory === 'realEstateServices'
  );
  const realEstateTechnical = rfiAttributes.filter(
    attr => attr.fieldCategory === 'realEstateTechnical'
  );
  const risks = rfiAttributes.filter(attr => attr.fieldCategory === 'risks');
  const additionalInfo = rfiAttributes.filter(
    attr => attr.fieldCategory === 'additionalInfo'
  );
  const { attr } = project;
  return (
    <CardWrapper name={t('formEditor.editForm')} headline>
      <Grid
        container
        spacing={4}
        direction="row"
        alignContent="center"
        justifyContent="center"
      >
        <Grid item lg={8} sm={6} xs={12}>
          <div>
            <div>
              <SortableForm
                items={attr.basicInfo}
                headline={t('formEditor.basicInfo')}
                cat="basicInfo"
                doc={doc}
                onReorder={onReorder}
              />
              <SortableForm
                items={attr.spaceInfo}
                headline={t('formEditor.spaceInfo')}
                cat="spaceInfo"
                doc={doc}
                onReorder={onReorder}
              />
              <SortableForm
                items={attr.economicInfo}
                headline={t('formEditor.economicInfo')}
                cat="economicInfo"
                doc={doc}
                onReorder={onReorder}
              />
              <SortableForm
                items={attr.parking}
                headline={t('formEditor.parking')}
                cat="parking"
                doc={doc}
                onReorder={onReorder}
              />
              <SortableForm
                items={attr.realEstateServices}
                headline={t('formEditor.realEstateServices')}
                cat="realEstateServices"
                doc={doc}
                onReorder={onReorder}
              />
              <SortableForm
                items={attr.realEstateTechnical}
                headline={t('formEditor.realEstateTechnical')}
                cat="realEstateTechnical"
                doc={doc}
                onReorder={onReorder}
              />
              <SortableForm
                items={attr.risks}
                headline={t('formEditor.risks')}
                cat="risks"
                doc={doc}
                onReorder={onReorder}
              />
              <SortableForm
                items={attr.additionalInfo}
                headline={t('formEditor.additionalInfo')}
                cat="additionalInfo"
                doc={doc}
                onReorder={onReorder}
              />
            </div>
          </div>
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <CardWrapper name={t('formEditor.attributes')}>
            <Accordion>
              <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                <Typography>{t('formEditor.basicInfo')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormAttrSelector
                  data={basicInfo}
                  onSelect={onSelect}
                  projectAttr={attr.basicInfo}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                <Typography>{t('formEditor.spaceInfo')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormAttrSelector
                  data={spaceInfo}
                  onSelect={onSelect}
                  projectAttr={attr.spaceInfo}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                <Typography>{t('formEditor.economicInfo')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormAttrSelector
                  data={economicInfo}
                  onSelect={onSelect}
                  projectAttr={attr.economicInfo}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                <Typography>{t('formEditor.parking')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormAttrSelector
                  data={parking}
                  onSelect={onSelect}
                  projectAttr={attr.parking}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                <Typography>{t('formEditor.realEstateServices')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormAttrSelector
                  data={realEstateServices}
                  onSelect={onSelect}
                  projectAttr={attr.realEstateServices}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                <Typography>{t('formEditor.realEstateTechnical')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormAttrSelector
                  data={realEstateTechnical}
                  onSelect={onSelect}
                  projectAttr={attr.realEstateTechnical}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                <Typography>{t('formEditor.risks')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormAttrSelector
                  data={risks}
                  onSelect={onSelect}
                  projectAttr={attr.risks}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                <Typography>{t('formEditor.additionalInfo')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormAttrSelector
                  data={additionalInfo}
                  onSelect={onSelect}
                  projectAttr={attr.additionalInfo}
                />
              </AccordionDetails>
            </Accordion>
          </CardWrapper>
        </Grid>
      </Grid>
    </CardWrapper>
  );
};
const mapStateToProps = (state, props) => ({
  project: R.path(['project', 'projects', [props.doc]], state),
  attr: R.path(['attr'], state),
});

FormEditor.propTypes = {
  doc: PropTypes.string.isRequired,
  attrArr: PropTypes.array.isRequired,
  attr: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
};

export default withTranslation('translations')(
  connect(mapStateToProps)(FormEditor)
);
