import React from 'react';
import PropTypes from 'prop-types';

import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { withTranslation } from 'react-i18next';

let EnhancedTableHeadToBeWrapped = ({ order, orderBy, t }) => {
  const createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  const rows = [
    {
      id: 'admin',
      numeric: false,
      disablePadding: false,
      label: t('labels.admin'),
    },
    {
      id: 'firstname',
      numeric: false,
      disablePadding: false,
      label: t('labels.firstName'),
    },
    {
      id: 'lastname',
      numeric: false,
      disablePadding: false,
      label: t('labels.lastName'),
    },
    {
      id: 'company',
      numeric: false,
      disablePadding: false,
      label: t('labels.company'),
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('labels.email'),
    },
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: t('labels.created'),
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: t('common.status'),
    },
    {
      id: 'edit',
      numeric: false,
      disablePadding: false,
      label: t('common.edit'),
    },
  ];

  // centeredColumnTopics are ['admin', 'createdDate', 'status', 'edit']
  const leftAlignedColumnTopics = ['firstname', 'lastname', 'company', 'email'];

  return (
    <TableHead>
      <TableRow>
        {rows.map(
          row => (
            <TableCell
              key={row.id}
              align={
                leftAlignedColumnTopics.includes(row.id) ? 'left' : 'center'
              }
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <Tooltip
                title="Sort"
                placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                enterDelay={300}
              >
                {leftAlignedColumnTopics.includes(row.id) ? (
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                ) : (
                  <div>{row.label}</div>
                )}
              </Tooltip>
            </TableCell>
          ),
          this
        )}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableHead = withTranslation('translations')(
  EnhancedTableHeadToBeWrapped
);
export default EnhancedTableHead;

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
