import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import * as R from 'ramda';

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Grid from '@mui/material/Grid';

import { validate, returnField } from '../../utils/formHelpers';

import CardWrapper from '../common/CardWrapper';

import { withTranslation } from 'react-i18next';

import { createAttr } from '../../store/actions/attrActions';
import { useTheme } from '@mui/material';

const inlineStyles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    color: '#000',
  },
  tabRoot: {},
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(1 * 2),
  },
});

// Check that the attribute name is unique by comparing lowercased versions of attribute names
// Display an error message if not - this prevents form submission
const checkUnique = (value, allValues, props) => {
  const { t } = props;
  const lowercaseWithoutSpaces = str => str.replace(' ', '').toLowerCase();
  const isEqual = n =>
    lowercaseWithoutSpaces(value) ===
      lowercaseWithoutSpaces(R.path(['key'], n)) ||
    lowercaseWithoutSpaces(value) ===
      lowercaseWithoutSpaces(R.path(['label', 'en'], n)) ||
    lowercaseWithoutSpaces(value) ===
      lowercaseWithoutSpaces(R.path(['label', 'fi'], n));
  const matchingAttributes =
    value && R.filter(isEqual, R.path(['attr'], props));
  return value && R.keys(matchingAttributes).length > 0
    ? t('createAttributes.attributeExists')
    : undefined;
};

const CreateAttr = ({
  pristine,
  submitting,
  handleSubmit,
  t,
  createAttr,
  attr,
}) => {
  const theme = useTheme();
  const classes = inlineStyles(theme);
  return (
    <CardWrapper name={t('createAttributes.createNewAttribute')} headline>
      <form onSubmit={handleSubmit(createAttr)}>
        <Grid
          container
          spacing={4}
          direction="row"
          alignContent="flex-end"
          alignItems="flex-end"
        >
          <Grid item sm={12} md={6}>
            <Field
              key="label.fi"
              name="label.fi"
              component={returnField('text')}
              label={t('createAttributes.label.finnish')}
              type="text"
              required
              fullWidth
              validate={[checkUnique]}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Field
              key="label.en"
              name="label.en"
              component={returnField('text')}
              label={t('createAttributes.label.english')}
              type="text"
              required
              fullWidth
              validate={[checkUnique]}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Field
              key="desc.fi"
              name="desc.fi"
              component={returnField('text')}
              label={t('createAttributes.description.finnish')}
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Field
              key="desc.en"
              name="desc.en"
              component={returnField('text')}
              label={t('createAttributes.description.english')}
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Field
              key="fieldCategory"
              id="fieldCategory"
              name="fieldCategory"
              component={returnField('select')}
              label={t('createAttributes.attributeCategory')}
              type="text"
              selOpts={[
                'basicInfo',
                'spaceInfo',
                'economicInfo',
                'parking',
                'realEstateServices',
                'realEstateTechnical',
                'risks',
                'additionalInfo',
              ]}
              required
              fullWidth
              translateOptions
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Field
              key="fieldType"
              id="fieldType"
              name="fieldType"
              component={returnField('select')}
              label={t('createAttributes.attributeInputFieldType')}
              type="text"
              selOpts={['text', 'number', 'textarea', 'select']}
              required
              fullWidth
              translateOptions
            />
          </Grid>
          <Grid item sm={12} md={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={classes.button}
              disabled={submitting || pristine}
            >
              {t('createAttributes.saveAttribute')}
              <Icon sx={classes.rightIcon}>cloud_upload</Icon>
            </Button>
          </Grid>
        </Grid>
      </form>
    </CardWrapper>
  );
};

const mapStateToProps = state => {
  const initialValues = {
    fieldCategory: 'basicInfo',
    fieldType: 'text',
  };
  const attr = R.path(['attr', 'attr'], state);
  return {
    initialValues,
    attr,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  createAttr: attr => dispatch(createAttr(attr, R.path(['t'], ownProps))),
});

const FormDecoratedComponent = reduxForm({
  form: 'createAttr',
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CreateAttr);

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(FormDecoratedComponent)
);
