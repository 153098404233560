import React, { useState } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { NavLink } from 'react-router-dom';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import red from '@mui/material/colors/red';
import green from '@mui/material/colors/green';

import IconButton from '@mui/material/IconButton';

import Icon from '@mui/material/Icon';

import {
  disableUser,
  enableUser,
  makeUserAdmin,
  removeUserAdminPrivileges,
} from '../../store/actions/userActions';
import EnhancedTableHead from './EnhancedTableHead';

import { store } from '../../store/store';
import { ModalDialog, LoadingModal } from '../../utils/modalHelpers';

import { withTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

function getSorting(order, orderBy) {
  const sortByThis = orderBy;

  const sortBy =
    order === 'asc'
      ? R.comparator((a, b) =>
          typeof R.prop(sortByThis, a.profile) === 'undefined' ||
          R.path(['profile', sortByThis], a).length === 0
            ? true
            : isNaN(Number(R.prop(sortByThis, a.profile)))
            ? moment(
                R.prop(sortByThis, a.profile),
                'DD/MM/YYYY',
                true
              ).isValid() &&
              moment(
                R.prop(sortByThis, b.profile),
                'DD/MM/YYYY',
                true
              ).isValid()
              ? moment(R.prop(`${sortByThis}`, a.profile), 'X').isBefore(
                  moment(R.prop(`${sortByThis}`, b.profile), 'X')
                )
              : String(R.prop(sortByThis, a.profile)).toLowerCase() <
                String(R.prop(sortByThis, b.profile)).toLowerCase()
            : Number(R.prop(sortByThis, a.profile)) <
              Number(R.prop(sortByThis, b.profile))
        )
      : R.comparator((a, b) =>
          typeof R.prop(sortByThis, b.profile) === 'undefined' ||
          R.path(['profile', sortByThis], b).length === 0
            ? true
            : isNaN(Number(R.prop(sortByThis, a.profile)))
            ? moment(
                R.prop(sortByThis, a.profile),
                'DD/MM/YYYY',
                true
              ).isValid() &&
              moment(
                R.prop(sortByThis, b.profile),
                'DD/MM/YYYY',
                true
              ).isValid()
              ? moment(R.prop(`${sortByThis}`, b.profile), 'X').isBefore(
                  moment(R.prop(`${sortByThis}`, a.profile), 'X')
                )
              : String(R.prop(sortByThis, a.profile)).toLowerCase() >
                String(R.prop(sortByThis, b.profile)).toLowerCase()
            : Number(R.prop(sortByThis, a.profile)) >
              Number(R.prop(sortByThis, b.profile))
        );

  return sortBy;
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1 * 3),
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    color: '#000',
    boxShadow: 'none',
    borderRadius: '0px',
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  colorSwitchBase: {
    color: red[500],
    '& + $colorBar': {
      backgroundColor: red[500],
    },
  },
  colorBar: {},
  colorChecked: {
    color: green[500],
    '& + $colorBar': {
      backgroundColor: green[500],
    },
  },
});

const UserTable = ({
  disableUser,
  enableUser,
  makeUserAdmin,
  removeUserAdminPrivileges,
  submitting,
  userChangeInProgress,
  users,
  t,
}) => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdDate');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [disableUserPopUpOpen, setDisableUserPopUpOpen] = useState(false);
  const [enableUserPopUpOpen, setEnableUserPopUpOpen] = useState(false);
  const [
    removeUserAdminPrivilegesPopUpOpen,
    setRemoveUserAdminPrivilegesPopUpOpen,
  ] = useState(false);
  const [addAdminPopUpOpen, setAddAdminPopUpOpen] = useState(false);
  const [disableTarget, setDisableTarget] = useState('');

  const openDisableUserPopUp = (event, uid) => {
    setDisableUserPopUpOpen(true);
    setDisableTarget(uid);
  };

  const openEnableUserPopUp = (event, uid) => {
    setEnableUserPopUpOpen(true);
    setDisableTarget(uid);
  };

  const openMakeUserAdminPopUp = (event, uid) => {
    setAddAdminPopUpOpen(true);
    setDisableTarget(uid);
  };

  const openRemoveAdminprivilegesPopUp = (event, uid) => {
    setRemoveUserAdminPrivilegesPopUpOpen(true);
    setDisableTarget(uid);
  };

  const closePopUps = () => {
    setDisableUserPopUpOpen(false);
    setEnableUserPopUpOpen(false);
    setRemoveUserAdminPrivilegesPopUpOpen(false);
    setAddAdminPopUpOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'desc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

  const userReducer = store.getState().user;
  const user = R.path(['users', disableTarget, 'profile'], userReducer);

  const usersMap = users.map(user => {
    const u = user;
    u.profile.createdDate = moment.unix(
      R.path(['system', 'createdDate', 'seconds'], user)
    );
    return u;
  });
  const rows = R.sort(getSorting(order, orderBy), usersMap);
  const theme = useTheme();
  const classes = styles(theme);
  return (
    <div style={classes.root}>
      <ModalDialog
        modalTitle="Disable-user"
        modalDescription="Disable-user"
        openState={disableUserPopUpOpen}
        onClose={closePopUps}
        modalHeadline={t('userManagement.confirmUserDisable', {
          firstName: user ? user.firstname : ' ',
          lastName: user ? user.lastname : ' ',
          email: user ? user.email : ' ',
        })}
        modalSubHeadline=""
        buttonSx={classes.button}
        iconSx={classes.rightIcon}
        onCancelClick={closePopUps}
        cancelButtonText={t('common.cancel')}
        onSubmitClick={() => {
          closePopUps();
          disableUser(disableTarget);
        }}
        submitButtonText={t('userManagement.confirmUserDisableButtonText')}
        submitting={submitting}
      />
      <ModalDialog
        modalTitle="Enable-user"
        modalDescription="Enable-user"
        openState={enableUserPopUpOpen}
        onClose={closePopUps}
        modalHeadline={t('userManagement.confirmUserEnable', {
          firstName: user ? user.firstname : ' ',
          lastName: user ? user.lastname : ' ',
          email: user ? user.email : ' ',
        })}
        modalSubHeadline=""
        buttonSx={classes.button}
        iconSx={classes.rightIcon}
        onCancelClick={closePopUps}
        cancelButtonText={t('common.cancel')}
        onSubmitClick={() => {
          closePopUps();
          enableUser(disableTarget);
        }}
        submitButtonText={t('userManagement.confirmUserEnableButtonText')}
        submitting={submitting}
      />
      <ModalDialog
        modalTitle="Make-user-admin"
        modalDescription="Make-user-admin"
        openState={addAdminPopUpOpen}
        onClose={closePopUps}
        modalHeadline={t('userManagement.confirmUserAdminPrivileges', {
          firstName: user ? user.firstname : ' ',
          lastName: user ? user.lastname : ' ',
          email: user ? user.email : ' ',
        })}
        modalSubHeadline=""
        buttonSx={classes.button}
        iconSx={classes.rightIcon}
        onCancelClick={closePopUps}
        cancelButtonText={t('common.cancel')}
        onSubmitClick={() => {
          closePopUps();
          makeUserAdmin(disableTarget);
        }}
        submitButtonText={t(
          'userManagement.confirmUserAdminPrivilegesButtonText'
        )}
        submitting={submitting}
      />
      <ModalDialog
        modalTitle="Remove-user-admin-privileges"
        modalDescription="Remove-user-admin-privileges"
        openState={removeUserAdminPrivilegesPopUpOpen}
        onClose={closePopUps}
        modalHeadline={t('userManagement.confirmUserAdminPrivilegesRemoval', {
          firstName: user ? user.firstname : ' ',
          lastName: user ? user.lastname : ' ',
          email: user ? user.email : ' ',
        })}
        modalSubHeadline=""
        buttonSx={classes.button}
        iconSx={classes.rightIcon}
        onCancelClick={closePopUps}
        cancelButtonText={t('common.cancel')}
        onSubmitClick={() => {
          closePopUps();
          removeUserAdminPrivileges(disableTarget);
        }}
        submitButtonText={t(
          'userManagement.confirmUserAdminPrivilegesRemovalButtonText'
        )}
        submitting={submitting}
      />
      <LoadingModal
        modalTitle="Wait-action-user"
        modalDescription="Wait-action-user"
        openState={userChangeInProgress}
        modalHeadline={t('userManagement.waitEdit', {
          firstName: user ? user.firstname : ' ',
          lastName: user ? user.lastname : ' ',
          email: user ? user.email : ' ',
        })}
        modalSubHeadline=""
      />
      <div style={classes.tableWrapper}>
        <Table sx={classes.table} aria-labelledby="tableTitle">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={usersMap.length}
          />
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(user => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={R.path(['profile', 'uid'], user)}
                >
                  <TableCell align="center">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={R.path(['mgmt', 'roles', 'admin'], user)}
                          onChange={event => {
                            !R.path(['mgmt', 'roles', 'admin'], user)
                              ? openMakeUserAdminPopUp(
                                  event,
                                  R.path(['profile', 'uid'], user)
                                )
                              : openRemoveAdminprivilegesPopUp(
                                  event,
                                  R.path(['profile', 'uid'], user)
                                );
                          }}
                          sx={{
                            '&.MuiSwitch-root .MuiSwitch-switchBase':
                              classes.colorSwitchBase,
                            '&.MuiSwitch-root .Mui-checked':
                              classes.colorChecked,
                          }}
                        />
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {R.path(['profile', 'firstname'], user)}
                  </TableCell>
                  <TableCell>{R.path(['profile', 'lastname'], user)}</TableCell>
                  <TableCell>{R.path(['profile', 'company'], user)}</TableCell>
                  <TableCell>{R.path(['profile', 'email'], user)}</TableCell>
                  <TableCell align="center">
                    {moment
                      .unix(R.path(['system', 'createdDate', 'seconds'], user))
                      .format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell align="center">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!R.path(['mgmt', 'locked'], user)}
                          onChange={event => {
                            R.path(['mgmt', 'locked'], user)
                              ? openEnableUserPopUp(
                                  event,
                                  R.path(['profile', 'uid'], user)
                                )
                              : openDisableUserPopUp(
                                  event,
                                  R.path(['profile', 'uid'], user)
                                );
                          }}
                          sx={{
                            '&.MuiSwitch-root .MuiSwitch-switchBase':
                              classes.colorSwitchBase,
                            '&.MuiSwitch-root .Mui-checked':
                              classes.colorChecked,
                          }}
                        />
                      }
                    />
                  </TableCell>

                  <TableCell align="center">
                    <IconButton
                      component={NavLink}
                      to={`/users/${R.path(['profile', 'uid'], user)}`}
                      aria-label="Edit"
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        component="div"
        count={usersMap.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

UserTable.propTypes = {
  submitting: PropTypes.bool,
  userChangeInProgress: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  userChangeInProgress: R.path(['user', 'userChangeInProgress'], state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  disableUser: uid => dispatch(disableUser(uid, R.path(['t'], ownProps))),
  enableUser: uid => dispatch(enableUser(uid, R.path(['t'], ownProps))),
  makeUserAdmin: uid => dispatch(makeUserAdmin(uid, R.path(['t'], ownProps))),
  removeUserAdminPrivileges: uid =>
    dispatch(removeUserAdminPrivileges(uid, R.path(['t'], ownProps))),
});

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(UserTable)
);
