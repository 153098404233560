import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';

import { Field } from 'redux-form';
import { returnField } from '../../utils/formHelpers';

import { withTranslation } from 'react-i18next';

const FirestoreFile = ({
  file, document, onDelete, path, disabled, allowMultipleFiles, t, thumbPath, showRole,
}) => {
  const fileNamePath = document ? `${document}.fileList.${R.head(file)}.fileName` : `fileList.${R.head(file)}.fileName`;
  const fileRolePath = document ? `${document}.fileList.${R.head(file)}.fileRole` : `fileList.${R.head(file)}.fileRole`;

  // Fix freezing issue when file[1]['fileName'] is undefined
  if (R.nth(1, file) && (R.path(['fileName'], R.nth(1, file)) === undefined)) {
    file[1]['fileName'] = '';
  }

  return (
    <ListItem
      role={undefined}
      dense
      style={{ padding: '0px', paddingBottom: '10px', borderBottom: '1px dashed #ddd' }}
    >
      <ListItemText>

        <Grid
          style={{ textAlign: 'left' }}
          direction="row"
          spacing={0}
          container
          justifyContent="flex-start"
          alignItems="flex-end"
          alignContent="flex-start"
        >
          <Grid item xs={4} sm={2}>
            <div style={{ marginBottom: R.path(['fileName'], R.nth(1, file)).match(/\.(jpeg|jpg|gif|png)$/) !== null ? '' : '15px' }}>
              {R.path(['fileName'], R.nth(1, file)).match(/\.(jpeg|jpg|gif|png)$/) !== null &&
              <img
                style={{ width: '50px', height: '50px' }}
                src={thumbPath || path}
                alt=""
              />}
              {R.path(['fileName'], R.nth(1, file)).match(/\.(pdf)$/) !== null &&
              <span style={{ border: '1px solid #000', padding: '15px 10px 15px 10px' }}>PDF</span>
            }
              {R.path(['fileName'], R.nth(1, file)).match(/\.(doc|docx)$/) !== null &&
              <span style={{ border: '1px solid #000', padding: '15px 10px 15px 10px' }}>DOC</span>
            }
              {R.path(['fileName'], R.nth(1, file)).match(/\.(ppt|pptx)$/) !== null &&
              <span style={{ border: '1px solid #000', padding: '15px 10px 15px 10px' }}>PPT</span>
            }
              {R.path(['fileName'], R.nth(1, file)).match(/\.(xls|xlsx)$/) !== null &&
              <span style={{ border: '1px solid #000', padding: '15px 10px 15px 10px' }}>XLS</span>
            }
              {R.path(['fileName'], R.nth(1, file)).match(/\.(dwg)$/) !== null &&
              <span style={{ border: '1px solid #000', padding: '15px 10px 15px 10px' }}>DWG</span>
            }
            </div>
          </Grid>
          {allowMultipleFiles &&
          <Grid item xs={8} sm={3}>

            {showRole ?

              <Field
                key={fileRolePath}
                name={fileRolePath}
                val={t(`fileManagement.${R.path(['fileRole'], R.nth(1, file))}`)}
                component={returnField('text')}
                disabled
                type="text"
                fullWidth
              />

              :
              <Field
                key={fileRolePath}
                name={fileRolePath}
                component={returnField('select')}
                label={t('fileManagement.type')}
                type="text"
                selOpts={['floorPlan', 'otherAttachment']}
                required
                fullWidth
                translateOptions
                disabled={disabled}
              />
}
          </Grid>
        }
          <Grid item xs={allowMultipleFiles ? 6 : 4} sm={allowMultipleFiles ? 5 : 8}>
            <Field
              key={fileNamePath}
              name={fileNamePath}
              val={R.path(['fileName'], R.nth(1, file))}
              component={returnField('text')}
              disabled
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item xs={allowMultipleFiles ? 3 : 2} sm={1}>
            <IconButton aria-label="Open" onClick={() => window.open(path)} size="large">
              <Icon>get_app</Icon>
            </IconButton>
          </Grid>
          <Grid item xs={allowMultipleFiles ? 3 : 2} sm={1}>
            {!disabled &&
              <IconButton
                aria-label="Delete"
                onClick={() => onDelete(R.path(['fileName'], R.nth(1, file)))}
                size="large">
                <Icon>delete</Icon>
              </IconButton>
            }
          </Grid>
        </Grid>
      </ListItemText>
    </ListItem>
  );
};


FirestoreFile.propTypes = {
  path: PropTypes.string.isRequired,
  thumbPath: PropTypes.string.isRequired,
  file: PropTypes.array.isRequired,
  document: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  allowMultipleFiles: PropTypes.bool,
};

FirestoreFile.defaultProps = {
  document: undefined,
};

export default withTranslation('translations')(FirestoreFile);
