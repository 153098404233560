import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { Field, reduxForm, initialize, change } from 'redux-form';
import { withTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';

import { editProjectParticipants } from '../../store/actions/projectActions';
import { validate, returnField } from '../../utils/formHelpers';
import { ascComparator, descComparator } from '../../utils/comparators';

const inlineStyles = {
  rightIcon: {
    marginLeft: 1,
  },
  tableLabel: {
    cursor: 'pointer',
    backgroundColor: 'rgb(227, 238, 255)',
    borderBottom: '2px solid black',
    height: '60px',
  },
};

const AddParticipants = ({
  users,
  handleSubmit,
  editProjectParticipants,
  doc,
  pristine,
  projectUsers,
  filteredAttachedSpaces,
  t,
}) => {
  const dispatch = useDispatch();
  const [selectAll, setSelectAll] = useState(true);
  const [sortOrder, setSortOrder] = useState('asc');
  const [orderByStr, setOrderByStr] = useState('company');

  const projectStatus = useSelector(state => ({
    projectStatus: R.path(
      ['project', 'projects', doc, 'projectInfo', 'status'],
      state
    ),
  }));

  const handleSelectAll = () => {
    const usersState = R.reduce(
      (acc, user) => {
        acc[user.uid] = selectAll;
        return acc;
      },
      {},
      users
    );
    setSelectAll(!selectAll);
    for (const [userId, userState] of Object.entries(usersState)) {
      dispatch(change('editParticipants', userId, userState));
    }
  };

  const resetForm = () => {
    const initialValues = {};
    users.forEach(user => {
      initialValues[user.uid] = !!projectUsers.hasOwnProperty(user.uid);
    });
    dispatch(initialize('editParticipants', initialValues));
  };

  const userArr = R.values(users);

  // To sort data, we need to enhance the user array to contain some extra variables to match the table head labels
  const enhancedUserArr = userArr.map(user => {
    const uid = R.path(['profile', 'uid'], user);

    const isOwnedByThisUser = val => R.path(['users', uid], val) !== undefined;
    const filteredRelatedToThisUserAttachedSpaces = R.pickBy(
      isOwnedByThisUser,
      filteredAttachedSpaces
    );

    const isActiveAndCandidate = val =>
      R.path(['active'], val) && R.path(['candidate'], val);
    const filteredRelatedToThisUserActiveAndCandidateAttachedSpaces = R.pickBy(
      isActiveAndCandidate,
      filteredRelatedToThisUserAttachedSpaces
    );

    return {
      ...user,
      participationStatus: projectUsers[uid],
      spacesAdded: `${R.prop(
        'length',
        R.keys(filteredRelatedToThisUserActiveAndCandidateAttachedSpaces)
      )}/${R.prop('length', R.keys(filteredRelatedToThisUserAttachedSpaces))}`,
    };
  });

  // Filter out disabled users from participants list
  const filteredUserArr = enhancedUserArr.filter(
    user => !R.path(['mgmt', 'locked'], user)
  );

  const changeSortOrder = newOrderStr => {
    if (newOrderStr === orderByStr) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderByStr(newOrderStr);
    }
  };

  const rows = R.sort(
    sortOrder === 'asc'
      ? ascComparator(orderByStr)
      : descComparator(orderByStr),
    filteredUserArr
  );

  const theads = [
    'participationStatus',
    'spacesAdded',
    'name',
    'company',
    'role',
    'area',
    'email',
    'phone',
  ];

  const tableBodyMaxHeight = window.innerHeight * 0.5;

  return (
    <form onSubmit={handleSubmit(editProjectParticipants)}>
      <div
        style={{
          maxHeight: `${tableBodyMaxHeight}px`,
          overflowY: 'auto',
        }}
      >
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  padding: '15px',
                  backgroundColor: 'rgb(227, 238, 255)',
                  borderBottom: '2px solid black',
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSelectAll}
                >
                  {selectAll
                    ? t('participants.selectAll')
                    : t('participants.clearSelection')}
                </Button>
              </TableCell>
              {theads &&
                theads.map(item => (
                  <TableCell
                    key={item}
                    sx={inlineStyles.tableLabel}
                    onClick={() => changeSortOrder(item)}
                  >
                    <TableSortLabel
                      active={orderByStr === item}
                      direction={sortOrder}
                      sx={inlineStyles.sortLabel}
                    >
                      {t(`participants.${item}`)}
                    </TableSortLabel>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(user => {
              const uid = R.path(['profile', 'uid'], user);

              const isOwnedByThisUser = val =>
                R.path(['users', uid], val) !== undefined;
              const filteredRelatedToThisUserAttachedSpaces = R.pickBy(
                isOwnedByThisUser,
                filteredAttachedSpaces
              );

              const isActiveAndCandidate = val =>
                R.path(['active'], val) && R.path(['candidate'], val);
              const filteredRelatedToThisUserActiveAndCandidateAttachedSpaces = R.pickBy(
                isActiveAndCandidate,
                filteredRelatedToThisUserAttachedSpaces
              );

              return (
                <Field
                  key={uid}
                  id={uid}
                  name={uid}
                  component={returnField('CheckBoxListUsers')}
                  type="text"
                  item={user}
                  t={t}
                  projectUsers={projectUsers}
                  uid={uid}
                  pid={doc}
                  filteredAttachedSpaces={
                    filteredRelatedToThisUserAttachedSpaces
                  }
                  filteredAttachedActiveAndCandidateSpaces={
                    filteredRelatedToThisUserActiveAndCandidateAttachedSpaces
                  }
                />
              );
            })}
          </TableBody>
        </Table>
      </div>
      {projectStatus === 'Draft' ? (
        <div>
          {t('participants.promptStatusChange')}
          <br />
        </div>
      ) : null}
      <br />
      <Button
        style={{ marginRight: '10px' }}
        variant="contained"
        color="secondary"
        disabled={projectStatus === 'Draft' || pristine}
        onClick={resetForm}
      >
        {t('common.cancel')}
        <Icon sx={inlineStyles.rightIcon}>cloud_upload</Icon>
      </Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={projectStatus === 'Draft' || pristine}
      >
        {t('participants.saveParticipants')}
        <Icon sx={inlineStyles.rightIcon}>cloud_upload</Icon>
      </Button>
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  const attachedSpaces = R.path(['space', 'attachedSpaces'], state);
  const pid = R.path(['doc'], ownProps);
  const isPartOfProject = (val, key) => val.project === pid;
  const filteredAttachedSpaces = R.pickBy(isPartOfProject, attachedSpaces);

  const users = R.path(['user', 'users'], state);
  const projectUsers = R.path(['project', 'projects', pid, 'users'], state);
  const projectStatus = R.path(
    ['project', 'projects', pid, 'projectInfo', 'status'],
    state
  );

  const markSelectedUsers = (num, key, obj) =>
    !!projectUsers.hasOwnProperty(key);
  const initialValues = R.mapObjIndexed(markSelectedUsers, users);

  return {
    initialValues,
    projectStatus,
    projectUsers,
    filteredAttachedSpaces,
    usersState: users,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  editProjectParticipants: values =>
    dispatch(
      editProjectParticipants(
        values,
        R.path(['doc'], ownProps),
        R.path(['project', 'projectInfo', 'status'], ownProps),
        R.path(['t'], ownProps)
      )
    ),
});

const FormDecoratedComponent = reduxForm({
  form: 'editParticipants',
  enableReinitialize: true,
  validate,
})(AddParticipants);

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(FormDecoratedComponent)
);
