import * as R from 'ramda';

import {
  SAVE_FILTERS,
  CLEAR_FILTERS,
} from '../actions/filterActions';

const initialState = {
  filters: {
    projects: {},
    attachedSpaces: {},
    spaces: {},
    landlordProjectDetailViewSpaces: {},
  },
};

export default function filterReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_FILTERS:
      return {
        ...state,
        filters: {
          projects: {},
          attachedSpaces: {},
          spaces: {},
          landlordProjectDetailViewSpaces: {},
          [R.path(['id'], action)]: R.path(['filters'], action),
        },
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        filters: {
          projects: {},
          attachedSpaces: {},
          spaces: {},
          landlordProjectDetailViewSpaces: {},
        },
      };
    default:
      return state;
  }
}
