import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import projectReducer from './projectReducer';
import spacesReducer from './spaceReducer';
import attrReducer from './attrReducer';
import userReducer from './userReducer';
import fileReducer from './fileReducer';
import filterReducer from './filterReducer';
import notifierReducer from './notifierReducer';
import clientReducer from './clientReducer';
import projectNotesReducer from './projectNotesReducer';
import joyrideReducer from './joyrideReducer';

export default combineReducers({
  project: projectReducer,
  form: reduxFormReducer,
  space: spacesReducer,
  attr: attrReducer,
  user: userReducer,
  file: fileReducer,
  filter: filterReducer,
  notifier: notifierReducer,
  client: clientReducer,
  projectNotes: projectNotesReducer,
  joyride: joyrideReducer,
});
