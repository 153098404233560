/* eslint-disable */
import React, { useState } from 'react';
import loginBg from './assets/img/helsinki2.jpg';
import logo from './assets/img/logo.png';
import { withTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const containerStyle = {
  background: `url(${loginBg})`,
  height: 90 + 'vh',
  paddingTop: 10 + 'vh',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center center',
};

const logoStyle = {
  width: 200 + 'px',
  margin: 25 + 'px auto',
  display: 'block',
};

const boxStyle = {
  maxWidth: '360px',
  minHeight: '200px',
  backgroundColor: 'white',
  margin: '0 auto',
  boxShadow:
    '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
};

const topicStyle = {
  padding: '24px',
  fontWeight: 700,
};

const textFieldStyle = {
  paddingLeft: '24px',
  maxWidth: '312px',
};

const buttonRowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '24px',
};

const buttonStyle = {
  width: '140px',
  alignSelf: 'center',
};

const RequestAccount = ({ backToLogin, t }) => {
  const [email, setEmail] = useState('');

  const sendEmail = () => {
    if (window) {
      window.location.href = `mailto:tenrep@newsec.fi?subject=${encodeURIComponent(
        t('requestAccount.requestForAccount')
      )}&body=${encodeURIComponent(
        t('requestAccount.emailBodyText')
      )} ${encodeURIComponent(email)}.`;
    }
  };

  return (
    <div style={containerStyle}>
      <img src={logo} style={logoStyle} />
      <div style={boxStyle}>
        <div style={topicStyle}>{t('requestAccount.requestAccount')}</div>
        <TextField
          style={textFieldStyle}
          type="text"
          label={t('requestAccount.giveYourEmailAddress')}
          name="requestAccount"
          margin="normal"
          onChange={event => setEmail(event.target.value)}
          fullWidth
        />

        <div style={buttonRowStyle}>
          <Button
            component="span"
            variant="contained"
            color="secondary"
            style={buttonStyle}
            onClick={backToLogin}
          >
            {t('requestAccount.backToLogin')}
          </Button>

          <Button
            component="span"
            variant="contained"
            color="primary"
            style={buttonStyle}
            onClick={sendEmail}
          >
            {t('requestAccount.sendRequest')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('translations')(RequestAccount);
