/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import App from './App';
import FirebaseLogin from './components/firebaseLogin/FirebaseLogin';
import loginBg from './assets/img/helsinki2.jpg';
import logo from './assets/img/logo.png';
import { ModalDialog } from './utils/modalHelpers';
import { withTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { detect } from 'detect-browser';

import RequestAccount from './RequestAccount';

const containerStyle = {
  background: `url(${loginBg})`,
  height: 90 + 'vh',
  paddingTop: 10 + 'vh',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center center',
};

const logoStyle = {
  width: 200 + 'px',
  margin: 25 + 'px auto',
  display: 'block',
};

const loadingStyle = {
  paddingTop: 250 + 'px',
  display: 'flex',
  justifyContent: 'center',
};

const buttonStyle = {
  margin: '0 auto',
  marginTop: '10px',
  display: 'block',
};

const styles = {
  button: {
    margin: 1,
  },
  rightIcon: {
    marginLeft: 1,
  },
};

const AppContainer = ({ t, classes }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [displayIEWarningPopUpOpen, setDisplayIEWarningPopUpOpen] = useState(
    detect().name === 'ie'
  );
  const [requestingAccount, setRequestingAccount] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    return onAuthStateChanged(auth, user => {
      if (user) {
        setUser(user);
        setLoading(false);
      } else {
        setUser(null);
        setLoading(false);
      }
    });
  }, []);

  const closePopUps = () => {
    setDisplayIEWarningPopUpOpen(false);
  };

  if (requestingAccount)
    return <RequestAccount backToLogin={() => setRequestingAccount(false)} />;

  if (loading)
    return (
      <div style={loadingStyle}>
        <CircularProgress />
      </div>
    );

  return user ? (
    <BrowserRouter>
      <App user={user} />
    </BrowserRouter>
  ) : (
    <div style={containerStyle}>
      <img src={logo} style={logoStyle} />
      <FirebaseLogin />
      <ModalDialog
        modalTitle="Display-IE-Warning"
        modalDescription="Display-IE-Warning"
        openState={displayIEWarningPopUpOpen}
        onClose={closePopUps}
        modalHeadline={t('IEWarning.IEWarningHeadline')}
        modalSubHeadline={t('IEWarning.IEWarningSubHeadline')}
        buttonSx={styles.button}
        iconSx={styles.rightIcon}
        cancelOption={false}
        onSubmitClick={closePopUps}
        submitButtonText={t('IEWarning.submitButtonText')}
      />
      <Button
        variant="text"
        sx={styles.button}
        style={buttonStyle}
        onClick={() => setRequestingAccount(true)}
      >
        {t('requestAccount.doYouNeedAnAccount')}
      </Button>
    </div>
  );
};

export default withTranslation('translations')(AppContainer);
