import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';

import { Field, reduxForm } from 'redux-form';

import * as moment from 'moment';

import { withTranslation } from 'react-i18next';

import red from '@mui/material/colors/red';
import green from '@mui/material/colors/green';

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { ModalPreview } from '../utils/modalHelpers';

import { validate, returnField } from '../utils/formHelpers';

import {
  updateProject,
  updateProjectLockedState,
} from '../store/actions/projectActions';
import NoAccess from '../components/navigation/NoAccess';

import FileManagement from '../components/fileManagement/FileManagement';
import CardWrapper from '../components/common/CardWrapper';

import RFILetter from '../components/common/RFILetter';
import { useTheme } from '@mui/material';

const inlineStyles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    color: '#000',
  },
  tabRoot: {},
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(1) * 2,
  },
  colorSwitchBase: {
    color: green[500],
    '& + $colorBar': {
      backgroundColor: green[500],
    },
  },
  colorBar: {},
  colorChecked: {
    color: red[500],
    '& + $colorBar': {
      backgroundColor: red[500],
    },
  },
});

let ProjectInfo = ({
  pristine,
  submitting,
  handleSubmit,
  formCurrentValues,
  admin,
  pid,
  projects,
  updateProjectLockedState,
  t,
  tenRepArr,
  tenrepsNames,
  updateProject,
  initialValues,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleSubmitCheck = obj => {
    const hasNewStatus =
      R.path(['projectInfo', 'status'], obj) !==
      R.path(['projectInfo', 'status'], initialValues);
    updateProject(obj, hasNewStatus);
  };
  const theme = useTheme();
  const classes = inlineStyles(theme);
  return admin ? (
    <CardWrapper
      name={
        !pid
          ? t('projectDetailView.createNewProject')
          : t('projectDetailView.projectDetails')
      }
      headline
    >
      {pid && (
        <FormControlLabel
          control={
            <Switch
              checked={R.path([pid, 'projectInfo', 'locked'], projects)}
              onChange={() =>
                updateProjectLockedState(
                  pid,
                  !R.path([pid, 'projectInfo', 'locked'], projects)
                )
              }
              sx={{
                '&.MuiSwitch-root .MuiSwitch-switchBase':
                  classes.colorSwitchBase,
                '&.MuiSwitch-root .Mui-checked': classes.colorChecked,
              }}
            />
          }
          label={
            R.path([pid, 'projectInfo', 'locked'], projects)
              ? t('projectDetailView.projectLocked')
              : t('projectDetailView.projectUnLocked')
          }
        />
      )}
      <form onSubmit={handleSubmit(handleSubmitCheck)}>
        <CardWrapper
          name={t('projectDetailView.generalInformation')}
          headline
          transparent
        >
          <Grid
            container
            spacing={4}
            direction="row"
            alignContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item sm={12} md={6}>
              <Field
                key="projectInfo.name"
                name="projectInfo.name"
                component={returnField('text')}
                label={t('projectDetailView.nameLabel')}
                type="text"
                required
                fullWidth
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Field
                key="clientVisibleToTenrepOnly"
                name="clientVisibleToTenrepOnly"
                component={returnField('text')}
                label={t('projectDetailView.clientForTenrepLabel')}
                type="text"
                required
                fullWidth
              />
            </Grid>
            {pid && (
              <Grid item sm={12} md={6}>
                <Field
                  key="projectInfo.propertyType"
                  name="projectInfo.propertyType"
                  component={returnField('text')}
                  label={t('projectDetailView.propertyTypeLabel')}
                  type="text"
                  required
                  fullWidth
                />
              </Grid>
            )}
            {pid && (
              <Grid item sm={12} md={6}>
                <Field
                  key="projectInfo.size"
                  name="projectInfo.size"
                  component={returnField('text')}
                  label={t('projectDetailView.sizeLabel')}
                  type="text"
                  required
                  fullWidth
                  adornment="m2"
                />
              </Grid>
            )}
            {pid && (
              <Grid item sm={12} md={6}>
                <Field
                  key="projectInfo.area"
                  name="projectInfo.area"
                  component={returnField('text')}
                  label={t('projectDetailView.areaLabel')}
                  type="text"
                  required
                  fullWidth
                />
              </Grid>
            )}
            <Grid item sm={12} md={6}>
              <Field
                key="projectInfo.contactPerson"
                id="projectInfo.contactPerson"
                name="projectInfo.contactPerson"
                component={returnField('selectObj')}
                label={t('projectDetailView.contactPersonLabel')}
                type="select"
                selOpts={tenRepArr}
                required
                fullWidth
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Field
                key="projectInfo.responsible"
                id="projectInfo.responsible"
                name="projectInfo.responsible"
                component={returnField('select')}
                label={t('projectDetailView.responsibleLabel')}
                type="text"
                selOpts={tenrepsNames}
                required
                fullWidth
                multiple
              />
            </Grid>
          </Grid>
        </CardWrapper>
        <CardWrapper
          name={t('projectDetailView.rfiLetterContents')}
          headline
          transparent
        >
          <Grid
            container
            spacing={4}
            direction="row"
            alignContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item sm={12} md={6}>
              <Field
                key="projectInfo.detailsHeadline"
                name="projectInfo.detailsHeadline"
                component={returnField('text')}
                label={t('projectDetailView.detailsHeadlineLabel')}
                type="text"
                required
                fullWidth
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Field
                key="projectInfo.detailsSubheader"
                name="projectInfo.detailsSubheader"
                component={returnField('text')}
                label={t('projectDetailView.detailsSubheaderLabel')}
                type="text"
                required
                fullWidth
              />
            </Grid>
            {pid && (
              <Grid item sm={12} md={6}>
                <Field
                  key="projectInfo.detailsDescription"
                  name="projectInfo.detailsDescription"
                  component={returnField('textarea')}
                  label={t('projectDetailView.detailsDescriptionLabel')}
                  type="text"
                  rows={4}
                  fullWidth
                />
              </Grid>
            )}
            {pid && (
              <Grid item sm={12} md={6}>
                <Field
                  key="projectInfo.detailsSpace"
                  name="projectInfo.detailsSpace"
                  component={returnField('textarea')}
                  label={t('projectDetailView.detailsSpaceLabel')}
                  type="text"
                  rows={4}
                  fullWidth
                />
              </Grid>
            )}
            {pid && (
              <Grid item sm={12} md={6}>
                <Field
                  key="projectInfo.detailsLocation"
                  name="projectInfo.detailsLocation"
                  component={returnField('text')}
                  label={t('projectDetailView.detailsLocationLabel')}
                  type="text"
                  fullWidth
                />
              </Grid>
            )}
            {pid && (
              <Grid item sm={12} md={6}>
                <Field
                  key="projectInfo.detailsTimeline"
                  name="projectInfo.detailsTimeline"
                  component={returnField('text')}
                  label={t('projectDetailView.detailsTimelineLabel')}
                  type="text"
                  fullWidth
                />
              </Grid>
            )}
            {pid && (
              <Grid item sm={12} md={6}>
                <Field
                  key="projectInfo.detailsRentalPeriod"
                  name="projectInfo.detailsRentalPeriod"
                  component={returnField('text')}
                  label={t('projectDetailView.detailsRentalPeriodLabel')}
                  type="text"
                  fullWidth
                />
              </Grid>
            )}
            <Grid item sm={12} md={6}>
              <Field
                key="projectInfo.client"
                name="projectInfo.client"
                component={returnField('text')}
                label={t('projectDetailView.clientLabel')}
                type="text"
                required
                fullWidth
              />
            </Grid>
            {pid && (
              <Grid item sm={12} md={6}>
                <Field
                  name="projectInfo.detailsOther"
                  component={returnField('editor')}
                  label={t('projectDetailView.detailsOtherLabel')}
                  type="text"
                  fullWidth
                />
              </Grid>
            )}
            {pid && (
              <Grid item sm={12} md={6}>
                <FileManagement
                  documentId={pid}
                  files={R.path(['fileList'], projects[pid])}
                  collection="projects"
                  allowMultipleFiles
                />
              </Grid>
            )}
            <Grid item sm={12} md={6}>
              <Field
                key="projectInfo.status"
                id="projectInfo.status"
                name="projectInfo.status"
                component={returnField('select')}
                label="Status"
                disabled={!pid}
                type="text"
                selOpts={[
                  'Draft',
                  'Round 1',
                  'Round 2',
                  'Round 3',
                  'Round 4',
                  'Round 5',
                  'Round 6',
                  'Closed',
                  'Archived',
                ]}
                required
                fullWidth
                translateOptions
              />
            </Grid>
            {pid && (
              <Grid item sm={12} md={6}>
                <Field
                  key="projectInfo.deadline"
                  id="projectInfo.deadline"
                  name="projectInfo.deadline"
                  component={returnField('date')}
                  label={t('projectDetailView.deadlineLabel')}
                  type="date"
                  normalize={value => moment(value).format('YYYY-MM-DD')}
                  required
                  fullWidth
                />
              </Grid>
            )}
            <Grid item sm={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={classes.button}
                disabled={submitting || pristine}
              >
                {t('projectDetailView.saveProject')}
                <Icon sx={classes.rightIcon}>cloud_upload</Icon>
              </Button>
            </Grid>
          </Grid>
        </CardWrapper>
      </form>
      <ModalPreview
        modalTitle="Preview"
        modalDescription="Preview RFI Letter"
        openState={modalOpen}
        buttonSx={classes.button}
        iconSx={classes.rightIcon}
        onCancelClick={() => setModalOpen(false)}
        cancelButtonText={t('common.close')}
        submitting={submitting}
      >
        <Typography
          align="center"
          variant="heading"
          color="primary"
          component="h1"
          gutterBottom
        >
          RFI LETTER
        </Typography>
        <RFILetter
          detailsClient={R.path(['projectInfo', 'client'], formCurrentValues)}
          detailsDescription={R.path(
            ['projectInfo', 'detailsDescription'],
            formCurrentValues
          )}
          detailsHeadline={R.path(
            ['projectInfo', 'detailsHeadline'],
            formCurrentValues
          )}
          detailsSubheader={R.path(
            ['projectInfo', 'detailsSubheader'],
            formCurrentValues
          )}
          detailsSpace={R.path(
            ['projectInfo', 'detailsSpace'],
            formCurrentValues
          )}
          detailsLocation={R.path(
            ['projectInfo', 'detailsLocation'],
            formCurrentValues
          )}
          detailsTimeline={R.path(
            ['projectInfo', 'detailsTimeline'],
            formCurrentValues
          )}
          detailsRentalPeriod={R.path(
            ['projectInfo', 'detailsRentalPeriod'],
            formCurrentValues
          )}
          detailsOther={R.path(
            ['projectInfo', 'detailsOther'],
            formCurrentValues
          )}
          deadline={R.path(['projectInfo', 'deadline'], formCurrentValues)}
          contactPersonInfo={R.path(
            ['projectInfo', 'contactPersonInfo'],
            formCurrentValues
          )}
          pid={pid}
          files={R.path(['fileList'], projects[pid])}
          preview
        />
      </ModalPreview>
      {pid && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setModalOpen(true)}
        >
          {t('projectDetailView.previewRFILetter')}
        </Button>
      )}
    </CardWrapper>
  ) : (
    <NoAccess />
  );
};

const mapStateToProps = (state, ownProps) => {
  const projects = R.path(['project', 'projects'], state);
  const attr = R.path(['attr', 'attr'], state);
  const pid = R.path(['pid'], ownProps);
  const contactPerson = R.path(
    ['form', pid, 'initial', 'projectInfo', 'contactPerson'],
    state
  );
  const users = R.path(['user', 'users'], state);

  const values = pid
    ? R.path(['form', pid, 'values'], state)
    : R.path(['form', 'newProject', 'values'], state);

  // Inject client data to project data from separate reducer
  if (pid && projects[pid]) {
    projects[pid].clientVisibleToTenrepOnly = R.path(
      ['client', 'clients', pid, 'client'],
      state
    );
  }

  // get default attr
  const filterAttr = n => n.default;
  const filterBasicInfo = n => n.fieldCategory === 'basicInfo';
  const filterSpaceInfo = n => n.fieldCategory === 'spaceInfo';
  const defaultAttr = R.filter(filterAttr, attr);
  const defaultBasicInfo = R.filter(filterBasicInfo, defaultAttr);
  const defaultSpaceInfo = R.filter(filterSpaceInfo, defaultAttr);
  const basicInfoArr = R.keys(defaultBasicInfo).map(key => attr[key]);
  const spaceInfoArr = R.keys(defaultSpaceInfo).map(key => attr[key]);

  // get admin users
  const filterAdmins = n => n.mgmt.roles.admin;
  const tenReps = R.filter(filterAdmins, users);
  const tenRepArr = R.keys(tenReps).map(key => tenReps[key]);

  const tenrepsNames = [];
  const prependKeyAndDouble = num =>
    tenrepsNames.push(R.path(['profile', 'firstname'], num));
  R.mapObjIndexed(prependKeyAndDouble, tenReps);

  const initValues = {
    clientVisibleToTenrepOnly: '',
    projectInfo: {
      name: '',
      responsible: [],
      client: '',
      detailsHeadline: '',
      detailsSubheader: '',
      detailsDescription: '',
      detailsSpace: '',
      detailsLocation: '',
      detailsTimeline: '',
      detailsRentalPeriod: '',
      detailsOther: '',
      status: 'Draft',
      propertyType: '',
      contactPerson: [],
      size: 0,
      locked: false,
    },
    attr: {
      basicInfo: basicInfoArr,
      spaceInfo: spaceInfoArr,
      economicInfo: [],
      parking: [],
      realEstateServices: [],
      realEstateTechnical: [],
      risks: [],
      additionalInfo: [],
    },
    system: {
      createdDate: '',
    },
    users: {},
  };

  return {
    initialValues: pid ? projects[pid] : initValues,
    projects,
    admin: R.path(['user', 'admin'], state),
    form: R.path(['form'], ownProps),
    tenRepArr,
    tenrepsNames,
    contactPerson,
    formCurrentValues: values,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateProject: (values, hasNewStatus) =>
    dispatch(
      updateProject(
        values,
        hasNewStatus,
        R.path(['pid'], ownProps),
        R.path(['navigate'], ownProps),
        R.path(['t'], ownProps)
      )
    ),
  updateProjectLockedState: (pid, checked) =>
    dispatch(updateProjectLockedState(pid, checked, R.path(['t'], ownProps))),
});

const FormDecoratedComponent = reduxForm({
  validate,
  enableReinitialize: true,
  updateUnregisteredFields: true,
  keepDirtyOnReinitialize: true,
})(ProjectInfo);

ProjectInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormDecoratedComponent);

export default withTranslation('translations')(ProjectInfo);
