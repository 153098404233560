import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { withTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Icon from '@mui/material/Icon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import NotificationChip from '../common/NotificationChip';

import filterResults from '../../utils/filterResults';

import SortableTableFilters from '../Projects/SortableTableFilters';
import { ascComparator, descComparator } from '../../utils/comparators';

import styles from './GenericSortableTable.module.scss';
import { useTheme } from '@mui/material';

const inlineStyles = theme => ({
  toolbar: {
    paddingLeft: '0px',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  selectAllButton: {
    padding: '4px 8px',
    minWidth: '0px',
  },
});

const GenericSortableTable = ({
  data,
  labels,
  filters,
  clearFilters,
  saveFilters,
  filterLabels,
  filterTypes,
  handleRowClick,
  actionMenuCB,
  actionMenuCBII,
  icon,
  altIcon,
  actionMenuCBIIAltText,
  actionMenuCBIIText,
  actionMenuCBText,
  actionMenuCBAltText,
  projects,
  sid,
  users,
  t,
  menu,
}) => {
  const [sortBy, setSortBy] = useState(undefined);
  const [orderByStr, setOrderByStr] = useState('status');
  const [sortOrder, setSortOrder] = useState('desc');
  const [searchTerms, setSearchTerms] = useState(filters ? { ...filters } : {});
  const [checked, setChecked] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (filters !== searchTerms) {
      setSearchTerms(filters);
    }
  }, [filters, searchTerms]);

  useEffect(() => {
    changeSortBy({ key: 'status' });
    if (!sid) {
      selectFirstRow();
    }

    return () => {
      clearFilters();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!sid) {
      selectFirstRow();
    }
  });

  const getRows = () => {
    const filteredRows = searchForMatchingItems();
    const filteredExpandedRows = filteredRows.map(item => {
      const spaceOwnerId = R.head(Object.keys(R.path(['users'], item)));
      const companyName = R.path([spaceOwnerId, 'profile', 'company'], users);
      const newItem = { ...item, ...item.attr, company: companyName };
      return newItem;
    });
    return R.sort(sortBy, filteredExpandedRows);
  };

  const selectFirstRow = () => {
    const rows = getRows();
    R.prop('length', rows) > 0 && handleRowClick(R.path(['id'], R.head(rows)));
  };

  const saveSearchTerms = newTerms => {
    const mergedTerms = { ...searchTerms, ...newTerms };
    setSearchTerms(mergedTerms);
    saveFilters(mergedTerms);
  };

  const searchForMatchingItems = () => {
    return filterResults(data, searchTerms);
  };

  const handleChange = event => {
    saveSearchTerms({ [event.target.name]: event.target.value });
  };

  const handleChangeCheckbox = name => event => {
    saveSearchTerms({
      [name]: event.target.checked
        ? ''
        : [
            'Draft',
            'Round 1',
            'Round 2',
            'Round 3',
            'Round 4',
            'Round 5',
            'Round 6',
            'Closed',
          ],
    });
  };

  const changeSortOrder = () => {
    setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const changeSortBy = sortByThis => {
    changeSortOrder();
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc'; // updated sortOrder may not be reflected immediately, re-calculate it
    setSortBy(() =>
      newOrder === 'asc'
        ? ascComparator(sortByThis.key)
        : descComparator(sortByThis.key)
    );
    setOrderByStr(sortByThis.key);
  };

  const handleSelectAll = () => {
    const newChecked = {};
    data.map(item => (newChecked[item.id] = !selectAll));
    setChecked(newChecked);
    setSelectAll(!selectAll);
  };

  const toggleCheckbox = rowId => {
    const newState = !checked[rowId];
    setChecked(prevChecked => ({ ...prevChecked, [rowId]: newState }));
  };

  const sendEmail = () => {
    const emails = getEmailList(checked);

    if (window) {
      window.location.href = `mailto:?bcc=${emails
        .toString()
        .replace(/,/g, '; ')}`;
    }
  };

  const getEmailList = selectedRowIds => {
    const filterSelectedSpaces = data.filter(
      item => R.path([R.path(['id'], item)], selectedRowIds) === true
    );

    const spaceOwnerEmails = R.uniq(
      filterSelectedSpaces.map(item => {
        const spaceOwnerId = R.head(Object.keys(R.path(['users'], item)));
        const email = R.path([spaceOwnerId, 'profile', 'email'], users);
        return email;
      })
    );
    return spaceOwnerEmails;
  };

  const rows = getRows();

  const ActionMenu = menu;

  const theme = useTheme();
  const classes = inlineStyles(theme);
  return R.prop('length', rows) === 0 ? (
    <div>
      <div style={classes.toolbar}>
        <SortableTableFilters
          data={data}
          handleChange={e => handleChange(e)}
          handleChangeCheckbox={e => handleChangeCheckbox(e)}
          searchTerms={searchTerms}
          filterLabels={filterLabels}
          filterTypes={filterTypes}
        />
      </div>
      <Divider />
      <Typography
        style={{ paddingTop: '20px', paddingBottom: '20px' }}
        variant="h6"
      >
        {t('spaces.noAttachedSpaces')}
      </Typography>
    </div>
  ) : (
    <div>
      <div style={classes.toolbar}>
        <SortableTableFilters
          data={data}
          handleChange={e => handleChange(e)}
          handleChangeCheckbox={e => handleChangeCheckbox(e)}
          searchTerms={searchTerms}
          filterLabels={filterLabels}
          filterTypes={filterTypes}
        />
      </div>
      <Divider />
      <div className={styles.tableWrapper}>
        <Table sx={styles.table} padding="none">
          <TableHead>
            <TableRow>
              <TableCell padding="normal">
                <Button
                  sx={classes.selectAllButton}
                  variant="outlined"
                  color="primary"
                  onClick={handleSelectAll}
                >
                  {!selectAll
                    ? t('participants.selectAll')
                    : t('participants.clearSelection')}
                </Button>
              </TableCell>
              {Array.from(labels).map((label, index) => (
                <TableCell
                  key={`${label}${index}`}
                  className={styles.tableLabel}
                  onClick={() => changeSortBy(label)}
                >
                  <TableSortLabel
                    active={orderByStr === label.key}
                    direction={sortOrder}
                  >
                    {label.finnish}
                  </TableSortLabel>
                </TableCell>
              ))}
              {menu && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              const oKeys = row.objectKeys;
              const spaceOwnerId = R.head(Object.keys(R.path(['users'], row)));
              const userIsAdmin = R.path(
                [spaceOwnerId, 'mgmt', 'roles', 'admin'],
                users
              );
              const companyName = R.path(
                [spaceOwnerId, 'profile', 'company'],
                users
              );

              return (
                <TableRow
                  className={styles.tableRow}
                  key={row.id}
                  onClick={() => handleRowClick(R.path(['id'], row))}
                  id={row.id}
                  selected={R.path(['id'], row) === sid}
                  style={{
                    backgroundColor: R.path(['id'], row) === sid && '#2D3E80',
                  }}
                >
                  <TableCell>
                    <Checkbox
                      checked={!!checked[row.id]}
                      tabIndex={-1}
                      disableRipple
                      onClick={() => toggleCheckbox(row.id)}
                      style={{ color: R.path(['id'], row) === sid && '#fff' }}
                    />
                  </TableCell>
                  {Array.from(oKeys).map((objectKey, index) => (
                    <TableCell
                      key={`${objectKey}${index}`}
                      style={{ color: R.path(['id'], row) === sid && '#fff' }}
                    >
                      {Array.isArray(R.path([objectKey], row)) ? (
                        R.path([objectKey], row).join(', ')
                      ) : objectKey !== 'status' ? (
                        objectKey === 'company' ? (
                          <div style={{ fontWeight: userIsAdmin ? '600' : '' }}>
                            {companyName}
                          </div>
                        ) : objectKey === 'rejection' ? (
                          !row.active ? (
                            <NotificationChip
                              label={t('spaces.setInactiveByUser')}
                              notActive={R.path(['active'], row)}
                              notCandidate={R.path(['candidate'], row)}
                            />
                          ) : (
                            R.pathOr(
                              R.pathOr('-', [objectKey], row),
                              ['attr', objectKey],
                              row
                            )
                          )
                        ) : (
                          R.pathOr(
                            R.pathOr('-', [objectKey], row),
                            ['attr', objectKey],
                            row
                          )
                        )
                      ) : (
                        <NotificationChip
                          statusEqual={
                            R.path(['status'], row) ===
                            R.path(
                              [
                                R.path(['project'], row),
                                'projectInfo',
                                'status',
                              ],
                              projects
                            )
                          }
                          notCandidate={!R.path(['candidate'], row)}
                          notActive={!R.path(['active'], row)}
                          label={
                            R.path(['active'], row)
                              ? t(`options.${R.path([objectKey], row)}`)
                              : t('options.inactive')
                          }
                        />
                      )}
                    </TableCell>
                  ))}

                  <TableCell>
                    <Icon
                      className={
                        row.onPresentation
                          ? styles.activeIcon
                          : styles.inActiveIcon
                      }
                    >
                      {row.onPresentation ? icon : altIcon}
                    </Icon>
                  </TableCell>
                  <TableCell>
                    {menu ? (
                      <ActionMenu
                        rowId={row.id}
                        menuItems={[
                          {
                            action: actionMenuCB,
                            icon: row.candidate ? 'not_interested' : 'undo',
                            contentText: row.candidate
                              ? actionMenuCBText
                              : actionMenuCBAltText,
                          },
                          {
                            action: actionMenuCBII,
                            icon: 'screen_share',
                            contentText: row.onPresentation
                              ? actionMenuCBIIText
                              : actionMenuCBIIAltText,
                          },
                        ]}
                      />
                    ) : null}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={sendEmail}
        disabled={!R.contains(true, R.values(checked))}
      >
        {t('mail.sendEmailToSelectedSpaceOwners')}
        <Icon sx={classes.rightIcon}>email</Icon>
      </Button>
    </div>
  );
};

GenericSortableTable.propTypes = {
  data: PropTypes.array.isRequired,
  filterLabels: PropTypes.object.isRequired,
  filterTypes: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
  saveFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  filters: PropTypes.object,
};

export default withTranslation('translations')(GenericSortableTable);
