import { REMOVE_FILE } from '../actions/fileActions';

const initialState = {};

export default function fileReducer(state = initialState, action) {
  switch (action.type) {
    case REMOVE_FILE:
      return {
        ...state,
      };
    default:
      return state;
  }
}
