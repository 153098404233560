import React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import Icon from '@mui/material/Icon';

import { withTranslation } from 'react-i18next';

import RFILetter from './RFILetter';
import RFILetterRow from './RFILetterRow';
import { useTheme } from '@mui/material';

const inlineStyles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  card: {
    display: 'flex',
    width: '100%',
    marginTop: '20px',
    position: 'relative',
  },
  flatCard: {
    display: 'flex',
    width: '100%',
    boxShadow: 'none',
    borderRadius: '0px',
  },
  headline: {
    borderBottom: '1px solid #ccc',
    textAlign: 'left',
    paddingBottom: '8px',
    paddingTop: '30px',
    color: '#2D3E80',
    fontWeight: 700,
  },
  detailsHeadline: {
    paddingLeft: '20px',
    fontSize: '40px',
  },
  normal: {
    textAlign: 'left',
    backgroundColor: '#2D3E80',
    color: '#fff',
    padding: '20px',
  },
  subHeadline: {
    textAlign: 'left',
    paddingTop: '20px',
    color: '#2D3E80',
  },
  details: {
    width: '100%',
    padding: '0',
  },
  content: {
    flex: '1 0 auto',
    paddingTop: '0px',
    paddingRight: '0px',
  },
  cover: {
    height: 151,
  },
  coverTransparent: {
    height: '50px',
    backgroundColor: 'transparent',
    fontSize: '50px',
    color: '#999',
  },
  spaceCover: {
    height: 151,
    marginLeft: '16px',
  },
  spaceCoverFallback: {
    height: 151,
    marginLeft: '16px',
    backgroundColor: '#ccc',
    fontSize: '50px',
    color: '#999',
  },
  controls: {
    display: 'flex',
    alignItems: 'left',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  expansionPanelSummary: {
    paddingLeft: '0px',
  },
  expansionPanel: {
    boxShadow: 'none',
  },
  field: {
    width: '98%',
    marginLeft: '2%',
  },
  caption: {
    color: '#555',
    textAlign: 'left',
    marginBottom: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
});

const CardWrapper = ({
  name,
  nameAddition,
  imageUrl,
  constrainImage,
  imageFallbackComponent,
  children,
  childrenTopMargin,
  indentContents,
  headline = false,
  shadow = false,
  transparent = false,
  normal = false,
  childContainer = false,
  detailsClient,
  detailsDescription,
  detailsHeadline,
  detailsSubheader,
  detailsSpace,
  detailsLocation,
  detailsTimeline,
  detailsRentalPeriod,
  detailsOther,
  detailsRightColumn,
  deadline,
  contactPersonInfo,
  isAdmin,
  contactDetailsName,
  contactDetailsPhone,
  contactDetailsEmail,
  contactDetailsCompany,
  caption,
  t,
  noPadding,
  noLeftPadding,
  mainPictureManagementComponent,
  mainPictureManagementComponentReplaceImage,
  pid,
  files,
}) => {
  const theme = useTheme();
  const classes = inlineStyles(theme);
  return (
    <Card
      sx={classes.flatCard}
      style={{
        marginBottom: shadow && '10px',
        backgroundColor: transparent
          ? 'transparent'
          : shadow
          ? 'whitesmoke'
          : '#fff',
      }}
    >
      <div style={classes.details}>
        {imageUrl && (
          <CardMedia
            sx={constrainImage ? classes.spaceCover : classes.cover}
            image={imageUrl}
            title={name}
          >
            {imageUrl && mainPictureManagementComponentReplaceImage}
          </CardMedia>
        )}
        {!imageUrl && imageFallbackComponent && (
          <div
            style={
              constrainImage
                ? classes.spaceCoverFallback
                : classes.coverTransparent
            }
          >
            {mainPictureManagementComponent}
          </div>
        )}
        <CardContent
          sx={classes.content}
          style={{
            paddingLeft: noLeftPadding && '0px',
            paddingRight: noPadding ? '0px' : '24px',
          }}
        >
          <Typography
            gutterBottom
            variant={headline ? 'h4' : 'h6'}
            component={headline ? 'h4' : 'h2'}
            sx={
              headline
                ? classes.headline
                : normal
                ? classes.normal
                : classes.subHeadline
            }
          >
            {name}{' '}
            {nameAddition && (
              <span style={{ fontSize: '.5em' }}>({nameAddition})</span>
            )}
            {childContainer && (
              <span
                style={{
                  float: 'right',
                  marginTop: childrenTopMargin || '-10px',
                }}
              >
                {childContainer}
              </span>
            )}
          </Typography>

          {caption && (
            <Typography gutterBottom variant="body2" sx={classes.caption}>
              {caption}
            </Typography>
          )}
          {contactDetailsName && (
            <span>
              {/**
            Space contact details
          */}
              {contactDetailsName && (
                <RFILetterRow
                  t={t}
                  label={t('rfiLetter.contactPerson')}
                  contactDetails
                  content={contactDetailsName}
                  variant="caption"
                  color="bodyTextColor"
                />
              )}
              {contactDetailsEmail && (
                <RFILetterRow
                  t={t}
                  label={t('rfiLetter.email')}
                  contactDetails
                  content={
                    <a href={`mailto:${contactDetailsEmail}`}>
                      {contactDetailsEmail}
                    </a>
                  }
                  variant="caption"
                  color="bodyTextColor"
                />
              )}
              {contactDetailsPhone && (
                <RFILetterRow
                  t={t}
                  label={t('rfiLetter.phone')}
                  contactDetails
                  content={contactDetailsPhone}
                  variant="caption"
                  color="bodyTextColor"
                />
              )}
              {contactDetailsCompany && (
                <RFILetterRow
                  t={t}
                  label={t('rfiLetter.company')}
                  contactDetails
                  content={contactDetailsCompany}
                  variant="caption"
                  color="bodyTextColor"
                />
              )}
            </span>
          )}
          {!isAdmin && detailsHeadline && (
            <Accordion defaultExpanded sx={classes.expansionPanel}>
              <AccordionSummary
                sx={classes.expansionPanelSummary}
                expandIcon={<Icon>expand_more</Icon>}
              >
                <Typography
                  align="left"
                  variant="h1"
                  color="primary"
                  sx={classes.detailsHeadline}
                >
                  {detailsHeadline && <b>{detailsHeadline}</b>}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={classes.details}>
                <Grid
                  direction="row"
                  spacing={3}
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item sm={8}>
                    <RFILetter
                      detailsClient={detailsClient}
                      detailsDescription={detailsDescription}
                      detailsHeadline={detailsHeadline}
                      detailsSubheader={detailsSubheader}
                      detailsSpace={detailsSpace}
                      detailsLocation={detailsLocation}
                      detailsTimeline={detailsTimeline}
                      detailsRentalPeriod={detailsRentalPeriod}
                      detailsOther={detailsOther}
                      deadline={deadline}
                      contactPersonInfo={contactPersonInfo}
                      pid={pid}
                      files={files}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    {detailsRightColumn && detailsRightColumn}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
          <div style={indentContents && classes.field}>{children}</div>
        </CardContent>
      </div>
    </Card>
  );
};

CardWrapper.propTypes = {
  indentContents: PropTypes.bool,
  subItem: PropTypes.bool,
  headline: PropTypes.bool,
  transparent: PropTypes.bool,
  shadow: PropTypes.bool,
  constrainImage: PropTypes.bool,
};

export default withTranslation('translations')(CardWrapper);
