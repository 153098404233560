import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';

import * as moment from 'moment';

import { Field, reduxForm } from 'redux-form';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';

import MainPictureManagement from '../components/fileManagement/MainPictureManagement';

import CardWrapper from '../components/common/CardWrapper';

import {
  deleteSpaceTemplate,
  updateSpaceTemplate,
  saveSpaceAsTemplate,
  updateAttachedSpace,
} from '../store/actions/spaceActions';

import FileManagement from '../components/fileManagement/FileManagement';
import { validate, returnField } from '../utils/formHelpers';
import { ModalDialog } from '../utils/modalHelpers';

import { withTranslation } from 'react-i18next';
import { withRouter } from '../utils/withRouter';
import { useTheme } from '@mui/material';

const inlineStyles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    color: '#000',
    padding: '20px',
    boxShadow: 'none',
  },
  content: {
    flexGrow: 1,
    flexBasis: '33.3%',
    minHeight: '800px',
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(1) * 2,
  },
  spaceDetailView: {
    borderLeft: '1px solid #eee',
    paddingLeft: '30px',
  },
});

function SpaceDetailView({
  openSpace,
  pristine,
  submitting,
  handleSubmit,
  saveSpaceAsTemplate,
  templates,
  sid,
  attr,
  isAdmin,
  children,
  presentation,
  openProject,
  openSpaceUser,
  users,
  spaceImageUrl,
  t,
  i18n,
  handleModalClose,
  navigate,
  updateSpaceTemplate,
  updateAttachedSpace,
  deleteSpaceTemplate,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [spaceToBeDeleted, setSpaceToBeDeleted] = useState('');

  const handleTemplateUpdate = obj => {
    if (templates) {
      updateSpaceTemplate(sid, obj);
    } else {
      updateAttachedSpace(sid, obj);
    }
  };

  const openDeleteSpaceModal = spaceId => {
    setModalOpen(true);
    setSpaceToBeDeleted(spaceId);
  };

  const deleteSpace = useCallback(() => {
    setModalOpen(false);
    navigate(`/${templates ? 'templates/' : 'spaces/'}`);
    deleteSpaceTemplate(spaceToBeDeleted);
  }, [deleteSpaceTemplate, navigate, templates, spaceToBeDeleted]);

  const closeDeleteSpaceModal = () => {
    setModalOpen(false);
  };

  const getLocale = () => {
    return i18n.language;
  };

  const theme = useTheme();
  const classes = inlineStyles(theme);

  // Replace the rest of the class render method with a functional component return
  return openSpace && sid ? (
    <CardWrapper
      name={`${R.path(['attr', 'address'], openSpace)}${
        R.path(['attr', 'spaceSize'], openSpace)
          ? `, ${R.path(['attr', 'spaceSize'], openSpace)} m2`
          : ''
      }`}
      childContainer={children}
      transparent
      normal
      imageUrl={spaceImageUrl}
      constrainImage
      imageFallbackComponent
      noPadding
      mainPictureManagementComponent={
        <MainPictureManagement
          allowMultipleFiles={false}
          documentId={sid}
          files={R.path(['mainImage'], openSpace)}
          collection={templates ? 'spaces' : 'attachedSpaces'}
          shadow
          presentation={presentation}
          disabled={presentation}
        />
      }
      mainPictureManagementComponentReplaceImage={
        <MainPictureManagement
          allowMultipleFiles={false}
          replaceImage
          documentId={sid}
          files={R.path(['mainImage'], openSpace)}
          collection={templates ? 'spaces' : 'attachedSpaces'}
          shadow
          presentation={presentation}
          disabled={presentation}
        />
      }
    >
      <form
        style={classes.content}
        onSubmit={handleSubmit(handleTemplateUpdate)}
      >
        {/* Print out space owner details */}
        {isAdmin && openSpaceUser && (
          <CardWrapper
            name={t('spaceDetail.contactPerson')}
            indentContents
            shadow
            contactDetailsName={`${R.path(
              [openSpaceUser, 'profile', 'firstname'],
              users
            )} ${R.path([openSpaceUser, 'profile', 'lastname'], users)}`}
            contactDetailsEmail={R.path(
              [openSpaceUser, 'profile', 'email'],
              users
            )}
            contactDetailsCompany={R.path(
              [openSpaceUser, 'profile', 'company'],
              users
            )}
            contactDetailsPhone={R.path(
              [openSpaceUser, 'profile', 'phone'],
              users
            )}
          />
        )}

        {isAdmin && !templates && (
          <CardWrapper
            name={t('spaceDetail.spaceStatus')}
            indentContents
            shadow
          >
            <Field
              key="status"
              id="status"
              name="status"
              component={returnField('select')}
              label="Status"
              type="text"
              disabled={presentation}
              selOpts={[
                'Draft',
                'Round 1',
                'Round 2',
                'Round 3',
                'Round 4',
                'Round 5',
                'Round 6',
                'Closed',
                'Archived',
              ]}
              fullWidth
              translateOptions
            />
          </CardWrapper>
        )}

        <FileManagement
          documentId={sid}
          files={R.path(['fileList'], openSpace)}
          collection={templates ? 'spaces' : 'attachedSpaces'}
          shadow
          presentation={presentation}
          disabled={presentation}
        />

        {Object.entries(attr).map(section => {
          // Section HEADLINE and INPUT FIELDS will be rendered based on this component

          return templates &&
            R.prop('length', R.nth(1, section)) === 0 ? null : (
            <CardWrapper
              name={t(`formEditor.${R.head(section)}`)}
              indentContents
              shadow
              key={R.head(section)}
            >
              {R.nth(1, section).map(item =>
                (isAdmin &&
                  openProject &&
                  R.any(
                    n => R.path(['key'], n) === R.path(['key'], item),
                    R.path(
                      ['attr', R.path(['fieldCategory'], item)],
                      openProject
                    )
                  )) ||
                templates ||
                R.has(R.path(['key'], item), R.path(['attr'], openSpace)) ? (
                  <Field
                    key={`attr.${R.path(['key'], item)}`}
                    name={`attr.${R.path(['key'], item)}`}
                    label={R.path(['label', getLocale()], item)}
                    component={returnField(R.path(['fieldType'], item))}
                    normalize={value =>
                      R.path(['fieldType'], item) === 'date'
                        ? moment(value).format('YYYY-MM-DD')
                        : value
                    }
                    disabled={
                      presentation ||
                      (!templates ? !isAdmin : R.path(['disabled'], item))
                    }
                    helperText={R.path(['desc', getLocale()], item)}
                    type={R.path(['fieldType'], item)}
                    required={false}
                    selOpts={R.path(['selOpts'], item)}
                    fullWidth
                    translateOptions
                    props={{ sid: sid }}
                  />
                ) : null
              )}
            </CardWrapper>
          );

          // If showing templates, do not render section if it does not have input fields to render
          // Otherwise render sections as normal
        })}

        {templates && !presentation && (
          <Button
            variant="contained"
            sx={classes.button}
            disabled={submitting}
            color="secondary"
            onClick={() => openDeleteSpaceModal(sid)}
          >
            {t('spaceDetail.removeTemplate')}
            <Icon sx={classes.rightIcon}>delete</Icon>
          </Button>
        )}
        {templates && !presentation && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={classes.button}
            disabled={submitting}
          >
            {t('spaceDetail.saveTemplate')}
            <Icon sx={classes.rightIcon}>cloud_upload</Icon>
          </Button>
        )}
        {!templates && isAdmin && !presentation && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={classes.button}
            disabled={submitting || pristine}
          >
            {t('spaceDetail.saveSpace')}
            <Icon sx={classes.rightIcon}>cloud_upload</Icon>
          </Button>
        )}
        {!templates && !presentation && (
          <Button
            variant="contained"
            sx={classes.button}
            disabled={submitting}
            color="secondary"
            onClick={() => {
              saveSpaceAsTemplate(sid);
              if (typeof window !== 'undefined') {
                window.scrollTo(0, 0);
              }
            }}
          >
            {t('spaceDetail.saveAsTemplate')}
            <Icon sx={classes.rightIcon}>cloud_upload</Icon>
          </Button>
        )}

        <ModalDialog
          modalTitle="Confirm-space-removal"
          modalDescription="Confirm-space-removal"
          openState={modalOpen}
          onClose={handleModalClose}
          modalHeadline={t('spaceDetail.confirmSpaceRemoval')}
          modalSubHeadline={spaceToBeDeleted}
          buttonSx={classes.button}
          iconSx={classes.rightIcon}
          onCancelClick={() => closeDeleteSpaceModal()}
          cancelButtonText={t('common.cancel')}
          onSubmitClick={() => deleteSpace()}
          submitButtonText={t('common.remove')}
          submitting={submitting}
        />
      </form>
    </CardWrapper>
  ) : (
    <div style={classes.content}>
      <Typography style={{ marginTop: '100px' }} variant="h6" component="h6">
        {t('spaceDetail.chooseSpaceToEdit')}
      </Typography>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  // No documentId ? If not there's no point of rendering anything further.
  // Conditional rendering will take care of the rest.

  const sid = R.path(['router', 'params', 'documentId'], ownProps);
  const spaces = R.path(['templates'], ownProps)
    ? R.path(['space', 'spaces'], state)
    : R.path(['space', 'attachedSpaces'], state);
  if (!sid || !spaces[sid]) {
    return {};
  }

  // USE TEMPLATES OR REAL ATTACHED SPACES ?

  const currentSpace = spaces[sid];

  // Return empty object if space is not among active ones if requested for them and vice versa - this prevents showing a space which is in the url, but is not part of this view
  if (
    R.path(['showActiveSpaces'], ownProps) &&
    (R.path(['active'], currentSpace) === false ||
      R.path(['candidate'], currentSpace) === false)
  ) {
    return {};
  }
  if (
    R.path(['showActiveSpaces'], ownProps) === false &&
    R.path(['active'], currentSpace) === true &&
    R.path(['candidate'], currentSpace) === true
  ) {
    return {};
  }

  // Take rfiAttributes and create an array of all the keys for later use plus filter out custom attributes
  const attr = R.path(['attr', 'attr'], state);

  const attrFiltered = R.path(['templates'], ownProps)
    ? R.filter(item => !R.path(['custom'], item), attr)
    : attr;
  const allAttrKeys = R.values(attrFiltered).map(item => R.path(['key'], item));

  const projects = R.path(['project', 'projects'], state);
  const currentProject = projects[R.path(['project'], currentSpace)];

  /**
   * Sort attributes according to specified section order
   */
  const sortAttr = (a, b) => {
    const sortingArr = [
      'basicInfo',
      'spaceInfo',
      'economicInfo',
      'parking',
      'realEstateServices',
      'realEstateTechnical',
      'risks',
      'additionalInfo',
    ];
    return sortingArr.indexOf(a) - sortingArr.indexOf(b);
  };

  // Take all project attributes, sort first by sortAttr and then order subitems by key
  const unOrderedAttr = R.path(['attr'], currentProject);

  const orderedAttr = {};
  unOrderedAttr &&
    Object.keys(unOrderedAttr)
      .sort(sortAttr)
      .forEach(key => {
        orderedAttr[key] = unOrderedAttr[key];
      });

  // Templates do not know anything about projects so we have to display attributes in a different way

  // Take attributes and create a new object with grouped attributes
  const allBasicInfoAttr = [
    ...R.values(
      R.filter(
        item => R.path(['fieldCategory'], item) === 'basicInfo',
        attrFiltered
      )
    ),
  ];
  const allSpaceInfoAttr = [
    ...R.values(
      R.filter(
        item => R.path(['fieldCategory'], item) === 'spaceInfo',
        attrFiltered
      )
    ),
  ];
  const allEconomicInfoAttr = [
    ...R.values(
      R.filter(
        item => R.path(['fieldCategory'], item) === 'economicInfo',
        attrFiltered
      )
    ),
  ];
  const allParkingAttr = [
    ...R.values(
      R.filter(
        item => R.path(['fieldCategory'], item) === 'parking',
        attrFiltered
      )
    ),
  ];
  const allRealEstateServicesAttr = [
    ...R.values(
      R.filter(
        item => R.path(['fieldCategory'], item) === 'realEstateServices',
        attrFiltered
      )
    ),
  ];
  const allRealEstateTechnicalAttr = [
    ...R.values(
      R.filter(
        item => R.path(['fieldCategory'], item) === 'realEstateTechnical',
        attrFiltered
      )
    ),
  ];
  const allRisksAttr = [
    ...R.values(
      R.filter(
        item => R.path(['fieldCategory'], item) === 'risks',
        attrFiltered
      )
    ),
  ];
  const allAdditionalInfoAttr = [
    ...R.values(
      R.filter(
        item => R.path(['fieldCategory'], item) === 'additionalInfo',
        attrFiltered
      )
    ),
  ];

  const sortBy = R.comparator(
    (a, b) =>
      Number(R.prop('templateOrder', a)) < Number(R.prop('templateOrder', b))
  );

  const attrExpanded = {
    basicInfo: R.path(['templates'], ownProps)
      ? R.sort(sortBy, allBasicInfoAttr)
      : allBasicInfoAttr,
    spaceInfo: R.path(['templates'], ownProps)
      ? R.sort(sortBy, allSpaceInfoAttr)
      : allSpaceInfoAttr,
    economicInfo: R.path(['templates'], ownProps)
      ? R.sort(sortBy, allEconomicInfoAttr)
      : allEconomicInfoAttr,
    parking: R.path(['templates'], ownProps)
      ? R.sort(sortBy, allParkingAttr)
      : allParkingAttr,
    realEstateServices: R.path(['templates'], ownProps)
      ? R.sort(sortBy, allRealEstateServicesAttr)
      : allRealEstateServicesAttr,
    realEstateTechnical: R.path(['templates'], ownProps)
      ? R.sort(sortBy, allRealEstateTechnicalAttr)
      : allRealEstateTechnicalAttr,
    risks: R.path(['templates'], ownProps)
      ? R.sort(sortBy, allRisksAttr)
      : allRisksAttr,
    additionalInfo: R.path(['templates'], ownProps)
      ? R.sort(sortBy, allAdditionalInfoAttr)
      : allAdditionalInfoAttr,
  };

  const extraKeys = [];

  // Add files support
  extraKeys.push('fileList');
  extraKeys.push('mainImage');
  // Add status to attributes for admin use
  R.path(['isAdmin'], ownProps) && extraKeys.push('status');

  // Take only attributes for form initial values that are relevant to this space
  const attributesToPick = R.path(['attr'], currentSpace)
    ? R.pick(allAttrKeys, R.path(['attr'], currentSpace))
    : {};
  const attributesToPickExtra = R.pick(extraKeys, currentSpace);

  const initialValues = {
    ...attributesToPickExtra,
    attr: {
      ...attributesToPick,
    },
  };

  const spaceMainPicture = R.path([sid, 'mainImage'], spaces);
  const spaceMainPictureKeys = R.keys(spaceMainPicture);
  const spaceMainPictureUrl =
    R.prop('length', spaceMainPictureKeys) > 0 &&
    R.path([R.head(spaceMainPictureKeys), 'resizedPath'], spaceMainPicture);

  return {
    sid,
    projects: R.path(['project', 'projects'], state),
    attr: R.path(['templates'], ownProps) ? attrExpanded : orderedAttr,
    spaces,
    openSpace: currentSpace,
    initialValues,
    users: R.path(['user', 'users'], state),
    openSpaceUser: R.head(Object.keys(R.path(['users'], currentSpace))),
    spaceImageUrl: spaceMainPictureUrl,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateSpaceTemplate: (sid, spaceContent) => {
    console.log('updateSpaceTemplate');
    dispatch(updateSpaceTemplate(sid, spaceContent, R.path(['t'], ownProps)));
  },
  updateAttachedSpace: (sid, spaceContent) => {
    console.log('updateAttachedSpace');
    dispatch(updateAttachedSpace(sid, spaceContent, R.path(['t'], ownProps)));
  },
  deleteSpaceTemplate: spaceId => {
    console.log('deleteSpaceTemplate');
    dispatch(deleteSpaceTemplate(spaceId, R.path(['t'], ownProps)));
  },
  saveSpaceAsTemplate: spaceId => {
    console.log('saveSpaceAsTemplate');
    dispatch(
      saveSpaceAsTemplate(
        spaceId,
        R.path(['navigate'], ownProps),
        R.path(['t'], ownProps)
      )
    );
  },
});

SpaceDetailView.propTypes = {
  openSpace: PropTypes.object,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  deleteSpaceTemplate: PropTypes.func.isRequired,
  updateSpaceTemplate: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  spaces: PropTypes.object,
  isAdmin: PropTypes.bool,
};

const FormDecoratedComponent = reduxForm({
  form: 'spaceDetail',
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  updateUnregisteredFields: true,
})(SpaceDetailView);

export default withRouter(
  withTranslation('translations')(
    connect(mapStateToProps, mapDispatchToProps)(FormDecoratedComponent)
  )
);
