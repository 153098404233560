import * as R from 'ramda';

import { UPDATE_PROJECT_NOTES_RESPONSE } from '../actions/projectNotesActions';

const initialState = {
  projectNotesInitialLoadComplete: false,
  projectNotes: {},
};

export default function projectNotesReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PROJECT_NOTES_RESPONSE:
      return {
        ...state,
        projectNotes: R.path(['projectNotes'], action),
        projectNotesInitialLoadComplete: true,
      };
    default:
      return state;
  }
}
