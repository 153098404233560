import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import Icon from '@mui/material/Icon';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';

import { ModalDialog, ModalFormDialog } from '../../utils/modalHelpers';

import { saveFilters, clearFilters } from '../../store/actions/filterActions';
import {
  updateAttachedSpace,
  updateAttachedSpaceOnPresentation,
  createSpaceAndAttachToProject,
} from '../../store/actions/spaceActions';
import ActionMenu from '../common/ActionMenu';
import GenericSortableTable from '../TenRepProject/GenericSortableTable';
import PrintableSpaceDetails from '../../containers/PrintableSpaceDetails';

import CardWrapper from '../common/CardWrapper';

import { withTranslation } from 'react-i18next';
import { withRouter } from '../../utils/withRouter';
import { useTheme } from '@mui/material';

const inlineStyles = theme => ({
  drawerPaper: {
    position: 'absolute',
    border: '0px',
    width: '100%',
    display: 'block',
  },
  toolbar: {
    paddingLeft: '20px',
  },
  docked: {
    flexBasis: '66.6%',
    position: 'relative',
  },
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});

let AttachedSpaces = ({
  createSpaceAndAttachToProject,
  openProject,
  pid,
  navigate,
  attachedSpaces,
  updateAttachedSpace,
  updateAttachedSpaceOnPresentation,
  spaceFilters,
  saveFilters,
  clearFilters,
  templates,
  isAdmin,
  sid,
  users,
  showActiveSpaces,
  projects,
  t,
}) => {
  const [addSpaceModalOpen, setAddSpaceModalOpen] = useState(false);
  const [
    cancelRejectionSpaceDialogOpen,
    setCancelRejectionSpaceDialogOpen,
  ] = useState(false);
  const [rejectSpaceDialogOpen, setRejectSpaceDialogOpen] = useState(false);
  const [targetSpace, setTargetSpace] = useState('');

  const openAddSpaceModal = () => setAddSpaceModalOpen(true);
  const closeAddSpaceModal = () => setAddSpaceModalOpen(false);

  const addSpaceToProject = initialData => {
    closeAddSpaceModal();
    createSpaceAndAttachToProject(
      undefined,
      undefined,
      pid,
      openProject,
      initialData,
      navigate
    );
  };

  const notInterested = () => {
    const isCandidate = attachedSpaces[sid]?.candidate;
    if (isCandidate) {
      setTargetSpace(sid);
      setRejectSpaceDialogOpen(true);
    } else {
      setTargetSpace(sid);
      setCancelRejectionSpaceDialogOpen(true);
    }
  };

  const setRejectionReason = initialData => {
    const updateObject = {
      candidate: false,
      rejection: initialData?.rejection,
    };
    setRejectSpaceDialogOpen(false);
    updateAttachedSpace(targetSpace, updateObject);
  };

  const cancelRejection = () => {
    const updateObject = {
      candidate: true,
      rejection: '',
    };
    setCancelRejectionSpaceDialogOpen(false);
    updateAttachedSpace(targetSpace, updateObject);
  };

  const closePopUps = () => {
    setCancelRejectionSpaceDialogOpen(false);
    setRejectSpaceDialogOpen(false);
  };

  const togglePresentationSelection = sid => {
    const selectedSpace = attachedSpaces[sid];
    const updateObject = {
      onPresentation: !selectedSpace.onPresentation,
    };
    updateAttachedSpaceOnPresentation(sid, updateObject);
  };

  const handleRowClick = rowId => {
    const temp = templates ? 'templates/' : 'spaces/';
    navigate(`/project/${pid}/${temp}${rowId}`);
  };

  const spacesArray = Object.entries(attachedSpaces).map(item => {
    const id = R.head(item);
    const fields = R.nth(1, item);
    if (item !== null) {
      return { id, ...fields };
    }
    return null;
  });

  // Get lists of relevant / declined spaces and list of emails of the owners of these spaces
  const relevantSpaces = spacesArray.filter(
    space => space.project === pid && space.candidate && space.active
  );
  const declinedSpaces = spacesArray.filter(
    space =>
      space.project === pid &&
      (space.candidate === false || space.active === false)
  );

  const refinedSpaces = Array.from(relevantSpaces).map(space => {
    const keys = ['city', 'address', 'spaceSize', 'status', 'company'];
    return {
      ...space,
      ...space.attr,
      objectKeys: keys,
      id: space.id,
    };
  });

  const refinedDroppedSpaces = Array.from(declinedSpaces).map(space => {
    const keys = [
      'city',
      'address',
      'spaceSize',
      'status',
      'company',
      'rejection',
    ];
    return {
      ...space,
      ...space.attr,
      objectKeys: keys,
      id: space.id,
    };
  });

  const labels = [
    { key: 'city', finnish: t('labels.city') },
    { key: 'address', finnish: t('labels.address') },
    { key: 'spaceSize', finnish: t('labels.spaceSize') },
    { key: 'status', finnish: 'Status' },
    { key: 'company', finnish: t('labels.company') },
    { key: 'onPresentation', finnish: t('labels.selected') },
  ];

  const droppedLabels = [
    { key: 'city', finnish: t('labels.city') },
    { key: 'address', finnish: t('labels.address') },
    { key: 'spaceSize', finnish: t('labels.spaceSize') },
    { key: 'status', finnish: 'Status' },
    { key: 'company', finnish: t('labels.company') },
    { key: 'rejection', finnish: t('labels.rejection') },
    { key: 'onPresentation', finnish: t('labels.selected') },
  ];

  const spaceFilterLabels = {
    city: t('labels.city'),
    address: t('labels.address'),
  };

  const spaceFilterTypes = {
    city: 'select',
    address: 'select',
  };

  const theme = useTheme();
  const classes = inlineStyles(theme);

  return (
    <CardWrapper
      name={
        showActiveSpaces
          ? t('labels.attachedSpaces')
          : t('labels.declinedSpaces')
      }
      nameAddition={`${R.prop(
        'length',
        showActiveSpaces ? refinedSpaces : refinedDroppedSpaces
      )} ${t('labels.pcs')}`}
      headline
      childrenTopMargin="-25px"
      childContainer={
        showActiveSpaces && (
          <Button
            variant="contained"
            color="primary"
            sx={classes.button}
            onClick={openAddSpaceModal}
          >
            {t('spaces.addNewSpace')}
            <Icon sx={classes.rightIcon}>add_circle</Icon>
          </Button>
        )
      }
    >
      <ModalDialog
        modalTitle="Cancel-rejection"
        modalDescription="Cancel-rejection"
        openState={cancelRejectionSpaceDialogOpen}
        onClose={closePopUps}
        modalHeadline={t('spaces.setAsCandidate')}
        modalSubHeadline=""
        buttonSx={classes.button}
        iconSx={classes.rightIcon}
        onCancelClick={closePopUps}
        cancelButtonText={t('common.cancel')}
        onSubmitClick={cancelRejection}
        submitButtonText={t('common.save')}
      />
      <ModalFormDialog
        modalTitle="Reject-space"
        modalDescription="Reject-space"
        openState={rejectSpaceDialogOpen}
        modalHeadline={t('presentation.setRejectionReason')}
        modalSubHeadline=""
        buttonSx={classes.button}
        iconSx={classes.rightIcon}
        onCancelClick={closePopUps}
        cancelButtonText={t('common.cancel')}
        cancelOption
        onSubmitClick={setRejectionReason}
        submitButtonText={t('common.save')}
        initValues={{}}
        fieldWidth={12}
        fields={[
          {
            key: 'rejection',
            label: t('labels.rejection'),
            type: 'textarea',
            required: true,
          },
        ]}
      />
      <div style={{ display: 'flex' }}>
        <Drawer
          variant="permanent"
          anchor="left"
          sx={classes.docked}
          PaperProps={{ sx: classes.drawerPaper }}
        >
          {showActiveSpaces ? (
            <GenericSortableTable
              data={refinedSpaces}
              labels={labels}
              filters={spaceFilters}
              filterLabels={spaceFilterLabels}
              filterTypes={spaceFilterTypes}
              saveFilters={saveFilters}
              clearFilters={clearFilters}
              navigate={navigate}
              menu={ActionMenu}
              handleRowClick={handleRowClick}
              actionMenuCB={notInterested}
              actionMenuCBII={togglePresentationSelection}
              actionMenuCBText={t('presentation.markAsUnselected')}
              actionMenuCBAltText={t('presentation.markAsSelected')}
              actionMenuCBIIText={t('presentation.removeFromPresentation')}
              actionMenuCBIIAltText={t('presentation.selectToPresentation')}
              icon="check_circle"
              altIcon="visibility_off"
              projects={projects}
              pid={pid}
              sid={sid}
              users={users}
            />
          ) : (
            <GenericSortableTable
              data={refinedDroppedSpaces}
              labels={droppedLabels}
              filters={spaceFilters}
              filterLabels={spaceFilterLabels}
              filterTypes={spaceFilterTypes}
              saveFilters={saveFilters}
              clearFilters={clearFilters}
              navigate={navigate}
              menu={ActionMenu}
              handleRowClick={handleRowClick}
              actionMenuCB={notInterested}
              actionMenuCBII={togglePresentationSelection}
              actionMenuCBText={t('presentation.markAsUnselected')}
              actionMenuCBAltText={t('presentation.markAsSelected')}
              actionMenuCBIIText={t('presentation.removeFromPresentation')}
              actionMenuCBIIAltText={t('presentation.selectToPresentation')}
              icon="check_circle"
              altIcon="visibility_off"
              projects={projects}
              pid={pid}
              sid={sid}
              users={users}
            />
          )}
        </Drawer>

        <PrintableSpaceDetails
          templates={templates}
          isAdmin={isAdmin}
          isInProject
          navigate={navigate}
          showActiveSpaces={showActiveSpaces}
          openProject={projects[pid]}
        />
      </div>
      <ModalFormDialog
        modalTitle="Add-space"
        modalDescription="Add-space"
        openState={addSpaceModalOpen}
        modalHeadline={t('modals.addNewSpace')}
        modalSubHeadline=""
        buttonSx={classes.button}
        iconSx={classes.rightIcon}
        onCancelClick={closeAddSpaceModal}
        cancelButtonText={t('common.cancel')}
        cancelOption
        onSubmitClick={addSpaceToProject}
        submitButtonText={t('common.save')}
        initValues={{}}
        fields={[
          {
            key: 'city',
            label: t('labels.city'),
            type: 'text',
            required: true,
          },
          {
            key: 'address',
            label: t('labels.address'),
            type: 'text',
            required: true,
          },
        ]}
      />
    </CardWrapper>
  );
};

const mapStateToProps = (state, ownProps) => {
  const spaceFilters = R.path(['filter', 'filters', 'attachedSpaces'], state);
  const attachedSpaces = R.path(['space', 'attachedSpaces'], state);
  const sid = R.path(['router', 'params', 'documentId'], ownProps);
  const spaces = '';
  const users = R.path(['user', 'users'], state);

  return {
    spaceFilters,
    attachedSpaces,
    spaces,
    sid,
    users,
    projects: R.path(['project', 'projects'], state),
  };
};
const mapDispatchToProps = (dispatch, ownProps) => ({
  saveFilters: filters => dispatch(saveFilters(filters, 'attachedSpaces')),
  clearFilters: () => dispatch(clearFilters()),
  updateAttachedSpace: (sid, obj) =>
    dispatch(updateAttachedSpace(sid, obj, R.path(['t'], ownProps))),
  updateAttachedSpaceOnPresentation: (sid, updateObject) =>
    dispatch(updateAttachedSpaceOnPresentation(sid, updateObject)),
  createSpaceAndAttachToProject: (
    template,
    sourceSpaceId,
    documentId,
    projectContent,
    initialData,
    navigate
  ) =>
    dispatch(
      createSpaceAndAttachToProject(
        template,
        sourceSpaceId,
        documentId,
        projectContent,
        initialData,
        navigate,
        R.path(['t'], ownProps)
      )
    ),
});

AttachedSpaces.propTypes = {
  sortBy: PropTypes.func,
  filteredRows: PropTypes.array,
  spaceFilters: PropTypes.object.isRequired,
  saveFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  navigate: PropTypes.func,
  projects: PropTypes.object.isRequired,
  documentId: PropTypes.string.isRequired,
};

AttachedSpaces = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AttachedSpaces)
);

export default withTranslation('translations')(AttachedSpaces);
