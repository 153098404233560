import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Card from '@mui/material/Card';
import Announcement from './Announcement';

import { withTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

const inlineStyles = theme => ({
  flatCard: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    boxShadow: 'none',
    borderRadius: '0px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

/**
 * Display space related announcement conditionally
 * @param {object} t
 * @param {object} openSpace
 * @param {string} projectStatus
 */
const ProjectAnnouncements = ({
  t,
  user,
  spacesArray,
  projectStatus,
  openProject,
}) => {
  const theme = useTheme();
  const classes = inlineStyles(theme);
  return (
    <Card sx={classes.flatCard}>
      {// Project has reached a new phase. You have no spaces participating to this project.
      !R.path(['admin'], user) &&
        projectStatus !== 'Round 1' &&
        projectStatus !== 'Draft' &&
        R.prop('length', spacesArray) === 0 && (
          <Announcement
            name={`${t('projectAnnouncements.noSpacesProjectStatusChange')}`}
            type="announcement"
            red
          />
        )}

      {// No active or candidate spaces in project.
      !R.path(['admin'], user) &&
        projectStatus !== 'Round 1' &&
        projectStatus !== 'Draft' &&
        R.prop(
          'length',
          R.filter(
            item =>
              R.propEq('candidate', true)(item) &&
              R.propEq('active', true)(item),
            spacesArray
          )
        ) === 0 && (
          <Announcement
            name={t('projectAnnouncements.noSpacesInProject')}
            type="announcement"
            red
          />
        )}

      {// Project is locked. Project will be re-opened again in the next round.
      R.path(['projectInfo', 'locked'], openProject) && (
        <Announcement
          name={t('projectAnnouncements.projectIsLocked')}
          subheader={t('projectAnnouncements.projectWillBeOpened')}
          type="announcement"
          green
        />
      )}

      {// Project has ended. Changes are no longer possible.
      projectStatus === 'Closed' && (
        <Announcement
          name={t('projectAnnouncements.projectHasEnded')}
          subheader={t('projectAnnouncements.noChangesPossible')}
          type="announcement"
          yellow
        />
      )}
    </Card>
  );
};

ProjectAnnouncements.propTypes = {
  t: PropTypes.func.isRequired,
  openProject: PropTypes.object.isRequired,
  projectStatus: PropTypes.string.isRequired,
};

export default withTranslation('translations')(ProjectAnnouncements);
