import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import CreateNewUser from '../components/userManagement/CreateNewUser';
import UserTable from '../components/userManagement/UserTable';
import NoAccess from '../components/navigation/NoAccess';
import CardWrapper from '../components/common/CardWrapper';

import { withTranslation } from 'react-i18next';

const UserManagementClass = ({ users, admin, t }) => (
  admin ?
    <div>
      <CardWrapper
        name={t('userManagement.addUsers')}
        headline
        transparent
      >
        <CreateNewUser userIds={R.keys(users)} />
      </CardWrapper>

      <CardWrapper
        name={t('userManagement.users')}
        headline
        transparent
      >
        <UserTable
          users={R.values(users)}
        />
      </CardWrapper>
    </div> : <NoAccess />
);

const mapStateToProps = state => ({
  users: R.path(['user', 'users'], state),
  admin: R.path(['user', 'admin'], state),
});

const UserManagement = connect(mapStateToProps)(withTranslation('translations')(UserManagementClass));

export default UserManagement;
