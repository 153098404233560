import * as R from 'ramda';
import * as moment from 'moment';

export const ascComparator = sortByThis => R.comparator((a, b) => (
  (typeof R.prop(sortByThis, a) === 'undefined') || R.prop(sortByThis, a) === '' ?
    true
    :
    ((typeof R.prop(sortByThis, b) === 'undefined') || R.prop(sortByThis, b) === '') ? false :
      isNaN(Number(R.prop(sortByThis, a))) ?
        (moment(R.prop(sortByThis, a), 'DD/MM/YYYY', true).isValid() && moment(R.prop(sortByThis, b), 'DD/MM/YYYY', true).isValid()) ?
          moment(R.prop(`${sortByThis}Unix`, a), 'X').isBefore(moment(R.prop(`${sortByThis}Unix`, b), 'X')) :
          String(R.prop(sortByThis, a)).toLowerCase() < String(R.prop(sortByThis, b)).toLowerCase()
        :
        Number(R.prop(sortByThis, a)) < Number(R.prop(sortByThis, b))));

export const descComparator = sortByThis => R.comparator((a, b) => (
  (typeof R.prop(sortByThis, b) === 'undefined') || R.prop(sortByThis, b) === '' ?
    true
    :
    ((typeof R.prop(sortByThis, a) === 'undefined') || R.prop(sortByThis, a) === '') ? false :
      isNaN(Number(R.prop(sortByThis, a))) ?
        (moment(R.prop(sortByThis, a), 'DD/MM/YYYY', true).isValid() && moment(R.prop(sortByThis, b), 'DD/MM/YYYY', true).isValid()) ?
          moment(R.prop(`${sortByThis}Unix`, b), 'X').isBefore(moment(R.prop(`${sortByThis}Unix`, a), 'X')) :
          String(R.prop(sortByThis, a)).toLowerCase() > String(R.prop(sortByThis, b)).toLowerCase() :
        Number(R.prop(sortByThis, a)) > Number(R.prop(sortByThis, b))));
