import {
  map,
  reduce,
  concat,
  pathOr,
  toPairs,
  insert,
} from "ramda";


/**
* convert an array to string for easy converting to Blob for export
* @param {[object]} arr            an array of objects
* @param {[string]} fields     array of fileds need to be selected
* @returns {string}
*/
const getBufferFromArray = (arr, sep) => {
  // This function will convert an list to string, elements are seperated by comma, with breakline at the end of each line
  const listToString = (list) => concat(reduce((acc, value) => concat(acc, `"${value}"${sep}`), '', list).slice(0, -1), '\n');
  // create string
  let str = '\uFEFF';
  // for each list in arr, convert to string and append to the str vairable
  str += reduce((acc, value)=>concat(acc, listToString(value)), '' , arr);
  // convert string to blob, return
  return str;
}

/**
* objToCsvBlob
* @param {string} obj                          the json object (not supporting nested for now)
* @param {[string]} fields                     array of fields need to be export from obj
* @param {string} [fileName=download.csv]      name of export file
* @param {string} [encoding=utf-8]             the encoding for the csv
* @returns {blob} 
* @example objToCsvBlob({
*      "random_key1": {
*          name: 'chen',
*          age: 12,
*      },
*      "random_key2": {
*          name: 'lee',
*          age: 21,
*      },
* }, ['name','age','gender'])
*/
export const objToCsvBlob = (obj, fields, separator=';', encoding='text/csv;encoding:utf-8') => {
  // convert the json to an array of json of selected fields
  const objFilterToArr = (obj) => reduce((arr, field) => concat(arr, [pathOr('', [field], obj)]), [], fields);
  const pairsToArr = (pair) => objFilterToArr(pair[1]);
  const arr = insert(0, fields, map(pairsToArr, toPairs(obj)));
  // Create buffer and convert to Blob
  const buf = getBufferFromArray(arr, separator);
  const fileBlob = new Blob([buf], {
      type: encoding,
  });
  return fileBlob;
}

/**
* arrToCsvBlob
* @param {string} array                          the json object (not supporting nested for now)
* @param {[string]} fields                     array of fields need to be export from obj
* @param {string} [fileName=download.csv]      name of export file
* @param {string} [encoding=utf-8]             the encoding for the csv
* @returns {blob} 
* @example arrToCsvBlob({
*        {
  *          name: 'chen',
  *          age: 12,
  *      },
  *      {
  *          name: 'lee',
  *          age: 21,
  *      },
  * }, ['name','age','gender'])
  */
  export const arrToCsvBlob = (array, fields, separator=';', encoding='text/csv;encoding:utf-8') => {
    // convert the json array to an array of json of selected fields
    const objFilterToArr = (obj) => reduce((arr, field) => concat(arr, [pathOr('', [field], obj)]), [], fields);
    const arr = insert(0, fields, map(objFilterToArr, array));
    // Create buffer and convert to Blob
    const buf = getBufferFromArray(arr, separator);
    const fileBlob = new Blob([buf], {
        type: encoding,
    });
    return fileBlob;
  }