import React from 'react';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import orange from '@mui/material/colors/orange';
import yellow from '@mui/material/colors/yellow';
import blue from '@mui/material/colors/blue';

const inlineStyles = {
  chip: {
    margin: 0,
  },
  green: {
    backgroundColor: green[500],
    color: '#fff',
  },
  red: {
    backgroundColor: red[500],
    color: '#fff',
  },
  orange: {
    backgroundColor: orange[500],
    color: '#eee',
  },
  yellow: {
    backgroundColor: yellow[500],
    color: '#000',
  },
  blue: {
    backgroundColor: blue[500],
    color: '#fff',
  },
};

/**
 * Chip used for displaying item status
 * @param {string} label        Chip text content
 * @param {obj} classes         Chip JSS classes
 * @param {string} color        Chip color
 */
const ColoredChip = ({ label, color }) => (
  <Chip label={label} sx={inlineStyles[color]} />
);

ColoredChip.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default ColoredChip;
