import * as R from 'ramda';

import {
  UPDATE_PROJECTS_RESPONSE,
  SAVE_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECTS_ATTR,
  SAVE_PROJECT_AS_DRAFT,
  UPDATE_PROJECT_INFO,

} from '../actions/projectActions';

const initialState = {
  productsInitialLoadComplete: false,
  projects: {},
  presentationView: false,
};

export default function projectReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PROJECTS_RESPONSE:
      return {
        ...state,
        projects: R.path(['projects'], action),
        productsInitialLoadComplete: true,
      };
    case SAVE_PROJECT:
      return {
        ...state,
      };
    case DELETE_PROJECT:
      return {
        ...state,
      };
    case UPDATE_PROJECTS_ATTR:
      return {
        ...state,
      };
    case UPDATE_PROJECT_INFO:
      return {
        ...state,
      };
    case SAVE_PROJECT_AS_DRAFT:
      return {
        ...state,
      };
    default:
      return state;
  }
}
