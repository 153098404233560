import React, { useState, useRef } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm, initialize } from 'redux-form';

import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';

import { validate, returnField } from '../../utils/formHelpers';
import { ascComparator, descComparator } from '../../utils/comparators';

const inlineStyles = {
  rightIcon: {
    marginLeft: 1,
  },
  tableLabel: {
    cursor: 'pointer',
    backgroundColor: 'rgb(227, 238, 255)',
    borderBottom: '2px solid black',
    height: '60px',
  },
};

const MailToUsers = ({
  users,
  t,
  projectUsers,
  doc,
  filteredAttachedSpaces,
  dispatch,
  handleSubmit,
}) => {
  const [selectAll, setSelectAll] = useState(true);
  const [sortOrder, setSortOrder] = useState('asc');
  const [orderByStr, setOrderByStr] = useState('company');
  const copyAreaRef = useRef(null);

  const toggleSelectAll = () => {
    const usersToSelect = R.map(() => selectAll, users);
    dispatch(initialize('mailToParticipants', usersToSelect));
    setSelectAll(!selectAll);
  };

  const sendEmail = obj => {
    const sendTo = R.reject(n => n === false, obj);
    const emails = R.values(R.pick(R.keys(sendTo), users)).map(
      user => user.profile.email
    );
    copyContents(emails.toString().replace(/,/g, '; '));
    if (window) {
      window.location.href = `mailto:?bcc=${emails.join('; ')}`;
    }
  };

  const copyContents = emails => {
    navigator.clipboard.writeText(emails).catch(err => {
      console.error('Failed to copy emails: ', err);
    });
  };

  const changeSortOrder = () => {
    setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const changeSortBy = sortByThis => {
    if (sortByThis === orderByStr) {
      changeSortOrder();
    } else {
      setOrderByStr(sortByThis);
    }
  };

  const enhanceUserArray = usersArray => {
    return usersArray.map(user => {
      const uid = R.path(['profile', 'uid'], user);
      const isOwnedByThisUser = val =>
        R.path(['users', uid], val) !== undefined;
      const filteredRelatedToThisUserAttachedSpaces = R.pickBy(
        isOwnedByThisUser,
        filteredAttachedSpaces
      );
      const isActiveAndCandidate = val =>
        R.path(['active'], val) && R.path(['candidate'], val);
      const filteredRelatedToThisUserActiveAndCandidateAttachedSpaces = R.pickBy(
        isActiveAndCandidate,
        filteredRelatedToThisUserAttachedSpaces
      );

      return {
        ...user,
        company: R.path(['profile', 'company'], user),
        participationStatus: projectUsers[uid],
        spacesAdded: `${
          R.keys(filteredRelatedToThisUserActiveAndCandidateAttachedSpaces)
            .length
        }/${R.keys(filteredRelatedToThisUserAttachedSpaces).length}`,
      };
    });
  };

  const rows = R.sort(
    sortOrder === 'asc'
      ? ascComparator(orderByStr)
      : descComparator(orderByStr),
    enhanceUserArray(R.values(users))
  );
  const theads = [
    'participationStatus',
    'spacesAdded',
    'name',
    'company',
    'role',
    'area',
    'email',
    'phone',
  ];

  const tableBodyMaxHeight = window.innerHeight * 0.5;
  return (
    <div>
      <form onSubmit={handleSubmit(sendEmail)}>
        <div
          style={{
            maxHeight: `${tableBodyMaxHeight}px`,
            overflowY: 'auto',
          }}
        >
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    padding: '15px',
                    backgroundColor: 'rgb(227, 238, 255)',
                    borderBottom: '2px solid black',
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={toggleSelectAll}
                  >
                    {selectAll
                      ? t('participants.selectAll')
                      : t('participants.clearSelection')}
                  </Button>
                </TableCell>
                {theads &&
                  theads.map(item => (
                    <TableCell
                      key={item}
                      sx={inlineStyles.tableLabel}
                      onClick={() => changeSortBy(item)}
                    >
                      <TableSortLabel
                        active={orderByStr === item}
                        direction={sortOrder}
                        sx={inlineStyles.sortLabel}
                      >
                        {t(`participants.${item}`)}
                      </TableSortLabel>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(user => {
                const uid = user.profile.uid;
                return (
                  <Field
                    key={uid}
                    id={uid}
                    name={uid}
                    component={returnField('CheckBoxListUsers')}
                    type="text"
                    item={user}
                    t={t}
                    projectUsers={projectUsers}
                    uid={uid}
                    pid={doc}
                    filteredAttachedSpaces={
                      enhanceUserArray([user])[0]
                        .filteredRelatedToThisUserAttachedSpaces
                    }
                    filteredAttachedActiveAndCandidateSpaces={
                      enhanceUserArray([user])[0]
                        .filteredRelatedToThisUserActiveAndCandidateAttachedSpaces
                    }
                  />
                );
              })}
            </TableBody>
          </Table>
        </div>
        <br />
        <Button type="submit" variant="contained" color="primary">
          {t('mail.sendEmail')}
          <Icon sx={inlineStyles.rightIcon}>email</Icon>
        </Button>
      </form>
      <br />
      <div id="copyArea" contentEditable="true" ref={copyAreaRef} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const attachedSpaces = R.path(['space', 'attachedSpaces'], state);
  const pid = R.path(['doc'], ownProps);
  const isPartOfProject = (val, key) => val.project === pid;
  const filteredAttachedSpaces = R.pickBy(isPartOfProject, attachedSpaces);

  const projectUsers = R.path(['project', 'projects', pid, 'users'], state);
  const projectStatus = R.path(
    ['project', 'projects', pid, 'projectInfo', 'status'],
    state
  );

  const users = R.map(() => false, R.path(['users'], ownProps));

  return {
    projectStatus,
    projectUsers,
    filteredAttachedSpaces,
    initialValues: users,
  };
};

const FormDecoratedComponent = reduxForm({
  form: 'mailToParticipants',
  validate,
})(MailToUsers);

export default withTranslation('translations')(
  connect(mapStateToProps, null)(FormDecoratedComponent)
);
