import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Icon from '@mui/material/Icon';

const ITEM_HEIGHT = 48;

const ActionMenu = ({ rowId, classes, menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <div>
      <IconButton
        aria-label="More"
        aria-controls={open ? 'long-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <Icon>more_vert</Icon>
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {menuItems.map(item => (
          <MenuItem
            key={`${rowId}${item.contentText}`}
            onClick={() => {
              item.action(rowId);
              handleClose();
            }}
            id={`${rowId}action`}
          >
            <Icon
              sx={{
                paddingRight: '20px',
              }}
            >
              {item.icon}
            </Icon>
            {item.contentText}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ActionMenu;
