import React from 'react';
import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const theme = createTheme(
  adaptV4Theme({
    typography: {
      fontFamily: '"SourceSerifPro", "Georgia"',
      fontSize: 13,
    },
    palette: {
      // Primary buttons and topics
      primary: {
        light: '#757ce8',
        main: '#2D3E80',
        dark: '#666',
        contrastText: '#fff',
      },
      // Secondary buttons
      secondary: {
        light: '#fff',
        main: 'rgba(224, 224, 224)',
        dark: '#666',
        contrastText: '#000',
      },
      background: {
        paper: '#fff',
      },
      bodyTextColor: '#03254c',
    },
    overrides: {
      /* Styles overrides for material-ui-pickers datepicker modal */
      MuiPickersCalendarHeader: {
        root: {
          borderBottom: '1px solid #DDD',
          paddingBottom: '8px',
        },
      },
      MuiPickersDay: {
        day: {
          color: '#2D3E80',
        },
        root: {
          '&$selected': {
            backgroundColor: '#2D3E80',
          },
        },
        current: {
          color: '#2D3E80',
        },
      },
      MuiButton: {
        outlinedPrimary: {
          border: '1px solid rgba(0, 0, 0, 0.23)',
          '&:hover': {
            backgroundColor: '#D8E2E2',
            border: '1px solid rgba(0, 0, 0, 0.23)',
          },
        },
      },
      MuiTab: {
        root: {
          minWidth: '160px',
        },
      },
      MuiTypography: {
        overline: {
          height: '23px',
          textTransform: 'lowercase',
          fontWeight: 'bold',
        },
      },
      MuiDatePickerToolbar: {
        title: {
          fontSize: '14px',
        },
      },
      MuiPickersToolbar: {
        root: {
          padding: '0px',
        },
        content: {
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
          '&:hover': {
            borderBottom: '3px solid rgba(0, 0, 0, 0.87)',
            cursor: 'pointer',
          },
        },
      },
      // hide cancel and ok
      MuiDialogActions: {
        root: {
          display: 'none !important',
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          border: '0px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
          borderRadius: '0px',
          '&:hover': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
            cursor: 'pointer',
          },
        },
      },
      MuiChip: {
        filledDefault: {
          background: grey[300],
        },
      },
      MuiInputLabel: {
        root: {
          position: 'absolute',
          left: '0px',
          top: '0px',
        },
        outlined: {
          position: 'absolute',
          left: '10px',
          top: '0px',
        },
      },
      MuiFormControlLabel: {
        root: {
          marginRight: '0px'
        }
      },
      MuiSwitch: {
        root: {
          marginLeft: '10px',
        }
      }
    },
  })
);

const NewsecTheme = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </StyledEngineProvider>
);

export default NewsecTheme;
