import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';

import * as moment from 'moment';

import Joyride from '../components/joyride/Joyride';

import { Field } from 'redux-form';
import Typography from '@mui/material/Typography';

import MainPictureManagement from '../components/fileManagement/MainPictureManagement';

import CardWrapper from '../components/common/CardWrapper';
import SpaceAnnouncements from '../components/common/SpaceAnnouncements';

import { updateAttachedSpace } from '../store/actions/spaceActions';

import FileManagement from '../components/fileManagement/FileManagement';
import { returnField } from '../utils/formHelpers';

import { withTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

const inlineStyles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    color: '#000',
    padding: '20px',
    boxShadow: 'none',
  },
  content: {
    flexGrow: 1,
    flexBasis: '33.3%',
    minHeight: '800px',
  },
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  spaceDetailView: {
    borderLeft: '1px solid #eee',
    paddingLeft: '30px',
  },
});

function LandlordSpaceDetailView({
  openSpace,
  sid,
  pid,
  children,
  presentation,
  openProject,
  spaceImageUrl,
  projectStatus,
  orderedAttr,
  attrCurrentValue,
  t,
  i18n,
}) {
  const getLocale = () => i18n.language;

  // Calculate relevant filled in keys vs. relevant total input fields
  const activeAttrKeys = [];
  const totalFields = R.sum(
    Object.entries(orderedAttr).map(section => {
      activeAttrKeys.push(...R.nth(1, section));
      return R.prop('length', R.nth(1, section));
    })
  );
  const filledInFields = R.keys(attrCurrentValue);
  const filteredFilledInFields = filledInFields.filter(item => {
    const active = activeAttrKeys.filter(attr => attr.key === item);
    return R.prop('length', active) > 0 ? item : null;
  });
  const amountOfFilledInFields = R.prop('length', filteredFilledInFields);
  const theme = useTheme();
  const classes = inlineStyles(theme);
  return openSpace && sid ? (
    <div style={classes.content} id="spaceDetailView">
      <Joyride
        view="spaceFeatures"
        steps={[
          {
            target: '#spaceDetailView',
            title: t('joyride.spaceFeatures.step0.headline'),
            content: t('joyride.spaceFeatures.step0.content'),
            disableBeacon: true,
            placement: 'top',
            styles: {
              options: {
                zIndex: 3000,
              },
            },
          },
          {
            target: 'tbody tr:first-child',
            title: t('joyride.spaceFeatures.step1.headline'),
            content: t('joyride.spaceFeatures.step1.content'),
            styles: {
              options: {
                zIndex: 3000,
              },
            },
          },
          {
            target: '#submitSpaces',
            title: t('joyride.spaceFeatures.step2.headline'),
            content: t('joyride.spaceFeatures.step2.content'),
            styles: {
              options: {
                zIndex: 3000,
              },
            },
          },
        ]}
      />
      <CardWrapper
        name={`${R.path(['attr', 'address'], openSpace)}${
          R.path(['attr', 'spaceSize'], openSpace)
            ? `, ${R.path(['attr', 'spaceSize'], openSpace)} m2`
            : ''
        } `}
        childContainer={children}
        transparent
        normal
        imageUrl={spaceImageUrl}
        constrainImage
        imageFallbackComponent
        noPadding
        sx={classes.content}
        mainPictureManagementComponent={
          <MainPictureManagement
            allowMultipleFiles={false}
            documentId={pid}
            document={sid}
            files={R.path(['mainImage'], openSpace)}
            collection="attachedSpaces"
            shadow
            presentation={presentation}
            disabled={
              !R.path(['candidate'], openSpace) ||
              !R.path(['active'], openSpace) ||
              R.path(['projectInfo', 'locked'], openProject) ||
              projectStatus === 'Closed' ||
              projectStatus === 'Archived'
            }
          />
        }
        mainPictureManagementComponentReplaceImage={
          <MainPictureManagement
            allowMultipleFiles={false}
            replaceImage
            documentId={pid}
            document={sid}
            files={R.path(['mainImage'], openSpace)}
            collection="attachedSpaces"
            shadow
            presentation={presentation}
            disabled={
              !R.path(['candidate'], openSpace) ||
              !R.path(['active'], openSpace) ||
              R.path(['projectInfo', 'locked'], openProject) ||
              projectStatus === 'Closed' ||
              projectStatus === 'Archived'
            }
          />
        }
      >
        <SpaceAnnouncements
          openSpace={openSpace}
          projectStatus={projectStatus}
          filledInAttributeFields={amountOfFilledInFields}
          totalAttributeFields={totalFields}
        />
        {Object.entries(orderedAttr).map(section =>
          R.prop('length', R.nth(1, section)) > 0 ? (
            <CardWrapper
              name={t(`formEditor.${R.head(section)}`)}
              indentContents
              shadow
              key={R.head(section)}
            >
              {R.nth(1, section).map(item => (
                <Field
                  key={`${sid}.attr.${R.path(['key'], item)}`}
                  name={`${sid}.attr.${R.path(['key'], item)}`}
                  label={R.path(['label', getLocale()], item)}
                  component={returnField(R.path(['fieldType'], item))}
                  normalize={value =>
                    R.path(['fieldType'], item) === 'date'
                      ? moment(value).format('YYYY-MM-DD')
                      : value
                  }
                  disabled={
                    !R.path(['candidate'], openSpace) ||
                    !R.path(['active'], openSpace) ||
                    R.path(['disabled'], item) ||
                    R.path(['projectInfo', 'locked'], openProject) ||
                    projectStatus === 'Closed' ||
                    projectStatus === 'Archived'
                  }
                  helperText={R.path(['desc', getLocale()], item)}
                  type={R.path(['fieldType'], item)}
                  required={R.path(['required'], item)}
                  selOpts={R.path(['selOpts'], item)}
                  fullWidth
                  translateOptions
                />
              ))}
            </CardWrapper>
          ) : null
        )}

        <FileManagement
          hideName
          documentId={pid}
          document={sid}
          files={R.path(['fileList'], openSpace)}
          collection="attachedSpaces"
          shadow
          presentation={presentation}
          disabled={
            !R.path(['candidate'], openSpace) ||
            !R.path(['active'], openSpace) ||
            R.path(['projectInfo', 'locked'], openProject) ||
            projectStatus === 'Closed' ||
            projectStatus === 'Archived'
          }
        />
      </CardWrapper>
    </div>
  ) : (
    <div style={classes.content}>
      <Typography style={{ marginTop: '100px' }} variant="h6" component="h6">
        {t('spaceDetail.chooseSpaceToEdit')}
      </Typography>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  // No documentId ? If not there's no point of rendering anything further.
  // Conditional rendering will take care of the rest.

  const sid = R.path(['sid'], ownProps);
  const spaces = R.path(['space', 'attachedSpaces'], state);
  if (!sid || !spaces[sid]) {
    return {};
  }

  const currentSpace = spaces[sid];

  const spaceMainPicture = R.path([sid, 'mainImage'], spaces);
  const spaceMainPictureKeys = R.keys(spaceMainPicture);
  const spaceMainPictureUrl =
    R.prop('length', spaceMainPictureKeys) > 0 &&
    R.path([R.head(spaceMainPictureKeys), 'resizedPath'], spaceMainPicture);

  return {
    projects: R.path(['project', 'projects'], state),
    attrCurrentValue: R.path(['form', 'project', 'values', sid, 'attr'], state),
    spaces,
    openSpace: currentSpace,
    users: R.path(['user', 'users'], state),
    openSpaceUser: R.head(Object.keys(R.path(['users'], currentSpace))),
    spaceImageUrl: spaceMainPictureUrl,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateAttachedSpace: (sid, spaceContent) =>
    dispatch(updateAttachedSpace(sid, spaceContent, R.path(['t'], ownProps))),
});

LandlordSpaceDetailView.propTypes = {
  openSpace: PropTypes.object,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  handleSubmit: PropTypes.func,
  deleteSpaceTemplate: PropTypes.func,
  updateSpaceTemplate: PropTypes.func,
  spaces: PropTypes.object,
};

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(LandlordSpaceDetailView)
);
