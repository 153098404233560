import { STATUS } from 'react-joyride';

export const CHANGE_JOYRIDE_STATE = 'CHANGE_JOYRIDE_STATE';

// Start a tour triggered by user click action on the nav action menu item. This will show the tour again even if it is already seen.
export const startTour = () => (dispatch) => {
  dispatch({
    type: 'CHANGE_JOYRIDE_STATE',
    userTriggeredTourInProgress: true,
  });
};

// Detect if joyride tour is finished and set userTriggeredTourInProgress to false to enable it to be started again PLUS set this specific tour as seen byt setting is false
export const changeJoyrideState = (data, view) => (dispatch) => {
  const { status } = data;

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    dispatch({
      type: 'CHANGE_JOYRIDE_STATE',
      [view]: false,
      userTriggeredTourInProgress: false,
    });
  }
};

