import * as R from 'ramda';

import { UPDATE_CLIENTS_RESPONSE } from '../actions/clientActions';
import { UPDATE_CLIENT } from '../actions/projectActions';

const initialState = {
  clientsInitialLoadComplete: false,
  clients: {},
};

export default function clientReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CLIENTS_RESPONSE:
      return {
        ...state,
        clients: R.path(['clients'], action),
        clientsInitialLoadComplete: true,
      };
    case UPDATE_CLIENT:
      return {
        ...state,
      };
    default:
      return state;
  }
}
