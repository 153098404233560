import * as R from 'ramda';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  getStorage,
  ref,
  getDownloadURL,
  getMetadata,
  uploadBytes,
} from 'firebase/storage';
import {
  initializeFirestore,
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  onSnapshot,
  setDoc,
  writeBatch,
  deleteField,
  deleteDoc,
  query,
  where,
} from 'firebase/firestore';

/**
 * Load firebase configuration from .env file and initialize firebase
 * .env file contents are loaded automatically under process.env in React
 */

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(config);
const auth = getAuth(app);

// Get a reference to the storage service
const storage = getStorage(app);

/**
 * Initialize firebase firestore database
 */
const db = initializeFirestore(app, {});

// Login screen functions
export const loginUser = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};
export const resetPassword = email => {
  return sendPasswordResetEmail(auth, email);
};

/**
 * UserException : Create an exception class UserException for throwing
 * @param {string} code       Error object property : code (not documented)
 * @param {string} message    Error object property : message
 */
export class UserException {
  constructor(code, message) {
    this.message = message;
    this.code = code;
  }
  // Make the exception convert to a pretty string when used as
  // a string (e.g. by the error console)
  // override
  toString() {
    return `${this.code}: "${this.message}"`;
  }
}

/**
 * Firebase common utils
 * Never use these directly to maintain a constant flow of data - access only from firebaseApi.js
 */

/**
 * queryFireStore : Use to GET collection (and document) contents from Firestore
 * @param {string} collection       firestore collection name
 * @param {string} document         firestore document id under collection
 * @returns {Promise}               document(s) object
 */
export const queryFirestore = async (collectionName, document) => {
  try {
    if (!collectionName) {
      throw new Error('queryFirestore: Collection name is required');
    } else if (collectionName && !document) {
      const ref = collection(db, collectionName);
      const querySnapshot = await getDocs(ref);
      const result = {};
      querySnapshot.forEach(doc => {
        result[doc.id] = doc.data();
      });
      return result;
    } else if (collectionName && document) {
      const ref = doc(db, collectionName, document);
      const docRef = await getDoc(ref);
      if (docRef.exists()) {
        return docRef.data();
      }
      return undefined;
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

/**
 * addFirestoreDoc : Add new firestore document to collection
 * @param {string} collection       firestore collection name
 * @param {object} obj              the firestore document contents you want to add
 * @returns {Promise}
 */
export const addFirestoreDoc = async (collectionName, obj) => {
  try {
    const ref = await addDoc(collection(db, collectionName), obj);
    await updateDoc(ref, {
      system: { createdDate: new Date(), updatedDate: new Date() },
    });
    return {
      message: 'addFirestoreDoc: Document successfully added!',
      id: ref.id,
    };
  } catch (err) {
    throw new Error(err.message);
  }
};

/**
 * subscribeToFirestoreCollection : Subscribe to Firestore collection

 * @param {string} collection       firestore collection name
 * @param {string} onUpdate
 * @returns {Promise}
 */
export const subscribeToFirestoreCollection = (collectionName, onUpdate) => {
  try {
    if (!collectionName) {
      throw new Error('collection cannot be empty');
    } else {
      return onSnapshot(collection(db, collectionName), snapshot => {
        const result = {};
        snapshot.forEach(doc => {
          result[doc.id] = doc.data();
        });
        onUpdate(result);
      });
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

/**
 * subscribeToFirestoreDocument : Subscribe to Firestore document
 * @param {string} collectionName   firestore collection name
 * @param {string} document         firestore document name
 * @param {function} onUpdate       callback to be called with the result
 * @returns {Promise}
 */
export const subscribeToFirestoreDocument = (
  collectionName,
  document,
  onUpdate
) => {
  try {
    if (!collectionName) {
      throw new Error('collection name can`t be empty');
    } else if (!document) {
      throw new Error('document name can`t be empty');
    } else {
      const docRef = doc(db, collectionName, document);
      return onSnapshot(docRef, snapshot => {
        const result = snapshot.data();
        onUpdate(result);
      });
    }
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * update firestore document
 * @param {string} collectionName   firestore collection name
 * @param {string} document         firestore document id under collection
 * @param {string} obj              the object you want to update
 * @example     updateFirestoreDoc('companies', '1EpRrc86y9U90y0MSHnC1HhrM543', {
                                   'profile.description': 'sdasdasdasdas',
                                   'profile.name': 'name',
                                   'profile.billingInfo.address': 'csacsac',
                                   'owners': [1,2,3]
                }).then().catch()
 * @returns {Promise}
 */
export const updateFirestoreDoc = async (collectionName, document, obj) => {
  try {
    const docData = await queryFirestore(collectionName, document);
    const docRef = doc(db, collectionName, document);
    const updateObject = {
      ...obj,
      system: {
        updatedDate: new Date(),
        createdDate: R.path(['system', 'createdDate'], docData),
      },
    };
    await updateDoc(docRef, updateObject);
    return {
      message: `Document ${document} in collection ${collectionName} successfully updated!`,
    };
  } catch (err) {
    console.log(
      `Firebase error trying to update document ${collectionName} in collection ${collection} using object ${JSON.stringify(
        obj
      )} leads to error: ${err}.`
    );
    throw new UserException(err.code, err.message);
  }
};

/**
 * Set firestore document
 * If merge is set to false, createdDate will be set to current date as it is considered a document creation with set id and content
 * @param {bool}   merge            use .set merge option
 * @param {string} collectionName   firestore collection name
 * @param {string} document         firestore document id under collection
 * @param {string} obj              the object you want to update
 * @example     setFirestoreDoc(true, 'companies', '1EpRrc86y9U90y0MSHnC1HhrM543', {
                                   'profile.description': 'sdasdasdasdas',
                                   'profile.name': 'name',
                                   'profile.billingInfo.address': 'csacsac',
                                   'owners': [1,2,3]
                }).then().catch()
 * @returns {Promise}
 */
export const setFirestoreDoc = async (merge, collectionName, document, obj) => {
  try {
    const docRef = doc(db, collectionName, document);
    const dateObj = merge
      ? { system: { updatedDate: new Date() } }
      : { system: { updatedDate: new Date(), createdDate: new Date() } };
    await setDoc(docRef, { ...obj, ...dateObj }, { merge });
    return {
      message: 'Document successfully updated!',
    };
  } catch (err) {
    console.log('firebase error: ', err);
    throw new UserException(err.code, err.message);
  }
};

/**
 * Set firestore document as batch
 * @param {bool}   merge            use .set merge option (currently not in use)
 * @param {string} collectionName   firestore collection name
 * @param {string} obj              all the items as one object you want to set
 * @example   setFirestoreDocBatch(true, 'companies', {
                                   'firstcompany': {something: 'something'},
                                   'secondcompany': {something: 'somethingelse'},
                }).then().catch()
 * @returns {Promise}
 */
export const setFirestoreDocBatch = async (merge, collectionName, obj) => {
  const batch = writeBatch(db);
  try {
    Object.entries(obj).map(item => {
      const ref = doc(db, collectionName, R.head(item));
      return batch.set(ref, R.nth(1, item));
    });
    await batch.commit();
  } catch (err) {
    console.log('firebase error: ', err);
    throw new UserException(err.code, err.message);
  }
};

/**
 * delete firestore field
 * @param {string} collection       firestore collection name
 * @param {string} document         the firestore document
 * @param {string} field            the field name (or point notation path) to be removed
 * @example deleteFirestoreDoc('companies','01uchdw4FIdbYNBn0lNDrxqk7jl1', 'files.qqwe986976qwr96').then().catch()
 * @returns {Promise}
 */
export const deleteFirestoreField = async (collectionName, document, field) => {
  try {
    const ref = doc(db, collectionName, document);
    await updateDoc(ref, {
      [field]: deleteField(),
    });
    return {
      message: 'Field successfully deleted!',
    };
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * delete firestore document
 * @param {string} collection       firestore collection name
 * @param {string} document         the firestore document id you want to delete
 * @example deleteFirestoreDoc('companies','01uchdw4FIdbYNBn0lNDrxqk7jl1').then().catch()
 * @returns {Promise}
 */
export const deleteFirestoreDoc = async (collectionName, document) => {
  try {
    const ref = doc(db, collectionName, document);
    await deleteDoc(ref);
    return {
      message: 'Document successfully deleted!',
    };
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Get file download url
 * @param {string} path             file path at storage
 * @example getFile('images/oi1h25oi1h25oi1h2').then().catch()
 * @returns {Promise}
 */
export const getFile = async path => {
  try {
    const fileRef = ref(storage, path);
    return await getDownloadURL(fileRef);
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Get file metadata
 * @param {string} folder             Folder at storage
 * @param {string} key                Key (File name) at storage
 * @param {bool}   isFileAnImage      Is file an image as boolean
 * @example getFileMetadata('images/oi1h25oi1h25oi1h2').then().catch()
 * @returns {Promise}
 */
export const getFileMetadata = async (folder, key, isFileAnImage) => {
  try {
    const filePath = isFileAnImage
      ? `${folder}/resized_${key}`
      : `${folder}/${key}`;
    const fileRef = ref(storage, filePath);
    return await getMetadata(fileRef);
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Upload file to storage
 * @param {string} name             new file name (should only consist of numbers or letters as slashes, points etc in the file name break up firestore storage)
 * @param {object} file             file interface
 * @param {string} folder           new or existing folder name
 */
export const uploadFirestore = async (name, file, folder) => {
  try {
    const metaData = {
      contentType: file.type,
    };
    const fileRef = ref(storage, `${folder}/${name}`);
    await uploadBytes(fileRef, file, metaData);
    return { message: 'File successfully updated' };
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * subscribeToUserDocs : Subscribe to Firestore docs user has access

 * @param {string} collection       firestore collection name
 * @param {string} onUpdate
 * @returns {Promise}
 */
export const subscribeToUsersDocs = (collectionName, onUpdate) => {
  const user = auth.currentUser;

  try {
    if (!collectionName) {
      throw new Error('collection name can`t be empty');
    } else {
      const collectionRef = collection(db, collectionName);
      const q = query(collectionRef, where(`users.${user.uid}`, '>=', 0));

      return onSnapshot(q, snapshot => {
        const result = {};
        snapshot.forEach(doc => {
          result[doc.id] = doc.data();
        });
        onUpdate(result);
      });
    }
  } catch (err) {
    console.log('firebase error: ', err);
    throw new UserException(err.code, err.message);
  }
};

/**
 * subscribeToUserProfile : Subscribe to Firestore docs user has access

 * @param {string} collection       firestore collection name
 * @param {string} onUpdate
 * @returns {Promise}
 */
export const subscribeToUserProfile = (collectionName, onUpdate) => {
  const user = auth.currentUser;
  const docRef = doc(db, collectionName, user.uid);

  try {
    if (!collectionName) {
      throw new Error('collection name can`t be empty');
    } else {
      return onSnapshot(docRef, docSnap => {
        if (docSnap.exists()) {
          const result = {};
          result[docSnap.data().profile.uid] = docSnap.data();
          onUpdate(result);
        } else {
          throw new Error(`No such document in "${collectionName}"`);
        }
      });
    }
  } catch (err) {
    console.log('firebase error: ', err);
    throw new UserException(err.code, err.message);
  }
};

export const getCurrentUserId = () => {
  const userId = auth.currentUser.uid;
  return userId;
};

export const checkIfUserIsAdmin = async () => {
  const user = auth.currentUser;
  const docRef = doc(db, 'users', user.uid);
  const docSnap = await getDoc(docRef);
  return docSnap.data()['mgmt']['roles']['admin'];
};

export const checkIfUserIsUpdatingOwnInfo = async updatedUser => {
  const currentUser = auth.currentUser;
  return updatedUser.uid === currentUser.uid;
};
