import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import * as moment from 'moment';

import Button from '@mui/material/Button';

import CardWrapper from '../components/common/CardWrapper';

import { withTranslation } from 'react-i18next';
import { withRouter } from '../utils/withRouter';

import exportSpaces from '../utils/dataExport';

const inlineStyles = {
  content: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    marginTop: 10,
    marginBottom: '20px',
    minHeight: 600,
  },
};

let Export = ({
  spaces,
  attachedSpaces,
  templates,
  isAdmin,
  projects,
  t,
  i18n,
  attr,
}) => {
  // Hide draft attached spaces from Tenrep in this view, disabled for now
  // const filteredAttachedSpaces = isAdmin ? R.filter(n => n.status !== 'Draft', attachedSpaces) : attachedSpaces;
  const filteredAttachedSpaces = attachedSpaces;

  // Display templates (spaces) or attached spaces depending on route
  const spacesToUse = templates ? spaces : filteredAttachedSpaces;

  const spacesToUseObject = Object.entries(spacesToUse);

  const spacesArray = spacesToUseObject.map(([uid, fields]) => ({
    id: uid,
    ...fields,
  }));

  const refinedSpaces = spacesArray.map(space => {
    const keys = templates
      ? ['city', 'address', 'spaceSize', 'updated']
      : ['projectName', 'city', 'address', 'spaceSize', 'status', 'updated'];
    const updated = moment
      .unix(R.path(['system', 'updatedDate', 'seconds'], space))
      .format('DD/MM/YYYY');
    const updatedUnix = moment.unix(
      R.path(['system', 'updatedDate', 'seconds'], space)
    );
    const projectName = R.path(
      [space.project, 'projectInfo', 'detailsHeadline'],
      projects
    );
    const propertyType = R.path(
      [space.project, 'projectInfo', 'propertyType'],
      projects
    );
    const projectClientVisibleToTenrepOnly = R.path(
      [space.project, 'clientVisibleToTenrepOnly'],
      projects
    );
    const spaceStatusComparisonToProjectStatus =
      R.path(['status'], space) ===
      R.path([space.project, 'projectInfo', 'status'], projects)
        ? 'active'
        : '';
    return {
      ...space,
      ...space.attr,
      objectKeys: keys,
      updated,
      updatedUnix,
      projectName,
      propertyType,
      projectClientVisibleToTenrepOnly,
      statusComparison: spaceStatusComparisonToProjectStatus,
      activeState: space.active && space.candidate ? 'active' : '',
    };
  });

  const exportData = type => {
    /*
     * At this stage get the translated column headers for spaces that will be exported
     * This is passed to the generic export functions
     */
    const translations = {};
    // build a simplified translation structure for data export
    Object.keys(attr).forEach(key => {
      let attrName = attr[key]['key'];
      translations[attrName] = R.path([key, 'label', i18n.language], attr);
    });

    /* Send spaces & optional translations to the generic export lib */
    exportSpaces(refinedSpaces, type, 'all', translations);
  };

  return (
    refinedSpaces && (
      <CardWrapper
        name={t('export.spacesTitle')}
        nameAddition={`${refinedSpaces.length} ${t('labels.pcs')}`}
        caption={t('export.spacesSummary')}
        transparent
        headline
      >
        {isAdmin && (
          <div style={{ display: 'flex' }}>
            <Button
              variant="contained"
              sx={inlineStyles.button}
              onClick={() => exportData('csv')}
              style={{ marginTop: '20px', marginRight: '20px' }}
            >
              {t('export.loadSelectedAsCSV')}
            </Button>
            <Button
              variant="contained"
              sx={inlineStyles.button}
              onClick={() => exportData('json')}
              style={{ marginTop: '20px' }}
            >
              {t('export.loadSelectedAsJSON')}
            </Button>
          </div>
        )}
      </CardWrapper>
    )
  );
};
const mapStateToProps = (state, ownProps) => {
  const sid = R.path(['router', 'params', 'documentId'], ownProps);
  const attr = R.path(['attr', 'attr'], state);
  return {
    spaces: R.path(['space', 'spaces'], state),
    attachedSpaces: R.path(['space', 'attachedSpaces'], state),
    projects: R.path(['project', 'projects'], state),
    sid,
    attr,
  };
};

Export.propTypes = {
  spaces: PropTypes.object.isRequired,
  attachedSpaces: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};

Export = withRouter(connect(mapStateToProps)(Export));

export default withTranslation('translations')(Export);
