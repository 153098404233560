import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Hidden from '@mui/material/Hidden';
import MenuItem from '@mui/material/MenuItem';

import Icon from '@mui/material/Icon';

import { withTranslation } from 'react-i18next';
import { NavLink } from './CustomNavLink';

import { ModalDialog } from '../../utils/modalHelpers';

import logo from '../../assets/img/logo.png';

import styles from './Navigation.module.scss';

import { getAuth, signOut } from 'firebase/auth';

const Navigation = ({ isAdmin, runTour, t, i18n }) => {
  const auth = getAuth();
  const [userMenu, setUserMenu] = useState(false);
  const [helpMenu, setHelpMenu] = useState(false);
  const [
    displayContactInfoPopUpOpen,
    setDisplayContactInfoPopUpOpen,
  ] = useState(false);
  const [mainNaviIndex, setMainNaviIndex] = useState(0);

  const closePopUps = () => {
    setDisplayContactInfoPopUpOpen(false);
    handleClose();
  };

  const handleMainNaviChange = event => {
    setMainNaviIndex(event.currentTarget.tabIndex);
  };

  const handleUserMenu = event => {
    setUserMenu(event.currentTarget);
  };

  const handleHelpMenu = event => {
    setHelpMenu(event.currentTarget);
  };

  const handleClose = () => {
    setUserMenu(false);
    setHelpMenu(false);
  };

  const showContactPopup = () => {
    setDisplayContactInfoPopUpOpen(true);
  };

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  const getLocale = () => i18n.language;

  // Hide tour from admins and show only on specific pages
  const showTourMenuItem =
    !isAdmin &&
    (window.location.pathname === '/' ||
      window.location.href.indexOf('/project/') !== -1);
  return (
    <div>
      <AppBar
        position="static"
        style={{ backgroundColor: 'rgba(204, 224, 255, 1)' }}
      >
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item lg={3} sm={3}>
              <NavLink to="/">
                <img alt="logo" src={logo} className={styles.logo} />
              </NavLink>
            </Grid>
            <Grid item lg={6} sm={6}>
              <Hidden mdDown>
                <Tabs value={false}>
                  <Tab
                    tabIndex={0}
                    onClick={handleMainNaviChange}
                    icon={<Icon>work</Icon>}
                    label={t('navigation.projects')}
                    component={NavLink}
                    to="/"
                    activeclassname={
                      mainNaviIndex === 0 ? styles.menuLinkActive : undefined
                    }
                    className={`${styles.menuLink} projects`}
                  />
                  <Tab
                    tabIndex={1}
                    onClick={handleMainNaviChange}
                    icon={<Icon>location_city</Icon>}
                    label={t('navigation.spaces')}
                    component={NavLink}
                    to="/spaces"
                    activeclassname={
                      mainNaviIndex === 1 ? styles.menuLinkActive : undefined
                    }
                    className={`${styles.menuLink} spaces`}
                  />
                  <Tab
                    tabIndex={2}
                    onClick={handleMainNaviChange}
                    icon={<Icon>storage</Icon>}
                    label={t('navigation.templates')}
                    component={NavLink}
                    to="/templates"
                    activeclassname={
                      mainNaviIndex === 2 ? styles.menuLinkActive : undefined
                    }
                    className={`${styles.menuLink} templates`}
                  />
                </Tabs>
              </Hidden>
              <Hidden mdUp>
                <Tabs value={false}>
                  <Tab
                    tabIndex={0}
                    onClick={handleMainNaviChange}
                    icon={<Icon>work</Icon>}
                    isActive={true}
                    component={NavLink}
                    to="/"
                    activeclassname={
                      mainNaviIndex === 0 ? styles.menuLinkActive : undefined
                    }
                    className={styles.menuLink}
                  />
                  <Tab
                    tabIndex={1}
                    onClick={handleMainNaviChange}
                    icon={<Icon>location_city</Icon>}
                    component={NavLink}
                    to="/spaces"
                    activeclassname={
                      mainNaviIndex === 1 ? styles.menuLinkActive : undefined
                    }
                    className={styles.menuLink}
                  />
                  <Tab
                    tabIndex={2}
                    onClick={handleMainNaviChange}
                    icon={<Icon>storage</Icon>}
                    component={NavLink}
                    to="/templates"
                    activeclassname={
                      mainNaviIndex === 2 ? styles.menuLinkActive : undefined
                    }
                    className={styles.menuLink}
                  />
                </Tabs>
              </Hidden>
            </Grid>

            <Grid item lg={3} sm={3} className={styles.menuActionSection}>
              {isAdmin && (
                <div>
                  <NavLink to="/newproject">
                    <Hidden mdUp>
                      <Button variant="outlined" className={styles.button}>
                        <Icon>add</Icon>
                      </Button>
                    </Hidden>
                    <Hidden mdDown>
                      <Button variant="outlined" className={styles.button}>
                        {t('navigation.newProject')}
                      </Button>
                    </Hidden>
                  </NavLink>
                </div>
              )}
              <Hidden mdDown>
                <div className={styles.langIconHolder}>
                  <IconButton onClick={() => changeLanguage('en')} size="large">
                    <Typography
                      className={styles.langLink}
                      variant="caption"
                      style={{
                        textDecoration: getLocale() === 'en' ? 'underline' : '',
                      }}
                    >
                      EN
                    </Typography>
                  </IconButton>
                  <IconButton onClick={() => changeLanguage('fi')} size="large">
                    <Typography
                      className={styles.langLink}
                      variant="caption"
                      style={{
                        textDecoration: getLocale() === 'fi' ? 'underline' : '',
                      }}
                    >
                      FI
                    </Typography>
                  </IconButton>
                </div>
              </Hidden>

              {!isAdmin && (
                <div>
                  <IconButton
                    aria-owns={helpMenu ? 'menu-help' : null}
                    aria-haspopup="true"
                    onClick={handleHelpMenu}
                    size="large"
                  >
                    <Icon>help</Icon>
                  </IconButton>
                  <Menu
                    id="menu-help"
                    anchorEl={helpMenu}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={helpMenu}
                    onClose={handleClose}
                  >
                    {showTourMenuItem && (
                      <MenuItem
                        onClick={() => {
                          runTour();
                          handleClose();
                        }}
                      >
                        {t('navigation.startTour')}
                      </MenuItem>
                    )}
                    <MenuItem onClick={showContactPopup}>
                      {t('navigation.showContactPopup')}
                    </MenuItem>
                  </Menu>
                </div>
              )}

              {auth && (
                <div>
                  <IconButton
                    aria-owns={userMenu ? 'menu-appbar' : null}
                    aria-haspopup="true"
                    onClick={handleUserMenu}
                    size="large"
                  >
                    <Icon>account_circle</Icon>
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={userMenu}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={userMenu}
                    onClose={handleClose}
                  >
                    <Hidden mdUp>
                      <MenuItem
                        onClick={() => changeLanguage('en')}
                        style={{
                          textDecoration:
                            getLocale() === 'en' ? 'underline' : '',
                        }}
                      >
                        EN
                      </MenuItem>
                      <MenuItem
                        onClick={() => changeLanguage('fi')}
                        style={{
                          textDecoration:
                            getLocale() === 'fi' ? 'underline' : '',
                        }}
                      >
                        FI
                      </MenuItem>
                    </Hidden>
                    <MenuItem
                      component={NavLink}
                      to="/profile"
                      onClick={() => handleClose('fi')}
                    >
                      {t('navigation.ownProfile')}
                    </MenuItem>
                    {isAdmin && (
                      <MenuItem
                        component={NavLink}
                        to="/users"
                        onClick={() => handleClose('fi')}
                      >
                        {t('navigation.manageUsers')}
                      </MenuItem>
                    )}
                    {isAdmin && (
                      <MenuItem
                        component={NavLink}
                        to="/export"
                        onClick={() => handleClose('fi')}
                      >
                        {t('navigation.export')}
                      </MenuItem>
                    )}
                    <MenuItem onClick={() => signOut(auth)}>
                      {t('navigation.logout')}
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </Grid>
          </Grid>
        </Toolbar>
        <ModalDialog
          modalTitle="Display-Contact-Info"
          modalDescription="Display-Contact-Info"
          openState={displayContactInfoPopUpOpen}
          onClose={closePopUps}
          modalHeadline={t('navigation.contactPopupHeadline')}
          modalSubHeadline={t('navigation.contactPopupSubHeadline')}
          // buttonSx={classes.button}
          // iconSx={classes.rightIcon}
          cancelOption={false}
          onSubmitClick={closePopUps}
          submitButtonText={t('navigation.contactPopupConfirmButton')}
        />
      </AppBar>
    </div>
  );
};

Navigation.propTypes = {};

export default withTranslation('translations')(Navigation);
