import * as firebaseApi from '../../api/firebaseApi';

export const REMOVE_FILE = 'REMOVE_FILE';
export const UPLOAD_FILE = 'UPLOAD_FILE';

export const removeFileReference = (collection, documentId, fileId) => (dispatch) => {
  firebaseApi.removeFileReference(collection, documentId, `fileList.${fileId}`);
  return { type: REMOVE_FILE };
};

export const uploadFile = (file, documentRef, collection, allowMultipleFiles, mainPicture, t) => (dispatch) => {
  dispatch({
    type: 'ENQUEUE_SNACKBAR',
    notification: {
      key: new Date().getTime() + Math.random(),
      message: t('snackbarNotifications.uploadStarted'),
      options: {
        variant: 'success',
      },
    },
  });

  firebaseApi
    .uploadFile(file, documentRef, collection, allowMultipleFiles, mainPicture)
    .then(() =>

      Promise.all([
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.uploadSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]))
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${UPLOAD_FILE}_FAILURE`,
          error,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.uploadFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]));
};
