import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Chip from '@mui/material/Chip';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';

import { CSS } from '@dnd-kit/utilities';

import Icon from '@mui/material/Icon';

import CardWrapper from '../common/CardWrapper';

import { useTranslation, withTranslation } from 'react-i18next';

// Avatar mixes up font icon styles so override them with 1em
const inlineStyles = {
  avatar: {
    width: '1em',
    height: '1em',
    fontSize: '1em',
    marginLeft: '4px',
  },
};

const SortableItem = ({ id, value }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });
  const { i18n } = useTranslation('translations');
  const getLocale = () => i18n.language;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    'list-style-type': 'none',
  };
  return (
    <li ref={setNodeRef} style={style} {...attributes}>
      <Chip
        {...listeners}
        avatar={
          <Icon style={{ fontSize: '2em' }} sx={inlineStyles.avatar}>
            drag_indicator
          </Icon>
        }
        label={`${R.path(['label', getLocale()], value)} - ${R.path(
          ['fieldType'],
          value
        )}`}
        component="div"
        clickable
      />
    </li>
  );
};

const SortableForm = ({ items, cat, onReorder, headline }) => {
  const { t } = useTranslation('translations');
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );
  const handleDragEnd = event => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex(item => item.key === active.id);
      const newIndex = items.findIndex(item => item.key === over.id);
      onReorder(arrayMove(items, oldIndex, newIndex), cat);
    }
  };

  return (
    <CardWrapper name={headline}>
      {items && items.length ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={items.map(item => item.key)}
            strategy={verticalListSortingStrategy}
          >
            <ul style={{ textAlign: 'left' }}>
              {items.map(item => (
                <SortableItem key={item.key} id={item.key} value={item} />
              ))}
            </ul>
          </SortableContext>
        </DndContext>
      ) : (
        t('formEditor.noAttributes')
      )}
    </CardWrapper>
  );
};

SortableForm.propTypes = {
  items: PropTypes.array.isRequired,
  cat: PropTypes.string.isRequired,
  onReorder: PropTypes.func.isRequired,
  headline: PropTypes.string.isRequired,
};

function arrayMove(array, from, to) {
  // Remove the item from the array
  const [item] = array.splice(from, 1);
  // Place the item back into the array at the new index
  array.splice(to, 0, item);
  return array;
}

export default withTranslation('translations')(SortableForm);
