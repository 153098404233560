import * as R from 'ramda';
import * as firebaseApi from '../../api/firebaseApi';

export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USERS_REQUEST = 'UPDATE_USERS_REQUEST';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USERS_RESPONSE = 'UPDATE_USERS_RESPONSE';
export const UPDATE_USERS_RESPONSE_LANDLORD = 'UPDATE_USERS_RESPONSE_LANDLORD';
export const DISABLE_USER = 'DISABLE_USER';
export const ENABLE_USER = 'ENABLE_USER';
export const MAKE_USER_ADMIN = 'MAKE_USER_ADMIN';
export const REMOVE_USER_ADMIN_PRIVILEGES = 'REMOVE_USER_ADMIN_PRIVILEGES';
export const USER_CHANGE_START = 'USER_CHANGE_START';
export const USER_CHANGE_END = 'USER_CHANGE_END';
export const USER_CREATE_START = 'USER_CREATE_START';
export const USER_CREATE_END = 'USER_CREATE_END';

const updateUsersRequest = {
  type: UPDATE_USERS_REQUEST,
};

const updateUsersResponse = users => ({
  type: UPDATE_USERS_RESPONSE,
  users,
});

const updateUsersResponseLandlord = users => ({
  type: UPDATE_USERS_RESPONSE_LANDLORD,
  users,
});

let usersUnsubscribe;

export const unsubscribeFromUserProfiles = () => (dispatch) => {
  if (usersUnsubscribe) usersUnsubscribe();
};

export const subscribeToUserProfiles = isAdmin => (dispatch) => {
  if (isAdmin === true) {
    dispatch(updateUsersRequest);
    usersUnsubscribe = firebaseApi.subscribeToUserProfiles((users) => {
      dispatch(updateUsersResponse(users));
    });
  } else {
    dispatch(updateUsersRequest);
    usersUnsubscribe = firebaseApi.subscribeToCurrentUserProfile((users) => {
      dispatch(updateUsersResponseLandlord(users));
    }, isAdmin);
  }
};

export const createUser = (user, t) => (dispatch) => {
  dispatch({ type: USER_CREATE_START, user });

  Promise.all([
    dispatch({
      type: 'ENQUEUE_SNACKBAR',
      notification: {
        key: new Date().getTime() + Math.random(),
        message: t('userManagement.creatingUser'),
        options: {
          variant: 'default',
        },
      },
    }),
  ]);
  firebaseApi.createUser(user)
    .then(() => {
      Promise.all([
        dispatch({
          type: CREATE_USER,
        }),
        dispatch({
          type: USER_CREATE_END,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.newUserSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${CREATE_USER}_FAILURE`,
          error,
        }),
        dispatch({
          type: USER_CREATE_END,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.newUserFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]));
};

export const updateUserProfile = (newProfile, t) => (dispatch) => {
  Promise.all([
    dispatch({
      type: 'ENQUEUE_SNACKBAR',
      notification: {
        key: new Date().getTime() + Math.random(),
        message: t('snackbarNotifications.saving'),
        options: {
          variant: 'default',
        },
      },
    }),
  ]);
  const user = R.pathOr('', ['uid'], newProfile);
  return firebaseApi.updateUserProfile(newProfile, user)
    .then(() => {
      Promise.all([
        dispatch({
          type: UPDATE_USER_PROFILE,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${UPDATE_USER_PROFILE}_FAILURE`,
          error,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]));
};

export const updateUserProfileWithMerge = (newProfile, t) => (dispatch) => {
  Promise.all([
    dispatch({
      type: 'ENQUEUE_SNACKBAR',
      notification: {
        key: new Date().getTime() + Math.random(),
        message: t('snackbarNotifications.saving'),
        options: {
          variant: 'default',
        },
      },
    }),
  ]);
  const user = R.pathOr('', ['profile', 'uid'], newProfile);
  firebaseApi.updateUserProfileWithMerge(newProfile, user)
    .then(() => {
      Promise.all([
        dispatch({
          type: UPDATE_USER_PROFILE,
        }),
        dispatch({
          type: USER_CHANGE_END,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${UPDATE_USER_PROFILE}_FAILURE`,
          error,
        }),
        dispatch({
          type: USER_CHANGE_END,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]));
};

export const disableUser = (uid, t) => (dispatch) => {
  dispatch({ type: USER_CHANGE_START });
  firebaseApi.disableUser(uid)
    .then(() => {
      Promise.all([
        dispatch(updateUserProfileWithMerge({ profile: { uid }, mgmt: { locked: true } }, t)),
        dispatch({
          type: DISABLE_USER,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.disableUserSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${DISABLE_USER}_FAILURE`,
          error,
        }),
        dispatch({
          type: USER_CHANGE_END,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.disableUserFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]));
};

export const enableUser = (uid, t) => (dispatch) => {
  dispatch({ type: USER_CHANGE_START });

  firebaseApi.enableUser(uid)
    .then(() => {
      Promise.all([
        dispatch(updateUserProfileWithMerge({ profile: { uid }, mgmt: { locked: false } }, t)),
        dispatch({
          type: ENABLE_USER,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.enableUserSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${ENABLE_USER}_FAILURE`,
          error,
        }),
        dispatch({
          type: USER_CHANGE_END,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.enableUserFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]));
};

export const makeUserAdmin = (uid, t) => (dispatch) => {
  dispatch({ type: USER_CHANGE_START });
  
  firebaseApi.updateUserProfileWithMerge({ profile: { uid }, mgmt: { roles: { admin: true } } }, uid)
    .then(() => {
      Promise.all([
        dispatch({
          type: MAKE_USER_ADMIN,
        }),
        dispatch({
          type: USER_CHANGE_END,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.makeUserAdminSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${MAKE_USER_ADMIN}_FAILURE`,
          error,
        }),
        dispatch({
          type: USER_CHANGE_END,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.makeUserAdminFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]));
};

export const removeUserAdminPrivileges = (uid, t) => (dispatch) => {
  dispatch({ type: USER_CHANGE_START });

  firebaseApi.updateUserProfileWithMerge({ profile: { uid }, mgmt: { roles: { admin: false } } }, uid)
    .then(() => {
      Promise.all([
        dispatch({
          type: REMOVE_USER_ADMIN_PRIVILEGES,
        }),
        dispatch({
          type: USER_CHANGE_END,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.removeUserAdminPrivilegesSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${REMOVE_USER_ADMIN_PRIVILEGES}_FAILURE`,
          error,
        }),
        dispatch({
          type: USER_CHANGE_END,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.removeUserAdminPrivilegesFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]));
};
