/**
 * safeFilename
 * This is a sinmple functiopn that replaces all charactersd in a string that are not allowed in filenames on Windows/Linux.
 * It will also remove whitespace as that is allowed but annoying :)
 * @param {string} string The string to convert to a safe filename
 * @returns {string} The string that can be used as a filename
 */

export const safeFilename = (string) => {
    let name = string;
    //These are all the chars not allowed (or incovenient) in filenames on Windows/Linux
    [/\s/g,'<','>',':','"','\\','|','?','*','/'].map(
        (schar) => {name = name.replace(schar, '');return null;}
    ); 
    return name;
}

/**
 * triggerDownload
 * Trigger the brwser to initiate a download of the input data
 * @param {Blob|object|string} data The data to include in the file download,it can either be a Blob or if a object/string it is converted to a blob with the specified type/encoding
 * @param {string} fileName The name given to the file that will be downloaded
 * @param {string} encoding The encoding to be used, default is text/plain;encoding:utf-8 other examples are: application/json;encoding:utf-8 or text/csv;encoding:utf-8
 */
export const triggerDownload = (data, fileName, encoding='text/plain;encoding:utf-8') => {
    try {    
        // If we have a Blob, all good - else make one
        let fileBlob = data;
        if (data instanceof Blob) {
        } else { 
            if (typeof data === 'object') {
                fileBlob = new Blob([JSON.stringify(data, undefined, '  ')], {type: encoding});
            }
        }

        
        // check browser, if IE then
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(fileBlob, this.safeFilename(fileName));
        }
        else { // not IE browser
            const link = document.createElement('a');
            if (link.download !== undefined) {
            const url = URL.createObjectURL(fileBlob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            }
        }
        return
    } catch (err) {
        console.error(`Error caught preparing file download - ${err.message}`);
    }
}