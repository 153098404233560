import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import 'moment/locale/fi';
import ReactQuill from 'react-quill';

import Modal from 'react-modal';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../_calendar.scss';

import { withTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import InputAdornment from '@mui/material/InputAdornment';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import ColoredChip from '../components/common/ColoredChip';

const RenderTextField = ({
  input,
  label,
  helperText,
  disabled,
  type,
  val,
  required,
  fullWidth,
  meta: { touched, error },
  adornment,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);

  const hideModal = () => setModalIsOpen(false);

  const handleChange = dateTime => {
    input.onChange(
      `${dateTime.getFullYear()}-${dateTime.getMonth() +
        1}-${dateTime.getDate()}`
    );
    hideModal();
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <div>
      <TextField
        variant="standard"
        label={label}
        error={touched && !!error}
        InputLabelProps={{
          shrink: true,
          style: {
            fontWeight: 'bold',
            whiteSpace: 'pre',
            color: disabled ? 'rgba(0, 0, 0, 0.66)' : 'rgba(0, 0, 0, 0.66)',
          },
        }}
        InputProps={{
          disableUnderline: disabled,
          endAdornment: adornment && (
            <InputAdornment position="end">{adornment}</InputAdornment>
          ),
        }}
        disabled={disabled}
        onKeyPress={e => {
          if (type === 'number') {
            const regexp = /[0-9,]+/;
            if (!regexp.test(e.key)) {
              e.preventDefault();
            }
          }
        }}
        onClick={type === 'date' ? openModal : undefined}
        helperText={helperText}
        fullWidth={fullWidth}
        required={required}
        margin="normal"
        type="text"
        {...input}
        value={val || input.value}
        sx={{
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
          },
        }}
      />
      {type === 'date' && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={hideModal}
          contentLabel="Calendar"
          style={customStyles}
        >
          <Calendar
            onChange={handleChange}
            value={
              input.value instanceof Date && !isNaN(input.value)
                ? new Date(input.value)
                : new Date()
            }
            locale={'fi'}
          />
        </Modal>
      )}
    </div>
  );
};

const renderTextArea = ({
  input,
  label,
  helperText,
  disabled,
  type,
  val,
  required,
  rows,
  fullWidth,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    variant="standard"
    label={label}
    error={touched && error}
    InputLabelProps={{
      shrink: true,
      style: {
        fontWeight: 'bold',
        whiteSpace: 'pre',
        color: disabled ? 'rgba(0, 0, 0, 0.54)' : 'rgba(0, 0, 0, 0.54)',
      },
    }}
    disabled={disabled}
    InputProps={{
      disableUnderline: disabled,
      style: { color: disabled ? 'inherit' : 'inherit' },
    }}
    helperText={helperText}
    multiline
    rows={rows || '4'}
    required={required}
    margin="normal"
    fullWidth={fullWidth}
    {...input}
  />
);

function RenderSelectFieldWrapped(props) {
  const [selected, setSelected] = useState('');

  const handleSelectChange = event => {
    setSelected(event.target.value);
  };

  const {
    input,
    label,
    helperText,
    disabled,
    type,
    required,
    fullWidth,
    selOpts,
    multiple,
    meta: { touched, error },
    t,
    translateOptions,
  } = props;

  const selectProps = disabled
    ? { IconComponent: 'span', multiple }
    : { multiple };

  return (
    <TextField
      variant="standard"
      label={label}
      select
      value={multiple ? [...selected] : selected}
      disabled={disabled}
      onChange={handleSelectChange}
      error={touched && error}
      InputProps={{
        disableUnderline: disabled,
        style: { color: disabled ? 'inherit' : 'inherit', textAlign: 'left' },
      }}
      InputLabelProps={{
        shrink: true,
        style: {
          fontWeight: 'bold',
          whiteSpace: 'pre',
          color: disabled ? 'rgba(0, 0, 0, 0.54)' : 'rgba(0, 0, 0, 0.54)',
        },
      }}
      SelectProps={selectProps}
      helperText={helperText}
      fullWidth={fullWidth}
      required={required}
      margin="normal"
      type={type}
      {...input}
    >
      {R.prop('length', selOpts) > 0 &&
        selOpts.map(item => (
          <MenuItem key={item} value={item}>
            {translateOptions ? t(`options.${item}`) : item}
          </MenuItem>
        ))}
    </TextField>
  );
}

const renderSelectField = withTranslation('translations')(
  RenderSelectFieldWrapped
);

const RenderObjectSelectField = props => {
  const [selected, setSelected] = useState('');

  const handleSelectChange = event => {
    setSelected(event.target.value);
  };

  const {
    input,
    label,
    helperText,
    disabled,
    type,
    required,
    fullWidth,
    selOpts,
    meta: { touched, error },
  } = props;

  return (
    <TextField
      variant="standard"
      label={label}
      select
      value={selected}
      onChange={handleSelectChange}
      error={touched && error}
      InputProps={{ style: { textAlign: 'left' } }}
      InputLabelProps={{
        shrink: true,
        style: { fontWeight: 'bold', whiteSpace: 'pre' },
      }}
      disabled={disabled}
      helperText={helperText}
      fullWidth={fullWidth}
      required={required}
      margin="normal"
      type={type}
      {...input}
    >
      {selOpts.map(item => (
        <MenuItem
          key={R.path(['profile', 'uid'], item)}
          value={R.path(['profile', 'uid'], item)}
        >
          {R.path(['profile', 'firstname'], item) ||
            R.path(['profile', 'email'], item)}
        </MenuItem>
      ))}
    </TextField>
  );
};

/**
 * Generate checkboxlist with given items
 * @param {array} items       List of checkbox item values
 * @param {array} name        Name / path of property e.g. propertyInfo.responsible
 * @param {array} label       Label to display for the whole group
 * @param {array} input       Contains at least onChange and onBlur functions called on toggle
 * @param {array} meta        Contains touched & error validation helpers
 */
function CheckBoxList(props) {
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    const { input } = props;
    setChecked([...input.value]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.input.value]);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    props.input.onBlur(newChecked);
    return props.input.onChange(newChecked);
  };

  const { items, label, meta } = props;
  const { touched, error } = meta;

  return (
    <div>
      <List
        component="nav"
        subheader={
          <ListSubheader component="div" style={{ fontWeight: 'bold' }}>
            {label}
          </ListSubheader>
        }
      >
        {items.map(item => (
          <ListItem
            key={R.head(item)}
            role={undefined}
            dense
            button
            onClick={handleToggle(
              R.path(['profile', 'firstname'], R.nth(1, item))
            )}
          >
            <Checkbox
              checked={
                checked.indexOf(
                  R.path(['profile', 'firstname'], R.nth(1, item))
                ) !== -1
              }
              tabIndex={-1}
              disableRipple
            />
            <ListItemText
              primary={`${R.path(
                ['profile', 'firstname'],
                R.nth(1, item)
              )} (${R.path(['profile', 'email'], R.nth(1, item))})`}
            />
          </ListItem>
        ))}
        {touched && error && <p className="error">{error}</p>}
      </List>
    </div>
  );
}

/**
 * Generate checkboxlist with given items. This is not a generalized class but could be made into one.
 * @param {array} items       List of checkbox item values
 * @param {array} name        Name / path of property e.g. propertyInfo.responsible
 * @param {array} label       Label to display for the whole group
 * @param {array} input       Contains at least onChange and onBlur functions called on toggle
 * @param {array} meta        Contains touched & error validation helpers
 */
const CheckBoxListUsers = ({
  input,
  item,
  uid,
  projectUsers,
  filteredAttachedSpaces,
  filteredAttachedActiveAndCandidateSpaces,
  t,
}) => {
  // useState hook to manage the 'checked' state.
  const [checked, setChecked] = useState(input.value || false);

  // useEffect hook to synchronize state with props when input.value changes.
  useEffect(() => {
    if (input.value !== checked) {
      setChecked(input.value);
    }
  }, [input.value, checked]);

  const handleToggle = value => {
    const { onChange } = input;
    onChange(value);
    setChecked(value);
  };

  const userState = R.path([uid], projectUsers);
  const color =
    userState < 1
      ? 'orange'
      : userState === 1
      ? 'yellow'
      : userState === 2
      ? 'red'
      : userState === 3
      ? 'blue'
      : 'green';

  const chip =
    R.path([uid], projectUsers) !== undefined ? (
      <ColoredChip
        label={t(`participationStatus.${R.path([uid], projectUsers)}`)}
        color={color}
      />
    ) : (
      t('participants.notInvited')
    );

  return (
    <TableRow
      key={uid}
      role={undefined}
      dense
      button
      onClick={() => handleToggle(!checked)}
    >
      <TableCell>
        <Checkbox checked={checked} tabIndex={-1} disableRipple />
      </TableCell>
      <TableCell>{chip}</TableCell>
      <TableCell>{`${R.prop(
        'length',
        R.keys(filteredAttachedActiveAndCandidateSpaces)
      )}/${R.prop('length', R.keys(filteredAttachedSpaces))}`}</TableCell>
      <TableCell>
        {item.profile.firstname} {item.profile.lastname}
      </TableCell>
      <TableCell>{item.profile.company}</TableCell>
      <TableCell>
        {R.path(['profile', 'role'], item) &&
          R.path(['profile', 'role'], item)
            .map(profileItem => t(`options.${profileItem}`))
            .join(', ')}
      </TableCell>
      <TableCell>
        {R.path(['profile', 'area'], item) &&
          R.path(['profile', 'area'], item)
            .map(profileItem => t(`options.${profileItem}`))
            .join(', ')}
      </TableCell>
      <TableCell>{item.profile.email}</TableCell>
      <TableCell>
        <span style={{ whiteSpace: 'nowrap' }}>{item.profile.phone}</span>
      </TableCell>
    </TableRow>
  );
};

/**
 * Generate checkboxlist with given items
 * @param {array} items       List of checkbox item values
 * @param {array} name        Name / path of property e.g. propertyInfo.responsible
 * @param {array} label       Label to display for the whole group
 * @param {array} input       Contains at least onChange and onBlur functions called on toggle
 * @param {array} meta        Contains touched & error validation helpers
 */
const CheckBoxEmails = ({
  checked: initialChecked,
  input: { onChange },
  item,
}) => {
  const [checked, setChecked] = useState(initialChecked);

  const handleToggle = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    onChange(newChecked);
  };

  return (
    <ListItem role={undefined} dense button onClick={handleToggle}>
      <Checkbox checked={checked} tabIndex={-1} disableRipple />
      <ListItemText primary={item} />
    </ListItem>
  );
};

/**
 * Generate checkboxlist with given items
 * Could be generalized, at the moment space specific
 * @param {array} items
 */
export const CheckBoxListSpaces = ({ items, saveSelectedItemsToParent }) => {
  const [checked, setChecked] = useState([]);

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);

    if (saveSelectedItemsToParent) {
      saveSelectedItemsToParent(newChecked);
    }
  };

  return (
    <div>
      <List>
        {items.map(item =>
          R.path(['candidate'], item) ? (
            <ListItem
              key={R.path(['spaceId'], item)}
              role={undefined}
              dense
              button
              onClick={() => handleToggle(R.path(['spaceId'], item))}
            >
              <Checkbox
                checked={checked.indexOf(R.path(['spaceId'], item)) !== -1}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText
                primary={`${R.path(['attr', 'spaceSize'], item)}m2 - ${R.path(
                  ['attr', 'address'],
                  item
                )}, ${R.path(['attr', 'area'], item)}, ${R.path(
                  ['attr', 'city'],
                  item
                )}`}
              />
            </ListItem>
          ) : null
        )}
      </List>
    </div>
  );
};

function Editor({ input, placeholder, label, preview, disabled, sid }) {
  const [text, setText] = useState(input.value || '');

  useEffect(() => {
    setText(input.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid]);

  useEffect(() => {
    if (input.value !== text) {
      input.onChange(text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.value, text]);

  const handleChange = value => {
    setText(value);
  };

  const readOnly = preview || disabled;
  const theme = readOnly ? null : undefined;

  return (
    <FormControl style={{ width: '100%' }} variant="standard">
      <InputLabel
        htmlFor={`quill-${label}`}
        style={{ fontSize: '.9em', fontWeight: 'bold' }}
      >
        {label}
      </InputLabel>
      <ReactQuill
        id={`quill-${label}`}
        placeholder={placeholder}
        value={text}
        defaultValue={text}
        onChange={handleChange}
        style={{ paddingTop: '50px' }}
        readOnly={readOnly}
        theme={theme}
      />
    </FormControl>
  );
}

export const validate = values => {
  const errors = {};
  const required = [];
  required.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
};

export const returnField = fieldType => {
  switch (fieldType) {
    case 'number':
    case 'text':
    case 'date':
      return RenderTextField;
    case 'textarea':
      return renderTextArea;
    case 'select':
      return renderSelectField;
    case 'selectObj':
      return RenderObjectSelectField;
    case 'CheckBoxList':
      return CheckBoxList;
    case 'CheckBoxListUsers':
      return CheckBoxListUsers;
    case 'CheckBoxEmails':
      return CheckBoxEmails;
    case 'editor':
      return Editor;
    default:
      return RenderTextField;
  }
};
