import * as R from 'ramda';

import { GET_RFI_ATTR, CREATE_ATTR } from '../actions/attrActions';

const initialState = {
  attrInitialLoadComplete: false,
  attr: [],
};


export default function attrReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_ATTR:
      return state;
    case GET_RFI_ATTR:
      return {
        ...state,
        attr: R.path(['attr'], action),
        attrInitialLoadComplete: true,
      };
    default:
      return state;
  }
}
