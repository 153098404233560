import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import * as moment from 'moment';

import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';

import { saveFilters, clearFilters } from '../store/actions/filterActions';
import { createSpaceTemplate } from '../store/actions/spaceActions';

import CardWrapper from '../components/common/CardWrapper';

import SortableDrawerMenu from '../components/Projects/SortableDrawerMenu';
import SpaceDetailView from './SpaceDetailView';

import { withTranslation } from 'react-i18next';
import { withRouter } from '../utils/withRouter';

const inlineStyles = {
  content: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    marginTop: 10,
    marginBottom: '20px',
    minHeight: 600,
  },
};

let Spaces = ({
  navigate,
  t,
  isAdmin,
  templates,
  saveFilters,
  clearFilters,
  createSpaceTemplate,
}) => {
  const [spacesCount, setSpacesCount] = useState(0);
  const dispatch = useDispatch();
  const params = useParams();
  const sid = R.path(['documentId'], params);

  const spaces = useSelector(state => R.path(['space', 'spaces'], state));
  const spaceFilters = useSelector(state =>
    R.path(['filter', 'filters', 'spaces'], state)
  );
  const attachedSpaces = useSelector(state =>
    R.path(['space', 'attachedSpaces'], state)
  );
  const projects = useSelector(state => R.path(['project', 'projects'], state));

  useEffect(() => {
    // Clear filters if using the same component to render spaces but changing from attached spaces to templates or vice versa
    dispatch(clearFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates]);
  const calculateItems = count => {
    setSpacesCount(count);
  };

  // Hide draft attached spaces from Tenrep in this view, disabled for now
  // const filteredAttachedSpaces = isAdmin ? R.filter(n => n.status !== 'Draft', attachedSpaces) : attachedSpaces;
  const filteredAttachedSpaces = attachedSpaces;

  // Display templates (spaces) or attached spaces depending on route
  const spacesToUse = templates ? spaces : filteredAttachedSpaces;

  const spacesToUseObject = Object.entries(spacesToUse);

  const spacesArray = spacesToUseObject
    ? spacesToUseObject.map(item => {
        const uid = R.head(item);
        const fields = R.nth(1, item);
        return { id: uid, ...fields };
      })
    : null;

  const refinedSpaces = spacesArray
    ? Array.from(spacesArray).map(space => {
        let keys = [];
        if (templates) {
          keys = isAdmin
            ? ['city', 'address', 'buildingPurpose', 'updated']
            : ['city', 'address', 'buildingPurpose', 'updated'];
        } else {
          keys = isAdmin
            ? [
                'projectName',
                'city',
                'address',
                'spaceSize',
                'status',
                'updated',
              ]
            : [
                'projectName',
                'city',
                'address',
                'spaceSize',
                'status',
                'updated',
              ];
        }

        const updated = moment
          .unix(R.path(['system', 'updatedDate', 'seconds'], space))
          .format('DD/MM/YYYY');
        const updatedUnix = moment.unix(
          R.path(['system', 'updatedDate', 'seconds'], space)
        );
        const projectName = R.path(
          [R.path(['project'], space), 'projectInfo', 'detailsHeadline'],
          projects
        );
        const spaceStatusComparisonToProjectStatus =
          R.path(['status'], space) ===
          R.path(
            [R.path(['project'], space), 'projectInfo', 'status'],
            projects
          )
            ? 'active'
            : '';
        return {
          ...space,
          ...space.attr,
          objectKeys: keys,
          updated,
          updatedUnix,
          projectName,
          statusComparison: spaceStatusComparisonToProjectStatus,
          activeState:
            R.path(['active'], space) && R.path(['candidate'], space)
              ? 'active'
              : '',
        };
      })
    : null;

  const spaceFilterLabels = templates
    ? {
        city: t('labels.city'),
        address: t('labels.address'),
      }
    : {
        projectName: t('labels.projectName'),
        city: t('labels.city'),
        address: t('labels.address'),
      };

  const spaceFilterTypes = templates
    ? {
        city: 'select',
        address: 'select',
      }
    : {
        projectName: 'select',
        city: 'select',
        address: 'select',
      };

  // Sort object by property values helper function
  var sortObjectByValue = R.pipe(
    R.toPairs,
    R.sortBy(R.nth(1)),
    Object.fromEntries
  );

  // Sort filters by translated label
  let sortedSpaceFilterLabels = sortObjectByValue(spaceFilterLabels);
  const sortedSpaceFilterLabelsKeys = Object.keys(sortedSpaceFilterLabels);
  let sortedSpaceFilterTypes = Object.fromEntries(
    sortedSpaceFilterLabelsKeys.map(item => [item, spaceFilterTypes[item]])
  );

  // Add active state checkbox as the last filter to prevent it being sorted
  if (!templates) {
    sortedSpaceFilterLabels = R.assoc(
      'activeState',
      t('labels.showActive'),
      sortedSpaceFilterLabels
    );
    sortedSpaceFilterTypes = R.assoc(
      'activeState',
      'checkbox',
      sortedSpaceFilterTypes
    );
  }

  const handleRowClick = rowId => {
    const temp = templates ? 'templates/' : 'spaces/';
    navigate(`/${temp}${rowId}`);
  };

  return refinedSpaces ? (
    <CardWrapper
      name={
        templates
          ? !isAdmin
            ? t('spaceDetail.mySavedTemplates')
            : t('spaceDetail.templates')
          : !isAdmin
          ? t('spaceDetail.mySubmittedSpaces')
          : t('spaceDetail.spaces')
      }
      nameAddition={
        templates
          ? `${spacesCount} ${t('labels.pcs')}`
          : `${spacesCount} ${t('labels.pcs')}`
      }
      caption={
        templates
          ? t('spaceDetail.templatesViewSummary')
          : t('spaceDetail.attachedSpacesViewSummary')
      }
      transparent
      headline
    >
      <div style={{ display: 'flex' }}>
        <SortableDrawerMenu
          data={refinedSpaces}
          filters={spaceFilters}
          filterLabels={sortedSpaceFilterLabels}
          filterTypes={sortedSpaceFilterTypes}
          saveFilters={saveFilters}
          clearFilters={clearFilters}
          navigate={navigate}
          handleRowClick={handleRowClick}
          sid={sid}
          projects={projects}
          sortColumn={templates ? 'city' : 'city'}
          checkboxOptions={['active']}
          reverseCheckboxOperation
          calculateItems={calculateItems}
        />
        <SpaceDetailView
          templates={templates}
          navigate={navigate}
          isAdmin={isAdmin}
        />
      </div>
      {templates && (
        <Button
          variant="contained"
          sx={inlineStyles.button}
          color="secondary"
          onClick={createSpaceTemplate}
          style={{ marginTop: '20px' }}
        >
          {t('spaceDetail.createTemplate')}
          <Icon sx={inlineStyles.rightIcon}>archive</Icon>
        </Button>
      )}
    </CardWrapper>
  ) : null;
};
const mapStateToProps = (state, ownProps) => {
  const sid = R.path(['router', 'params', 'documentId'], ownProps);
  return {
    spaces: R.path(['space', 'spaces'], state),
    spaceFilters: R.path(['filter', 'filters', 'spaces'], state),
    attachedSpaces: R.path(['space', 'attachedSpaces'], state),
    projects: R.path(['project', 'projects'], state),
    sid,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  saveFilters: filters => dispatch(saveFilters(filters, 'spaces')),
  clearFilters: () => dispatch(clearFilters()),
  createSpaceTemplate: () =>
    dispatch(createSpaceTemplate(R.path(['t'], ownProps))),
});

Spaces.propTypes = {
  spaces: PropTypes.object.isRequired,
  spaceFilters: PropTypes.object.isRequired,
  attachedSpaces: PropTypes.object.isRequired,
  saveFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

Spaces = withRouter(connect(mapStateToProps, mapDispatchToProps)(Spaces));

export default withTranslation('translations')(Spaces);
