import * as firebaseApi from '../../api/firebaseApi';

export const UPDATE_CLIENTS_REQUEST = 'UPDATE_CLIENTS_REQUEST';
export const UPDATE_CLIENTS_RESPONSE = 'UPDATE_CLIENTS_RESPONSE';


const updateClientsRequest = {
  type: UPDATE_CLIENTS_REQUEST,
};

const updateClientsResponse = clients => ({
  type: UPDATE_CLIENTS_RESPONSE,
  clients,
});

let clientsUnsubscribe;

export const unsubscribeFromClients = () => (dispatch) => {
  if (clientsUnsubscribe) clientsUnsubscribe();
};

export const subscribeToClients = isAdmin => (dispatch) => {
  if (isAdmin === true) {
    dispatch(updateClientsRequest);
    clientsUnsubscribe = firebaseApi.subscribeToClients((clients) => {
      dispatch(updateClientsResponse(clients));
    });
  } else {
    dispatch(updateClientsResponse({}));
  }
};
