import React, { useState, useEffect } from 'react';
// import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { reduxForm } from 'redux-form';

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Hidden from '@mui/material/Hidden';

import Joyride from '../components/joyride/Joyride';

import SortableDrawerMenu from '../components/Projects/SortableDrawerMenu';

import { validate } from '../utils/formHelpers';
import {
  ModalDialog,
  ModalMultipleSelection,
  ModalFormDialog,
} from '../utils/modalHelpers';
import IntegrationReactSelect from '../utils/renderAutoCompleteField';
import CardWrapper from '../components/common/CardWrapper';

import ProjectAnnouncements from '../components/common/ProjectAnnouncements';

import { saveFilters, clearFilters } from '../store/actions/filterActions';

import { withTranslation } from 'react-i18next';
import { withRouter } from '../utils/withRouter';

import Announcement from '../components/common/Announcement';

import {
  saveProject,
  saveProjectAsDraft,
  updateProjectUserStatus,
} from '../store/actions/projectActions';
import {
  createSpaceAndAttachToProject,
  deleteAttachedSpace,
  saveSpaceAsTemplate,
  deActivateSpace,
} from '../store/actions/spaceActions';
import LandlordSpaceDetailView from './LandlordSpaceDetailView';
import { useTheme } from '@mui/material';

const inlineStyles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    color: '#000',
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  formControl: {
    display: 'flex',
    margin: theme.spacing(1),
    marginTop: '16px',
    minWidth: 120,
    marginBottom: '20px',
  },
});

let timeout = null;

/**
 * A component for displaying a project detail view on the landlord side
 */
const LandlordProjectDetailView = ({
  openProject,
  pristine,
  submitting,
  handleSubmit,
  saveProject,
  initialValues,
  spaceTemplates,
  spacesArray,
  spacesDraftArray,
  projectUserState,
  user,
  pid,
  projectImageUrl,
  t,
  navigate,
  projects,
  spaceFilters,
  saveFilters,
  clearFilters,
  spaces,
  sid,
  attachedSpaces,
  deActivateSpace,
  createSpaceAndAttachToProject,
  deleteAttachedSpace,
  updateProjectUserStatus,
  currentUserId,
  saveSpaceAsTemplate,
  // haveNonSubmittedSpaces
}) => {
  const [template, setTemplate] = useState('');
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [deactivationModalOpen, setDeactivationModalOpen] = useState(false);
  const [spaceToBeDeactivated, setSpaceToBeDeactivated] = useState('');
  const [thankYouModalOpen, setThankYouModalOpen] = useState(false);
  const [selectedSpaceId, setSelectedSpaceId] = useState(undefined);
  const [addSpaceModalOpen, setAddSpaceModalOpen] = useState(false);
  const [submitType, setSubmitType] = useState('');
  const [spaceToBeDeactivatedName, setSpaceToBeDeactivatedName] = useState('');

  /* Currently unused
  const [modalOpen, setModalOpen] = useState(false);
  const [spaceToBeDeleted, setSpaceToBeDeleted] = useState('');
  const [spaceToBeDeletedName, setSpaceToBeDeletedName] = useState('');
   */

  // Equivalent of componentDidMount and componentDidUpdate
  useEffect(() => {
    if (sid) {
      handleRowClick(sid);
    }
  }, [sid]); // If sid changes, the hook will run again

  const openAddSpaceModal = () => {
    setAddSpaceModalOpen(true);
  };

  const closeAddSpaceModal = () => {
    setAddSpaceModalOpen(false);
  };

  const openThankYouModal = () => {
    setThankYouModalOpen(true);
  };

  const closeThankYouModal = () => {
    setThankYouModalOpen(false);
  };

  const openSubmitSpacesModal = () => {
    setSubmitModalOpen(true);
  };

  const closeSubmitSpacesModal = () => {
    setSubmitModalOpen(false);
  };

  const openDeactivateSpaceModal = spaceId => {
    const selectedSpace = R.path([spaceId], attachedSpaces);
    const spaceName = `${R.path(['attr', 'address'], selectedSpace)}`;

    setDeactivationModalOpen(true);
    setSpaceToBeDeactivated(spaceId);
    setSpaceToBeDeactivatedName(spaceName);
  };

  const deactivateSpace = () => {
    setDeactivationModalOpen(false);
    deActivateSpace(spaceToBeDeactivated);
  };

  const closeDeactivateSpaceModal = () => {
    setDeactivationModalOpen(false);
  };

  const handleTemplateChange = event => {
    setTemplate(event.value);
  };

  const addSpaceToProject = initialData => {
    createSpaceAndAttachToProject(
      spaces[template],
      template,
      pid,
      openProject,
      initialData,
      navigate
    );
    setTemplate('');
    closeAddSpaceModal();
  };

  /* Currently unused
   const deleteSpace = () => {
     setModalOpen(false);
     deleteAttachedSpace(pid, spaceToBeDeleted);
   };

  const closeDeleteSpaceModal = () => {
    setModalOpen(false);
  };
   */

  const submit = (values, dispatch) => {
    if (submitType === 'sendRFI') {
      openSubmitSpacesModal();
    }
    setSubmitType('');
  };

  /**
   * Change user state to 1 (SEEN) if user is in state 0 (NOT SEEN)
   */
  const seenProject = () => {
    if (projectUserState < 1) {
      updateProjectUserStatus(pid, currentUserId, 1);
    }
  };

  /**
   * Change user state to 3 (INTERESTED)
   */
  const participateToProject = () => {
    if (projectUserState < 3) {
      updateProjectUserStatus(pid, currentUserId, 3);
    }
  };

  /**
   * Change user state to 2 (NOT INTERESTED), redirect back to main page
   */
  const donotParticipateToProject = () => {
    if (projectUserState < 2) {
      updateProjectUserStatus(pid, currentUserId, 2);
    }
    navigate('/');
  };

  /**
   * Sort attributes according to specified section order
   */
  const sortAttr = (a, b) => {
    const sortingArr = [
      'basicInfo',
      'spaceInfo',
      'economicInfo',
      'parking',
      'realEstateServices',
      'realEstateTechnical',
      'risks',
      'additionalInfo',
    ];
    return sortingArr.indexOf(a) - sortingArr.indexOf(b);
  };

  const handleRowClick = rowId => {
    setSelectedSpaceId(rowId);
  };

  // Sort attributes object as custom order is needed, see sortAttr function for preferred order
  const unOrderedAttr = R.path(['attr'], openProject);
  const orderedAttr = {};
  unOrderedAttr &&
    Object.keys(unOrderedAttr)
      .sort(sortAttr)
      .forEach(key => {
        orderedAttr[key] = unOrderedAttr[key];
      });

  seenProject();

  const projectStatus = R.path(['projectInfo', 'status'], openProject);
  const contactPersonInfo = R.path(
    ['projectInfo', 'contactPersonInfo'],
    openProject
  );

  const refinedSpaces = spacesArray
    ? Array.from(spacesArray).map(item => {
        const keys = ['city', 'address', 'spaceSize', 'grossRent', 'status'];
        return {
          ...item,
          ...item.attr,
          id: R.path(['spaceId'], item),
          objectKeys: keys,
        };
      })
    : null;

  const spaceFilterLabels = {
    city: t('labels.city'),
    address: t('labels.address'),
  };

  const spaceFilterTypes = {
    city: 'select',
    address: 'select',
  };

  const spacesWithDraftStatus =
    spacesArray.filter(space => space.status === 'Draft').length > 0;

  const theme = useTheme();
  const classes = inlineStyles(theme);

  return openProject && initialValues && projectStatus !== 'Draft' ? (
    <CardWrapper
      name={t('projectDetail.projectHeadline')}
      detailsClient={R.path(['projectInfo', 'client'], openProject)}
      detailsDescription={R.path(
        ['projectInfo', 'detailsDescription'],
        openProject
      )}
      detailsHeadline={R.path(['projectInfo', 'detailsHeadline'], openProject)}
      detailsSubheader={R.path(
        ['projectInfo', 'detailsSubheader'],
        openProject
      )}
      detailsSpace={R.path(['projectInfo', 'detailsSpace'], openProject)}
      detailsLocation={R.path(['projectInfo', 'detailsLocation'], openProject)}
      detailsTimeline={R.path(['projectInfo', 'detailsTimeline'], openProject)}
      detailsRentalPeriod={R.path(
        ['projectInfo', 'detailsRentalPeriod'],
        openProject
      )}
      detailsOther={R.path(['projectInfo', 'detailsOther'], openProject)}
      deadline={R.path(['projectInfo', 'deadline'], openProject)}
      contactPersonInfo={contactPersonInfo}
      imageUrl={projectImageUrl}
      isAdmin={R.path(['admin'], user)}
      pid={pid}
      files={R.path(['fileList'], projects[pid])}
      headline
      transparent
      detailsRightColumn={
        <ProjectAnnouncements
          projectStatus={projectStatus}
          user={user}
          spacesArray={spacesArray}
          openProject={openProject}
        />
      }
    >
      <form onSubmit={handleSubmit(submit)}>
        {
          // React-router-dom v6 does not support this yet
          /* Todo: <Prompt
            when={haveNonSubmittedSpaces}
            message={() =>
              t('projectDetail.reminderNonSubmittedSpaces')
            }
          /> */
        }

        {/**

              Prompt user to participate if user is in these states:

              0 NOT SEEN
              1 SEEN
              2 NOT INTERESTED

              On conditions:

              - Project is in it's first status (ROUND 1) which means it's new
              - User has not added spaces yet

            */
        projectUserState < 3 &&
          projectStatus === 'Round 1' &&
          R.prop('length', spacesArray) === 0 && (
            <span>
              <Button
                variant="contained"
                color="primary"
                sx={classes.button}
                onClick={participateToProject}
                id="participateToProject"
              >
                {t('projectDetail.participateByAttachingSpace')}
                <Icon sx={classes.rightIcon}>check_circle</Icon>
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={classes.button}
                onClick={donotParticipateToProject}
                id="donotParticipateToProject"
              >
                {t('projectDetail.doNotParticipate')}
                <Icon sx={classes.rightIcon}>highlight_off</Icon>
              </Button>
              <Joyride
                view="participateInProject"
                steps={[
                  {
                    target: '#participateToProject',
                    title: t('joyride.participateInProject.step0.headline'),
                    content: t('joyride.participateInProject.step0.content'),
                    disableBeacon: true,
                  },
                  {
                    target: '#donotParticipateToProject',
                    title: t('joyride.participateInProject.step1.headline'),
                    content: t('joyride.participateInProject.step1.content'),
                  },
                ]}
              />
            </span>
          )}

        {/*
          Show space attachment UI on these conditions:

          User has expressed interest by clicking on the participate button (project user state >= 3 ) AND project is in ROUND 1
          OR
          User has attached spaces
          OR
          Project status is not ROUND 1
        */
        ((projectUserState >= 3 && projectStatus === 'Round 1') ||
          R.prop('length', spacesArray) > 0 ||
          projectStatus !== 'Round 1') && (
          <CardWrapper name={t('projectDetail.submittedSpaces')} headline>
            {/**

            Display space addition UI

            */
            projectStatus === 'Round 1' &&
              !R.path(['projectInfo', 'locked'], openProject) && (
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={classes.button}
                    onClick={openAddSpaceModal}
                    id="addNewSpace"
                  >
                    {t('projectDetail.addNewSpace')}
                    <Hidden mdDown>
                      <Icon sx={classes.rightIcon}>add_circle</Icon>
                    </Hidden>
                  </Button>
                  <span> {t('projectDetail.orUseTemplate')} </span>
                  <IntegrationReactSelect
                    options={spaceTemplates}
                    label={t('projectDetail.useTemplate')}
                    onTemplateChange={e => handleTemplateChange(e)}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    sx={classes.button}
                    onClick={addSpaceToProject}
                    disabled={!template}
                    id="addNewSpaceUsingTemplate"
                  >
                    {t('projectDetail.useTemplate')}
                    <Hidden mdDown>
                      <Icon sx={classes.rightIcon}>add_circle</Icon>
                    </Hidden>
                  </Button>
                </div>
              )}

            {/**

          Show announcement: No spaces attached

          */}
            {projectStatus === 'Round 1' &&
            R.prop('length', refinedSpaces) === 0 ? (
              <div style={{ display: 'inline-block', width: '400px' }}>
                <Announcement
                  name={`${t('projectDetail.noSpacesAttached')}`}
                  type="announcement"
                  orange
                />
                <Joyride
                  view="addNewSpace"
                  steps={[
                    {
                      target: '#addNewSpace',
                      title: t('joyride.addNewSpace.step0.headline'),
                      content: t('joyride.addNewSpace.step0.content'),
                      disableBeacon: true,
                    },
                    {
                      target: '#addNewSpaceUsingTemplate',
                      title: t('joyride.addNewSpace.step1.headline'),
                      content: t('joyride.addNewSpace.step1.content'),
                    },
                  ]}
                />
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <SortableDrawerMenu
                  data={refinedSpaces}
                  refinedSpaces={refinedSpaces}
                  filters={spaceFilters}
                  filterLabels={spaceFilterLabels}
                  filterTypes={spaceFilterTypes}
                  saveFilters={saveFilters}
                  clearFilters={clearFilters}
                  navigate={navigate}
                  handleRowClick={handleRowClick}
                  sid={selectedSpaceId}
                  projects={projects}
                  sortColumn="city"
                  saveSpaceAsTemplate={saveSpaceAsTemplate}
                  openProject={openProject}
                  openDeactivateSpaceModal={openDeactivateSpaceModal}
                  menu
                  checkboxOptions={[
                    'Draft',
                    'Round 1',
                    'Round 2',
                    'Round 3',
                    'Round 4',
                    'Round 5',
                    'Round 6',
                    'Closed',
                  ]}
                />
                <LandlordSpaceDetailView
                  navigate={navigate}
                  openProject={openProject}
                  projectStatus={projectStatus}
                  orderedAttr={orderedAttr}
                  sid={selectedSpaceId}
                  pid={pid}
                />
              </div>
            )}

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <br />

              {R.prop(
                'length',
                R.filter(n => n.candidate, spacesArray)
              ) > 0 &&
                R.prop(
                  'length',
                  R.filter(n => n.active, spacesArray)
                ) > 0 &&
                projectStatus !== 'Closed' &&
                projectStatus !== 'Archived' &&
                !R.path(['projectInfo', 'locked'], openProject) && (
                  <Announcement
                    name={
                      !pristine
                        ? t('autosaveSpacesNotifications.unsavedChanges')
                        : spacesWithDraftStatus
                        ? t('autosaveSpacesNotifications.savedChanges')
                        : t('autosaveSpacesNotifications.spacesSubmitted')
                    }
                    type={pristine ? 'check_circle' : 'cached'}
                    orange={submitting}
                    green={pristine}
                    noStretch
                  />
                )}

              {/**

            Show space submit button if user has spaces that are still candidates and project is not closed

            */}
              {R.prop(
                'length',
                R.filter(n => n.candidate, spacesArray)
              ) > 0 &&
                R.prop(
                  'length',
                  R.filter(n => n.active, spacesArray)
                ) > 0 &&
                projectStatus !== 'Closed' &&
                projectStatus !== 'Archived' &&
                !R.path(['projectInfo', 'locked'], openProject) && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={classes.button}
                    disabled={submitting || !pristine}
                    onClick={() => setSubmitType('sendRFI')}
                    id="submitSpaces"
                  >
                    {t('projectDetail.submitSpaces')}
                    <Icon sx={classes.rightIcon}>cloud_upload</Icon>
                  </Button>
                )}
            </div>

            <ModalDialog
              modalTitle="Thank-user"
              modalDescription="Thank-user"
              openState={thankYouModalOpen}
              modalHeadline={t('modals.thankForParticipation')}
              modalSubHeadline={t('modals.followProjectAt', {
                url: `https://tenrep.newsec.fi/project/${pid}`,
              })}
              sx={classes.button}
              iconSx={classes.rightIcon}
              onCancelClick={() => {}}
              cancelButtonText=""
              cancelOption={false}
              onSubmitClick={closeThankYouModal}
              submitButtonText={t('common.close')}
              submitting={submitting}
            />

            {/* Currently unused
            <ModalDialog
              modalTitle="Delete-space"
              modalDescription="Delete-space"
              openState={modalOpen}
              modalHeadline={t('modals.confirmSpaceRemoval')}
              modalSubHeadline={spaceToBeDeletedName}
              buttonSx={classes.button}
              iconSx={classes.rightIcon}
              onCancelClick={() => closeDeleteSpaceModal()}
              cancelButtonText={t('common.cancel')}
              onSubmitClick={() => deleteSpace()}
              submitButtonText={t('common.remove')}
              submitting={submitting}
            /> */}

            <ModalMultipleSelection
              modalTitle="Select-spaces-for-submit"
              modalDescription="Select-spaces-for-submit"
              modalHeadline={t('modals.chooseSpacesToBeSubmitted')}
              modalSubHeadline={`${R.path(
                ['projectInfo', 'detailsHeadline'],
                openProject
              )}`}
              buttonSx={classes.button}
              iconSx={classes.rightIcon}
              openState={submitModalOpen}
              onCancelClick={() => closeSubmitSpacesModal()}
              onSubmitClick={selectedSpaces => {
                closeSubmitSpacesModal();
                openThankYouModal();
                return saveProject(
                  selectedSpaces,
                  projectStatus,
                  projectUserState
                );
              }}
              cancelButtonText={t('common.cancel')}
              submitButtonText={t('modals.submitSelected')}
              submitting={submitting}
              items={spacesDraftArray}
            />

            <ModalDialog
              modalTitle="Deactivate-space"
              modalDescription="Deactivate-space"
              openState={deactivationModalOpen}
              modalHeadline={t('modals.confirmAttachedSpaceRemoval')}
              modalSubHeadline={spaceToBeDeactivatedName}
              buttonSx={classes.button}
              iconSx={classes.rightIcon}
              onCancelClick={() => closeDeactivateSpaceModal()}
              cancelButtonText={t('common.cancel')}
              onSubmitClick={() => deactivateSpace()}
              submitButtonText={t('modals.removeSpaceFromProject')}
              submitting={submitting}
            />
            <ModalFormDialog
              modalTitle="Add-space"
              modalDescription="Add-space"
              openState={addSpaceModalOpen}
              modalHeadline={t('modals.addNewSpace')}
              modalSubHeadline=""
              buttonSx={classes.button}
              iconSx={classes.rightIcon}
              onCancelClick={closeAddSpaceModal}
              cancelButtonText={t('common.cancel')}
              cancelOption
              onSubmitClick={addSpaceToProject}
              submitButtonText={t('common.save')}
              initValues={
                R.path([template, 'attr', 'city'], spaces) ||
                R.path([template, 'attr', 'address'], spaces)
                  ? {
                      city: R.path([template, 'attr', 'city'], spaces),
                      address: R.path([template, 'attr', 'address'], spaces),
                    }
                  : {}
              }
              initialValuesConditions={
                !(
                  R.has(
                    'city',
                    R.path([template, 'attr', 'city'], spaces) ||
                      R.path([template, 'attr', 'address'], spaces)
                      ? {
                          city: R.path([template, 'attr', 'city'], spaces),
                          address: R.path(
                            [template, 'attr', 'address'],
                            spaces
                          ),
                        }
                      : {}
                  ) ||
                  R.has(
                    'address',
                    R.path([template, 'attr', 'city'], spaces) ||
                      R.path([template, 'attr', 'address'], spaces)
                      ? {
                          city: R.path([template, 'attr', 'city'], spaces),
                          address: R.path(
                            [template, 'attr', 'address'],
                            spaces
                          ),
                        }
                      : {}
                  )
                )
              }
              fields={[
                {
                  key: 'city',
                  label: t('labels.city'),
                  type: 'text',
                  required: true,
                },
                {
                  key: 'address',
                  label: t('labels.address'),
                  type: 'text',
                  required: true,
                },
              ]}
            />
          </CardWrapper>
        )}
      </form>
    </CardWrapper>
  ) : null;
};

const mapStateToProps = (state, ownProps) => {
  const attachedSpaces = R.path(['space', 'attachedSpaces'], state);
  const pid = R.path(['router', 'params', 'projectId'], ownProps);
  const sid = R.path(['router', 'params', 'documentId'], ownProps);
  const isPartOfProject = (val, key) => val.project === pid;
  const filteredAttachedSpaces = R.pickBy(isPartOfProject, attachedSpaces);

  /**
   * Map filteredAttachedSpaces object to a spacesArray array and sort it
   */
  const spacesArray = [];
  const pushToSpacesArray = (num, key, obj) =>
    spacesArray.push({ ...num, spaceId: key });
  R.mapObjIndexed(pushToSpacesArray, filteredAttachedSpaces);
  spacesArray.sort((a, b) => {
    if (!R.path(['system', 'createdDate', 'seconds'], a)) {
      return 1;
    }
    if (!R.path(['system', 'createdDate', 'seconds'], b)) {
      return -1;
    }
    return (
      R.path(['system', 'createdDate', 'seconds'], a) -
      R.path(['system', 'createdDate', 'seconds'], b)
    );
  });

  /**
   * Collect all spaces as array for to be presented in the submit confirm modal (both draft & submitted)
   */
  const spacesDraftArray = [];
  const pushToSpacesDraftArray = (num, key, obj) =>
    spacesDraftArray.push({ ...num, spaceId: key });
  R.mapObjIndexed(
    pushToSpacesDraftArray,
    R.path(['form', 'project', 'values'], state)
  );

  // Boolean value of non-submitted spaces existing in this project
  // const haveNonSubmittedSpaces = spacesDraftArray.filter(item => R.path(['status'], item) === 'Draft').length > 0;

  const projects = R.path(['project', 'projects'], state);
  const currentProject = projects[pid];
  const templates = R.path(['space', 'spaces'], state);
  const currentUserId = R.path(['userId'], ownProps);

  const projectFiles = R.path(['project', 'projects', pid, 'mainImage'], state);
  const projectMainPictureKeys = R.keys(projectFiles);
  const projectImageUrl =
    R.prop('length', projectMainPictureKeys) > 0
      ? R.path([R.head(projectMainPictureKeys), 'resizedPath'], projectFiles)
      : undefined;

  return {
    projects,
    pid,
    sid,
    spaceTemplates: templates,
    spacesArray,
    spacesDraftArray,
    spaces: templates,
    openProject: currentProject,
    initialValues: filteredAttachedSpaces,
    attachedSpaces,
    currentUserId,
    projectUserState: R.path(['users', currentUserId], currentProject),
    user: R.path(['user'], state),
    projectImageUrl,
    spaceFilters: R.path(
      ['filter', 'filters', 'landlordProjectDetailViewSpaces'],
      state
    ),
    // haveNonSubmittedSpaces
  };
};
const mapDispatchToProps = (dispatch, ownProps) => ({
  saveFilters: filters =>
    dispatch(saveFilters(filters, 'landlordProjectDetailViewSpaces')),
  clearFilters: () => dispatch(clearFilters()),
  saveProject: (selectedSpaces, projectStatus, projectUserState) =>
    dispatch(
      saveProject(
        selectedSpaces,
        projectStatus,
        projectUserState,
        R.path(['userId'], ownProps),
        R.path(['router', 'params', 'projectId'], ownProps),
        R.path(['t'], ownProps)
      )
    ),
  deActivateSpace: spaceId =>
    dispatch(deActivateSpace(spaceId, R.path(['t'], ownProps))),
  saveProjectAsDraft: spacesContent =>
    dispatch(saveProjectAsDraft(spacesContent, R.path(['t'], ownProps))),
  createSpaceAndAttachToProject: (
    template,
    sourceSpaceId,
    documentId,
    projectContent,
    initialData,
    navigate
  ) =>
    dispatch(
      createSpaceAndAttachToProject(
        template,
        sourceSpaceId,
        documentId,
        projectContent,
        initialData,
        navigate,
        R.path(['t'], ownProps)
      )
    ),
  deleteAttachedSpace: spaceId =>
    dispatch(deleteAttachedSpace(spaceId, R.path(['t'], ownProps))),
  updateProjectUserStatus: (pid, uid, newState) =>
    dispatch(updateProjectUserStatus(pid, uid, newState)),
  saveSpaceAsTemplate: spaceId =>
    dispatch(
      saveSpaceAsTemplate(
        spaceId,
        R.path(['navigate'], ownProps),
        R.path(['t'], ownProps)
      )
    ),
});

const FormDecoratedComponent = reduxForm({
  form: 'project',
  validate,
  enableReinitialize: true,
  updateUnregisteredFields: true,
  keepDirtyOnReinitialize: true,
  onChange: (values, dispatch, props) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => props.submit(), 3000);
  },
  onSubmit: (values, dispatch, props) => {
    const projectStatus = R.path(
      ['openProject', 'projectInfo', 'status'],
      props
    );
    const spacesArray = R.path(['spacesArray'], props);
    const openProject = R.path(['openProject'], props);
    R.prop(
      'length',
      R.filter(n => n.candidate, spacesArray)
    ) > 0 &&
      R.prop(
        'length',
        R.filter(n => n.active, spacesArray)
      ) > 0 &&
      projectStatus !== 'Closed' &&
      projectStatus !== 'Archived' &&
      !R.path(['projectInfo', 'locked'], openProject) &&
      props.saveProjectAsDraft(values);
  },
})(LandlordProjectDetailView);

LandlordProjectDetailView.propTypes = {
  openProject: PropTypes.object.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  saveProject: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  spaces: PropTypes.object.isRequired,
  spacesArray: PropTypes.array.isRequired,
  saveProjectAsDraft: PropTypes.func.isRequired,
  createSpaceAndAttachToProject: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  attachedSpaces: PropTypes.object,
  pid: PropTypes.string.isRequired,
  projectImageUrl: PropTypes.string,
};

export default withRouter(
  withTranslation('translations')(
    connect(mapStateToProps, mapDispatchToProps)(FormDecoratedComponent)
  )
);
