import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';

import { Field, reduxForm } from 'redux-form';

import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';

import { validate, returnField } from '../utils/formHelpers';

import { updateProjectNotes } from '../store/actions/projectNotesActions';
import NoAccess from '../components/navigation/NoAccess';

import CardWrapper from '../components/common/CardWrapper';
import { useTheme } from '@mui/material';

const inlineStyles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});

let Notes = ({
  pristine,
  submitting,
  handleSubmit,
  admin,
  updateProjectNotes,
  t,
}) => {
  const theme = useTheme();
  const classes = inlineStyles(theme);
  return admin ? (
    <CardWrapper name={t('projectDetailView.notes')} headline>
      <form onSubmit={handleSubmit(updateProjectNotes)}>
        <Field
          key="projectNotes"
          name="projectNotes"
          component={returnField('editor')}
          label={t('projectDetailView.notesDescription')}
          type="text"
          required
          fullWidth
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={classes.button}
          disabled={submitting || pristine}
        >
          {t('common.save')}
          <Icon sx={classes.rightIcon}>cloud_upload</Icon>
        </Button>
      </form>
    </CardWrapper>
  ) : (
    <NoAccess />
  );
};

const mapStateToProps = (state, ownProps) => {
  const pid = R.path(['pid'], ownProps);

  const initValues = {
    projectNotes: '',
  };

  return {
    initialValues: pid
      ? R.path(['projectNotes', 'projectNotes', pid], state)
      : initValues,
    admin: R.path(['user', 'admin'], state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateProjectNotes: values =>
    dispatch(
      updateProjectNotes(
        values,
        R.path(['pid'], ownProps),
        R.path(['t'], ownProps)
      )
    ),
});

const FormDecoratedComponent = reduxForm({
  form: 'projectNotes',
  validate,
  enableReinitialize: true,
  updateUnregisteredFields: true,
  keepDirtyOnReinitialize: true,
})(Notes);

Notes = connect(mapStateToProps, mapDispatchToProps)(FormDecoratedComponent);

export default withTranslation('translations')(Notes);
