export const SAVE_FILTERS = 'SAVE_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

export const saveFilters = (filters, id) => ({
  type: SAVE_FILTERS,
  filters,
  id,
});

export const clearFilters = () => ({
  type: CLEAR_FILTERS,
});
