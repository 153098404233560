import * as R from 'ramda';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  addFirestoreDoc,
  UserException,
  subscribeToFirestoreCollection,
  updateFirestoreDoc,
  setFirestoreDoc,
  setFirestoreDocBatch,
  deleteFirestoreDoc,
  getFile,
  uploadFirestore,
  subscribeToUsersDocs,
  getCurrentUserId,
  subscribeToUserProfile,
  deleteFirestoreField,
  getFileMetadata,
  checkIfUserIsAdmin,
  checkIfUserIsUpdatingOwnInfo,
} from './firebaseUtils';

/**
 * Firebase project specific API methods
 */

/**
 * Create a new project to Firestore
 * @param {object} obj      form object
 * @returns {promise}
 */
export const createProject = async obj => {
  try {
    const res = await addFirestoreDoc('projects', obj);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Delete project from Firestore
 * @param {string} doc      form object
 * @returns {promise}
 */
export const deleteProject = async doc => {
  try {
    const res = await deleteFirestoreDoc('projects', doc);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Create a new attr to Firestore
 * @param {object} obj      form object
 * @returns {promise}
 */
export const createAttr = async obj => {
  const objCopy = { ...obj };
  if (objCopy.fieldType === 'select') {
    objCopy.selOpts = ['yes', 'no'];
  }

  const objRefined = { ...objCopy, custom: true };
  try {
    const res = await addFirestoreDoc('rfiAttributes', objRefined);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Create a new space to to Firestore
 * Attach the space to project
 * @param {object} obj      form object
 * @returns {promise}
 */
export const createSpaceAndAttachToProject = async (
  newAttributes,
  projectId,
  template,
  sourceSpaceId
) => {
  try {
    const userId = getCurrentUserId();

    const spaceObject = {
      status: 'Draft',
      rejection: '',
      tenrepSeen: true,
      project: projectId,
      candidate: true,
      onPresentation: true,
      active: true, // Landlord will be able to disable a space when project is set a new status
      users: {
        [userId]: 0,
      },
      attr: {
        ...newAttributes,
      },
    };

    const res = await addFirestoreDoc('attachedSpaces', spaceObject);
    if (template !== undefined) {
      handleTemplateFiles(sourceSpaceId, template, res.id, 'attachedSpaces');
    }
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Delete space from a project
 * @param {object} obj      form object
 * @returns {promise}
 */
export const removeSpaceFromProject = async spaceId => {
  try {
    const res = await deleteFirestoreDoc('attachedSpaces', spaceId);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Delete space template
 * @param {object} obj      form object
 * @returns {promise}
 */
export const removeSpaceTemplate = async spaceId => {
  try {
    const res = await deleteFirestoreDoc('spaces', spaceId);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Start listening to changes in projects
 * @param {function} onUpdate     function to call when changes occur, new users object as an attribute
 * @returns {function}            unsubscription method
 */
export const subscribeToProjects = onUpdate => {
  try {
    return subscribeToFirestoreCollection('projects', snapshot => {
      onUpdate(snapshot);
    });
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Start listening to changes in projects
 * @param {function} onUpdate     function to call when changes occur, new users object as an attribute
 * @returns {function}            unsubscription method
 */
export const subscribeToUserProjects = (onUpdate, userId) => {
  try {
    return subscribeToUsersDocs('projects', snapshot => {
      const filteredSnapshot = {};
      Object.keys(snapshot).map(id => {
        const project = snapshot[id]; // project
        if (project.users) {
          Object.keys(project.users).map(user => {
            if (user !== userId) {
              delete project.users[user];
            }
            return null;
          });
        }
        filteredSnapshot[id] = project;

        return null;
      });
      onUpdate(filteredSnapshot);
    });
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Start listening to changes in user spaces
 * @param {function} onUpdate     function to call when changes occur, new users object as an attribute
 * @returns {function}            unsubscription method
 */
export const subscribeToUserSpaces = onUpdate => {
  try {
    return subscribeToUsersDocs('spaces', snapshot => {
      onUpdate(snapshot);
    });
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Start listening to changes in spaces
 * @param {function} onUpdate     function to call when changes occur, new users object as an attribute
 * @returns {function}            unsubscription method
 */
export const subscribeToSpaces = onUpdate => {
  try {
    return subscribeToFirestoreCollection('spaces', snapshot => {
      onUpdate(snapshot);
    });
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Start listening to changes in current user profile
 * @param {function} onUpdate     function to call when changes occur, new users object as an attribute
 * @returns {function}            unsubscription method
 */
export const subscribeToCurrentUserProfile = onUpdate => {
  try {
    return subscribeToUserProfile('users', snapshot => {
      onUpdate(snapshot);
    });
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Start listening to changes in all users profiles
 * @param {function} onUpdate     function to call when changes occur, new users object as an attribute
 * @returns {function}            unsubscription method
 */
export const subscribeToUserProfiles = onUpdate => {
  try {
    return subscribeToFirestoreCollection('users', snapshot => {
      onUpdate(snapshot);
    });
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Start listening to changes in user attached spaces
 * @param {function} onUpdate     function to call when changes occur, new users object as an attribute
 * @returns {function}            unsubscription method
 */
export const subscribeToUserAttachedSpaces = onUpdate => {
  try {
    return subscribeToUsersDocs('attachedSpaces', snapshot => {
      onUpdate(snapshot);
    });
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Start listening to changes in attached spaces
 * @param {function} onUpdate     function to call when changes occur, new users object as an attribute
 * @returns {function}            unsubscription method
 */
export const subscribeToAttachedSpaces = onUpdate => {
  try {
    return subscribeToFirestoreCollection('attachedSpaces', snapshot => {
      onUpdate(snapshot);
    });
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Start listening to changes in rfiAttributes
 * @param {function} onUpdate     function to call when changes occur, new users object as an attribute
 * @returns {function}            unsubscription method
 */
export const subscribeToRfiAttributes = onUpdate => {
  try {
    return subscribeToFirestoreCollection('rfiAttributes', snapshot => {
      onUpdate(snapshot);
    });
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Start listening to changes in clients
 * @param {function} onUpdate     function to call when changes occur, new users object as an attribute
 * @returns {function}            unsubscription method
 */
export const subscribeToClients = onUpdate => {
  try {
    return subscribeToFirestoreCollection('clients', snapshot => {
      onUpdate(snapshot);
    });
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Start listening to changes in project notes
 * @param {function} onUpdate     function to call when changes occur, new users object as an attribute
 * @returns {function}            unsubscription method
 */
export const subscribeToProjectNotes = onUpdate => {
  try {
    return subscribeToFirestoreCollection('projectNotes', snapshot => {
      onUpdate(snapshot);
    });
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Update a project
 * @param {string} pid      project id
 * @param {object} obj      update object
 * @returns {promise}
 */
export const updateProject = async (pid, obj) => {
  try {
    const project = {
      spaces: {
        ...obj.spaces,
      },
    };

    const res = await updateFirestoreDoc('projects', pid, project);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Update project notes
 * @param {string} pid      project id
 * @param {object} obj      update object
 * @returns {promise}
 */
export const updateProjectNotes = async (pid, obj) => {
  try {
    const res = await setFirestoreDoc(true, 'projectNotes', pid, obj);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Update project user status
 * @param {string} pid      project id
 * @param {object} uid      user id
 * @param {object} number   new state
 * @returns {promise}
 */
export const updateProjectUserStatus = async (pid, uid, newState) => {
  try {
    const project = {
      users: {
        [uid]: newState,
      },
    };

    const res = await setFirestoreDoc(true, 'projects', pid, project);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Create a new empty space template
 * @returns {promise}
 */
export const createSpaceTemplate = async () => {
  try {
    const userId = getCurrentUserId();

    const spaceObject = {
      users: {
        [userId]: 0,
      },
    };

    const res = await addFirestoreDoc('spaces', spaceObject);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Update single space template
 * @param {string} sid      space id
 * @param {object} obj      update object
 * @returns {promise}
 */
export const updateSpaceTemplate = async (sid, obj) => {
  try {
    const res = await setFirestoreDoc(true, 'spaces', sid, obj);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Update single attached space
 * @param {string} sid      space id
 * @param {object} obj      update object
 * @returns {promise}
 */
export const updateAttachedSpace = async (sid, obj) => {
  try {
    const res = await setFirestoreDoc(true, 'attachedSpaces', sid, obj);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Update project attachedSpaces as batch operation
 * @param {obj} obj      update object
 * @returns {promise}
 */
export const updateProjectSpaces = async obj => {
  try {
    const res = await setFirestoreDocBatch(true, 'attachedSpaces', obj);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Update a project attr
 * @param {string} cid      project id
 * @param {object} obj      update object
 * @returns {promise}
 */
export const updateProjectAttr = async (cid, obj, cat) => {
  try {
    const attrCat = {
      attr: obj,
    };
    const res = await updateFirestoreDoc('projects', cid, attrCat);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Update a project info
 * @param {string} pid      project id
 * @param {object} obj      update object
 * @returns {promise}
 */
export const updateProjectInfo = async (pid, obj) => {
  try {
    const projectInfo = {
      projectInfo: obj,
    };
    const res = await updateFirestoreDoc('projects', pid, projectInfo);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Update project locked state
 * @param {string} pid      project id
 * @param {object} newState   new state
 * @returns {promise}
 */
export const updateProjectLockedState = async (pid, newState) => {
  try {
    const project = {
      projectInfo: {
        locked: newState,
      },
    };

    const res = await setFirestoreDoc(true, 'projects', pid, project);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Get file web url with storage path
 * @param {string} path     File path at firebase storage
 * @returns {promise}
 */
export const getFileUrl = async path => {
  try {
    const res = await getFile(path);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Copy file to another directory
 * @param {string} sourcePath        File source path
 * @param {string} targetPath        File target path
 */
export const copyFile = async (sourcePath, targetPath) => {
  try {
    const functions = getFunctions();
    const copyFileFn = httpsCallable(functions, 'copyFile');
    const res = await copyFileFn({
      sourcePath,
      targetPath,
      fileBucket: 'megatenrepdemo.appspot.com',
    });
    return {
      res: res.data,
      message: `File copied from ${sourcePath} to ${targetPath}`,
    };
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Write file details under projects attachedSpaces
 * @param {string} collection               collection name
 * @param {string} documentId               document id
 * @param {object} file                     file interface
 * @param {object} timeStampedFileSize      safe format object key
 * @param {bool}   allowMultipleFiles       If set to false, files will be overwritten on file upload
 * @param {bool}   mainPicture              If set to true will write file as main picture
 * @returns {promise}
 */
export const writeFileDetails = async (
  collection,
  documentId,
  file,
  timeStampedFileSize,
  allowMultipleFiles,
  mainPicture,
  contentType
) => {
  // If no filename is present, fall back to timeStampedFileSize as each file has to have a name in the system

  const role = mainPicture ? 'mainPicture' : 'otherAttachment';

  try {
    let res;
    let obj;

    if (contentType.startsWith('image/')) {
      const thumbPath = `${documentId}/thumb_${timeStampedFileSize}`;
      const midPath = `${documentId}/mid_${timeStampedFileSize}`;
      const resizedPath = `${documentId}/resized_${timeStampedFileSize}`;
      const thumbPathGenerated = await getFile(thumbPath);
      const midPathGenerated = await getFile(midPath);
      const resizedPathGenerated = await getFile(resizedPath);

      obj = {
        fileName: R.prop('name', file) || timeStampedFileSize,
        fileRole: role,
        thumbPath: thumbPathGenerated,
        midPath: midPathGenerated,
        resizedPath: resizedPathGenerated,
      };
    } else {
      const path = `${documentId}/${timeStampedFileSize}`;
      const pathGenerated = await getFile(path);

      obj = {
        fileName: R.prop('name', file) || timeStampedFileSize,
        fileRole: role,
        path: pathGenerated,
      };
    }

    const targetObject = mainPicture ? 'mainImage' : 'fileList';

    // If only one file is allowed, overwrite current file references with and empty object
    if (!allowMultipleFiles) {
      res = await updateFirestoreDoc(collection, documentId, {
        [targetObject]: {},
      });
    }

    const filesObject = {
      [targetObject]: {
        [timeStampedFileSize]: {
          ...obj,
        },
      },
    };

    res = await setFirestoreDoc(true, collection, documentId, filesObject);

    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Handle files in case an attached space copied to a template had any
 * This means copying the files to another directory in the storage and updating all paths in the mainImage and fileList objects in the database
 * @param {*} sid     Space id of the source space
 * @param {*} obj     Space contents of the source space
 * @param {*} id      Space id of the target space
 * @param {*} targetCollection    Collection where the target space resides (e.g. attachedSpaces or spaces)
 */
export const handleTemplateFiles = async (sid, obj, id, targetCollection) => {
  const objHasOwnProperty = R.has(R.__, obj);

  if (objHasOwnProperty('fileList')) {
    Object.entries(obj.fileList).forEach(async ([key, value]) => {
      const fileListHasOwnProperty = R.has(R.__, value);
      const isFileAnImage = fileListHasOwnProperty('resizedPath');
      const fileContentType = await getFileMetadata(sid, key, isFileAnImage);

      try {
        let res;
        if (fileContentType.contentType.startsWith('image/')) {
          res = await copyFile(`${sid}/thumb_${key}`, `${id}/thumb_${key}`);
          res = await copyFile(`${sid}/mid_${key}`, `${id}/mid_${key}`);
          res = await copyFile(`${sid}/resized_${key}`, `${id}/resized_${key}`);
          res = await writeFileDetails(
            targetCollection,
            id,
            { name: R.path(['fileName'], value) },
            key,
            true,
            false,
            fileContentType.contentType
          );
        } else {
          res = await copyFile(`${sid}/${key}`, `${id}/${key}`);
          res = await writeFileDetails(
            targetCollection,
            id,
            { name: R.path(['fileName'], value) },
            key,
            true,
            false,
            fileContentType.contentType
          );
        }
        return res;
      } catch (err) {
        throw new UserException(err.code, err.message);
      }
    });
  }

  if (objHasOwnProperty('mainImage')) {
    Object.entries(obj.mainImage).forEach(async ([key, value]) => {
      const fileContentType = await getFileMetadata(sid, key, true);

      try {
        let res = await copyFile(`${sid}/thumb_${key}`, `${id}/thumb_${key}`);
        res = await copyFile(`${sid}/mid_${key}`, `${id}/mid_${key}`);
        res = await copyFile(`${sid}/resized_${key}`, `${id}/resized_${key}`);
        res = await writeFileDetails(
          targetCollection,
          id,
          { name: R.path(['fileName'], value) },
          key,
          true,
          true,
          fileContentType.contentType
        );
        return res;
      } catch (err) {
        throw new UserException(err.code, err.message);
      }
    });
  }
  return null;
};

/**
 * Create single space template using attached space as source of information
 * @param {string} sid      Source space ID
 * @param {object} obj      Source space contents
 * @returns {promise}
 */
export const createSpaceTemplateWithData = async (sid, obj) => {
  const userId = getCurrentUserId();

  const spaceObject = obj;

  // Overwrite users to make sure when Admin copies another user's template it will be marked as the admin's own template
  spaceObject.users = {
    [userId]: 0,
  };

  try {
    const res = await addFirestoreDoc('spaces', spaceObject);
    await handleTemplateFiles(sid, obj, res.id, 'spaces');
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Upload file to storage and write file info to cloud firestore
 * @param {object}  file                 file interface
 * @param {string}  documentId           document id
 * @param {string}  collection           collection name
 * @param {bool}    allowMultipleFiles   if set false, will overwrite on upload
 * @param {bool}    mainPicture          set file role to mainPicture if true
 */
export const uploadFile = async (
  file,
  documentId,
  collection,
  allowMultipleFiles,
  mainPicture
) => {
  const now = +new Date();
  const timeStampedFileSize = `${now}${file.size}`;
  const role = mainPicture ? 'mainPicture' : 'otherAttachment';
  const contentType = file.type;
  const filePath = `${documentId}/${timeStampedFileSize}`;

  try {
    if (!contentType.startsWith('image/') && role === 'mainPicture') {
      throw new Error();
    }

    let res = await uploadFirestore(
      timeStampedFileSize,
      file,
      documentId,
      collection,
      role,
      allowMultipleFiles
    );
    if (contentType.startsWith('image/')) {
      const functions = getFunctions();
      const createImageSizes = httpsCallable(functions, 'createImageSizes');
      res = await createImageSizes({
        contentType,
        bucket: 'megatenrepdemo.appspot.com',
        name: filePath,
      });
    }
    res = await writeFileDetails(
      collection,
      documentId,
      file,
      timeStampedFileSize,
      allowMultipleFiles,
      mainPicture,
      contentType
    );
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Delete file reference
 * @param {string} documentId   id of target document
 * @param {string} fileId       id of target file
 * @returns {promise}
 */
export const removeFileReference = async (collection, documentId, filePath) => {
  try {
    const res = await deleteFirestoreField(collection, documentId, filePath);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

export const createUser = async userObject => {
  try {
    const functions = getFunctions();
    const createUser = httpsCallable(functions, 'createUser');
    const res = await createUser(userObject);
    // await sendPasswordResetEmail(userObject.email);
    return {
      uid: res.data.uid,
      message: 'user created in both Auth and Firestore',
    };
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

// Disable user from auth using Admin SDK
export const disableUser = async uid => {
  const hasAdminRights = await checkIfUserIsAdmin();
  if (!hasAdminRights) return false;

  try {
    const functions = getFunctions();
    const disableUser = httpsCallable(functions, 'disableUser');
    const res = await disableUser(uid);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

// Enable user to auth using Admin SDK
export const enableUser = async uid => {
  const hasAdminRights = await checkIfUserIsAdmin();
  if (!hasAdminRights) return false;

  try {
    const functions = getFunctions();
    const enableUser = httpsCallable(functions, 'enableUser');
    const res = await enableUser(uid);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

// update userProfile in FS using Admin SDK
export const updateUserProfile = async (newUser, user) => {
  // Conditional here (if user === '') for userid and pass uid as parameter with default of ''
  let userid = getCurrentUserId();
  if (user !== '') {
    userid = user;
  }
  const data = {
    profile: newUser,
    uid: userid,
  };

  const hasAdminRights = await checkIfUserIsAdmin();
  const updatingOwnInformation = await checkIfUserIsUpdatingOwnInfo(newUser);

  if (hasAdminRights || updatingOwnInformation) {
    try {
      const functions = getFunctions();
      const updateUserProfile = httpsCallable(functions, 'updateUserProfile');
      const res = await updateUserProfile(data);
      return res;
    } catch (err) {
      throw new UserException(err.code, err.message);
    }
  }
};

// update userProfile in FS using Admin SDK
export const updateUserProfileWithMerge = async (userData, user) => {
  const data = {
    profile: { ...userData.profile, uid: user },
    mgmt: { ...userData.mgmt },
  };

  const hasAdminRights = await checkIfUserIsAdmin();
  if (!hasAdminRights) return false;

  try {
    const functions = getFunctions();
    const updateUserProfileWithMerge = httpsCallable(
      functions,
      'updateUserProfileWithMerge'
    );
    const res = await updateUserProfileWithMerge(data);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Update a project attr
 * @param {string} cid      project id
 * @param {object} obj      update object
 * @returns {promise}
 */
export const updateProjectUsers = async (projectUsers, doc) => {
  const users = { users: { ...projectUsers } };
  try {
    const res = await updateFirestoreDoc('projects', doc, users);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Deactivate attached space
 * @param {object} params   object of all params
 * @returns {promise}
 */
export const deactivateAttachedSpace = async documentId => {
  const active = { active: false };
  try {
    const res = await updateFirestoreDoc('attachedSpaces', documentId, active);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

// This will update is space selected to presentation view
export const updateAttachedSpacesOnPresentation = async params => {
  const doc = params.id;
  const onPresentation = { onPresentation: !params.onPresentation };
  try {
    const res = await updateFirestoreDoc('attachedSpaces', doc, onPresentation);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

export const updateAttachedSpaceLocation = async (data, params) => {
  const results = R.path(['results'], data);
  const geometryLocation = R.path(['geometry', 'location'], R.head(results));
  const location = { location: geometryLocation };
  const documentId = R.path(['id'], params);

  try {
    const res = await updateFirestoreDoc(
      'attachedSpaces',
      documentId,
      location
    );
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/*
 * params ($object) includes address and city of the place you are looking nearby places for
 * type ($string) includes Google maps place type as it is in https://developers.google.com/places/supported_types
 * key ($string) is key you want to save in DB
 */
export const getDistanceToPlace = async (params, type, key) => {
  const doc = params.id;
  const refinedParams = params;
  refinedParams.type = type;

  try {
    const functions = getFunctions();
    const getDistanceToGMapsPlaces = httpsCallable(
      functions,
      'getDistanceToGMapsPlaces'
    );
    const res = await getDistanceToGMapsPlaces(refinedParams);
    const distanceToPlace = { [key]: res.data };
    await updateFirestoreDoc('attachedSpaces', doc, distanceToPlace);
    return {
      message: 'success',
    };
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Update single client
 * @param {object} pid      project id - clients are saved under project id
 * @param {object} obj      update object
 * @returns {promise}
 */
export const updateClient = async (pid, obj) => {
  try {
    const res = await setFirestoreDoc(true, 'clients', pid, obj);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};
