import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { withTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import TableSortLabel from '@mui/material/TableSortLabel';
import Icon from '@mui/material/Icon';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import NotificationChip from '../common/NotificationChip';

import styles from './SpacesTable.module.scss';

function SpacesTable({
  labels,
  sid,
  selectFirstRowOnMount,
  handleRowClick,
  showDetails,
  actionMenuCB,
  actionMenuCBII,
  icon,
  altIcon,
  actionMenuCBIIAltText,
  actionMenuCBIIText,
  actionMenuCBText,
  actionMenuCBAltText,
  spaceDetailsOpen,
  setTextFilters,
  setMaxFilters,
  filters,
  projects,
  hideFilters,
  t,
  spacesOnPresentation,
  columns,
  menu,
  data,
}) {
  useEffect(() => {
    if (!sid && selectFirstRowOnMount) {
      selectFirstRow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid, selectFirstRowOnMount]); // mimicking componentDidMount and componentDidUpdate.

  function getSpaceImage(spaceMainPicture) {
    const spaceMainPictureKeys = R.keys(spaceMainPicture);
    const spaceMainPictureUrl =
      R.prop('length', spaceMainPictureKeys) > 0 &&
      R.path([R.head(spaceMainPictureKeys), 'thumbPath'], spaceMainPicture);
    return spaceMainPictureUrl;
  }

  function getRows() {
    return data.map(space => ({
      ...space.attr,
      onPresentation: space.onPresentation,
      candidate: space.candidate,
      objectKeys: columns,
      id: space.id,
      fileList: space.fileList,
      mainImage: space.mainImage,
    }));
  }

  function selectFirstRow() {
    const rows = getRows();
    if (R.prop('length', rows) > 0) {
      handleRowClick(0, R.path(['id'], R.head(rows)));
    }
  }

  const orderByStr = 'name';
  const sortOrder = 'desc';

  const rows = getRows();

  const ActionMenu = menu;

  return (
    <div>
      {spacesOnPresentation && (
        <div
          style={{ display: hideFilters && 'none' }}
          className={styles.gridWrap}
        >
          <Grid
            style={{ textAlign: 'left' }}
            direction="row"
            spacing={3}
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="flex-start"
          >
            {filters.map(filter => (
              <Grid key={filter.label} item xs={12} sm={6} md={4}>
                <TextField
                  label={filter.label}
                  margin="normal"
                  name={filter.name}
                  onChange={event => setTextFilters(event)}
                  fullWidth
                />
              </Grid>
            ))}
            <Grid key="distanceToMetro" item xs={12} sm={6} md={4}>
              <TextField
                type="number"
                name="distanceToMetro"
                label={t('presentation.distanceToSubway')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                }}
                margin="normal"
                onChange={event => setMaxFilters(event, '<')}
                fullWidth
              />
            </Grid>
            <Grid key="distanceToTram" item xs={12} sm={6} md={4}>
              <TextField
                type="number"
                name="distanceToTram"
                label={t('presentation.distanceToTram')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                }}
                margin="normal"
                onChange={event => setMaxFilters(event, '<')}
                fullWidth
              />
            </Grid>
            <Grid key="distanceToTrain" item xs={12} sm={6} md={4}>
              <TextField
                type="number"
                name="distanceToTrain"
                label={t('presentation.distanceToTrain')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                }}
                margin="normal"
                onChange={event => setMaxFilters(event, '<')}
                fullWidth
              />
            </Grid>
            <Grid key="distanceToBus" item xs={12} sm={6} md={4}>
              <TextField
                type="number"
                name="distanceToBus"
                label={t('presentation.distanceToBus')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                }}
                margin="normal"
                onChange={event => setMaxFilters(event, '<')}
                fullWidth
              />
            </Grid>
            <Grid key="spaceSize" item xs={12} sm={6} md={4}>
              <TextField
                type="number"
                name="spaceSize"
                label={t('presentation.minSize')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m2</InputAdornment>
                  ),
                }}
                margin="normal"
                onChange={event => setMaxFilters(event, '>', true)}
                fullWidth
              />
            </Grid>
            <Grid key="netRent" item xs={12} sm={6} md={4}>
              <TextField
                type="number"
                name="netRent"
                label={t('presentation.maxNetRent')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
                margin="normal"
                onChange={event => setMaxFilters(event, '<', true)}
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
      )}
      <Divider />
      <Table className={styles.table} size="small">
        <TableHead padding="default">
          <TableRow>
            <TableCell />
            {Array.from(labels).map((label, index) => (
              <TableCell key={`${label}${index}`} className={styles.tableLabel}>
                <TableSortLabel
                  active={orderByStr === label.key}
                  direction={sortOrder}
                >
                  {label.finnish}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell className={styles.tableLabel} />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            const oKeys = row.objectKeys;

            return (
              <TableRow
                className={styles.tableRow}
                key={R.path(['id'], row)}
                id={R.path(['id'], row)}
                onClick={() => handleRowClick(i, R.path(['id'], row))}
                style={{
                  backgroundColor: R.path(['id'], row) === sid && '#2D3E80',
                }}
              >
                <TableCell>
                  {getSpaceImage(R.path(['mainImage'], row)) && (
                    <img
                      style={{ width: '50px', height: '50px' }}
                      src={getSpaceImage(R.path(['mainImage'], row))}
                      alt=""
                    />
                  )}
                </TableCell>
                {Array.from(oKeys).map((objectKey, index) => (
                  <TableCell
                    key={`${objectKey}${index}`}
                    style={{ color: R.path(['id'], row) === sid && '#fff' }}
                  >
                    {Array.isArray(R.path([objectKey], row)) ? (
                      R.path([objectKey], row).join(', ')
                    ) : objectKey !== 'status' ? (
                      R.pathOr(t('common.notDefined'), [objectKey], row)
                    ) : (
                      <NotificationChip
                        statusEqual={
                          R.path(['status'], row) ===
                          R.path(
                            [R.path(['project'], row), 'projectInfo', 'status'],
                            projects
                          )
                        }
                        notCandidate={!R.path(['candidate'], row)}
                        notActive={!R.path(['active'], row)}
                        label={
                          R.path(['active'], row)
                            ? t(`options.${R.path([objectKey], row)}`)
                            : t('options.inactive')
                        }
                      />
                    )}
                  </TableCell>
                ))}
                <TableCell>
                  {' '}
                  <Icon
                    className={
                      spaceDetailsOpen ? styles.mapIcon : styles.infoIcon
                    }
                    onClick={() => showDetails(R.path(['id'], row))}
                  >
                    {spaceDetailsOpen ? icon : altIcon}
                  </Icon>
                </TableCell>
                <TableCell>
                  {' '}
                  {menu ? (
                    <ActionMenu
                      rowId={row.id}
                      menuItems={[
                        {
                          action: actionMenuCB,
                          icon: row.candidate ? 'not_interested' : 'undo',
                          contentText: row.candidate
                            ? actionMenuCBText
                            : actionMenuCBAltText,
                        },
                        {
                          action: actionMenuCBII,
                          icon: 'screen_share',
                          contentText: row.onPresentation
                            ? actionMenuCBIIText
                            : actionMenuCBIIAltText,
                        },
                      ]}
                    />
                  ) : null}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

SpacesTable.propTypes = {
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  spacesOnPresentation: PropTypes.bool.isRequired,
};

export default withTranslation('translations')(SpacesTable);
