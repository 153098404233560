import React from 'react';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon';
import Avatar from '@mui/material/Avatar';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import grey from '@mui/material/colors/grey';

const inlineStyles = {
  chip: {
    margin: 0,
  },
  greenChip: {
    backgroundColor: green[500],
    color: '#fff',
  },
  redChip: {
    backgroundColor: red[500],
    color: '#fff',
  },
  blackChip: {
    backgroundColor: grey[900],
    color: '#fff',
  },
  greenStatus: {
    color: '#fff',
    backgroundColor: green[500],
  },
  redStatus: {
    color: '#fff',
    backgroundColor: red[500],
  },
  blackStatus: {
    color: '#fff',
    backgroundColor: grey[900],
  },
  normalStatus: {
    color: '#fff',
  },
};

/**
 * Chip used for displaying item status
 * @param {string} label        Chip text content
 * @param {bool} statusEqual    Marked true if item status is the same as project status
 * @param {bool} notCandidate   Marked as true if item is not candidate
 * @param {bool} notActive      Marked as true if item is not active
 */
const NotificationChip = ({
  label,
  statusEqual,
  notCandidate,
  notActive,
  project,
}) => (
  <Chip
    avatar={
      !project ? (
        notActive ? (
          <Avatar sx={inlineStyles.blackStatus}>
            <Icon style={{ color: 'white', fontSize: 20 }}>delete_forever</Icon>
          </Avatar>
        ) : notCandidate ? (
          <Avatar sx={inlineStyles.redStatus}>
            <Icon style={{ color: 'white', fontSize: 20 }}>remove_circle</Icon>
          </Avatar>
        ) : statusEqual ? (
          <Avatar sx={inlineStyles.greenStatus}>
            <Icon style={{ color: 'white', fontSize: 20 }}>done</Icon>
          </Avatar>
        ) : null
      ) : null
    }
    label={label}
    sx={
      notActive
        ? inlineStyles.blackChip
        : notCandidate
        ? inlineStyles.redChip
        : statusEqual
        ? inlineStyles.greenChip
        : null
    }
  />
);

NotificationChip.propTypes = {
  label: PropTypes.string.isRequired,
  statusEqual: PropTypes.bool,
  notCandidate: PropTypes.bool,
  notActive: PropTypes.bool,
  project: PropTypes.bool,
};

export default NotificationChip;
