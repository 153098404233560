import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { Field, reduxForm } from 'redux-form';
import { validate, returnField } from '../utils/formHelpers';
import { updateUserProfile } from '../store/actions/userActions';

import CardWrapper from '../components/common/CardWrapper';
import Grid from '@mui/material/Grid';

import { withTranslation } from 'react-i18next';
import { withRouter } from '../utils/withRouter'

const User = ({
  handleSubmit,
  updateUserProfile,
  pristine,
  submitting,
  i18n,
  t,
}) => {
  const getLocale = () => i18n.language;

  return (

    <CardWrapper
      name={t('userManagement.editUser')}
      caption={t('userManagement.viewSummary')}
      headline
      transparent
    >
      <form onSubmit={handleSubmit(updateUserProfile)}>
        <CardWrapper>
          <Grid container spacing={4} direction="row" alignContent="flex-start" alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <Field
                key="firstname"
                name="firstname"
                component={returnField('text')}
                label={t('labels.firstName')}
                type="text"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                key="lastname"
                name="lastname"
                component={returnField('text')}
                label={t('labels.lastName')}
                type="text"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                key="company"
                name="company"
                component={returnField('text')}
                label={t('labels.company')}
                type="text"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                key="email"
                name="email"
                component={returnField('text')}
                label={t('labels.email')}
                type="text"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                key="title"
                name="title"
                component={returnField('text')}
                label={t('labels.title')}
                type="text"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                key="phone"
                name="phone"
                component={returnField('text')}
                label={t('labels.phone')}
                type="text"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                key="role"
                id="role"
                name="role"
                component={returnField('select')}
                label={t('labels.role')}
                type="text"
                selOpts={['owner', 'constructor', 'propertyManager', 'broker']}
                normalize={value => (( (Array.isArray(value) && value.length === 0)) || !Array.isArray(value) ? ['owner'] : value)}
                translateOptions
                required
                fullWidth
                multiple
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                key="area"
                id="area"
                name="area"
                component={returnField('select')}
                label={t('labels.area')}
                type="text"
                selOpts={['wholeFinland', 'helsinkiMetropolitanArea', 'tampere', 'oulu', 'turku', 'jyväskylä', 'otherFinland']}
                normalize={value => (((Array.isArray(value) && value.length === 0)) || !Array.isArray(value) ? ['wholeFinland'] : value)}
                translateOptions
                required
                fullWidth
                multiple
              />
            </Grid>
          </Grid>
          <br />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={pristine || submitting}
          ><Icon>save</Icon> {t('userManagement.saveUser')}
          </Button>
          <br /><br />
          <a target="_blank" rel="noopener noreferrer" style={{ fontSize: '.8em', textDecoration: 'underline', color: '#999' }} href={getLocale() === 'fi' ? 'https://www.newsec.fi/tietosuojaseloste-tenrep/' : 'https://www.newsec.fi/privacy-statementclients/'}>{t('userManagement.privacyStatement')}</a>
        </CardWrapper>
      </form>
    </CardWrapper>
  );
};


const mapStateToProps = (state, ownProps) => {
  const userId = R.path(['router', 'params', 'userId'], ownProps) || R.path(['userId'], ownProps);
  const users = R.path(['user', 'users'], state);

  const { profile } = users[userId];

  const initialValues = { role: [], area: [], ...profile };

  return ({
    initialValues,
  });
};
const mapDispatchToProps = (dispatch, ownProps) => ({
  updateUserProfile: formContent => dispatch(updateUserProfile(formContent, R.path(['t'], ownProps))),
});

const FormDecoratedComponent = reduxForm({
  form: 'User',
  validate,
})(User);

export default withRouter(withTranslation('translations')(connect(
  mapStateToProps,
  mapDispatchToProps,
)((FormDecoratedComponent))));
