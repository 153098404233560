import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';

import {
  removeFileReference,
  uploadFile,
} from '../../store/actions/fileActions';

import { withTranslation } from 'react-i18next';

/**
 * Add file management with upload, delete and file listing with image or filetype displayed
 * @param {} files                        Files object that contains the file items
 * @param {} document                     Can be used if the target item is a child item - e.g. documentId would be a project id and document would be space id. Leave undefined if there is no need for this functionality.
 * @param {} removeFileReference          Action to be called on file delete which actually just removes the file reference from the target item
 * @param {} collection                   Which collection does the target item belong to, e.g. 'attachedSpaces' or 'projects'
 * @param {} documentId                   Target item id
 * @param {} restrictToImages             Defaults to false, if set to true will only allow images to be uploaded
 * @param {bool} allowMultipleFiles       Allow target item to have more than one files attached to it, default is true, if set to false will overwrite the one file attached on upload
 */
let MainPictureManagement = ({
  document,
  documentId,
  disabled,
  replaceImage,
  collection,
  allowMultipleFiles,
  uploadFile,
  t,
  presentation = false,
}) => {
  const documentRef = document || documentId;
  const acceptImages = 'image/jpeg, image/png';

  const uploadEventMainPicture = event => {
    const filesList = event.target.files;

    const uploadWrapper = file =>
      uploadFile(file, documentRef, collection, allowMultipleFiles, true, t);
    R.map(uploadWrapper, filesList);
  };

  if (presentation) {
    return null;
  }

  return (
    <div>
      <div>
        <input
          accept={acceptImages}
          id={`${documentRef}.fileDataMainPicture`}
          type="file"
          style={{ display: 'none' }}
          onChange={uploadEventMainPicture}
        />
        <label htmlFor={`${documentRef}.fileDataMainPicture`}>
          {replaceImage ? (
            <Button
              component="span"
              style={{ float: 'right' }}
              variant="text"
              color="primary"
              disabled={disabled}
            >
              <Icon style={{ color: '#fff' }}>photo_camera</Icon>
            </Button>
          ) : (
            <Button
              component="span"
              style={{ marginTop: '50px' }}
              variant="contained"
              color="primary"
              disabled={disabled}
            >
              {t('fileManagement.uploadMainPicture')}
              <Icon style={{ float: 'right', marginLeft: '8px' }}>
                photo_camera
              </Icon>
            </Button>
          )}
        </label>
      </div>
    </div>
  );
};

MainPictureManagement.propTypes = {
  document: PropTypes.string,
  documentId: PropTypes.string.isRequired,
  files: PropTypes.object,
  removeFileReference: PropTypes.func.isRequired,
  collection: PropTypes.string.isRequired,
  allowMultipleFiles: PropTypes.bool,
  restrictToImages: PropTypes.bool,
  disabled: PropTypes.bool,
};

MainPictureManagement.defaultProps = {
  files: undefined,
  document: undefined,
  allowMultipleFiles: false,
  restrictToImages: false,
  disabled: false,
};

const mapDispatchToProps = dispatch => ({
  removeFileReference: (collection, documentId, fileId) =>
    dispatch(removeFileReference(collection, documentId, fileId)),
  uploadFile: (
    file,
    documentRef,
    collection,
    allowMultipleFiles,
    mainPicture,
    t
  ) =>
    dispatch(
      uploadFile(
        file,
        documentRef,
        collection,
        allowMultipleFiles,
        mainPicture,
        t
      )
    ),
});

MainPictureManagement = connect(
  null,
  mapDispatchToProps
)(withTranslation('translations')(MainPictureManagement));

export default MainPictureManagement;
