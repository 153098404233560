import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { Field, reduxForm, initialize } from 'redux-form';
import { validate, returnField } from '../../utils/formHelpers';
import CardWrapper from '../common/CardWrapper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { LoadingModal } from '../../utils/modalHelpers';
import { createUser } from '../../store/actions/userActions';

import { withTranslation } from 'react-i18next';

let CreateNewUser = ({
  handleSubmit,
  submitting,
  pristine,
  user,
  userCreateInProgress,
  t,
  dispatch,
}) => {
  const handleCreateUser = obj => {
    dispatch(initialize('newUser', {}));
    dispatch(createUser(obj, t));
  };

  return (
    <CardWrapper>
      <LoadingModal
        modalTitle="Wait-action-user"
        modalDescription="Wait-action-user"
        openState={userCreateInProgress}
        modalHeadline={t('userManagement.waitCreate', {
          email: user ? user.email : ' ',
        })}
        modalSubHeadline=""
      />
      <form onSubmit={handleSubmit(handleCreateUser)}>
        <Grid
          container
          spacing={4}
          direction="row"
          alignContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item sm={12} md={6}>
            <Field
              key="email"
              name="email"
              label={t('userManagement.email')}
              component={returnField('text')}
              type="text"
              required
              fullWidth
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitting || pristine}
            >
              {t('common.save')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </CardWrapper>
  );
};
const mapStateToProps = state => ({
  userCreateInProgress: R.path(['user', 'userCreateInProgress'], state),
  user: R.path(['user', 'user'], state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  createUser: formContent =>
    dispatch(createUser(formContent, R.path(['t'], ownProps))),
});

const FormDecoratedComponent = reduxForm({
  form: 'newUser',
  validate,
})(CreateNewUser);

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(FormDecoratedComponent)
);
