import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import Button from '@mui/material/Button';

import SpaceDetailView from './SpaceDetailView';

import { withTranslation } from 'react-i18next';

const PrintableSpaceDetails = ({
  openProject,
  t,
  templates,
  isAdmin,
  isInProject,
  navigate,
  showActiveSpaces,
}) => {
  const componentRef = useRef();
  return (
    <SpaceDetailView
      ref={componentRef}
      templates={templates}
      isAdmin={isAdmin}
      isInProject={isInProject}
      navigate={navigate}
      showActiveSpaces={showActiveSpaces}
      openProject={openProject}
    >
      <ReactToPrint
        trigger={() => (
          <Button variant="contained" color="primary">
            {t('presentation.print')}
          </Button>
        )}
        content={() => componentRef.current}
        copyStyles
        style={{ fontSize: '85px' }}
      />
    </SpaceDetailView>
  );
};

export default withTranslation('translations')(PrintableSpaceDetails);
