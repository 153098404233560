import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';

import { withTranslation } from 'react-i18next';
import { withRouter } from '../utils/withRouter';

import CardWrapper from '../components/common/CardWrapper';

import NoAccess from '../components/navigation/NoAccess';
import Presentation from '../components/Presentation/Presentation';

let PresentationContainer = ({
  projects,
  admin,
  pid,
  isAdmin,
  templates,
  navigate,
  projectImageUrl,
}) => {
  return admin ? (
    <CardWrapper
      name={R.path([pid, 'projectInfo', 'name'], projects)}
      description={R.path([pid, 'projectInfo', 'detailsDescription'], projects)}
      imageUrl={projectImageUrl}
      transparent
      headline
    >
      <Presentation
        pid={pid}
        templates={templates}
        isAdmin={isAdmin}
        navigate={navigate}
        projects={projects}
      />
    </CardWrapper>
  ) : (
    <NoAccess />
  );
};

const mapStateToProps = (state, ownProps) => {
  const pid = R.path(['router', 'params', 'projectId'], ownProps);
  const projectFiles = R.path(['project', 'projects', pid, 'mainImage'], state);
  const projectMainPictureKeys = R.keys(projectFiles);
  const projectImageUrl =
    R.prop('length', projectMainPictureKeys) > 0 &&
    R.path([R.head(projectMainPictureKeys), 'resizedPath'], projectFiles);

  return {
    projects: R.path(['project', 'projects'], state),
    attr: R.path(['attr', 'attr'], state),
    admin: R.path(['user', 'admin'], state),
    isAdmin: R.path(['user', 'admin'], state),
    pid,
    projectImageUrl,
  };
};

PresentationContainer = withRouter(
  connect(mapStateToProps, null)(PresentationContainer)
);

export default withTranslation('translations')(PresentationContainer);
