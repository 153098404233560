import * as R from 'ramda';

/**
 * Filter results
 * This is a generalized helper for filtering an array of item objects using multiple searchTerms.
 * It takes the items and list of searchTerms and returns all those that match all of the terms.
 * @param {array} items
 * @param {object} searchTerms      Object of all the current searchTerms as key value pairs
 * @param {bool} partialSearch      Use partial search (useful for text fields) if true, exact search (useful for dropdowns) if false
 */
const filterResults = (items, searchTerms, partialSearch) => {
  // If searchTerms are null or undefined -> return all items
  if (searchTerms === null || searchTerms === undefined) {
    return items;
  }

  // If no searchterms -> return all items
  if (Object.values(searchTerms).every(term => term === '')) {
    return items;
  }

  // Initialize array for functions
  const funcs = [];

  /* eslint-disable */

  // Partialsearch uses indexOf to locate partial strings - for example match input field contents against target data
  partialSearch ?
    Object.entries(searchTerms).forEach(([key, value]) => {
      
      Array.isArray(value) ?

      // Search term item is an array
      funcs.push(items => (value.length > 0 ?
        items.filter(item => (Array.isArray(R.path([key], item)) ?
          
          // The target data item is an array
          R.prop('length', R.path([key], item).filter(arrayItem => arrayItem && R.any((a) => { return arrayItem.toUpperCase().indexOf(String(a).toUpperCase()) > -1}, value )) ) > 0
          :
          // The target data item is a string
          R.any((a) => { return a === '' ? true : R.path([key], item) && R.path([key], item).toUpperCase().indexOf(String(a).toUpperCase()) > -1}, value) ))
        : items))

      :

      // Search term item is a string
      funcs.push(items => (value !== '' ?
        items.filter(item => (Array.isArray(R.path([key], item)) ?

          // The target data item is an array
          R.prop('length', R.path([key], item).filter(arrayItem => arrayItem && arrayItem.toUpperCase().indexOf(value.toUpperCase()) > -1)) > 0
          :
          // The target data item is a string
          R.path([key], item) && R.path([key], item).toUpperCase().indexOf(value.toUpperCase()) > -1))
        : items));

    })
    :

    // Non-partialsearch looks for exact matches with R.contains
    Object.entries(searchTerms).forEach(([key, value]) => {
      funcs.push(items => (value !== '' ?
        items.filter(item => (Array.isArray(R.path([key], item)) ?
          R.contains(value, R.path([key], item)) :
          R.contains(R.path([key], item), value))) : items));
    });
  /* eslint-enable */

  // When all these functions are ready we will compose them
  // Composition is applying one function to the results of another
  // So if there e.g. two search terms active
  //    - first function will return items that match the first search term
  //    - second function will use those items returned from the first function and check if they match the second search term
  //    - the overall filtered list of items is returned to the component
  const composeFunctions = R.compose(...funcs);
  return composeFunctions(items);
};

export default filterResults;
