import React, { useState } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import InputLabel from '@mui/material/InputLabel';
import Icon from '@mui/material/Icon';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';

import filterResults from '../../utils/filterResults';

import AddParticipants from './AddParticipants';
import MailToUsers from '../Messaging/MailToUsers';
import CardWrapper from '../common/CardWrapper';

import { withTranslation } from 'react-i18next';

import styles from './FormStyles.module.scss';
import { useTheme } from '@mui/material';

const inlineStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  inputLabel: {
    fontSize: '1em',
    fontWeight: 'bold',
    whiteSpace: 'pre',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  formControl: {
    margin: theme.spacing(1),
    marginTop: '16px',
    minWidth: 120,
    marginBottom: '20px',
  },
  formControlCheckBox: {
    marginLeft: '20px',
  },
  selectEmpty: {
    marginTop: theme.spacing(1) * 2,
  },
});

function Participants({ project, doc, users, t }) {
  const [searchTerms, setSearchTerms] = useState({ role: [''], area: [''] });
  const [emailSearchTerms, setEmailSearchTerms] = useState({
    role: [''],
    area: [''],
  });

  const saveSearchTerms = newTerms => {
    setSearchTerms(prevTerms => ({ ...prevTerms, ...newTerms }));
  };

  const saveEmailSearchTerms = newTerms => {
    setEmailSearchTerms(prevTerms => ({ ...prevTerms, ...newTerms }));
  };

  const searchForMatchingItems = data => {
    return filterResults(data, searchTerms, true);
  };

  const searchForMatchingEmailItems = data => {
    return filterResults(data, emailSearchTerms, true);
  };
  const theme = useTheme();
  const classes = inlineStyles(theme);

  const handleChange = event => {
    event.target.name === 'area' || event.target.name === 'role'
      ? saveSearchTerms({
          [event.target.name]:
            R.prop('length', event.target.value) > 0
              ? event.target.value
              : [''],
        })
      : saveSearchTerms({ [event.target.name]: event.target.value });
  };

  const handleEmailSearchChange = event => {
    event.target.name === 'area' || event.target.name === 'role'
      ? saveEmailSearchTerms({
          [event.target.name]:
            R.prop('length', event.target.value) > 0
              ? event.target.value
              : [''],
        })
      : saveEmailSearchTerms({ [event.target.name]: event.target.value });
  };

  const usersExpanded = R.values(users).map(item => ({
    ...item,
    ...item.profile,
    name:
      item.profile.firstname && item.profile.lastname
        ? `${item.profile.firstname} ${item.profile.lastname}`
        : '',
  }));
  const filteredRows = searchForMatchingItems(usersExpanded);

  const projectUsers = R.path(['users'], project);
  const projectUserKeys = R.keys(projectUsers);
  const relevantUsers = R.pick(projectUserKeys, users);
  const isLocked = n => !R.path(['mgmt', 'locked'], n);
  const relevantUsersFiltered = R.filter(isLocked, relevantUsers);

  const relevantUsersExpanded = R.map(
    item => ({
      ...item,
      ...item.profile,
      name:
        item.profile.firstname && item.profile.lastname
          ? `${item.profile.firstname} ${item.profile.lastname}`
          : '',
    }),
    relevantUsersFiltered
  );
  const filteredEmailRowsValues = searchForMatchingEmailItems(
    R.values(relevantUsersExpanded)
  );
  const filteredEmailRowsKeys = filteredEmailRowsValues.map(item => item.uid);
  const filteredEmailRowsObjects = R.pick(
    filteredEmailRowsKeys,
    relevantUsersExpanded
  );

  return (
    <CardWrapper name={t('participants.projectParticipants')} headline>
      <Accordion>
        <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
          <h3>{t('participants.chooseParticipants')}</h3>
        </AccordionSummary>
        <AccordionDetails style={{ paddingBottom: '0px', marginLeft: '30px' }}>
          <span className={styles.filterField}>
            <TextField
              InputLabelProps={{
                shrink: true,
                style: {
                  fontWeight: 'bold',
                  whiteSpace: 'pre',
                  color: 'rgba(0, 0, 0, 0.54)',
                },
              }}
              variant="standard"
              type="text"
              name="name"
              label={t('participants.name')}
              margin="normal"
              onChange={handleChange}
            />
          </span>
          <span className={styles.filterField}>
            <TextField
              InputLabelProps={{
                shrink: true,
                style: {
                  fontWeight: 'bold',
                  whiteSpace: 'pre',
                  color: 'rgba(0, 0, 0, 0.54)',
                },
              }}
              variant="standard"
              type="text"
              name="company"
              label={t('participants.company')}
              margin="normal"
              onChange={handleChange}
            />
          </span>
          <span className={styles.filterField}>
            <FormControl sx={classes.formControl} key="role" variant="standard">
              <InputLabel shrink sx={classes.inputLabel} htmlFor="role">
                {t('participants.role')}
              </InputLabel>
              <Select
                value={[...searchTerms.role]}
                onChange={handleChange}
                inputProps={{
                  name: 'role',
                  id: 'role',
                }}
                sx={classes.selectEmpty}
                translateOptions
                multiple
                displayEmpty
              >
                <MenuItem value="">{t('common.all')}</MenuItem>
                {['owner', 'constructor', 'propertyManager', 'broker'].map(
                  item => (
                    <MenuItem key={`${item}`} value={item}>
                      {t(`options.${item}`)}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </span>
          <span className={styles.filterField}>
            <FormControl sx={classes.formControl} key="area" variant="standard">
              <InputLabel shrink sx={classes.inputLabel} htmlFor="role">
                {t('participants.area')}
              </InputLabel>
              <Select
                value={[...searchTerms.area]}
                onChange={handleChange}
                inputProps={{
                  name: 'area',
                  id: 'area',
                }}
                sx={classes.selectEmpty}
                translateOptions
                multiple
                displayEmpty
              >
                <MenuItem value="">{t('participants.selectAll')}</MenuItem>
                {[
                  'wholeFinland',
                  'helsinkiMetropolitanArea',
                  'tampere',
                  'oulu',
                  'turku',
                  'jyväskylä',
                  'otherFinland',
                ].map(item => (
                  <MenuItem key={`${item}`} value={item}>
                    {t(`options.${item}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>
        </AccordionDetails>
        <Divider />
        <AccordionDetails
          style={{ backgroundColor: '#E3EEFF', padding: '0px 0px 15px 0px' }}
        >
          <AddParticipants project={project} doc={doc} users={filteredRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
          <h3>{t('participants.sendEmail')}</h3>
        </AccordionSummary>
        <AccordionDetails style={{ paddingBottom: '0px', marginLeft: '30px' }}>
          <span className={styles.filterField}>
            <TextField
              InputLabelProps={{
                shrink: true,
                style: {
                  fontWeight: 'bold',
                  whiteSpace: 'pre',
                  color: 'rgba(0, 0, 0, 0.54)',
                },
              }}
              variant="standard"
              type="text"
              name="name"
              label={t('participants.name')}
              margin="normal"
              onChange={handleEmailSearchChange}
            />
          </span>
          <span className={styles.filterField}>
            <TextField
              InputLabelProps={{
                shrink: true,
                style: {
                  fontWeight: 'bold',
                  whiteSpace: 'pre',
                  color: 'rgba(0, 0, 0, 0.54)',
                },
              }}
              variant="standard"
              type="text"
              name="company"
              label={t('participants.company')}
              margin="normal"
              onChange={handleEmailSearchChange}
            />
          </span>
          <span className={styles.filterField}>
            <FormControl sx={classes.formControl} key="role" variant="standard">
              <InputLabel shrink sx={classes.inputLabel} htmlFor="role">
                {t('participants.role')}
              </InputLabel>
              <Select
                value={[...emailSearchTerms.role]}
                onChange={handleEmailSearchChange}
                inputProps={{
                  name: 'role',
                  id: 'role',
                }}
                sx={classes.selectEmpty}
                translateOptions
                multiple
                displayEmpty
              >
                <MenuItem value="">{t('common.all')}</MenuItem>
                {['owner', 'constructor', 'propertyManager', 'broker'].map(
                  item => (
                    <MenuItem key={`${item}`} value={item}>
                      {t(`options.${item}`)}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </span>
          <span className={styles.filterField}>
            <FormControl sx={classes.formControl} key="area" variant="standard">
              <InputLabel shrink sx={classes.inputLabel} htmlFor="role">
                {t('participants.area')}
              </InputLabel>
              <Select
                value={[...emailSearchTerms.area]}
                onChange={handleEmailSearchChange}
                inputProps={{
                  name: 'area',
                  id: 'area',
                }}
                sx={classes.selectEmpty}
                translateOptions
                multiple
                displayEmpty
              >
                <MenuItem value="">{t('participants.selectAll')}</MenuItem>
                {[
                  'wholeFinland',
                  'helsinkiMetropolitanArea',
                  'tampere',
                  'oulu',
                  'turku',
                  'jyväskylä',
                  'otherFinland',
                ].map(item => (
                  <MenuItem key={`${item}`} value={item}>
                    {t(`options.${item}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>
        </AccordionDetails>
        <Divider />
        <AccordionDetails
          style={{ backgroundColor: '#E3EEFF', padding: '0px 0px 15px 0px' }}
        >
          <MailToUsers
            users={filteredEmailRowsObjects}
            projectUsers={projectUsers}
            doc={doc}
          />
        </AccordionDetails>
      </Accordion>
    </CardWrapper>
  );
}

const mapStateToProps = state => {
  const users = R.path(['user', 'users'], state);
  return {
    users,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation('translations')(Participants));
