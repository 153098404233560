import React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Icon from '@mui/material/Icon';
import Avatar from '@mui/material/Avatar';

import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import orange from '@mui/material/colors/orange';

import { withTranslation } from 'react-i18next';

const inlineStyles = {
  card: {
    display: 'flex',
    boxShadow: 'none',
    borderRadius: '0px',
  },
  flatCard: {
    display: 'flex',
    width: '100%',
    boxShadow: 'none',
    borderRadius: '0px',
  },
  cardHeaderNoStretch: {
    textAlign: 'left',
  },
  cardHeader: {
    width: '100%',
    textAlign: 'left',
  },
  greenStatus: {
    color: '#fff',
    backgroundColor: green[500],
  },
  redStatus: {
    color: '#fff',
    backgroundColor: red[500],
  },
  orangeStatus: {
    color: '#eee',
    backgroundColor: orange[500],
  },
};

/**
 * Display single announcement as a card
 * @param {string} name
 * @param {string} subheader
 * @param {string} type
 * @param {bool} red
 * @param {bool} green
 */
const Announcement = ({ name, subheader, type, red, green, noStretch }) => (
  <Card sx={noStretch ? inlineStyles.card : inlineStyles.flatCard}>
    <CardHeader
      sx={
        noStretch ? inlineStyles.cardHeaderNoStretch : inlineStyles.cardHeader
      }
      avatar={
        <Avatar
          sx={
            red
              ? inlineStyles.redStatus
              : green
              ? inlineStyles.greenStatus
              : inlineStyles.orangeStatus
          }
        >
          <Icon>{type}</Icon>
        </Avatar>
      }
      title={name}
      subheader={subheader}
    />
  </Card>
);

Announcement.propTypes = {
  name: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  type: PropTypes.string.isRequired,
  red: PropTypes.bool,
  green: PropTypes.bool,
};

export default withTranslation('translations')(Announcement);
