/* eslint-disable */
import React, { useState, useEffect } from 'react';
import loginBg from './../../assets/img/helsinki2.jpg';
import { withTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { loginUser, resetPassword } from '../../api/firebaseUtils';

const containerStyle = {
  background: `url(${loginBg})`,
  paddingTop: 10 + 'px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center center',
};

const boxStyle = {
  maxWidth: '360px',
  minHeight: '200px',
  backgroundColor: 'white',
  margin: '0 auto',
  boxShadow:
    '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
};

const topicStyle = {
  padding: '24px',
  fontWeight: 500,
  fontSize: '20px',
};

const paragraphStyle = {
  padding: '0 24px',
};

const textFieldStyle = {
  paddingLeft: '24px',
  maxWidth: '312px',
};

const buttonRowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '24px',
};

const buttonStyle = {
  width: '140px',
  alignSelf: 'center',
};

const FirebaseLogin = ({ t }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [recovery, setRecovery] = useState(false);
  const [errorText, setErrorText] = useState(undefined);
  const [resetInfoText, setResetInfoText] = useState(undefined);
  const [enterPressed, setEnterPressed] = useState(false);

  const login = () => {
    loginUser(email, password).catch(error => {
      const errorCode = error.code;
      if (errorCode === 'auth/user-not-found') {
        setErrorText(t('login.userNotFound'));
      } else if (errorCode === 'auth/wrong-password') {
        setErrorText(t('login.passwordIncorrect'));
      } else {
        setErrorText(t('login.loginFailed'));
      }
    });
  };

  const reset = () => {
    resetPassword(email)
      .then(() => {
        setErrorText(undefined);
        setRecovery(false);
        setResetInfoText(
          `${t('login.resetPart1')} ${email} ${t('login.resetPart2')}`
        );
      })
      .catch(() => {
        setErrorText(t('login.resetFailed'));
      });
  };

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter' && !enterPressed) {
        setEnterPressed(true);
        event.preventDefault();
        if (!recovery && email.length > 0 && password.length > 0) {
          login();
        } else if (recovery && email.length > 0) {
          reset();
        }
      }
    };
    const keyUpHandler = event => {
      if (event.key === 'Enter') {
        setEnterPressed(false);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    document.addEventListener('keyup', keyUpHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
      document.removeEventListener('keyup', keyUpHandler);
    };
  }, [email, password, enterPressed]);

  if (resetInfoText) {
    return (
      <div style={containerStyle}>
        <div style={boxStyle}>
          <div style={topicStyle}>{t('login.recoverPassword')}</div>
          <p style={paragraphStyle}>{resetInfoText}</p>
          <div style={buttonRowStyle}>
            <Button
              component="span"
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={() => setResetInfoText(undefined)}
            >
              {t('login.back')}
            </Button>
          </div>
        </div>
      </div>
    );
  } else if (recovery) {
    return (
      <div style={containerStyle}>
        <div style={boxStyle}>
          <div style={topicStyle}>{t('login.recoverPassword')}</div>
          <p style={paragraphStyle}>{t('login.recoverPasswordInstructions')}</p>
          {errorText ? (
            <p style={{ ...paragraphStyle, color: 'red' }}>{errorText}</p>
          ) : (
            ''
          )}
          <TextField
            style={textFieldStyle}
            type="text"
            label={t('login.email')}
            value={email}
            name="email"
            margin="normal"
            onChange={event => setEmail(event.target.value)}
            fullWidth
          />

          <div style={buttonRowStyle}>
            <Button
              component="span"
              variant="contained"
              color="secondary"
              style={buttonStyle}
              onClick={() => {
                setErrorText('');
                setRecovery(false);
              }}
            >
              {t('login.back')}
            </Button>

            <Button
              component="span"
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={reset}
            >
              {t('login.send')}
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={containerStyle}>
        <div style={boxStyle}>
          <div style={topicStyle}>{t('login.signInWithEmail')}</div>
          {errorText ? (
            <p style={{ ...paragraphStyle, color: 'red' }}>{errorText}</p>
          ) : (
            ''
          )}
          <TextField
            style={textFieldStyle}
            type="text"
            label={t('login.email')}
            value={email}
            name="email"
            margin="normal"
            onChange={event => setEmail(event.target.value)}
            fullWidth
          />
          <TextField
            style={textFieldStyle}
            type="password"
            label={t('login.password')}
            value={password}
            name="password"
            margin="normal"
            onChange={event => setPassword(event.target.value)}
            fullWidth
          />

          <div style={buttonRowStyle}>
            <Button
              component="span"
              variant="contained"
              color="secondary"
              style={buttonStyle}
              onClick={() => {
                setErrorText('');
                setPassword('');
                setRecovery(true);
              }}
            >
              {t('login.troubleSigningIn')}
            </Button>

            <Button
              component="span"
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={login}
            >
              {t('login.signIn')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
};

export default withTranslation('translations')(FirebaseLogin);
