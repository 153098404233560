import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import RFILetterRow from './RFILetterRow';

import Grid from '@mui/material/Grid';

import FileManagement from '../fileManagement/FileManagement';

import { withTranslation } from 'react-i18next';

const inlineStyles = {
  informationGrid: {
    maxWidth: '100%',
    marginBottom: '30px',
  },
};

const RFILetter = ({
  detailsClient,
  detailsDescription,
  detailsSubheader,
  detailsSpace,
  detailsLocation,
  detailsTimeline,
  detailsRentalPeriod,
  detailsOther,
  deadline,
  contactPersonInfo,
  pid,
  files,
  preview,
  t,
}) => (
  <div>
    <Grid
      direction="row"
      spacing={1}
      container
      justifyContent="flex-start"
      alignItems="center"
      sx={inlineStyles.informationGrid}
      color="bodyTextColor"
    >
      {detailsSubheader && (
        <RFILetterRow
          preview={preview}
          t={t}
          label=""
          content={detailsSubheader}
          variant={preview ? 'h4' : 'h5'}
          color="primary"
        />
      )}
      {detailsClient && (
        <RFILetterRow
          t={t}
          label={t('rfiLetter.client')}
          content={detailsClient}
          variant="caption"
        />
      )}
      {detailsDescription && (
        <RFILetterRow
          t={t}
          label={t('rfiLetter.description')}
          content={detailsDescription}
          variant="caption"
        />
      )}
      {detailsSpace && (
        <RFILetterRow
          t={t}
          label={t('rfiLetter.spaceRequirements')}
          content={detailsSpace}
          variant="caption"
        />
      )}
      {detailsLocation && (
        <RFILetterRow
          t={t}
          label={t('rfiLetter.location')}
          content={detailsLocation}
          variant="caption"
        />
      )}
      {detailsTimeline && (
        <RFILetterRow
          t={t}
          label={t('rfiLetter.timeframe')}
          content={detailsTimeline}
          variant="caption"
        />
      )}
      {detailsRentalPeriod && (
        <RFILetterRow
          t={t}
          label={t('rfiLetter.rentalPeriod')}
          content={detailsRentalPeriod}
          variant="caption"
        />
      )}
      {detailsOther && (
        <RFILetterRow
          t={t}
          label={t('rfiLetter.otherInformation')}
          content={detailsOther}
          editorPreview
          variant="caption"
        />
      )}
      {deadline && (
        <RFILetterRow
          t={t}
          label=""
          deadline
          content={deadline}
          variant="caption"
        />
      )}

      {/**
      Project contact details
    */}
      {R.path(['firstname'], contactPersonInfo) && (
        <RFILetterRow
          t={t}
          label={t('rfiLetter.contactPerson')}
          contactDetails
          content={`${R.path(['firstname'], contactPersonInfo)} ${R.path(
            ['lastname'],
            contactPersonInfo
          )}`}
          variant="caption"
        />
      )}
      {R.path(['email'], contactPersonInfo) && (
        <RFILetterRow
          t={t}
          label={t('rfiLetter.email')}
          contactDetails
          content={
            <a href={`mailto:${R.path(['email'], contactPersonInfo)}`}>
              {R.path(['email'], contactPersonInfo)}
            </a>
          }
          variant="caption"
        />
      )}
      {R.path(['phone'], contactPersonInfo) && (
        <RFILetterRow
          t={t}
          label={t('rfiLetter.phone')}
          contactDetails
          content={R.path(['phone'], contactPersonInfo)}
          variant="caption"
        />
      )}

      {files && pid && (
        <Grid item sm={12}>
          <Grid
            direction="row"
            spacing={0}
            container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item md={3} sm={4} />
            <Grid item md={9} sm={8}>
              <FileManagement
                documentId={pid}
                files={files}
                collection="projects"
                allowMultipleFiles
                disabled
                noLeftPadding
                showRole
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  </div>
);

RFILetter.propTypes = {
  theme: PropTypes.object.isRequired,
  imageUrl: PropTypes.string,
  indentContents: PropTypes.bool,
  subItem: PropTypes.bool,
  headline: PropTypes.bool,
  transparent: PropTypes.bool,
  shadow: PropTypes.bool,
  constrainImage: PropTypes.bool,
};

export default withTranslation('translations')(RFILetter);
