import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { emphasize, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Icon from '@mui/material/Icon';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import * as R from 'ramda';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'initial',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `calc(${theme.spacing(1)} / 2) calc(${theme.spacing(1)} / 4)`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)} calc(${theme.spacing(1)} * 2)`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1299,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 600,
    width: '100%',
    verticalAlign: 'bottom',
  },
  selectEmpty: {
    marginTop: theme.spacing(1 * 2),
    zIndex: 1201,
  },
  integrationReactSelectLabel: {
    position: 'absolute',
    left: '-15px',
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      sx={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      sx={props.selectProps.classes.input}
      InputProps={{
        inputComponent,
        inputProps: {
          sx: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      sx={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      sx={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div style={props.selectProps.classes.valueContainer}>{props.children}</div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      sx={{
        ...props.selectProps.classes.chip,
        ...props.selectProps.classes.chipFocused,
      }} //props.isFocused
      onDelete={props.removeProps.onClick}
      deleteIcon={<Icon {...props.removeProps}>cancel</Icon>}
    />
  );
}

function Menu(props) {
  return (
    <Paper square sx={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const IntegrationReactSelect = ({ options, label, onTemplateChange }) => {
  const [single, setSingle] = useState(null);
  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  const handleChange = value => {
    setSingle(value);
    onTemplateChange(value);
  };

  const optionsArray = Object.keys(options)
    .map(key => {
      const num = options[key];
      const item = {
        value: key,
        label: `${R.path(['attr', 'address'], num)}, ${R.path(
          ['attr', 'city'],
          num
        )}, ${R.path(['attr', 'spaceSize'], num)}m2`,
      };
      if (R.path(['attr', 'address'], num)) {
        return item;
      }
      return null;
    })
    .filter(Boolean); // filter out any null values
  const theme = useTheme();
  const classes = styles(theme);
  return (
    <FormControl sx={classes.formControl}>
      <InputLabel
        shrink
        htmlFor={`${label}`}
        sx={classes.integrationReactSelectLabel}
      >
        {label}
      </InputLabel>
      <Select
        sx={classes.selectEmpty}
        classes={classes}
        styles={selectStyles}
        options={optionsArray}
        components={components} // you will want to pass components as a prop if they are required
        value={single}
        placeholder=""
        onChange={value => handleChange(value)}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          name: label,
          id: `${label}`,
        }}
        name={label}
      />
    </FormControl>
  );
};

IntegrationReactSelect.propTypes = {
  options: PropTypes.object.isRequired,
};

export default IntegrationReactSelect;
