import React from 'react';
import Button from '@mui/material/Button';

const TogglePresentation = props => (
  <div>
    <Button variant="contained" color="primary">
        {props.label}
    </Button>
  </div>
);

export default TogglePresentation;
