import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, Route, Routes } from 'react-router-dom';
import { withRouter } from './utils/withRouter';
import { checkIfUserIsAdmin } from './api/firebaseUtils';

import * as R from 'ramda';

import { withTranslation } from 'react-i18next';

import { SnackbarProvider } from 'notistack';

import Notifier from './components/common/Notifier';

import {
  subscribeToProjects,
  unsubscribeFromProjects,
} from './store/actions/projectActions';
import {
  subscribeToSpaces,
  unsubscribeFromSpaces,
  subscribeToAttachedSpaces,
  unsubscribeFromAttachedSpaces,
} from './store/actions/spaceActions';
import {
  subscribeToRfiAttr,
  unsubscribeFromRfiAttr,
} from './store/actions/attrActions';
import {
  subscribeToUserProfiles,
  unsubscribeFromUserProfiles,
  updateUserProfile,
} from './store/actions/userActions';
import {
  subscribeToClients,
  unsubscribeFromClients,
} from './store/actions/clientActions';
import {
  subscribeToProjectNotes,
  unsubscribeFromProjectNotes,
} from './store/actions/projectNotesActions';

import { startTour } from './store/actions/joyrideActions';

import Navigation from './components/navigation/Navigation';
import Projects from './containers/Projects';
import ProjectInfo from './containers/ProjectInfo';
import LandlordProjectDetailView from './containers/LandlordProjectDetailView';
import ProjectDetailView from './containers/ProjectDetailView';
import PresentationContainer from './containers/PresentationContainer';
import Spaces from './containers/Spaces';
import Export from './containers/Export';
import UserManagement from './containers/UserManagement';
import User from './containers/User';

import { ModalFormDialog } from './utils/modalHelpers';

import './App.scss';

const styles = {
  button: {
    margin: 1,
  },
  rightIcon: {
    marginLeft: 1,
  },
};

let App = ({
  subscribeToProjects,
  unsubscribeFromProjects,
  subscribeToSpaces,
  unsubscribeFromSpaces,
  subscribeToRfiAttr,
  unsubscribeFromRfiAttr,
  subscribeToUserProfiles,
  unsubscribeFromUserProfiles,
  subscribeToAttachedSpaces,
  unsubscribeFromAttachedSpaces,
  subscribeToClients,
  unsubscribeFromClients,
  subscribeToProjectNotes,
  unsubscribeFromProjectNotes,
  startTour,
  updateUserProfile,
  productsInitialLoadComplete,
  spacesInitialLoadComplete,
  attachedSpacesInitialLoadComplete,
  usersInitialLoadComplete,
  attributesInitialLoadComplete,
  clientsInitialLoadComplete,
  projectNotesInitialLoadComplete,
  profile,
  t,
  router,
  user,
}) => {
  const userId = user.uid;
  const [isAdmin, setIsAdmin] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    checkIfUserIsAdmin().then(admin => {
      const projectParams = {
        uid: userId,
        isAdmin: admin,
      };
      setIsAdmin(admin);

      subscribeToProjects(projectParams);
      subscribeToSpaces(admin);
      subscribeToAttachedSpaces(admin);
      subscribeToUserProfiles(admin);
      subscribeToRfiAttr(admin);
      subscribeToClients(admin);
      subscribeToProjectNotes(admin);
    });

    // Specify how to clean up after this effect:
    return () => {
      unsubscribeFromProjects();
      unsubscribeFromSpaces();
      unsubscribeFromAttachedSpaces();
      unsubscribeFromRfiAttr();
      unsubscribeFromUserProfiles();
      unsubscribeFromClients();
      unsubscribeFromProjectNotes();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]); // Only re-run the effect if userId changes

  const saveUserDetails = data => {
    return updateUserProfile(data);
  };

  // Construct initialValues and drop out createdDate
  const initialValues_construct = usersInitialLoadComplete
    ? { role: [], area: [], ...profile }
    : {};
  const { createdDate, ...initialValues } = initialValues_construct;

  // All of these requirements have to be met, otherwiser user will be presented with a dialog prompt asking him/her to fill in user information
  const allRequiredUserDataIsFilledIn = usersInitialLoadComplete
    ? R.prop('length', R.path(['firstname'], profile)) > 0 &&
      R.prop('length', R.path(['lastname'], profile)) > 0 &&
      R.prop('length', R.path(['company'], profile)) > 0 &&
      R.prop('length', R.path(['email'], profile)) > 0 &&
      R.prop('length', R.path(['phone'], profile)) > 0 &&
      R.prop('length', R.path(['area'], profile)) > 0 &&
      R.prop('length', R.path(['role'], profile)) > 0
    : true;

  if (!allRequiredUserDataIsFilledIn && router.location.pathname !== '/') {
    navigate('/');
  }

  /**
   * Nothing at all is rendered before projects and spaces are loaded to prevent errors.
   */

  let isPresentation = false;

  if (window.location.href.indexOf('presentation') !== -1) {
    isPresentation = true;
  }

  return isAdmin !== null &&
    userId &&
    productsInitialLoadComplete &&
    spacesInitialLoadComplete &&
    attachedSpacesInitialLoadComplete &&
    usersInitialLoadComplete &&
    attributesInitialLoadComplete &&
    clientsInitialLoadComplete &&
    projectNotesInitialLoadComplete ? (
    <SnackbarProvider maxSnack={3}>
      <div className="App">
        <Notifier />
        <div>
          {!isPresentation ? (
            <Navigation isAdmin={isAdmin} runTour={() => startTour()} />
          ) : null}

          <div className="container">
            <Routes>
              <Route
                path="newproject"
                element={<ProjectInfo form="newProject" navigate={navigate} />}
              />

              <Route
                path="presentation/:projectId"
                element={isAdmin ? <PresentationContainer /> : null}
              />
              <Route
                path="presentation/:projectId/spaces/:documentId"
                element={
                  <PresentationContainer templates={false} isAdmin={isAdmin} />
                }
              />

              <Route
                path="project/:projectId"
                element={
                  isAdmin ? (
                    <ProjectDetailView userId={userId} />
                  ) : (
                    <LandlordProjectDetailView userId={userId} />
                  )
                }
              ></Route>
              <Route
                path="project/:projectId/spaces/:documentId"
                element={
                  isAdmin ? (
                    <ProjectDetailView templates={false} isAdmin={isAdmin} />
                  ) : (
                    <LandlordProjectDetailView userId={userId} />
                  )
                }
              />

              <Route
                path="spaces"
                element={<Spaces templates={false} isAdmin={isAdmin} />}
              >
                <Route
                  path=":documentId"
                  element={<Spaces templates={false} isAdmin={isAdmin} />}
                />
              </Route>

              <Route
                path="templates"
                element={<Spaces templates isAdmin={isAdmin} />}
              >
                <Route
                  path=":documentId"
                  element={<Spaces templates isAdmin={isAdmin} />}
                />
              </Route>

              <Route path="export" element={<Export isAdmin={isAdmin} />} />

              <Route path="users" element={<UserManagement />} />
              <Route path="users/:userId" element={<User />} />

              <Route path="profile" element={<User userId={userId} />} />

              <Route
                path="/"
                element={
                  <Projects
                    isAdmin={isAdmin}
                    allRequiredUserDataIsFilledIn={
                      allRequiredUserDataIsFilledIn
                    }
                    userId={userId}
                  />
                }
              />
            </Routes>

            <ModalFormDialog
              modalTitle="Fill-user-details"
              modalDescription="Fill-user-details"
              openState={!allRequiredUserDataIsFilledIn}
              modalHeadline={t('userManagement.updateUserInformation')}
              modalSubHeadline={t(
                'userManagement.updateUserInformationInstructions'
              )}
              buttonSx={styles.button}
              iconSx={styles.rightIcon}
              cancelOption={false}
              onSubmitClick={saveUserDetails}
              submitButtonText={t('common.save')}
              initValues={initialValues}
              fields={[
                {
                  key: 'firstname',
                  label: t('labels.firstName'),
                  type: 'text',
                  required: true,
                },
                {
                  key: 'lastname',
                  label: t('labels.lastName'),
                  type: 'text',
                  required: true,
                },
                {
                  key: 'company',
                  label: t('labels.company'),
                  type: 'text',
                  required: true,
                },
                {
                  key: 'email',
                  label: t('labels.email'),
                  type: 'text',
                  required: true,
                },
                {
                  key: 'title',
                  label: t('labels.title'),
                  type: 'text',
                  required: false,
                },
                {
                  key: 'phone',
                  label: t('labels.phone'),
                  type: 'text',
                  required: true,
                },
                {
                  key: 'role',
                  label: t('labels.role'),
                  type: 'text',
                  required: true,
                  component: 'select',
                  translateOptions: true,
                  selOpts: [
                    'owner',
                    'constructor',
                    'propertyManager',
                    'broker',
                  ],
                  normalize: 'owner',
                  multiple: true,
                  helperText: t(
                    'userManagement.updateUserInformationChooseAtLeastOneRole'
                  ),
                },
                {
                  key: 'area',
                  label: t('labels.area'),
                  type: 'text',
                  required: true,
                  component: 'select',
                  translateOptions: true,
                  selOpts: [
                    'wholeFinland',
                    'helsinkiMetropolitanArea',
                    'tampere',
                    'oulu',
                    'turku',
                    'jyväskylä',
                    'otherFinland',
                  ],
                  normalize: 'wholeFinland',
                  multiple: true,
                  helperText: t(
                    'userManagement.updateUserInformationChooseAtLeastOneArea'
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </SnackbarProvider>
  ) : null;
};

const mapStateToProps = (state, ownProps) => {
  const users = R.path(['user', 'users'], state);
  const usersInitialLoadComplete = R.path(
    ['user', 'usersInitialLoadComplete'],
    state
  );
  const userId = R.path(['user', 'uid'], ownProps);
  const { profile } = usersInitialLoadComplete ? users[userId] : {};

  return {
    productsInitialLoadComplete: R.path(
      ['project', 'productsInitialLoadComplete'],
      state
    ),
    spacesInitialLoadComplete: R.path(
      ['space', 'spacesInitialLoadComplete'],
      state
    ),
    attachedSpacesInitialLoadComplete: R.path(
      ['space', 'attachedSpacesInitialLoadComplete'],
      state
    ),
    usersInitialLoadComplete: R.path(
      ['user', 'usersInitialLoadComplete'],
      state
    ),
    attributesInitialLoadComplete: R.path(
      ['attr', 'attrInitialLoadComplete'],
      state
    ),
    clientsInitialLoadComplete: R.path(
      ['client', 'clientsInitialLoadComplete'],
      state
    ),
    projectNotesInitialLoadComplete: R.path(
      ['projectNotes', 'projectNotesInitialLoadComplete'],
      state
    ),
    profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  subscribeToProjects: admin => dispatch(subscribeToProjects(admin)),
  unsubscribeFromProjects: () => dispatch(unsubscribeFromProjects()),
  subscribeToSpaces: admin => dispatch(subscribeToSpaces(admin)),
  unsubscribeFromSpaces: () => dispatch(unsubscribeFromSpaces()),
  subscribeToRfiAttr: admin => dispatch(subscribeToRfiAttr(admin)),
  unsubscribeFromRfiAttr: () => dispatch(unsubscribeFromRfiAttr()),
  subscribeToUserProfiles: admin => dispatch(subscribeToUserProfiles(admin)),
  unsubscribeFromUserProfiles: () => dispatch(unsubscribeFromUserProfiles()),
  subscribeToAttachedSpaces: admin =>
    dispatch(subscribeToAttachedSpaces(admin)),
  unsubscribeFromAttachedSpaces: () =>
    dispatch(unsubscribeFromAttachedSpaces()),
  subscribeToClients: admin => dispatch(subscribeToClients(admin)),
  unsubscribeFromClients: () => dispatch(unsubscribeFromClients()),
  subscribeToProjectNotes: admin => dispatch(subscribeToProjectNotes(admin)),
  unsubscribeFromProjectNotes: () => dispatch(unsubscribeFromProjectNotes()),
  startTour: () => dispatch(startTour()),
  updateUserProfile: formContent =>
    dispatch(updateUserProfile(formContent, R.path(['t'], ownProps))),
});

App = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

export default withTranslation('translations')(App);
