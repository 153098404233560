import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as moment from 'moment';

import { NavLink } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';

import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import Collapse from '@mui/material/Collapse';

import CardWrapper from '../common/CardWrapper';

import filterResults from '../../utils/filterResults';

import SortableTableFilters from './SortableTableFilters';
import NotificationChip from '../common/NotificationChip';

import { withTranslation } from 'react-i18next';
import NotificationAvatar from '../common/NotificationAvatar';

import { ascComparator, descComparator } from '../../utils/comparators';
import { useTheme } from '@mui/material';

const inlineStyles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    color: '#000',
    boxShadow: 'none',
    borderRadius: '0px',
  },
  toolbar: {
    paddingLeft: '20px',
  },
  table: {
    overflowX: 'auto',
  },
  tableLabel: {
    cursor: 'pointer',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableRow: {
    '& tr:nth-of-type(even)': { backgroundColor: '#e1e6ea' },
  },
  leftPaddingZero: {
    paddingLeft: '0px',
  },
});

function SortableExpandingTable({
  data,
  labels,
  filterLabels,
  filterTypes,
  subItems,
  subItemAttributes,
  uid,
  t,
  lng,
  calculateItems,
  isAdmin,
  saveFilters,
  clearFilters,
  filters,
}) {
  const [sortTouched, setSortTouched] = useState(false);
  const [sortOrder, setSortOrder] = useState('desc');
  const [orderByStr, setOrderByStr] = useState('deadline');
  const [searchTerms, setSearchTerms] = useState(filters);
  const [open, setOpen] = useState({});
  const [rowCount, setRowCount] = useState(0);

  const [sortBy, setSortBy] = useState(() => ascComparator('deadline'));

  // Filter row data with current searchTerms before render
  const filteredRows = filterResults(data, searchTerms);

  // Sort row data
  const sortedRows = R.sort(sortBy, filteredRows);

  // Sort the items with exclamation mark (with status 0) to top on component mount (state.sortTouched)
  const sortByStatus = R.comparator((a, b) =>
    R.path(['users', uid], a) === 0
      ? R.path(['users', uid], a) < R.path(['users', uid], b)
      : 0
  );
  const rows = sortTouched ? sortedRows : R.sort(sortByStatus, sortedRows);

  useEffect(() => {
    setSearchTerms(filters);
    changeSortBy({ key: 'deadline' });
    saveSearchTerms({
      status: [
        'Draft',
        'Round 1',
        'Round 2',
        'Round 3',
        'Round 4',
        'Round 5',
        'Round 6',
        'Closed',
      ],
    });

    return () => clearFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSearchTerms = useCallback(
    newTerms => {
      const mergedTerms = {
        ...searchTerms,
        ...newTerms,
      };
      setSearchTerms(mergedTerms);
      saveFilters(mergedTerms);
    },
    [searchTerms, saveFilters]
  );

  const handleChange = event => {
    saveSearchTerms({ [event.target.name]: event.target.value });
  };

  const handleChangeCheckbox = name => event => {
    saveSearchTerms({
      [name]: event.target.checked
        ? ''
        : [
            'Draft',
            'Round 1',
            'Round 2',
            'Round 3',
            'Round 4',
            'Round 5',
            'Round 6',
            'Closed',
          ],
    });
  };

  const handleClick = rowId => {
    setOpen({ ...open, [rowId]: !open[rowId] });
  };

  const changeSortOrder = () => {
    setSortOrder(currentSortOrder =>
      currentSortOrder === 'asc' ? 'desc' : 'asc'
    );
  };

  const changeSortBy = sortByThis => {
    changeSortOrder();
    setSortBy(() =>
      sortOrder === 'asc'
        ? ascComparator(sortByThis.key)
        : descComparator(sortByThis.key)
    );
    setOrderByStr(sortByThis.key);
  };

  useEffect(() => {
    if (calculateItems) {
      const filteredRows = filterResults(data, searchTerms);
      const newRowCount = R.prop('length', filteredRows);
      if (rowCount !== newRowCount) {
        setRowCount(newRowCount);
        calculateItems(newRowCount);
      }
    }
  }, [calculateItems, rowCount, data, searchTerms]);

  useEffect(() => {
    moment.locale(lng);
    moment.updateLocale('fi', {
      relativeTime: {
        future: '%s päästä',
        past: '%s sitten',
        s: 'muutama sekunti',
        ss: '%d sekuntia',
        m: 'minuutti',
        mm: '%d minuuttia',
        h: 'tunti',
        hh: '%d tuntia',
        d: 'päivä',
        dd: '%d päivää',
        M: 'a kuukausi',
        MM: '%d kuukautta',
        y: 'a vuosi',
        yy: '%d vuotta',
      },
    });
  }, [lng]);

  const theme = useTheme();
  const classes = inlineStyles(theme);
  return R.prop('length', rows) === 0 ? (
    <Paper sx={classes.root}>
      <Typography
        style={{ paddingTop: '20px', paddingBottom: '20px' }}
        variant="h5"
      >
        {t('projects.noProjects')}
      </Typography>
    </Paper>
  ) : (
    <Paper sx={classes.root}>
      <div style={classes.toolbar}>
        <SortableTableFilters
          data={data}
          handleChange={e => handleChange(e)}
          handleChangeCheckbox={e => handleChangeCheckbox(e)}
          searchTerms={searchTerms}
          filterLabels={filterLabels}
          filterTypes={filterTypes}
        />
      </div>
      <div style={classes.tableWrapper}>
        <Divider />
        <Table sx={classes.table} size="small">
          <TableHead padding="default">
            <TableRow>
              {Array.from(labels).map((label, index) => (
                <TableCell
                  key={`${label}${index}`}
                  sx={classes.tableLabel}
                  onClick={() => {
                    changeSortBy(label);
                    setSortTouched(true);
                  }}
                >
                  <TableSortLabel
                    active={orderByStr === label.key}
                    direction={sortOrder}
                    sx={classes.sortLabel}
                  >
                    {label.finnish}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell sx={classes.sortLabel}>
                {isAdmin ? t('spaces.spaces') : t('spaces.mySpaces')}
              </TableCell>
              <TableCell sx={classes.tableLabel} />
            </TableRow>
          </TableHead>

          {rows.map(row => {
            const oKeys = row.objectKeys;

            const itemSubItemsCount = R.prop(
              'length',
              Object.entries(
                R.filter(
                  item => R.path(['project'], item) === R.path(['id'], row),
                  subItems
                )
              )
            );
            const itemSubItemsCandidateCount = R.prop(
              'length',
              Object.entries(
                R.filter(
                  item =>
                    R.path(['project'], item) === R.path(['id'], row) &&
                    R.path(['candidate'], item),
                  subItems
                )
              )
            );
            const itemHasSubItems = Boolean(itemSubItemsCount);

            const rowId = row.id;

            return (
              <TableBody
                key={rowId}
                sx={classes.tableRow}
                className="tableBody"
              >
                <TableRow key={rowId} onClick={() => handleClick(rowId)}>
                  {/* Render a notification icon if user status is "Not seen" */}
                  {R.path(['users', uid], row) === 0 && !isAdmin && (
                    <TableCell padding="checkbox">
                      <NotificationAvatar />
                    </TableCell>
                  )}
                  {R.path(['users', uid], row) !== 0 && !isAdmin && (
                    <TableCell padding="checkbox" />
                  )}

                  <TableCell size="small" sx={classes.leftPaddingZero}>
                    <IconButton
                      className="edit"
                      component={NavLink}
                      to={`/project/${rowId}`}
                      size="large"
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                  </TableCell>

                  {Array.from(oKeys).map((objectKey, index) => (
                    <TableCell key={`${objectKey}${index}`}>
                      {Array.isArray(R.path([objectKey], row)) ? (
                        R.path([objectKey], row).join(', ')
                      ) : objectKey === 'status' ? (
                        <NotificationChip
                          project
                          notActive={
                            R.path([objectKey], row) === 'Closed' ||
                            R.path([objectKey], row) === 'Archived'
                          }
                          statusEqual={
                            R.path([objectKey], row) !== 'Draft' &&
                            R.path([objectKey], row) !== 'Closed' &&
                            R.path([objectKey], row) !== 'Archived'
                          }
                          label={t(`options.${R.path([objectKey], row)}`)}
                        />
                      ) : objectKey === 'updated' ? (
                        moment()
                          .subtract(14, 'days')
                          .isBefore(
                            moment(R.path([objectKey], row), 'DD/MM/YYYY')
                          ) ? (
                          moment(R.path(['updatedUnix'], row), 'X').fromNow()
                        ) : (
                          moment(R.path([objectKey], row), 'DD/MM/YYYY').format(
                            'DD/MM/YYYY'
                          )
                        )
                      ) : (
                        R.pathOr(t('common.notDefined'), [objectKey], row)
                      )}
                    </TableCell>
                  ))}
                  <TableCell>
                    <span>
                      {itemSubItemsCandidateCount}
                      {'\u00A0'}/{'\u00A0'}
                      {itemSubItemsCount}
                    </span>
                  </TableCell>

                  <TableCell>
                    {itemHasSubItems &&
                      (open[rowId] ? (
                        <Icon>expand_less</Icon>
                      ) : (
                        <Icon>expand_more</Icon>
                      ))}
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{
                    height: 'initial',
                    padding: '0px',
                    backgroundColor: '#eee',
                    display:
                      itemHasSubItems && open[rowId] ? 'table-row' : 'none',
                    boxShadow: 'inset 0px 2px 5px 0px rgba(0,0,0,0.4)',
                  }}
                >
                  <TableCell colSpan={12}>
                    <Collapse
                      component="div"
                      in={open[rowId]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <CardWrapper
                        name={t('projects.attachedSpaces')}
                        headline
                        transparent
                        noPadding
                      >
                        <Table size="small">
                          <TableHead padding="default">
                            {subItems && (
                              <TableRow>
                                {subItemAttributes.map((item, index) => (
                                  <TableCell
                                    key={item}
                                    style={{
                                      borderLeft:
                                        index === 0 ? '' : '1px dotted #ccc',
                                    }}
                                  >
                                    {t(`labels.${item}`)}
                                  </TableCell>
                                ))}
                              </TableRow>
                            )}
                          </TableHead>
                          <TableBody>
                            {subItems &&
                              Object.entries(subItems).map(
                                subItem =>
                                  R.nth(1, subItem).project === rowId && (
                                    <TableRow key={R.head(subItem)}>
                                      {subItemAttributes.map((item, index) => (
                                        <TableCell
                                          key={item}
                                          style={{
                                            borderLeft:
                                              index === 0
                                                ? ''
                                                : '1px dotted #ccc',
                                          }}
                                        >
                                          {item === 'status' && (
                                            <NotificationChip
                                              statusEqual={
                                                R.nth(1, subItem).status ===
                                                R.path(['status'], row)
                                              }
                                              notCandidate={
                                                !R.nth(1, subItem).candidate
                                              }
                                              notActive={
                                                !R.nth(1, subItem).active
                                              }
                                              label={
                                                R.nth(1, subItem).active
                                                  ? t(
                                                      `options.${
                                                        R.nth(1, subItem).status
                                                      }`
                                                    )
                                                  : t('options.inactive')
                                              }
                                            />
                                          )}
                                          {item === 'rejection' &&
                                            R.nth(1, subItem)[item]}
                                          {item === 'candidate'
                                            ? R.nth(1, subItem)[item]
                                              ? t('common.yes')
                                              : t('common.no')
                                            : ''}
                                          {item !== 'candidate' &&
                                          item !== 'status' &&
                                          item !== 'rejection'
                                            ? R.path(
                                                ['attr', item],
                                                R.nth(1, subItem)
                                              )
                                              ? R.path(
                                                  ['attr', item],
                                                  R.nth(1, subItem)
                                                )
                                              : '0'
                                            : ''}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  )
                              )}
                          </TableBody>
                        </Table>
                      </CardWrapper>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })}
        </Table>
      </div>
    </Paper>
  );
}

SortableExpandingTable.propTypes = {
  data: PropTypes.array.isRequired,
  filterLabels: PropTypes.object.isRequired,
  filterTypes: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
  saveFilters: PropTypes.func.isRequired,
  filters: PropTypes.object,
};

export default withTranslation('translations')(SortableExpandingTable);
