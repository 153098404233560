import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Card from '@mui/material/Card';
import Announcement from './Announcement';

import { withTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

const inlineStyles = theme => ({
  flatCard: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    boxShadow: 'none',
    borderRadius: '0px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

/**
 * Display space related announcement conditionally
 * @param {object} t
 * @param {object} openSpace
 * @param {string} projectStatus
 */
const SpaceAnnouncements = ({
  t,
  openSpace,
  projectStatus,
  filledInAttributeFields,
  totalAttributeFields,
}) => {
  const theme = useTheme();
  const classes = inlineStyles(theme);
  return (
    <Card sx={classes.flatCard}>
      {// This space has been rejected and edit functionality is disabled. Reason:
      !R.path(['candidate'], openSpace) && (
        <Announcement
          name={`${t('spaceAnnouncements.spaceHasBeenRejected')}`}
          subheader={R.pathOr('-', ['rejection'], openSpace)}
          type="announcement"
          red
        />
      )}

      {// You have deactivated this space.
      !R.path(['active'], openSpace) && (
        <Announcement
          name={t('spaceAnnouncements.userDeactivatedSpace')}
          type="announcement"
          red
        />
      )}

      {// This space is still a candidate in this project.
      R.path(['candidate'], openSpace) &&
        R.path(['active'], openSpace) &&
        projectStatus !== 'Closed' &&
        R.path(['status'], openSpace) !== 'Draft' && (
          <Announcement
            name={t('spaceAnnouncements.spaceStillAsCandidate')}
            type="announcement"
            green
          />
        )}

      {// This space is a draft and you have to submit it to participate in the project.
      projectStatus !== 'Closed' &&
        R.path(['status'], openSpace) === 'Draft' && (
          <Announcement
            name={t('spaceAnnouncements.spaceNotSubmitted')}
            type="announcement"
            yellow
          />
        )}

      {
        // Show amount of filled in attribute fields
        <Announcement
          name={`${filledInAttributeFields}/${totalAttributeFields} ${t(
            'spaceAnnouncements.spacesFilledIn'
          )}`}
          type="announcement"
          yellow={filledInAttributeFields < totalAttributeFields}
          green={filledInAttributeFields === totalAttributeFields}
        />
      }
    </Card>
  );
};

SpaceAnnouncements.propTypes = {
  t: PropTypes.func.isRequired,
  openSpace: PropTypes.object.isRequired,
  projectStatus: PropTypes.string.isRequired,
};

export default withTranslation('translations')(SpaceAnnouncements);
