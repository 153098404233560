import React from 'react';

import * as R from 'ramda';

import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { validate, returnField, CheckBoxListSpaces } from './formHelpers';
import { useTheme } from '@mui/material';

/** Loading modal
 * @param {string}  modalTitle           for aria - modal title such as 'simple-modal-title'
 * @param {string}  modalDescription     for aria - modal description such as 'simple-modal-description'
 * @param {bool}    openState            component state variable, e.g. this.state.modalOpen
 * @param {string}  modalHeadline        main headline e.g. 'Haluatko poistaa tilan:'
 * @param {string}  modalSubHeadline     subheadline e.g. 'Mannerheimintie 1 a 2, 3.krs"
 */
export const LoadingModal = ({
  modalTitle,
  modalDescription,
  openState,
  modalHeadline,
  modalSubHeadline,
}) => (
  <Dialog
    aria-labelledby={modalTitle}
    aria-describedby={modalDescription}
    open={openState}
    scroll="paper"
    maxWidth="md"
  >
    <DialogContent>
      <Typography variant="h6">{modalHeadline}</Typography>
      <Typography variant="subtitle1">{modalSubHeadline}</Typography>
      <br />
      <div style={{ width: '100%', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    </DialogContent>
  </Dialog>
);

/** Dialog modal component
 * @param {string}  modalTitle           for aria - modal title such as 'simple-modal-title'
 * @param {string}  modalDescription     for aria - modal description such as 'simple-modal-description'
 * @param {bool}    openState            component state variable, e.g. this.state.modalOpen
 * @param {func}    onClose              function for handling modal close event
 * @param {string}  modalHeadline        main headline e.g. 'Haluatko poistaa tilan:'
 * @param {string}  modalSubHeadline     subheadline e.g. 'Mannerheimintie 1 a 2, 3.krs"
 * @param {string}  iconSx        icon class name e.g. classes.rightIcon
 * @param {func}    onCancelClick        modal cancel click function handler
 * @param {string}  cancelButtonText     modal cancel button text
 * @param {bool}    cancelOption         if false, cancel option will not be shown to user
 * @param {func}    onSubmitClick        modal submit click function handler
 * @param {string}  submitButtonText     modal submit button text
 * @param {bool}    submitting           is form submitting
 */
export const ModalDialog = ({
  modalTitle,
  modalDescription,
  openState,
  onClose,
  modalHeadline,
  modalSubHeadline,
  buttonSx,
  iconSx,
  onCancelClick,
  cancelButtonText,
  cancelOption = true,
  onSubmitClick,
  submitButtonText,
  submitting,
}) => (
  <Dialog
    aria-labelledby={modalTitle}
    aria-describedby={modalDescription}
    open={openState}
    onClose={onClose}
    scroll="paper"
    maxWidth="md"
  >
    <DialogContent>
      <Typography variant="h6">{modalHeadline}</Typography>
      <br />
      <Typography variant="subtitle1">{modalSubHeadline}</Typography>
      <br />
      <div style={{ width: '100%', textAlign: 'center' }}>
        {cancelOption && (
          <Button
            variant="contained"
            sx={buttonSx}
            disabled={submitting}
            onClick={onCancelClick}
          >
            {cancelButtonText}
            <Icon sx={iconSx}>cancel</Icon>
          </Button>
        )}
        <Button
          variant="contained"
          sx={buttonSx}
          disabled={submitting}
          onClick={onSubmitClick}
        >
          {submitButtonText}
          <Icon sx={iconSx}>check</Icon>
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);

/** Multiple selection radio modal component
 * TODO: Generalize
 * @param {string}  modalTitle           for aria - modal title such as 'simple-modal-title'
 * @param {string}  modalDescription     for aria - modal description such as 'simple-modal-description'
 * @param {bool}    openState            component state variable, e.g. this.state.modalOpen
 * @param {func}    onClose              function for handling modal close event
 * @param {string}  modalHeadline        main headline e.g. 'Haluatko poistaa tilan:'
 * @param {string}  modalSubHeadline     subheadline e.g. 'Mannerheimintie 1 a 2, 3.krs"
 * @param {string}  iconSx        icon class name e.g. classes.rightIcon
 * @param {func}    onCancelClick        modal cancel click function handler
 * @param {string}  cancelButtonText     modal cancel button text
 * @param {bool}    cancelOption         if false, cancel option will not be shown to user
 * @param {func}    onSubmitClick        modal submit click function handler
 * @param {string}  submitButtonText     modal submit button text
 * @param {bool}    submitting           is form submitting
 */
export const ModalMultipleSelection = ({
  modalTitle,
  modalDescription,
  openState,
  onClose,
  modalHeadline,
  modalSubHeadline,
  buttonSx,
  iconSx,
  onCancelClick,
  cancelButtonText,
  cancelOption = true,
  onSubmitClick,
  submitButtonText,
  submitting,
  items,
}) => {
  let selectedItems = [];
  return (
    <Dialog
      aria-labelledby={modalTitle}
      aria-describedby={modalDescription}
      open={openState}
      onClose={onClose}
      scroll="paper"
      maxWidth="md"
    >
      <DialogContent>
        <Typography variant="subtitle1">{modalHeadline}</Typography>
        <Typography variant="caption">{modalSubHeadline}</Typography>
        <br />
        <CheckBoxListSpaces
          items={items}
          saveSelectedItemsToParent={selectedSpaces =>
            (selectedItems = selectedSpaces)
          }
        />
        <div style={{ width: '100%', textAlign: 'center' }}>
          {cancelOption && (
            <Button
              variant="contained"
              sx={buttonSx}
              disabled={submitting}
              onClick={onCancelClick}
            >
              {cancelButtonText}
              <Icon sx={iconSx}>cancel</Icon>
            </Button>
          )}
          <Button
            variant="contained"
            sx={buttonSx}
            disabled={submitting}
            onClick={() => onSubmitClick(selectedItems)}
          >
            {submitButtonText}
            <Icon sx={iconSx}>save</Icon>
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

/** Preview modal component
 * @param {string}  modalTitle           for aria - modal title such as 'simple-modal-title'
 * @param {string}  modalDescription     for aria - modal description such as 'simple-modal-description'
 * @param {bool}    openState            component state variable, e.g. this.state.modalOpen
 * @param {func}    onClose              function for handling modal close event
 * @param {string}  modalHeadline        main headline e.g. 'Haluatko poistaa tilan:'
 * @param {string}  modalSubHeadline     subheadline e.g. 'Mannerheimintie 1 a 2, 3.krs"
 * @param {string}  iconSx        icon class name e.g. classes.rightIcon
 * @param {func}    onCancelClick        modal cancel click function handler
 * @param {string}  cancelButtonText     modal cancel button text
 * @param {bool}    cancelOption         if false, cancel option will not be shown to user
 * @param {func}    onSubmitClick        modal submit click function handler
 * @param {string}  submitButtonText     modal submit button text
 * @param {bool}    submitting           is form submitting
 */
export const ModalPreview = ({
  modalTitle,
  modalDescription,
  openState,
  onClose,
  buttonSx,
  iconSx,
  onCancelClick,
  cancelButtonText,
  cancelOption = true,
  submitting,
  children,
}) => (
  <Dialog
    aria-labelledby={modalTitle}
    aria-describedby={modalDescription}
    open={openState}
    onClose={onClose}
    scroll="paper"
    maxWidth="md"
  >
    <DialogContent>
      {children}
      <br />
      <div style={{ width: '100%', textAlign: 'center' }}>
        {cancelOption && (
          <Button
            variant="contained"
            sx={buttonSx}
            disabled={submitting}
            color="primary"
            onClick={onCancelClick}
          >
            {cancelButtonText}
            <Icon sx={iconSx}>cancel</Icon>
          </Button>
        )}
      </div>
    </DialogContent>
  </Dialog>
);

const modalDialogFormInlineStyles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    color: '#000',
  },
  form: {
    textAlign: 'center',
  },
  tabRoot: {},
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(1) * 2,
  },
});

/** Modal form dialog
 * Used for e.g. asking user for required initial data when creating a new item
 * Example use case is to ask for a city and address before allowing to create a new space
 * @param {string}  modalTitle           for aria - modal title such as 'simple-modal-title'
 * @param {string}  modalDescription     for aria - modal description such as 'simple-modal-description'
 * @param {bool}    openState            component state variable, e.g. this.state.modalOpen
 * @param {func}    onClose              function for handling modal close event
 * @param {string}  modalHeadline        main headline e.g. 'Haluatko poistaa tilan:'
 * @param {string}  modalSubHeadline     subheadline e.g. 'Mannerheimintie 1 a 2, 3.krs"
 * @param {string}  iconSx        icon class name e.g. classes.rightIcon
 * @param {func}    onCancelClick        modal cancel click function handler
 * @param {string}  cancelButtonText     modal cancel button text
 * @param {bool}    cancelOption         if false, cancel option will not be shown to user
 * @param {func}    onSubmitClick        modal submit click function handler
 * @param {string}  submitButtonText     modal submit button text
 * @param {bool}    submitting           is form submitting
 */
let ModalFormDialog = ({
  modalTitle,
  modalDescription,
  openState,
  onClose,
  modalHeadline,
  modalSubHeadline,
  buttonSx,
  iconSx,
  onCancelClick,
  cancelButtonText,
  cancelOption = true,
  submitButtonText,
  submitting,
  pristine,
  handleSubmit,
  fields,
  initialValuesConditions,
  fieldWidth,
  onSubmitClick,
}) => {
  const handleSubmitCheck = initialData => {
    return onSubmitClick(initialData);
  };
  const theme = useTheme();
  const classes = modalDialogFormInlineStyles(theme);
  return (
    <Dialog
      aria-labelledby={modalTitle}
      aria-describedby={modalDescription}
      open={openState}
      onClose={onClose}
    >
      <DialogContent>
        <form onSubmit={handleSubmit(handleSubmitCheck)} style={classes.form}>
          <Typography variant="h6">{modalHeadline}</Typography>
          <Typography variant="subtitle1">{modalSubHeadline}</Typography>
          <br />

          <Grid
            container
            spacing={4}
            direction="row"
            alignContent="flex-start"
            alignItems="flex-start"
          >
            {fields.map(item => (
              <Grid
                item
                sm={12}
                md={fieldWidth || 6}
                key={R.path(['key'], item)}
              >
                <Field
                  key={R.path(['key'], item)}
                  name={R.path(['key'], item)}
                  component={
                    R.path(['component'], item)
                      ? returnField(R.path(['component'], item))
                      : returnField('text')
                  }
                  label={R.path(['label'], item)}
                  type={R.path(['type'], item)}
                  required={R.path(['required'], item)}
                  fullWidth
                  selOpts={
                    R.path(['selOpts'], item)
                      ? R.path(['selOpts'], item)
                      : undefined
                  }
                  normalize={
                    R.path(['normalize'], item)
                      ? value =>
                          (Array.isArray(value) && value.length === 0) ||
                          !Array.isArray(value)
                            ? [R.path(['normalize'], item)]
                            : value
                      : value => value
                  }
                  translateOptions={
                    R.path(['translateOptions'], item) === true ? true : false
                  }
                  multiple={R.path(['multiple'], item) === true ? true : false}
                  helperText={R.path(['helperText'], item)}
                />
              </Grid>
            ))}
          </Grid>
          {cancelOption && (
            <Button
              variant="contained"
              sx={buttonSx}
              disabled={submitting}
              color="secondary"
              onClick={onCancelClick}
            >
              {cancelButtonText}
              <Icon sx={iconSx}>cancel</Icon>
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={classes.button}
            disabled={
              submitting ||
              (pristine && initialValuesConditions && initialValuesConditions)
            }
          >
            {submitButtonText}
            <Icon sx={classes.rightIcon}>cloud_upload</Icon>
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => {
  const initialValues = R.path(['initValues'], ownProps);

  return {
    initialValues,
  };
};

const ModalFormDialogDecoratedComponent = reduxForm({
  form: 'addSpaceDialog',
  validate,
  enableReinitialize: true,
  updateUnregisteredFields: true,
  keepDirtyOnReinitialize: true,
})(ModalFormDialog);

ModalFormDialog = connect(
  mapStateToProps,
  null
)(ModalFormDialogDecoratedComponent);

export { ModalFormDialog };
