import React from 'react';

import { withTranslation } from 'react-i18next';

const NoAccess = ({ t }) => (
  <div>
    <h2>{t('noAccess.noAccess')}</h2>
  </div>
);
export default withTranslation('translations')(NoAccess);
