import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';

import { ModalDialog } from '../../utils/modalHelpers';

import FirestoreFile from './FirestoreFile';

import CardWrapper from '../common/CardWrapper';
import { useState } from 'react';

import {
  removeFileReference,
  uploadFile,
} from '../../store/actions/fileActions';

import { withTranslation } from 'react-i18next';

/**
 * Add file management with upload, delete and file listing with image or filetype displayed
 * @param {} files                        Files object that contains the file items
 * @param {} document                     Can be used if the target item is a child item - e.g. documentId would be a project id and document would be space id. Leave undefined if there is no need for this functionality.
 * @param {} removeFileReference          Action to be called on file delete which actually just removes the file reference from the target item
 * @param {} collection                   Which collection does the target item belong to, e.g. 'attachedSpaces' or 'projects'
 * @param {} documentId                   Target item id
 * @param {} restrictToImages             Defaults to false, if set to true will only allow images to be uploaded
 * @param {bool} allowMultipleFiles       Allow target item to have more than one files attached to it, default is true, if set to false will overwrite the one file attached on upload
 */

const inlineStyles = {
  button: {
    margin: 1,
  },
  rightIcon: {
    marginLeft: 1,
  },
  caption: { color: '#999', fontSize: '0.8em' },
};

let FileManagement = ({
  document,
  files,
  removeFileReference,
  uploadFile,
  collection,
  documentId,
  restrictToImages = false,
  allowMultipleFiles = true,
  disabled = false,
  shadow,
  presentation = false,
  hideName,
  noLeftPadding,
  showRole,
  classes,
  t,
}) => {
  const [fileRef, setFileRef] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);

  const [fileToBeDeleted, setFileToBeDeleted] = useState('');
  const documentRef = document || documentId;

  const uploadEvent = event => {
    const filesList = event.target.files;
    const documentRef = document || documentId;

    const mainPicture = false;

    const uploadWrapper = file =>
      uploadFile(
        file,
        documentRef,
        collection,
        allowMultipleFiles,
        mainPicture,
        t
      );

    R.map(uploadWrapper, filesList);
  };

  // ACCEPTING CURRENTLY
  // JPG, PNG
  // PDF, DOC, DOCX
  // DWG
  // XLS, XLSX
  // PPT, PPTX
  const acceptAll =
    'image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/acad, image/vnd.dwg, image/x-dwg, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation';
  const acceptImages = 'image/jpeg, image/png, image/vnd.dwg, image/x-dwg';

  return (
    <CardWrapper
      name={!hideName && t('fileManagement.fileManagement')}
      shadow={shadow}
      noLeftPadding={noLeftPadding}
    >
      <Grid
        style={{ textAlign: 'left' }}
        direction="row"
        spacing={3}
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        {!presentation && (
          <Grid item xs={12} md={6}>
            {/*
              Note that this file input field is not part of the redux-form ecosystem
              If it would be all file data would be sent to cloud firestore on form submit
              We want to upload when file is selected and not wait for form submission
              We therefore handle file submits independently through onChange event fire
            */}

            <div>
              <input
                accept={restrictToImages ? acceptImages : acceptAll}
                id={`${documentRef}.fileData`}
                multiple={allowMultipleFiles}
                type="file"
                style={{ display: 'none' }}
                onChange={event => uploadEvent(event)}
              />
              <label htmlFor={`${documentRef}.fileData`}>
                {!disabled && (
                  <Button
                    component="span"
                    variant="contained"
                    color="primary"
                    disabled={disabled}
                  >
                    {t('fileManagement.attachFiles')}
                  </Button>
                )}
              </label>
            </div>
          </Grid>
        )}
        {!presentation && !disabled && (
          <Grid item xs={12} md={6}>
            <Typography
              sx={inlineStyles.caption}
              variant="caption"
              id="filemanagement-subtitle"
            >
              <b>{t('fileManagement.acceptedFileFormats')}:</b>{' '}
              {restrictToImages
                ? t('fileManagement.onlyImages')
                : t('fileManagement.allFormats')}
            </Typography>
            <br />
            <Typography
              sx={inlineStyles.caption}
              variant="caption"
              id="filemanagement-subtitle"
            >
              <b>{t('fileManagement.maxAmountOfFiles')}:</b>{' '}
              {allowMultipleFiles
                ? t('fileManagement.noRestrictions')
                : t('fileManagement.limitedToOne')}
            </Typography>
          </Grid>
        )}
      </Grid>
      {files && (
        <List>
          {Object.entries(files).map((file, index) =>
            R.path(['fileRole'], R.nth(1, file)) === 'mainPicture' ? null : (
              <FirestoreFile
                key={`${R.head(file)}${index}`}
                document={document}
                file={file}
                path={
                  R.path(['resizedPath'], R.nth(1, file)) ||
                  R.path(['path'], R.nth(1, file))
                }
                thumbPath={R.path(['thumbPath'], R.nth(1, file))}
                onDelete={fileName => {
                  setFileRef(R.head(file));
                  setFileToBeDeleted(fileName);
                  setModalOpen(true);
                }}
                disabled={disabled}
                allowMultipleFiles={allowMultipleFiles}
                showRole={showRole}
              />
            )
          )}
        </List>
      )}
      <ModalDialog
        modalTitle="Delete-file"
        modalDescription="Delete-file"
        openState={modalOpen}
        modalHeadline={t('modals.confirmFileRemoval')}
        modalSubHeadline={fileToBeDeleted}
        buttonSx={inlineStyles.button}
        iconSx={inlineStyles.rightIcon}
        onCancelClick={() => setModalOpen(false)}
        cancelButtonText={t('common.cancel')}
        onSubmitClick={() => {
          removeFileReference(collection, documentRef, fileRef);
          setModalOpen(false);
        }}
        submitButtonText={t('common.remove')}
      />
    </CardWrapper>
  );
};

FileManagement.propTypes = {
  document: PropTypes.string,
  documentId: PropTypes.string.isRequired,
  files: PropTypes.object,
  removeFileReference: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  collection: PropTypes.string.isRequired,
  allowMultipleFiles: PropTypes.bool,
  restrictToImages: PropTypes.bool,
  disabled: PropTypes.bool,
};

FileManagement.defaultProps = {
  files: undefined,
  document: undefined,
  allowMultipleFiles: true,
  restrictToImages: false,
  disabled: false,
};

const mapDispatchToProps = dispatch => ({
  removeFileReference: (collection, documentId, fileId) =>
    dispatch(removeFileReference(collection, documentId, fileId)),
  uploadFile: (
    file,
    documentRef,
    collection,
    allowMultipleFiles,
    mainPicture,
    t
  ) =>
    dispatch(
      uploadFile(
        file,
        documentRef,
        collection,
        allowMultipleFiles,
        mainPicture,
        t
      )
    ),
});

FileManagement = connect(
  null,
  mapDispatchToProps
)(withTranslation('translations')(FileManagement));

export default FileManagement;
