import * as R from 'ramda';
import * as moment from 'moment';
import i18next from 'i18next';

import { arrToCsvBlob } from './csvHelper';
import {triggerDownload} from './downloadHelper';

export const exportTypes = {
    JSON: "json",
    CSV: "csv"
}

const exportSpaces = (spacesForExport, exportType, exportPrefix='export', translations=null) => {
    // CSV requires column headers & this selects the data to export.
    // If a header is not specified here -> it won't be exported. 

    let csvExportColumns = [];
    if (exportType === exportTypes.CSV) {
        // For the export make sure important columns appear first - these are mapped when building the export
        // These will be created using translations
        csvExportColumns = [
            'id','projectId','projectName',i18next.t('projectDetailView.propertyTypeLabel'),'clientVisibleToTenrepOnly','created','updated','imgMain',
            i18next.t('labels.area'),
            i18next.t('labels.address'),
            i18next.t('labels.city'),
            'lat','lng',
            'buildYear',
            i18next.t('presentation.distanceToSubway'),
            i18next.t('presentation.distanceToTram'),
            i18next.t('presentation.distanceToTrain'),
            i18next.t('presentation.distanceToBus')
        ];

        // Count the max number of attachments that are present in the full export & generate that number of export headers
        let maxSpaceImages = 0;
        let maxSpaceOtherAttachments = 0;
        R.keys(spacesForExport).map(((key) => {
            // Get all the attributes that will be used in column headers
            R.keys(spacesForExport[key].attr).map(((key) => {
                let tkey = (translations && translations[key]) ? translations[key] : key;
                if (!csvExportColumns.includes(`attr:${tkey}`)) {
                    csvExportColumns.push(`attr:${tkey}`);
                }
                return null;
            }));
            // Count the number of images & attachments the space has
            // If this is larger than any ones previously found, increase the max ammount the space can have
            const imgReducer = (acc, fkey) => {
                return (R.path([key, 'fileList', fkey, 'resizedPath'], spacesForExport)) ? acc + 1 : acc;
            };
            const spaceAdditionalImages = R.reduce(imgReducer,0,R.keys(spacesForExport[key].fileList));
            if (spaceAdditionalImages>maxSpaceImages) { maxSpaceImages = spaceAdditionalImages; }
            const attachmentReducer = (acc, fkey) => {
                // Anything that's not an image
                return (R.path([key, 'fileList', fkey, 'path'], spacesForExport)) ? acc + 1 : acc;
            };
            const spaceOtherAttachments = R.reduce(attachmentReducer,0,R.keys(spacesForExport[key].fileList));
            if (spaceOtherAttachments>maxSpaceOtherAttachments) { maxSpaceOtherAttachments = spaceOtherAttachments; }
            return null;
        }));

        // Add the max number of image headers
        for(let i=0;i<maxSpaceImages;i++) {
            csvExportColumns.push(`img:${i}`);
        };
        // Add the max number of files
        for(let i=0;i<maxSpaceOtherAttachments;i++) {
            csvExportColumns.push(`file:${i}`);
        };
    }; 

    // For each space, take the data for export 
    const spaceDataForExport = R.keys(spacesForExport).map(((key) => {

        // Get the created & updated date to human readable format
        let created = "";
        let updated = "";
        let timestampFormat = "YYYY.MM.DD hh:mm:ss";
        try {
            if (R.path([key, 'system', 'createdDate', 'seconds'], spacesForExport)) {
                let date = moment(R.path([key, 'system', 'createdDate', 'seconds'], spacesForExport,0)*1000);
                created = date.format(timestampFormat);
            }
        } catch (e) {
            console.log(`Could not parse createdDate for space id ${R.path([key, 'id'], spacesForExport) || key} : `,R.path([key, 'system', 'createdDate'], spacesForExport));
            console.log(e);
        }
       
        
        try {
            if (R.path([key, 'system', 'updatedDate', 'seconds'], spacesForExport)) {
                let date = moment((R.path([key, 'system', 'updatedDate', 'seconds'], spacesForExport,0)*1000));
                updated = date.format(timestampFormat);
            }
        } catch (e) {
            console.log(`Could not parse updatedDate for space id ${R.path([key, 'id'], spacesForExport) || key} : `,R.path([key, 'system', 'updatedDate'], spacesForExport));
            console.log(e);
        }

        // We need the first one image URL, so find & set that
        let imgMain = "";
        R.keys(R.path([key, 'mainImage'], spacesForExport)).map((ikey) => {
            try {
                imgMain = R.path([key, 'mainImage', ikey, 'resizedPath'], spacesForExport).replace(/&token=[a-zA-Z0-9-]+/g, '');  // Remove token
            } catch (err) {
                // Ignore
            }
            return null;
        });
        
        // Get all the other image URLs
        let imgs = {};
        let imgCounter = 0;
        let files = {};
        let fileCounter = 0;
        R.keys(R.path([key, 'fileList'], spacesForExport)).map((fkey) => {
            // Files will only have an mid/resized/thumbPath OR path - this differentiates between images & other files
            const imageUrl = R.path([key, 'fileList', fkey, 'resizedPath'], spacesForExport);
            const fileUrl = R.path([key, 'fileList', fkey, 'path'], spacesForExport);
            if (imageUrl) {
                if (exportType !== exportTypes.JSON) {
                    // Flatten data 
                    imgs[`img:${imgCounter}`] = imageUrl.replace(/&token=[a-zA-Z0-9-]+/g, '');
                } else {
                    if (!imgs['img']) {
                        imgs['img'] = {};
                    }
                    imgs['img'][`${imgCounter}`] = imageUrl.replace(/&token=[a-zA-Z0-9-]+/g, '');
                }
                imgCounter++;
            }
            if (fileUrl) {
                if (exportType !== exportTypes.JSON) {
                    // Flatten data 
                    files[`file:${fileCounter}`] = fileUrl.replace(/&token=[a-zA-Z0-9-]+/g, '');
                } else {
                    if (!files['file']) {
                        files['file'] = {};
                    }
                    files['file'][`${fileCounter}`] = fileUrl.replace(/&token=[a-zA-Z0-9-]+/g, '');
                }
                fileCounter++;
            }
            return null; // Remove token
        });

        // For space attributes, CSV exports required flattened data, whilst JSON can be nested, so the attr object is buiklt accordingly.
        // The data keys/headers will be translated.
        let attr = {};

        R.keys(R.path([key, 'attr'], spacesForExport)).map((akey) => {
            // Translate & then check
            let tkey = (translations && translations[akey]) ? translations[akey] : akey;
            if (exportType !== exportTypes.JSON) {
                // Flatten data 
                attr[`attr:${tkey}`] = R.path([key, 'attr', akey], spacesForExport);
            } else {
                if (!attr['attr']) {
                    attr['attr'] = {};
                }
                attr['attr'][tkey] = R.path([key, 'attr', akey], spacesForExport);
            }
            return null;
        });        

        // Map the export object
        const obj = {
            id: R.path([key, 'id'], spacesForExport) || key,
            projectId: R.path([key, 'project'], spacesForExport),
            projectName: R.path([key, 'projectName'], spacesForExport),
            [i18next.t('projectDetailView.propertyTypeLabel')]: R.path([key, 'propertyType'], spacesForExport),
            clientVisibleToTenrepOnly: R.path([key, 'projectClientVisibleToTenrepOnly'], spacesForExport),
            created: created,
            updated: updated,
            imgMain: imgMain,
            [i18next.t('labels.area')]: R.path([key, 'attr', 'area'], spacesForExport),
            [i18next.t('labels.address')]: R.path([key, 'attr', 'address'], spacesForExport),
            [i18next.t('labels.city')]: R.path([key, 'attr', 'city'], spacesForExport),
            [i18next.t('labels.buildYear')]: R.path([key, 'attr', 'buildYear'], spacesForExport),
            lat: R.path([key, 'location', 'lat'], spacesForExport),
            lng: R.path([key, 'location', 'lng'], spacesForExport),
            [i18next.t('presentation.distanceToBus')]: R.path([key, 'distanceToBus'], spacesForExport),
            [i18next.t('presentation.distanceToSubway')]: R.path([key, 'distanceToMetro'], spacesForExport),
            [i18next.t('presentation.distanceToTrain')]: R.path([key, 'distanceToTrain'], spacesForExport),
            [i18next.t('presentation.distanceToTram')]: R.path([key, 'distanceToTram'], spacesForExport),
            ...attr,
            ...imgs,
            ...files
        };
        return obj;
    }));
    if (exportType === "json") { 
        triggerDownload(spaceDataForExport,`${exportPrefix}-spaces-${moment().format('DD.MM.YYYY')}.json`, 'text/json;encoding:utf-8');
    } else {
        triggerDownload(arrToCsvBlob(spaceDataForExport, csvExportColumns), `${exportPrefix}-spaces-${moment().format('DD.MM.YYYY')}.csv`);
    }
};

export default exportSpaces;