import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import { withSnackbar } from 'notistack';
import { removeSnackbar } from '../../store/actions/notifierActions';

const Notifier = ({ enqueueSnackbar }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(state =>
    R.path(['notifier', 'notifications'], state)
  );

  const displayed = useRef([]);

  const storeDisplayed = id => {
    displayed.current = [...displayed.current, id];
  };

  useEffect(() => {
    notifications.forEach(({ key, message, options }) => {
      // Do nothing if snackbar is already displayed
      if (displayed.current.includes(key)) return;
      // Display snackbar using notistack
      enqueueSnackbar(message, options);
      // Keep track of snackbars that we've displayed
      storeDisplayed(key);
      // Dispatch action to remove snackbar from redux store
      dispatch(removeSnackbar(key));
    });
  }, [notifications, enqueueSnackbar, dispatch]);

  return null;
};

export default withSnackbar(Notifier);
