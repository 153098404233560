import * as firebaseApi from '../../api/firebaseApi';

export const UPDATE_PROJECT_NOTES_REQUEST = 'UPDATE_PROJECT_NOTES_REQUEST';
export const UPDATE_PROJECT_NOTES_RESPONSE = 'UPDATE_PROJECT_NOTES_RESPONSE';
export const UPDATE_PROJECT_NOTES = 'UPDATE_PROJECT_NOTES';


const updateProjectNotesRequest = {
  type: UPDATE_PROJECT_NOTES_REQUEST,
};

const updateProjectNotesResponse = projectNotes => ({
  type: UPDATE_PROJECT_NOTES_RESPONSE,
  projectNotes,
});

let projectNotesUnsubscribe;

export const unsubscribeFromProjectNotes = () => (dispatch) => {
  if (projectNotesUnsubscribe) projectNotesUnsubscribe();
};

export const subscribeToProjectNotes = isAdmin => (dispatch) => {
  if (isAdmin === true) {
    dispatch(updateProjectNotesRequest);
    projectNotesUnsubscribe = firebaseApi.subscribeToProjectNotes((projectNotes) => {
      dispatch(updateProjectNotesResponse(projectNotes));
    });
  } else {
    dispatch(updateProjectNotesResponse({}));
  }
};

export const updateProjectNotes = (values, pid, t) => (dispatch) => {
  firebaseApi
    .updateProjectNotes(pid, values)
    .then(() =>
      Promise.all([
        dispatch({
          type: UPDATE_PROJECT_NOTES,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]))
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${UPDATE_PROJECT_NOTES}_FAILURE`,
          error,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]));
};
