import * as R from 'ramda';

import {
  UPDATE_SPACES_RESPONSE,
  UPDATE_ATTACHED_SPACES_RESPONSE,
  CREATE_SPACE,
} from '../actions/spaceActions';

const initialState = {
  spacesInitialLoadComplete: false,
  spaces: {},
  attachedSpaces: {},
  spaceFilters: {
    city: '',
    address: '',
    spaceSize: '',
    renovation: '',
  },
};

export default function spaceReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_SPACE:
      return state;
    case UPDATE_SPACES_RESPONSE:
      return {
        ...state,
        spaces: R.path(['spaces'], action),
        spacesInitialLoadComplete: true,
      };
    case UPDATE_ATTACHED_SPACES_RESPONSE:
      return {
        ...state,
        attachedSpaces: R.path(['attachedSpaces'], action),
        attachedSpacesInitialLoadComplete: true,
      };
    default:
      return state;
  }
}
