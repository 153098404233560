import React from 'react';
import * as R from 'ramda';

import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon';

import { withTranslation } from 'react-i18next';

const FormAttrSelector = ({
  data, onSelect, i18n, projectAttr,
}) => {
  const getLocale = () => i18n.language;
  return (
    <ul style={{ textAlign: 'left' }}>
      {data.map((t) => {
        const selectedAttr = R.find(R.propEq('key', t.key))(projectAttr);
        return (
          <li style={{ listStyleType: 'none', marginBottom: '4px' }}>
            <Chip
              label={R.path(['label', getLocale()], t)}
              onClick={e => onSelect(e, t.key, t.fieldCategory)}
              onDelete={e => onSelect(e, t.key, t.fieldCategory)}
              deleteIcon={selectedAttr !== undefined ? '' : <Icon>add</Icon>}
              color={selectedAttr !== undefined ? 'primary' : 'secondary'}
            />
          </li>);
      })}
    </ul>
  );
};

export default withTranslation('translations')(FormAttrSelector);
