import * as R from 'ramda';

import {
  UPDATE_USERS_RESPONSE,
  UPDATE_USERS_RESPONSE_LANDLORD,
  USER_CHANGE_START,
  USER_CHANGE_END,
  USER_CREATE_START,
  USER_CREATE_END,
} from '../actions/userActions';

const initialState = {
  usersInitialLoadComplete: false,
  users: {},
  userChangeInProgress: false,
  userCreateInProgress: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USERS_RESPONSE:
      return {
        ...state,
        users: R.path(['users'], action),
        usersInitialLoadComplete: true,
        admin: true,
      };
    case UPDATE_USERS_RESPONSE_LANDLORD:
      return {
        ...state,
        users: R.path(['users'], action),
        usersInitialLoadComplete: true,
        admin: false,
      };
    case USER_CHANGE_START:
      return {
        ...state,
        userChangeInProgress: true,
        user: R.path(['user'], action),
      };
    case USER_CHANGE_END:
      return {
        ...state,
        userChangeInProgress: false,
      };
    case USER_CREATE_START:
      return {
        ...state,
        userCreateInProgress: true,
        user: R.path(['user'], action),
      };
    case USER_CREATE_END:
      return {
        ...state,
        userCreateInProgress: false,
      };
    default:
      return state;
  }
}
