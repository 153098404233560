import * as R from 'ramda';

import { CHANGE_JOYRIDE_STATE } from '../actions/joyrideActions';

// These are different views. Every view has a true state in the beginning so the tour will get triggered automatically when landing into this view.
// After the view specific tour has been seen, it will be set to false and can be retriggered only by the user from the main nav action menu which will set userTriggeredTourInProgress to true
const initialState = {
  projects: true,
  participateInProject: true,
  addNewSpace: true,
  spaceFeatures: true,
  userTriggeredTourInProgress: false,
};

export default function filterReducer(state = initialState, action) {
  const hasparticipateInProject = R.has('participateInProject');
  const hasprojects = R.has('projects');
  const hasaddNewSpace = R.has('addNewSpace');
  const hasspaceFeatures = R.has('spaceFeatures');

  switch (action.type) {
    case CHANGE_JOYRIDE_STATE:
      return {
        ...state,
        projects: hasprojects(action) ? R.path(['projects'], action) : R.path(['projects'], state),
        participateInProject: hasparticipateInProject(action) ? R.path(['participateInProject'], action) : R.path(['participateInProject'], state),
        addNewSpace: hasaddNewSpace(action) ? R.path(['addNewSpace'], action) : R.path(['addNewSpace'], state),
        spaceFeatures: hasspaceFeatures(action) ? R.path(['spaceFeatures'], action) : R.path(['spaceFeatures'], state),
        userTriggeredTourInProgress: R.path(['userTriggeredTourInProgress'], action),
      };
    default:
      return state;
  }
}
