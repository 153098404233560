import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import * as moment from 'moment';

import { saveFilters, clearFilters } from '../store/actions/filterActions';

import CardWrapper from '../components/common/CardWrapper';
import SortableExpandingTable from '../components/Projects/SortableExpandingTable';

import { withTranslation } from 'react-i18next';

import Joyride from '../components/joyride/Joyride';

let Projects = ({
  projects,
  projectFilters,
  saveFilters,
  clearFilters,
  attachedSpaces,
  t,
  userId,
  isAdmin,
  allRequiredUserDataIsFilledIn,
}) => {
  const [projectCount, setProjectCount] = useState(0);

  const projectsArray = Object.entries(projects).map(item => {
    const id = R.head(item);
    const fields = R.nth(1, item);
    return { id, ...fields };
  });

  const refinedProjects = Array.from(projectsArray).map(project => {
    const keys = !isAdmin
      ? [
          'status',
          'client',
          'propertyType',
          'size',
          'area',
          'contactPersonName',
          'updated',
          'deadline',
        ]
      : [
          'status',
          'client',
          'responsible',
          'size',
          'propertyType',
          'area',
          'updated',
          'deadline',
        ];
    const deadlineDateString = moment(
      R.path(['projectInfo', 'deadline'], project),
      'YYYY-MM-DD'
    ).format('DD/MM/YYYY');
    const deadlineDateStringUnix = moment(
      R.path(['projectInfo', 'deadline'], project),
      'YYYY-MM-DD'
    ).format('X');

    const updated = moment
      .unix(R.path(['system', 'updatedDate', 'seconds'], project))
      .format('DD/MM/YYYY');
    const updatedUnix = moment.unix(
      R.path(['system', 'updatedDate', 'seconds'], project)
    );

    return {
      ...project.projectInfo,
      ...project.attr,
      id: project.id,
      objectKeys: keys,
      deadline: deadlineDateString,
      deadlineUnix: deadlineDateStringUnix,
      client: !isAdmin
        ? project.projectInfo.client
        : project.clientVisibleToTenrepOnly,
      updated,
      updatedUnix,
      users: project.users,
    };
  });

  const labels = !isAdmin
    ? [
        { key: 'notification', finnish: t('labels.notification') },
        { key: 'edit', finnish: t('labels.edit') },
        { key: 'status', finnish: 'Status' },
        { key: 'client', finnish: t('labels.client') },
        { key: 'propertyType', finnish: t('labels.type') },
        { key: 'size', finnish: t('labels.spaceSize') },
        { key: 'area', finnish: t('labels.location') },
        { key: 'contactPersonName', finnish: t('labels.contactPersons') },
        { key: 'updated', finnish: t('labels.updated') },
        { key: 'deadline', finnish: t('labels.deadline') },
      ]
    : [
        { key: 'edit', finnish: t('labels.edit') },
        { key: 'status', finnish: 'Status' },
        { key: 'client', finnish: t('labels.client') },
        { key: 'responsible', finnish: t('labels.responsible') },
        { key: 'size', finnish: t('labels.spaceSize') },
        { key: 'propertyType', finnish: t('labels.type') },
        { key: 'area', finnish: t('labels.location') },
        { key: 'updated', finnish: t('labels.updated') },
        { key: 'deadline', finnish: t('labels.deadline') },
      ];

  const projectFilterLabels = isAdmin
    ? {
        responsible: t('labels.responsible'),
        area: t('labels.location'),
        propertyType: t('labels.type'),
        status: t('labels.showArchived'),
      }
    : {
        status: t('labels.showArchived'),
      };

  const projectFilterTypes = isAdmin
    ? {
        responsible: 'select',
        area: 'select',
        propertyType: 'select',
        status: 'checkbox',
      }
    : {
        status: 'checkbox',
      };

  return (
    <CardWrapper
      name={t('projectDetail.projectsHeadline')}
      nameAddition={`${projectCount} ${t('labels.pcs')}`}
      caption={t('projects.viewSummary')}
      transparent
      headline
    >
      {!isAdmin && allRequiredUserDataIsFilledIn && (
        <Joyride
          view="projects"
          steps={[
            {
              target: 'body',
              title: t('joyride.projects.step0.headline'),
              content: t('joyride.projects.step0.content'),
              placement: 'center',
            },
            {
              target: 'header',
              title: t('joyride.projects.step1.headline'),
              content: t('joyride.projects.step1.content'),
            },
            {
              target: '.tableBody tr:first-child .edit',
              title: t('joyride.projects.step2.headline'),
              content: t('joyride.projects.step2.content'),
            },
          ]}
        />
      )}
      <SortableExpandingTable
        data={refinedProjects}
        labels={labels}
        filtersId="projects"
        filters={projectFilters}
        filterLabels={projectFilterLabels}
        filterTypes={projectFilterTypes}
        saveFilters={saveFilters}
        clearFilters={clearFilters}
        subItems={attachedSpaces}
        calculateItems={setProjectCount}
        subItemAttributes={[
          'address',
          'city',
          'area',
          'status',
          'candidate',
          'rejection',
        ]}
        uid={userId}
        isAdmin={isAdmin}
      />
    </CardWrapper>
  );
};
const mapStateToProps = (state, ownProps) => {
  // Hide draft projects from Tenrep in this view
  const filteredProjects = R.filter(
    (project) => ownProps.isAdmin || project.projectInfo.status !== "Draft",
    state.project.projects
  );

  // Hide draft attached spaces from Tenrep in this view
  const filteredAttachedSpaces = R.path(['isAdmin'], ownProps)
    ? R.filter(
        n => n.status !== 'Draft',
        R.path(['space', 'attachedSpaces'], state)
      )
    : R.path(['space', 'attachedSpaces'], state);
  return {
    projects: filteredProjects,
    projectFilters: R.path(['filter', 'filters', 'projects'], state),
    attachedSpaces: filteredAttachedSpaces,
  };
};
const mapDispatchToProps = dispatch => ({
  saveFilters: filters => dispatch(saveFilters(filters, 'projects')),
  clearFilters: () => dispatch(clearFilters()),
});

Projects = connect(mapStateToProps, mapDispatchToProps)(Projects);

Projects.propTypes = {
  isAdmin: PropTypes.bool,
};

Projects.defaultProps = {
  isAdmin: false,
};

export default withTranslation('translations')(Projects);
