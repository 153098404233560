import React from 'react';

import Icon from '@mui/material/Icon';
import Avatar from '@mui/material/Avatar';
import red from '@mui/material/colors/red';

const inlineStyles = {
  redStatus: {
    color: '#fff',
    backgroundColor: red[500],
  },
};

/**
 * Display notification icon inside an avatar wrapper
 * @param {obj} classes    JSS classes object
 */
const NotificationAvatar = () => (
  <Avatar sx={inlineStyles.redStatus}>
    <Icon style={{ fontSize: 20 }}>feedback</Icon>
  </Avatar>
);

export default NotificationAvatar;
