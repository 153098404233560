import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import ReactJoyride from 'react-joyride';
import PropTypes from 'prop-types';

import { changeJoyrideState } from '../../store/actions/joyrideActions';

import { withTranslation } from 'react-i18next';

const Joyride = ({
  t, changeJoyrideState, showTourToUser, steps,
}) => (
  <ReactJoyride
    callback={changeJoyrideState}
    disableCloseOnEsc
    disableOverlayClose
    continuous
    run={showTourToUser}
    locale={{
      back: t('joyride.navigation.back'),
      close: t('joyride.navigation.close'),
      last: t('joyride.navigation.last'),
      next: t('joyride.navigation.next'),
      skip: t('joyride.navigation.skip'),
    }}
    scrollToFirstStep
    showProgress
    showSkipButton
    steps={steps}
    styles={{
      options: {
        primaryColor: '#2D3E80',
        width: 600,
      },
      tooltip: {
        borderRadius: 0,
      },
      buttonNext: {
        fontFamily: 'SourceSerifPro',
        fontSize: '0.875rem',
        fontWeight: '500',
        minWidth: '64px',
        padding: '8px 16px',
        textTransform: 'uppercase',
      },
      buttonBack: {
        fontFamily: 'SourceSerifPro',
        fontSize: '0.875rem',
        fontWeight: '500',
        minWidth: '64px',
        padding: '8px 16px',
        textTransform: 'uppercase',
      },
      buttonClose: {
        display: 'none',
      },
      tooltipContent: {
        whiteSpace: 'pre-wrap',
        textAlign: 'left',
      },
    }}
  />
);

const mapStateToProps = (state, ownProps) => ({
  showTourToUser: R.path(['joyride', 'userTriggeredTourInProgress'], state) || R.path(['joyride', R.path(['view'], ownProps)], state),
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  changeJoyrideState: data => dispatch(changeJoyrideState(data, R.path(['view'], ownProps))),
});

Joyride.propTypes = {
  t: PropTypes.func.isRequired,
  changeJoyrideState: PropTypes.func.isRequired,
  showTourToUser: PropTypes.bool.isRequired,
  steps: PropTypes.array.isRequired,
  view: PropTypes.string.isRequired,
};

export default withTranslation('translations')(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Joyride));

