import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import rootReducer from './reducers/rootReducer';

function loadDevTools() {
  /* eslint-disable */
  return process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f;
  /* eslint-enable */
}

// persist state
const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['pagination', 'errors', 'form', 'project', 'space', 'attr', 'user', 'client', 'notifier', 'file', 'projectNotes', 'filter'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  compose(applyMiddleware(thunk), loadDevTools()),
);

export const persistor = persistStore(store);
