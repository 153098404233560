import { reset } from 'redux-form';
import * as R from 'ramda';
import * as firebaseApi from '../../api/firebaseApi';

export const GET_RFI_ATTR = 'GET_RFI_ATTR';
export const CREATE_ATTR = 'CREATE_ATTR';

const getRfiAttr = attr => ({
  type: GET_RFI_ATTR,
  attr,
});

let attrUnSubscribe;

export const subscribeToRfiAttr = () => (dispatch) => {
  attrUnSubscribe = firebaseApi.subscribeToRfiAttributes((attr) => {
    dispatch(getRfiAttr(attr));
  });
};

export const unsubscribeFromRfiAttr = () => () => {
  if (attrUnSubscribe) attrUnSubscribe();
};

export const createAttr = (obj, t) => (dispatch) => {
  const camelize = str => str.replace(/\W+(.)/g, (match, chr) => chr.toUpperCase());

  const attrObject = { ...obj, key: camelize(R.path(['label', 'en'], obj)) };

  firebaseApi
    .createAttr(attrObject)
    .then(() =>

      Promise.all([
        dispatch(reset('createAttr')),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]))
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${CREATE_ATTR}_FAILURE`,
          error,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]));
};
