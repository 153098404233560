import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
  height: '100%'
};

function GMap({ markers, defaultCenter }) {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyArA87_GnYSpWbiBXafbI1rw06DRqShJ60"
  })

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={10}
      center={defaultCenter}
    >
      {markers && markers.map(location => (
        location === defaultCenter ?
          <Marker
            position={location}
            key={`${R.path(['lng'], location)}_${R.path(['lat'], location)}`}
            icon={{
              url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
            }}
          /> :
          <Marker
            position={location}
            key={`${R.path(['lng'], location)}_${R.path(['lat'], location)}`}
            icon={{
              url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
            }}
          />
      ))}

    </GoogleMap>
  ) : <></>;
}

GMap.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.shape({
    lng: PropTypes.number.isRequired,
    lat: PropTypes.number.isRequired,
  })).isRequired,
  defaultCenter: PropTypes.shape({
    lng: PropTypes.number.isRequired,
    lat: PropTypes.number.isRequired,
  }).isRequired,
};


export default GMap;

