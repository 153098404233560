import * as R from 'ramda';
import { unregisterField } from 'redux-form';
import * as firebaseApi from '../../api/firebaseApi';

export const UPDATE_SPACES_REQUEST = 'UPDATE_SPACES_REQUEST';
export const UPDATE_ATTACHED_SPACES_REQUEST = 'UPDATE_ATTACHED_SPACES_REQUEST';
export const UPDATE_SPACES_RESPONSE = 'UPDATE_SPACES_RESPONSE';
export const UPDATE_ATTACHED_SPACES_RESPONSE = 'UPDATE_ATTACHED_SPACES_RESPONSE';
export const CREATE_SPACE = 'CREATE_SPACE';
export const CREATE_SPACE_AND_ATTACH_TO_PROJECT = 'CREATE_SPACE_AND_ATTACH_TO_PROJECT';
export const DELETE_SPACE = 'DELETE_SPACE';
export const DELETE_SPACE_TEMPLATE = 'DELETE_SPACE_TEMPLATE';
export const SAVE_SPACE_AS_TEMPLATE = 'SAVE_SPACE_AS_TEMPLATE';
export const SAVE_SPACE_AS_TEMPLATE_REQUEST = 'SAVE_SPACE_AS_TEMPLATE_REQUEST';
export const SAVE_SPACE_AS_TEMPLATE_FAILURE = 'SAVE_SPACE_AS_TEMPLATE_FAILURE';
export const DELETE_SPACE_TEMPLATE_REQUEST = 'DELETE_SPACE_TEMPLATE_REQUEST';
export const UPDATE_SPACE_TEMPLATE_REQUEST = 'UPDATE_SPACE_TEMPLATE_REQUEST';
export const UPDATE_SPACE_TEMPLATE = 'UPDATE_SPACE_TEMPLATE';
export const CREATE_SPACE_TEMPLATE = 'CREATE_SPACE_TEMPLATE';
export const UPDATE_ATTACHED_SPACE_REQUEST = 'UPDATE_ATTACHED_SPACE_REQUEST';
export const UPDATE_ATTACHED_SPACE = 'UPDATE_ATTACHED_SPACE';
export const UPDATE_ATTACHED_SPACES_CANDIDATE_AND_REJECTION = 'UPDATE_ATTACHED_SPACES_CANDIDATE';
export const UPDATE_ATTACHED_SPACES_ON_PRESENTATION = 'UPDATE_ATTACHED_SPACES_ON_PRESENTATION';
export const DEACTIVATE_SPACE = 'DEACTIVATE_SPACE';

const updateSpacesRequest = {
  type: UPDATE_SPACES_REQUEST,
};

const saveSpaceAsTemplateRequest = {
  type: SAVE_SPACE_AS_TEMPLATE_REQUEST,
};

const deleteSpaceTemplateRequest = {
  type: DELETE_SPACE_TEMPLATE_REQUEST,
};

const updateSpaceTemplateRequest = {
  type: UPDATE_SPACE_TEMPLATE_REQUEST,
};

const updateAttachedSpaceRequest = {
  type: UPDATE_ATTACHED_SPACE_REQUEST,
};

const updateAttachedSpacesRequest = {
  type: UPDATE_ATTACHED_SPACES_REQUEST,
};

const updateAttachedSpacesResponse = attachedSpaces => ({
  type: UPDATE_ATTACHED_SPACES_RESPONSE,
  attachedSpaces,
});

const updateSpacesResponse = spaces => ({
  type: UPDATE_SPACES_RESPONSE,
  spaces,
});

let spacesUnsubscribe;
let attachedSpacesUnsubscribe;


export const subscribeToSpaces = isAdmin => (dispatch) => {
  if (isAdmin === true) {
    dispatch(updateSpacesRequest);
    spacesUnsubscribe = firebaseApi.subscribeToSpaces((spaces) => {
      dispatch(updateSpacesResponse(spaces));
    });
  } else {
    dispatch(updateSpacesRequest);
    spacesUnsubscribe = firebaseApi.subscribeToUserSpaces((spaces) => {
      dispatch(updateSpacesResponse(spaces));
    }, isAdmin);
  }
};


export const unsubscribeFromSpaces = () => (dispatch) => {
  if (spacesUnsubscribe) spacesUnsubscribe();
};

export const createSpaceAndAttachToProject = (template, sourceSpaceId, projectId, openProject, initialData, navigate, t) => (dispatch) => {
  const projectRFIAttributes = R.path(['attr'], openProject);

  // Push attributes to an array (while stripping object keys such as "basicInfo")
  const attrArray = [];
  const getValues = (num, keyAttr, obj) => num.map(item => attrArray.push(item));
  R.mapObjIndexed(getValues, projectRFIAttributes);

  // Get all keys of attributes such as "propertyOwner" as an array
  const projectRFIAttributesKeys = attrArray.map(item => item.key);
  // If a template is specified pick all matching keys from the attribute key array to construct the basis for the new space
  const newAttributes = template ? R.pick(projectRFIAttributesKeys, R.path(['attr'], template)) : { ...initialData };

  firebaseApi.createSpaceAndAttachToProject(newAttributes, projectId, template, sourceSpaceId)
    .then((results) => {
      navigate && navigate(`/project/${projectId}`);

      const city = R.path(['city'], newAttributes);
      const address = R.path(['address'], newAttributes);
      // Update space location using google maps api if address or city has changed
      if (city && address) {
        dispatch(updateAttachedSpaceLocation(R.path(['id'], results), address, city));
      }

      Promise.all([
        dispatch({
          type: CREATE_SPACE_AND_ATTACH_TO_PROJECT,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${CREATE_SPACE_AND_ATTACH_TO_PROJECT}_FAILURE`,
          error,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]));
};

export const deleteAttachedSpace = (spaceId, t) => (dispatch) => {
  dispatch(unregisterField('project', spaceId));
  firebaseApi.removeSpaceFromProject(spaceId)
    .then(() => {
      Promise.all([
        dispatch({
          type: DELETE_SPACE,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${DELETE_SPACE}_FAILURE`,
          error,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]));
};

export const createSpaceTemplate = t => (dispatch) => {
  firebaseApi.createSpaceTemplate()
    .then(() => {
      Promise.all([
        dispatch({
          type: CREATE_SPACE_TEMPLATE,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${CREATE_SPACE_TEMPLATE}_FAILURE`,
          error,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]));
};

export const saveSpaceAsTemplate = (spaceId, navigate, t) => (dispatch, getState) => {
  dispatch(saveSpaceAsTemplateRequest);
  const space = R.path(['space', 'attachedSpaces', [spaceId]], getState());
  const allAttributes = R.path(['attr', 'attr'], getState());
  const spaceAttr = R.path(['attr'], space);
  const spaceFiles = R.path(['fileList'], space);
  const spaceMainPicture = R.path(['mainImage'], space);
  const spaceUsers = R.path(['users'], space);

  const spaceTemplate = {
    attr: { ...spaceAttr },
    users: { ...spaceUsers },
    fileList: { ...spaceFiles },
    mainImage: { ...spaceMainPicture },
  };

  // Take all custom attributes
  const allCustomAttributes = R.filter(item => item.custom, allAttributes);

  // Push only the keys of these custom attributes into an array
  const customKeys = [];
  const getKeys = (num) => { customKeys.push(num.key); return num.key; };
  R.mapObjIndexed(getKeys, allCustomAttributes);

  // Drop all custom attributes from the template arrays as we do not want to save them to the templates
  const attributesWithoutCustomOnes = {};
  const getAttributes = (num, key) => { if (!R.contains(key, customKeys)) { attributesWithoutCustomOnes[key] = num; } };
  R.mapObjIndexed(getAttributes, spaceTemplate.attr);
  spaceTemplate.attr = attributesWithoutCustomOnes;

  firebaseApi
    .createSpaceTemplateWithData(spaceId, spaceTemplate)
    .then((results) => {
      if (navigate) { navigate(`/templates/${R.path(['id'], results)}`); }
      Promise.all([
        dispatch({
          type: SAVE_SPACE_AS_TEMPLATE,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${SAVE_SPACE_AS_TEMPLATE}_FAILURE`,
          error,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]));
};

export const deleteSpaceTemplate = (spaceId, t) => (dispatch) => {
  dispatch(deleteSpaceTemplateRequest);
  firebaseApi.removeSpaceTemplate(spaceId)
    .then(() => {
      Promise.all([
        dispatch({
          type: DELETE_SPACE_TEMPLATE,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${DELETE_SPACE_TEMPLATE}_FAILURE`,
          error,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]));
};

export const updateSpaceTemplate = (sid, spaceContent, t) => (dispatch) => {
  dispatch(updateSpaceTemplateRequest);
  firebaseApi.updateSpaceTemplate(sid, spaceContent)
    .then(() => {
      Promise.all([
        dispatch({
          type: UPDATE_SPACE_TEMPLATE,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${UPDATE_SPACE_TEMPLATE}_FAILURE`,
          error,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]));
};

export const updateAttachedSpace = (sid, spaceContent, t) => (dispatch, getState) => {
  const space = R.path(['space', 'attachedSpaces', [sid]], getState());
  const address = R.path(['attr', 'address'], space);
  const city = R.path(['attr', 'city'], space);

  dispatch(updateAttachedSpaceRequest);
  firebaseApi.updateAttachedSpace(sid, spaceContent)
    .then(() => {
      // Update space location using google maps api if address or city has changed OR location has not been saved to the space
      const objHasOwnProperty = R.has(R.__, spaceContent);

      // The same action is used for dropping spaces from the project, and in that case *R.path(['attr', 'address'], spaceContent)* is undefined
      if (R.path(['attr', 'address'], spaceContent) && (address !== R.path(['attr', 'address'], spaceContent) || city !== R.path(['attr', 'city'], spaceContent) || !objHasOwnProperty('location'))) {
        dispatch(updateAttachedSpaceLocation(sid, R.path(['attr', 'address'], spaceContent), R.path(['attr', 'city'], spaceContent)));
      }

      Promise.all([
        dispatch({
          type: UPDATE_ATTACHED_SPACE,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch((error) => {
      Promise.all([
        dispatch({
          type: `${UPDATE_ATTACHED_SPACE}_FAILURE`,
          error,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]);
    });
};

export const subscribeToAttachedSpaces = isAdmin => (dispatch) => {
  if (isAdmin === true) {
    dispatch(updateAttachedSpacesRequest);
    attachedSpacesUnsubscribe = firebaseApi.subscribeToAttachedSpaces((attachedSpaces) => {
      dispatch(updateAttachedSpacesResponse(attachedSpaces));
    });
  } else {
    dispatch(updateAttachedSpacesRequest);
    attachedSpacesUnsubscribe = firebaseApi.subscribeToUserAttachedSpaces((attachedSpaces) => {
      dispatch(updateAttachedSpacesResponse(attachedSpaces));
    }, isAdmin);
  }
};

export const unsubscribeFromAttachedSpaces = () => (dispatch) => {
  if (attachedSpacesUnsubscribe) attachedSpacesUnsubscribe();
};

export const updateAttachedSpaceOnPresentation = (sid, updateObject) => (dispatch) => {
  firebaseApi.updateAttachedSpace(sid, updateObject);
};

export const updateAttachedSpaceLocation = (sid, address, city) => (dispatch) => {
  const parsedAddress = address.replace(' ', '+');

  const params = {
    id: sid,
    address,
    city,
  };

  return fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${parsedAddress}+${city},+CA&key=AIzaSyArA87_GnYSpWbiBXafbI1rw06DRqShJ60`)
    .then(response => response.json())
    .then(data => firebaseApi.updateAttachedSpaceLocation(data, params))
    .then(() => firebaseApi.getDistanceToPlace(params, 'train_station', 'distanceToTrain'))
    .then(() => firebaseApi.getDistanceToPlace(params, 'bus_station', 'distanceToBus'))
    .then(() => firebaseApi.getDistanceToPlace(params, 'light_rail_station', 'distanceToTram'))
    .then(() => firebaseApi.getDistanceToPlace(params, 'subway_station', 'distanceToMetro'));
};

export const deActivateSpace = (spaceId, t) => (dispatch) => {
  firebaseApi.deactivateAttachedSpace(spaceId)
    .then(() => {
      Promise.all([
        dispatch({
          type: DEACTIVATE_SPACE,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${DEACTIVATE_SPACE}_FAILURE`,
          error,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]));
};
