import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as moment from 'moment';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import NotificationChip from '../common/NotificationChip';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import ActionMenu from '../common/ActionMenu';

import SortableTableFilters from './SortableTableFilters';

import filterResults from '../../utils/filterResults';
import { ascComparator, descComparator } from '../../utils/comparators';

import { withTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

const inlineStyles = theme => ({
  drawerPaper: {
    position: 'absolute',
    border: '0px',
    width: '100%',
    display: 'block',
    top: 'auto',
  },
  toolbar: {
    paddingLeft: '20px',
  },
  docked: {
    flexBasis: '66.6%',
    position: 'relative',
  },
  sortLabel: {
    color: '#000',
  },
  table: {
    overflowX: 'auto',
  },
  tableLabel: {
    cursor: 'pointer',
  },
  tableRow: {
    '& tr:nth-of-type(even)': { backgroundColor: '#e1e6ea' },
  },
});

const SortableDrawerMenu = ({
  sortColumn,
  sid,
  filters,
  data,
  handleRowClick,
  saveFilters,
  clearFilters,
  filterLabels,
  filterTypes,
  checkboxOptions,
  reverseCheckboxOperation,
  t,
  calculateItems,
  projects,
  saveSpaceAsTemplate,
  openProject,
  openDeactivateSpaceModal,
  refinedSpaces,
  menu,
}) => {
  const [searchTerms, setSearchTerms] = useState(filters || {});
  const [orderByStr, setOrderByStr] = useState(sortColumn);
  const [sortOrder, setSortOrder] = useState('asc');
  const [rowCount, setRowCount] = useState(0);
  const sortBy =
    sortOrder === 'asc'
      ? ascComparator(orderByStr)
      : descComparator(orderByStr);

  useEffect(() => {
    if (!sid) {
      selectFirstRow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid]); // Re-run effect if `sid` changes

  useEffect(() => {
    setSearchTerms(filters);
  }, [filters]); // Re-run effect if `filters` prop changes

  useEffect(() => {
    return () => {
      clearFilters(); // Equivalent to componentWillUnmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only run once, equivalent to componentWillUnmount

  const selectFirstRow = () => {
    const filteredRows = searchForMatchingItems();
    const rows = R.sort(sortBy, filteredRows);
    if (R.prop('length', rows) > 0) {
      handleRowClick(R.path(['id'], R.head(rows)));
    }
  };

  const saveSearchTerms = newTerms => {
    const mergedTerms = {
      ...searchTerms,
      ...newTerms,
    };
    setSearchTerms(mergedTerms);
    saveFilters(mergedTerms);
  };

  const searchForMatchingItems = () => {
    return filterResults(data, searchTerms);
  };

  const handleChange = event => {
    saveSearchTerms({ [event.target.name]: event.target.value });
  };

  const handleChangeCheckbox = name => event => {
    if (reverseCheckboxOperation) {
      saveSearchTerms({ [name]: event.target.checked ? checkboxOptions : '' });
    } else {
      saveSearchTerms({ [name]: event.target.checked ? '' : checkboxOptions });
    }
  };

  const changeSortOrder = () => {
    setSortOrder(currentSortOrder =>
      currentSortOrder === 'asc' ? 'desc' : 'asc'
    );
  };

  const changeSortBy = sortByThis => {
    changeSortOrder();
    setOrderByStr(sortByThis);
  };

  // Filter row data with current searchTerms
  const filteredRows = searchForMatchingItems();

  // Sort row data
  const rows = R.sort(
    sortOrder === 'asc'
      ? ascComparator(orderByStr)
      : descComparator(orderByStr),
    filteredRows
  );

  // Get tablehead labels
  const theads = R.path(['objectKeys'], R.head(rows));
  // Send row count to parent element for display
  useEffect(() => {
    const currentRowCount = R.prop('length', rows);
    if (rowCount !== currentRowCount) {
      setRowCount(currentRowCount);
      calculateItems?.(currentRowCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, rowCount]);
  const theme = useTheme();
  const classes = inlineStyles(theme);
  return R.prop('length', rows) === 0 ? (
    <Drawer
      variant="permanent"
      sx={classes.docked}
      PaperProps={{ sx: classes.drawerPaper }}
      anchor="left"
    >
      <div style={classes.toolbar}>
        <SortableTableFilters
          data={data}
          handleChange={handleChange}
          handleChangeCheckbox={handleChangeCheckbox}
          searchTerms={searchTerms}
          filterLabels={filterLabels}
          filterTypes={filterTypes}
        />
      </div>
      <Divider />
      <Typography style={{ marginTop: '20px' }} variant="h6">
        {t('spaces.noAttachedSpaces')}
      </Typography>
    </Drawer>
  ) : (
    <Drawer
      variant="permanent"
      sx={classes.docked}
      PaperProps={{ sx: classes.drawerPaper }}
      anchor="left"
    >
      <div style={classes.toolbar}>
        <SortableTableFilters
          data={data}
          handleChange={handleChange}
          handleChangeCheckbox={handleChangeCheckbox}
          searchTerms={searchTerms}
          filterLabels={filterLabels}
          filterTypes={filterTypes}
          reverseCheckboxOperation={reverseCheckboxOperation}
        />
      </div>
      <Divider />
      <Table sx={classes.table} size="small">
        <TableHead>
          <TableRow>
            {theads &&
              theads.map(item => (
                <TableCell
                  key={item}
                  sx={classes.tableLabel}
                  onClick={() => changeSortBy(item)}
                >
                  <TableSortLabel
                    active={orderByStr === item}
                    direction={sortOrder}
                    sx={classes.sortLabel}
                  >
                    {t(`labels.${item}`)}
                  </TableSortLabel>
                </TableCell>
              ))}
            {menu && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody sx={classes.tableRow}>
          {rows &&
            rows.map(row => {
              const oKeys = row.objectKeys;
              return (
                <TableRow
                  key={R.path(['id'], row)}
                  selected={R.path(['id'], row) === sid}
                  hover
                  style={{
                    backgroundColor: R.path(['id'], row) === sid && '#2D3E80',
                  }}
                >
                  {Array.from(oKeys).map((objectKey, index) => (
                    <TableCell
                      onClick={() =>
                        objectKey === 'projectName'
                          ? null
                          : handleRowClick(R.path(['id'], row))
                      }
                      key={`${objectKey}${index}`}
                      style={{ color: R.path(['id'], row) === sid && '#fff' }}
                    >
                      {Array.isArray(R.path([objectKey], row)) ? (
                        R.path([objectKey], row).join(', ')
                      ) : objectKey !== 'status' ? (
                        objectKey === 'updated' ? (
                          moment()
                            .subtract(14, 'days')
                            .isBefore(
                              moment(R.path([objectKey], row), 'DD/MM/YYYY')
                            ) ? (
                            moment(
                              R.path(['updatedUnix'], row),
                              'DD/MM/YYYY'
                            ).fromNow()
                          ) : (
                            moment(
                              R.path([objectKey], row),
                              'DD/MM/YYYY'
                            ).format('DD/MM/YYYY')
                          )
                        ) : objectKey === 'projectName' ? (
                          <Link
                            to={`/project/${R.path(['project'], row)}`}
                            style={{
                              color:
                                R.path(['id'], row) === sid ? '#fff' : '#555',
                              borderBottom:
                                R.path(['id'], row) === sid
                                  ? '1px solid #fff'
                                  : '1px solid #555',
                            }}
                          >
                            {R.path([objectKey], row)}
                          </Link>
                        ) : (
                          R.pathOr(
                            t('common.notDefined'),
                            ['attr', objectKey],
                            row
                          )
                        )
                      ) : (
                        <NotificationChip
                          statusEqual={
                            R.path(['status'], row) ===
                            R.path(
                              [
                                R.path(['project'], row),
                                'projectInfo',
                                'status',
                              ],
                              projects
                            )
                          }
                          notCandidate={!R.path(['candidate'], row)}
                          notActive={!R.path(['active'], row)}
                          label={
                            R.path(['active'], row)
                              ? t(`options.${R.path([objectKey], row)}`)
                              : t('options.inactive')
                          }
                        />
                      )}
                    </TableCell>
                  ))}

                  {menu && (
                    <TableCell padding="none">
                      <ActionMenu
                        rowId={row.id}
                        menuItems={[
                          {
                            action: saveSpaceAsTemplate,
                            icon: 'cloud_upload',
                            contentText: t('spaceDetail.saveAsTemplate'),
                          },
                          ...(R.path(
                            ['active'],
                            R.find(R.propEq('id', sid))(refinedSpaces)
                          ) &&
                          R.path(
                            ['candidate'],
                            R.find(R.propEq('id', sid))(refinedSpaces)
                          ) &&
                          !R.path(['projectInfo', 'locked'], openProject)
                            ? [
                                {
                                  action: openDeactivateSpaceModal,
                                  icon: 'cancel',
                                  contentText: t(
                                    'projectDetail.removeSpaceFromProject'
                                  ),
                                },
                              ]
                            : []),
                        ]}
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Drawer>
  );
};

SortableDrawerMenu.propTypes = {
  data: PropTypes.array.isRequired,
  filterLabels: PropTypes.object.isRequired,
  filterTypes: PropTypes.object.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  saveFilters: PropTypes.func.isRequired,
  projects: PropTypes.object,
  clearFilters: PropTypes.func.isRequired,
  sid: PropTypes.string,
  t: PropTypes.func.isRequired,
  saveSpaceAsTemplate: PropTypes.func,
};

export default withTranslation('translations')(SortableDrawerMenu);
