import * as R from 'ramda';
import * as firebaseApi from '../../api/firebaseApi';

export const UPDATE_PROJECTS_REQUEST = 'UPDATE_PROJECTS_REQUEST';
export const UPDATE_PROJECTS_RESPONSE = 'UPDATE_PROJECTS_RESPONSE';

export const SAVE_PROJECT = 'SAVE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const UPDATE_PROJECTS_ATTR = 'UPDATE_PROJECTS_ATTR';
export const UPDATE_PROJECT_INFO = 'UPDATE_PROJECT_INFO';
export const UPDATE_PROJECT_USERS = 'UPDATE_PROJECT_USERS';
export const SAVE_PROJECT_AS_DRAFT = 'SAVE_PROJECT_AS_DRAFT';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const UPDATE_PROJECT_LOCKED_STATE = 'UPDATE_PROJECT_LOCKED_STATE';

export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';

export const EDIT_PARTICIPANTS = 'EDIT_PARTICIPANTS';

const updateProjectsRequest = {
  type: UPDATE_PROJECTS_REQUEST,
};

const updateProjectsResponse = projects => ({
  type: UPDATE_PROJECTS_RESPONSE,
  projects,
});

let projectsUnsubscribe;

/**
 * Subscribe to projects
 * Automatically stay updated about projects
 * @param {*} params 
 */
export const subscribeToProjects = params => (dispatch) => {
  const isAdmin = R.path(['isAdmin'], params);
  const userId = R.path(['uid'], params);
  if (isAdmin === true) {
    dispatch(updateProjectsRequest);
    projectsUnsubscribe = firebaseApi.subscribeToProjects((projects) => {
      dispatch(updateProjectsResponse(projects));
    });
  } else {
    dispatch(updateProjectsRequest);
    projectsUnsubscribe = firebaseApi.subscribeToUserProjects((projects) => {
      dispatch(updateProjectsResponse(projects));
    }, userId);
  }
};

/**
 * Unsubscription from projects
 */
export const unsubscribeFromProjects = () => () => {
  if (projectsUnsubscribe) projectsUnsubscribe();
};

/**
 * Update project attributes from RFI form builder
 * @param {*} doc 
 * @param {*} items 
 * @param {*} cat 
 * @param {*} t           Translation helper
 */
export const updateProjectsAttr = (doc, items, cat, t) => dispatch => firebaseApi
  .updateProjectAttr(doc, items, cat)
  .then(results =>
    Promise.all([
      dispatch({
        type: UPDATE_PROJECTS_ATTR,
        projectDetails: results,
      }),
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          key: new Date().getTime() + Math.random(),
          message: t('snackbarNotifications.saveSuccess'),
          options: {
            variant: 'success',
          },
        },
      }),
    ]))
  .catch(error =>
    Promise.all([
      dispatch({
        type: UPDATE_PROJECTS_ATTR,
        error,
      }),
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          key: new Date().getTime() + Math.random(),
          message: t('snackbarNotifications.saveFailure'),
          options: {
            variant: 'error',
          },
        },
      }),
    ]));

/**
 * Update project user status
 * Will update the user status within the specified project to the provided new state (0-4 as a number)
 * @param {*} pid         Project ID
 * @param {*} uid         User ID
 * @param {*} newState    New user status (0-4)
 */
export const updateProjectUserStatus = (pid, uid, newState) => dispatch =>
  firebaseApi.updateProjectUserStatus(pid, uid, newState)
    .then(() =>
      Promise.all([
        dispatch({
          type: UPDATE_USER_STATUS,
        }),
      ]))
    .catch(error =>
      Promise.all([
        dispatch({
          type: UPDATE_USER_STATUS,
          error,
        }),
      ]));

/**
 * Save project as a draft
 * Will save the spaces to the project but not mark them as active
 * @param {*} spacesContent           Spaces that user has in the project
 * @param {*} t                       Translation helper
 */
export const saveProjectAsDraft = (spacesContent, t) => (dispatch, getState) => {
  const attachedSpacesCurrentState = R.path(['space', 'attachedSpaces'], getState());

  firebaseApi.updateProjectSpaces(spacesContent)
    .then(() => {
      // Check if we need to update the space location object
      // If city or address is changed we have to fetch it again
      // Also if the location object is missing we will do the same thing

      const updateLocationIfNeeded = (value, key) => {
        const spaceToCompareWith = R.path([key], spacesContent);
        if (
          R.path(['attr', 'city'], value) !== R.path(['attr', 'city'], spaceToCompareWith)
          ||
          R.path(['attr', 'address'], value) !== R.path(['attr', 'address'], spaceToCompareWith)
          ||
          !R.path([key, 'location'], spacesContent)
        ) {
          const city = R.path([key, 'attr', 'city'], spacesContent);
          const address = R.path([key, 'attr', 'address'], spacesContent);

          if (city && address) {
            dispatch(updateAttachedSpaceLocation(key, address, city));
          }
        }
      };
      R.forEachObjIndexed(updateLocationIfNeeded, attachedSpacesCurrentState);

      Promise.all([
        dispatch({
          type: SAVE_PROJECT_AS_DRAFT,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch((error) => {
      Promise.all([
        dispatch({
          type: SAVE_PROJECT_AS_DRAFT,
          error,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]);
    });
};

/**
 * Update project locked state
 * Basically a switch to change project from locked to unlocked or vice versa
 * @param {*} pid                 Project ID
 * @param {boolean} checked       Locked 
 * @param {*} t                   Translation helper
 */
export const updateProjectLockedState = (pid, checked, t) => dispatch =>
  firebaseApi.updateProjectLockedState(pid, checked)
    .then(() => {
      Promise.all([
        dispatch({
          type: UPDATE_PROJECT_LOCKED_STATE,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch((error) => {
      Promise.all([
        dispatch({
          type: UPDATE_PROJECT_LOCKED_STATE,
          error,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]);
    });


/**
 *  Save project
 *  Will get all project related spaces as spacesContent from form reducer
 *  Will filter out all spaces that are not marked for sending with the RFI response
 *  Will change the space status to active on those spaces
 *  Will send those spaces to firebase for update
 * @param {array} selectedSpaces       Contains all selected spaces as an array
 * @param {number} projectStatus
 * @param {number} projectUserState    User state in the project as a number (0-4)
 * @param {string} uid                 User ID
 * @param {sting} pid                  Project ID     
 */
export const saveProject = (selectedSpaces, projectStatus, projectUserState, uid, pid, t) => (dispatch, getState) => {
  const spacesContent = R.path(['form', 'project', 'values'], getState());
  const attachedSpacesCurrentState = R.path(['space', 'attachedSpaces'], getState());

  const spacesToBeSent = R.pick(selectedSpaces, spacesContent);
  const changeSpaceToActiveState = item => item.status = projectStatus;
  R.map(changeSpaceToActiveState, spacesToBeSent);

  firebaseApi.updateProjectSpaces(spacesToBeSent)
    .then(() => {
      // Check if we need to update the space location object
      // If city or address is changed we have to fetch it again
      // Also if the location object is missing we will do the same thing

      const updateLocationIfNeeded = (value, key) => {
        const spaceToCompareWith = R.path([key], spacesToBeSent);
        if (
          R.path(['attr', 'city'], value) !== R.path(['attr', 'city'], spaceToCompareWith)
          ||
          R.path(['attr', 'address'], value) !== R.path(['attr', 'address'], spaceToCompareWith)
          ||
          !R.path([key, 'location'], spacesToBeSent)
        ) {
          const city = R.path([key, 'attr', 'city'], spacesToBeSent);
          const address = R.path([key, 'attr', 'address'], spacesToBeSent);

          if (city && address) {
            dispatch(updateAttachedSpaceLocation(key, address, city));
          }
        }
      };
      R.forEachObjIndexed(updateLocationIfNeeded, attachedSpacesCurrentState);

      // Get the amount of spaces user is submitting which are considered candidate and active
      const isActiveAndCandidate = item => { return R.propEq('candidate', true)(item) && R.propEq('active', true)(item)} 
      const activeAndCandidateSpaces = R.filter(isActiveAndCandidate, spacesToBeSent);
      const activeAndCandidateSpacesAmount = R.keys(activeAndCandidateSpaces).length;

      // Update user status to "submitted" if user state is not as "submitted" already and user is submitting spaces marked as candidate & active
      if(projectUserState < 4 && activeAndCandidateSpacesAmount > 0){
        dispatch(updateProjectUserStatus(pid, uid, 4));
      }

      Promise.all([
        dispatch({
          type: SAVE_PROJECT,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch((error) => {
      Promise.all([
        dispatch({
          type: SAVE_PROJECT,
          error,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]);
    });
};

/**
 * Edit project participants
 * Compare current users against form values
 * If user already exists, do not alter user status
 * If user does not exist yet, add it with newPartipantStatus
 * If user exists but has value false in the form values, filter it out as it has been removed from the participants list
 * @param {obj} userlist           Form values of all users with true or false as value based on checkbox checked value
 * @param {string} doc             Project id
 * @param {string} projectStatus   Project status
 * @param {func} t                 Translate function
 */
export const editProjectParticipants = (userlist, doc, projectStatus, t) => (dispatch, getState) => {
  const projectCurrentUsers = R.path(['project', 'projects', doc, 'users'], getState());

  const newParticipantStatus = 0;

  const updatedUsers =
  R.map(
    userStatus =>
      (userStatus === true ? newParticipantStatus : userStatus),
    R.filter(userValue => userValue !== false, R.mergeWith(
      (currUser, formValuesUser) => formValuesUser && currUser,
      projectCurrentUsers,
      userlist,
    )),
  );

  firebaseApi.updateProjectUsers(updatedUsers, doc)
    .then(() => {
      Promise.all([
        dispatch({
          type: EDIT_PARTICIPANTS,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveSuccess'),
            options: {
              variant: 'success',
            },
          },
        }),
      ]);
    })
    .catch((error) => {
      Promise.all([
        dispatch({
          type: EDIT_PARTICIPANTS,
          error,
        }),
        dispatch({
          type: 'ENQUEUE_SNACKBAR',
          notification: {
            key: new Date().getTime() + Math.random(),
            message: t('snackbarNotifications.saveFailure'),
            options: {
              variant: 'error',
            },
          },
        }),
      ]);
    });
};

/**
 * Update project
 * @param {*} values 
 * @param {*} hasNewStatus 
 * @param {*} pid 
 * @param {*} navigate 
 * @param {*} t 
 */
export const updateProject = (values, hasNewStatus, pid, navigate, t) => (dispatch, getState) => {
  const users = R.path(['user', 'users'], getState());
  const contactId = R.path(['projectInfo', 'contactPerson'], values);
  const contact = R.pathOr({}, [[contactId], 'profile'], users);
  const refinedValues = { ...values };

  R.omit(['clientVisibleToTenrepOnly'], refinedValues);

  // Collect only the values needed for later displaying contact person info to prevent the "custom moment object error"
  // which originates from trying to update the project info which contactpersoninfo which contains to contact person createdDate
  // which is unnecessary to store inside the projectinfo
  const contactRefined = {
    firstname: R.pathOr('', ['firstname'], contact),
    lastname: R.pathOr('', ['lastname'], contact),
    email: R.pathOr('', ['email'], contact),
    phone: R.pathOr('', ['phone'], contact),
  };

  refinedValues.projectInfo.contactPersonInfo = contactRefined;
  refinedValues.projectInfo.contactPersonName = `${R.pathOr('', ['firstname'], contact)} ${R.pathOr('', ['lastname'], contact)}`;
  if (!pid) {
    firebaseApi
      .createProject(refinedValues)
      .then((results) => {
        const { id } = results;

        firebaseApi
          .updateClient(id, { client: R.path(['clientVisibleToTenrepOnly'], values) })
          .then(() => dispatch({
            type: UPDATE_CLIENT,
          }))
          .catch(error => dispatch({
            type: `${UPDATE_CLIENT}_FAILURE`,
            error,
          }));

        navigate(`/project/${id}`);
        Promise.all([
          dispatch({
            type: CREATE_PROJECT,
          }),
          dispatch({
            type: 'ENQUEUE_SNACKBAR',
            notification: {
              key: new Date().getTime() + Math.random(),
              message: t('snackbarNotifications.saveSuccess'),
              options: {
                variant: 'success',
              },
            },
          }),
        ]);
      })
      .catch(error =>
        Promise.all([
          dispatch({
            type: `${CREATE_PROJECT}_FAILURE`,
            error,
          }),
          dispatch({
            type: 'ENQUEUE_SNACKBAR',
            notification: {
              key: new Date().getTime() + Math.random(),
              message: t('snackbarNotifications.saveFailure'),
              options: {
                variant: 'error',
              },
            },
          }),
        ]));
  } else {
    firebaseApi
      .updateClient(pid, { client: R.path(['clientVisibleToTenrepOnly'], values) })
      .then(() => dispatch({
        type: UPDATE_CLIENT,
      }))
      .catch(error => dispatch({
        type: `${UPDATE_CLIENT}_FAILURE`,
        error,
      }));

    firebaseApi
      .updateProjectInfo(pid, R.path(['projectInfo'], refinedValues))
      .then(() =>
        Promise.all([
          dispatch({
            type: UPDATE_PROJECT_INFO,
          }),
          dispatch({
            type: 'ENQUEUE_SNACKBAR',
            notification: {
              key: new Date().getTime() + Math.random(),
              message: t('snackbarNotifications.saveSuccess'),
              options: {
                variant: 'success',
              },
            },
          }),
        ]))
      .catch(error =>
        Promise.all([
          dispatch({
            type: `${UPDATE_PROJECT_INFO}_FAILURE`,
            error,
          }),
          dispatch({
            type: 'ENQUEUE_SNACKBAR',
            notification: {
              key: new Date().getTime() + Math.random(),
              message: t('snackbarNotifications.saveFailure'),
              options: {
                variant: 'error',
              },
            },
          }),
        ]));
  }
};

/**
 * Update attached space location
 * Will contact GMAPS api and fetch distance matrix data based on address and city provided
 * @param {*} sid         Space ID
 * @param {*} address     Address
 * @param {*} city        City
 */
export const updateAttachedSpaceLocation = (sid, address, city) => (dispatch) => {
  const parsedAddress = address.replace(' ', '+');

  const params = {
    id: sid,
    address,
    city,
  };

  return fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${parsedAddress}+${city},+CA&key=AIzaSyArA87_GnYSpWbiBXafbI1rw06DRqShJ60`)
    .then(response => response.json())
    .then(data => firebaseApi.updateAttachedSpaceLocation(data, params))
    .then(() => firebaseApi.getDistanceToPlace(params, 'train_station', 'distanceToTrain'))
    .then(() => firebaseApi.getDistanceToPlace(params, 'bus_station', 'distanceToBus'))
    .then(() => firebaseApi.getDistanceToPlace(params, 'light_rail_station', 'distanceToTram'))
    .then(() => firebaseApi.getDistanceToPlace(params, 'subway_station', 'distanceToMetro'));
};

