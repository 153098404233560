import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Link } from 'react-router-dom';

import { withRouter } from '../utils/withRouter';
import { withTranslation } from 'react-i18next';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import CardWrapper from '../components/common/CardWrapper';
import FormEditor from '../components/NewProject/FormEditor';
import ProjectInfo from './ProjectInfo';
import CreateAttr from '../components/NewProject/CreateAttr';
import AttachedSpaces from '../components/TenRepProject/AttachedSpaces';
import NoAccess from '../components/navigation/NoAccess';
import Participants from '../components/NewProject/Participants';
import TogglePresentation from '../components/Presentation/TogglePresentation';
import Notes from './Notes';

import MainPictureManagement from '../components/fileManagement/MainPictureManagement';

import styles from './ProjectDetailView.module.scss';

function TabContainer({ children }) {
  return <Typography component="div">{children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.object.isRequired,
};

const inlineStyles = {
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
    backgroundColor: '#fff',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
    height: '5px',
  },
};

let ProjectDetailView = ({
  projects,
  admin,
  pid,
  attr,
  isAdmin,
  templates,
  navigate,
  projectImageUrl,
  t,
}) => {
  const [chosenTab, setChosenTab] = useState(0);

  const handleTabChange = (event, value) => {
    setChosenTab(value);
    navigate(`/project/${pid}`);
  };

  const attributes = R.values(attr);

  return admin && projects[pid] ? (
    <CardWrapper
      name={R.path([pid, 'projectInfo', 'name'], projects)}
      imageUrl={projectImageUrl}
      headline
      transparent
      imageFallbackComponent
      mainPictureManagementComponent={
        <MainPictureManagement
          allowMultipleFiles={false}
          documentId={pid}
          files={R.path(['mainImage'], projects[pid])}
          collection="projects"
          shadow
        />
      }
      mainPictureManagementComponentReplaceImage={
        <MainPictureManagement
          allowMultipleFiles={false}
          replaceImage
          documentId={pid}
          files={R.path(['mainImage'], projects[pid])}
          collection="projects"
          shadow
        />
      }
    >
      <div className={styles.presentationBtnContainer}>
        <Link to={`/presentation/${pid}`} target="_blank">
          <TogglePresentation
            pid={pid}
            label={t('projectDetailView.presentationView')}
          />
        </Link>
      </div>
      <Tabs
        value={chosenTab}
        onChange={handleTabChange}
        sx={inlineStyles.tabsRoot}
        TabIndicatorProps={{ sx: inlineStyles.tabsIndicator }}
      >
        <Tab
          style={{ color: '#222' }}
          key="Project info"
          label={t('projectDetailView.projectInfo')}
          className={styles.uninclude}
        />
        <Tab
          style={{ color: '#222' }}
          key="Notes"
          label={t('projectDetailView.notes')}
          className={styles.uninclude}
        />
        <Tab
          style={{ color: '#222' }}
          key="Create attributes"
          label={t('projectDetailView.createAttributes')}
          className={styles.uninclude}
        />
        <Tab
          style={{ color: '#222' }}
          key="Form editor"
          label={t('projectDetailView.formEditor')}
          className={styles.uninclude}
        />
        <Tab
          style={{ color: '#222' }}
          key="Attached spaces"
          label={t('projectDetailView.attachedSpaces')}
          className={styles.uninclude}
        />
        <Tab
          style={{ color: '#222' }}
          key="Declined spaces"
          label={t('projectDetailView.declinedSpaces')}
          className={styles.uninclude}
        />
        <Tab
          style={{ color: '#222' }}
          key="Participants"
          label={t('projectDetailView.participants')}
          className={styles.uninclude}
        />
      </Tabs>

      {chosenTab === 0 ? (
        <TabContainer id="test" key="Project info" className="printUnInclude">
          <ProjectInfo pid={pid} form={pid} />
        </TabContainer>
      ) : null}
      {chosenTab === 1 ? (
        <TabContainer key="Notes" className="printUnInclude">
          <Notes pid={pid} />
        </TabContainer>
      ) : null}
      {chosenTab === 2 ? (
        <TabContainer key="Create attributes" className="printUnInclude">
          <CreateAttr />
        </TabContainer>
      ) : null}
      {chosenTab === 3 ? (
        <TabContainer key="Form editor" className="printUnInclude">
          <div className={styles.formBuilderContainer}>
            <FormEditor
              attrArr={attributes}
              rfi={R.path([pid, 'attr'], projects)}
              doc={pid}
            />
          </div>
        </TabContainer>
      ) : null}
      {chosenTab === 4 ? (
        <TabContainer key="Attached spaces" className="printInclude">
          <AttachedSpaces
            showActiveSpaces
            pid={pid}
            templates={templates}
            isAdmin={isAdmin}
            navigate={navigate}
            openProject={R.path([pid], projects)}
          />
        </TabContainer>
      ) : null}
      {chosenTab === 5 ? (
        <TabContainer key="Declined spaces" className="printInclude">
          <AttachedSpaces
            showActiveSpaces={false}
            pid={pid}
            templates={templates}
            isAdmin={isAdmin}
            navigate={navigate}
            openProject={R.path([pid], projects)}
          />
        </TabContainer>
      ) : null}
      {chosenTab === 6 ? (
        <TabContainer key="Participants" className="printUnInclude">
          <Participants project={R.path([pid], projects)} doc={pid} />
        </TabContainer>
      ) : null}
    </CardWrapper>
  ) : (
    <NoAccess />
  );
};

const mapStateToProps = (state, ownProps) => {
  const pid = R.path(['router', 'params', 'projectId'], ownProps);
  const projectFiles = R.path(['project', 'projects', pid, 'mainImage'], state);
  const projectMainPictureKeys = R.keys(projectFiles);
  const projectImageUrl =
    R.prop('length', projectMainPictureKeys) > 0 &&
    R.path([R.head(projectMainPictureKeys), 'resizedPath'], projectFiles);

  return {
    projects: R.path(['project', 'projects'], state),
    attr: R.path(['attr', 'attr'], state),
    admin: R.path(['user', 'admin'], state),
    isAdmin: R.path(['user', 'admin'], state),
    pid,
    projectImageUrl,
  };
};

ProjectDetailView = withRouter(
  connect(mapStateToProps, null)(ProjectDetailView)
);

export default withTranslation('translations')(ProjectDetailView);
