import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import { withTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

const inlineStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  inputLabel: {
    fontSize: '0.8em',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginBottom: '20px',
  },
  formControlCheckBox: {
    marginLeft: '20px',
  },
  selectEmpty: {
    marginTop: theme.spacing(1) * 2,
  },
});

const getDropdownContents = (items, category) => {
  // Return a unique array of not null flattened categories which are present on the items array of objects
  const list = R.uniq(
    R.flatten(
      Array.from(items)
        .filter(item => !!R.path([category], item))
        .map(item => R.path([category], item))
    )
  );

  list.map(item => (
    <MenuItem key={`${item}`} value={item}>
      {item}
    </MenuItem>
  ));

  return list.map(item => (
    <MenuItem key={`${item}`} value={item}>
      {item}
    </MenuItem>
  ));
};

const ProjectFilters = props => {
  const {
    handleChange,
    handleChangeCheckbox,
    searchTerms,
    data,
    filterLabels,
    filterTypes,
    reverseCheckboxOperation,
    t,
  } = props;
  const theme = useTheme();
  const classes = inlineStyles(theme);
  return (
    <div style={classes.root}>
      {Object.keys(filterLabels).map((item, index) => {
        if (filterTypes[item] === 'select') {
          return (
            <FormControl
              sx={{ ...classes.formControl, m: 1, minWidth: 120 }}
              key={`${item}${index}`}
              variant="standard"
            >
              <InputLabel sx={classes.inputLabel} htmlFor={`${item}-simple`}>
                {filterLabels[item]}
              </InputLabel>
              <Select
                value={searchTerms[item] ? searchTerms[item] : ''}
                onChange={e => handleChange(e)}
                inputProps={{
                  name: item,
                  id: `${item}-simple`,
                }}
                sx={classes.selectEmpty}
              >
                <MenuItem value="">
                  <em>{t('common.all')}</em>
                </MenuItem>
                {getDropdownContents(data, item)}
              </Select>
            </FormControl>
          );
        } else if (filterTypes[item] === 'checkbox') {
          return (
            <FormControlLabel
              key={`${item}${index}`}
              sx={classes.formControlCheckBox}
              control={
                <Checkbox
                  checked={
                    reverseCheckboxOperation
                      ? !(
                          searchTerms[item] === '' ||
                          searchTerms[item] === undefined
                        )
                      : searchTerms[item] === '' ||
                        searchTerms[item] === undefined
                  }
                  onChange={handleChangeCheckbox(item)}
                  value={item}
                  sx={classes.root}
                />
              }
              label={
                <Typography style={{ fontSize: '.8em' }}>
                  {filterLabels[item]}
                </Typography>
              }
            />
          );
        }
        return null;
      })}
    </div>
  );
};

ProjectFilters.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleChangeCheckbox: PropTypes.func.isRequired,
  searchTerms: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  filterLabels: PropTypes.object.isRequired,
  filterTypes: PropTypes.object.isRequired,
};

export default withTranslation('translations')(ProjectFilters);
