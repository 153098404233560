import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import NewsecTheme from './components/NewSecTheme';

import React from 'react';
import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';
import AppContainer from './AppContainer';
import { unregister } from './registerServiceWorker';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import { store, persistor } from './store/store';

import './i18n';

import './index.scss';
import 'react-quill/dist/quill.snow.css';

Sentry.init({
  dsn: 'https://6f0f1b693673408abe8f46986c66b95b@sentry.io/1483714',
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <NewsecTheme>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppContainer />
      </PersistGate>
    </Provider>
  </NewsecTheme>
);
unregister();
