import React from 'react';
import PropTypes from 'prop-types';

import ReactQuill from 'react-quill';

import * as moment from 'moment';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { withTranslation } from 'react-i18next';
import { styled } from '@mui/material';

const StyledReactQuill = styled(props => (
  <ReactQuill {...props} theme={null} />
))`
  & .ql-editor {
    padding: 0px;
    padding-bottom: 20px;
    font-family: 'SourceSerifPro', 'Georgia';
    font-size: 0.7rem;
  }
`;

const RFILetterRow = ({
  label,
  content,
  variant,
  color,
  deadline,
  editorPreview,
  classes,
  preview,
  fileManagement,
  t,
}) => (
  <Grid item sm={12}>
    <Grid
      direction="row"
      spacing={3}
      container
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      {!preview && (
        <Grid item md={3} sm={4}>
          <Typography
            style={{ color: '#999', fontWeight: 'bold', display: 'block' }}
            align="right"
            variant={variant}
          >
            {label}
          </Typography>
        </Grid>
      )}
      <Grid item md={9} sm={8}>
        {editorPreview ? (
          <StyledReactQuill
            id={`quill-${label}`}
            defaultValue={content}
            style={{ padding: '0' }}
            readOnly
          />
        ) : (
          <Typography
            align="left"
            variant={variant}
            color={color}
            style={{
              marginBottom: variant === 'h5' ? '20px' : '',
              paddingLeft: preview && '24px',
              paddingBottom: preview && '20px',
            }}
          >
            {variant !== 'h4' && variant !== 'h5' && !deadline && (
              <span style={{ display: 'block' }}>{content}</span>
            )}
            {(variant === 'h4' || variant === 'h5') && !deadline && (
              <b>{content}</b>
            )}
            {deadline && (
              <span style={{ display: 'block' }}>
                <b>
                  {t('rfiLetter.requestAnswer', {
                    deadline: moment(content).format('DD.MM.YYYY'),
                  })}
                </b>
              </span>
            )}
          </Typography>
        )}
      </Grid>
    </Grid>
  </Grid>
);

export default withTranslation('translations')(RFILetterRow);

RFILetterRow.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  deadline: PropTypes.string,
  t: PropTypes.func,
};
